import { Field, Formik, Form } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { RxTriangleDown } from "react-icons/rx";
import Select from "react-select";
import { TASK_URLS } from "../../task-management/constants/TaskUrls";
import { toast } from "react-toastify";
import { TASK_CONSTANTS } from "../../task-management/constants/task-constants";
import { axiosService } from "services/axios.service";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { ROLE_URLS } from "modules/user-management/components/role-management/constants/user-role-urls";
import { ThemeContext } from "ThemeContext";

function CreateTaskModal({ selectedLead, open, onClose }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [taskOwnerOptions, setTaskOwnerOptions] = useState([]);
  const [taskOwner, setTaskOwner] = useState();

  const { theme, toggleTheme } = useContext(ThemeContext);

  useEffect(() => {
    axiosService
      .get(`${ROLE_URLS.FACULTY_GET_URL}`)
      .then((response) => {
        const user = response?.data?.data;
        const taskOwnerOptions = user.map((user) => ({
          value: user.firstName,
          label: user.firstName,
          taskOwnerObject: user,
        }));

        if (taskOwnerOptions) {
          setTaskOwnerOptions(taskOwnerOptions);
        }
      })

      .catch(() => {});
  }, [location.pathname]);

  const Priority = [
    { value: "LOW", label: "LOW" },
    { value: "MEDIUM", label: "MEDIUM" },
    { value: "HIGH", label: "HIGH" },
  ];
  const PriorityStatus = [
    { value: "ACTIVE", label: "ACTIVE" },
    { value: "WARM", label: "WARM" },
    { value: "COLD", label: "COLD" },
    { value: "CLOSEDWON", label: "CLOSED-WON" },
    { value: "CLOSEDLOST", label: "CLOSED-LOST" },
  ];

  const initialValues = {
    taskOwner: "",
    subject: "",
    relatedTo: selectedLead,
    dueDate: "",
    status: "",
    priority: "",
  };

  const onSubmit = async (values) => {
    try {
      let requestData = {
        taskOwner: taskOwner,
        subject: values.subject,
        relatedTo: selectedLead,
        dueDate: values.dueDate,
        status: values.status,
        priority: values.priority,
      };
      if (
        (values.relatedTo === "", values.status === "", values.priority === "")
      ) {
        toast.error("Mandatory fields are required!");
      } else {
        const response = await axiosService.post(
          `${TASK_URLS.CREATE_TASKS}`,
          requestData,
          {
            headers: { "Content-Type": "application/json" },
          }
        );

        if (response.status === 200) {
          toast.success(TASK_CONSTANTS.SUCCESS);
          navigate("/lead-management/view-task");
        } else {
          toast.error(TASK_CONSTANTS.FAILED);
        }
      }
    } catch (error) {
      toast.error(TASK_CONSTANTS.ERROR_OCCUR);
    }
  };

  return (
    <Modal
      className="crm-modal"
      isOpen={open}
      onRequestClose={() => onClose()}
      shouldReturnFocusAfterClose={false}
    >
      <div className="modal-Con">
        <div
          className="col-11 col-md-7 p-3"
          style={{
            height: "auto",
            backgroundColor: theme === "light" ? "#F5F5F5" : "#212226",
          }}
        >
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={onSubmit}
          >
            {({ values }) => (
              <Form>
                <div className="pb-4">
                  <div className="user-head-container flex-row gap-2 pt-0">
                    <div className="d-flex align-items-center gap-1">
                      <span className="user-title-text">Create Task</span>
                    </div>
                    <div className="d-flex align-items-center gap-3">
                      <button
                        className="user-save-head-button"
                        type="button"
                        onClick={() => onClose()}
                      >
                        Close
                      </button>
                      <button className="user-head-button" type="submit">
                        Save
                      </button>
                    </div>
                  </div>
                  <div className="task-grid-container">
                    <div style={{ marginTop: "15px" }}>
                      <span className="task-information">Task Information</span>
                    </div>
                    <div className="row" style={{ marginTop: "20px" }}>
                      <div className="col-12 col-md-6 col-lg-6  d-flex flex-column">
                        <label className="task-label-name">Task Owner *</label>

                        <Field
                          as="select"
                          name="taskOwner"
                          render={({ field, form }) => (
                            <Select
                              value={taskOwnerOptions.find(
                                (option) => option.value === field.value
                              )}
                              onChange={(option) => {
                                form.setFieldValue(field.name, option.value);
                                setTaskOwner(option?.taskOwnerObject);
                              }}
                              options={taskOwnerOptions}
                              isSearchable={false}
                              classNamePrefix="react-select-big"
                              components={{
                                DropdownIndicator: () => (
                                  <RxTriangleDown color="#000000" />
                                ),
                                IndicatorSeparator: null,
                              }}
                              placeholder="Choose a Contact"
                              menuPlacement="auto"
                              menuPosition="fixed"
                            />
                          )}
                        />
                      </div>
                      <div className="col-12 col-md-6 col-lg-6  d-flex flex-column">
                        <label className="task-label-name">Subject</label>
                        <Field
                          className=" task-subject-input col-8 col-md-10"
                          placeholder="Add a subject to task"
                          name="subject"
                        />
                      </div>
                      <div className="col-12 col-md-6 col-lg-6  d-flex flex-column">
                        <label className="task-label-name">Due Date</label>
                        <Field
                          type="date"
                          className="task-date-input col-8 col-md-10"
                          placeholder="Select a date"
                          name="dueDate"
                        />
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: "20px" }}>
                      <div className="col-12 col-md-6 col-lg-6  d-flex flex-column">
                        <label className="task-label-name">Status *</label>
                        <Field
                          as="select"
                          name="status"
                          render={({ field, form }) => (
                            <Select
                              value={PriorityStatus.find(
                                (option) => option.value === field.value
                              )}
                              onChange={(option) =>
                                form.setFieldValue(field.name, option.value)
                              }
                              options={PriorityStatus}
                              isSearchable={false}
                              classNamePrefix="react-select-big"
                              components={{
                                DropdownIndicator: () => (
                                  <RxTriangleDown color="#000000" />
                                ),
                                IndicatorSeparator: null,
                              }}
                              placeholder="Choose an Option"
                              menuPlacement="top"
                              menuPosition="fixed"
                            />
                          )}
                        />
                      </div>
                      <div className="col-12 col-md-6 col-lg-6  d-flex flex-column">
                        <label className="task-label-name">Priority *</label>
                        <Field
                          as="select"
                          name="priority"
                          render={({ field, form }) => (
                            <Select
                              id="leadOwner"
                              value={Priority.find(
                                (option) => option.value === field.value
                              )}
                              onChange={(option) =>
                                form.setFieldValue(field.name, option.value)
                              }
                              options={Priority}
                              isSearchable={false}
                              classNamePrefix="react-select-big"
                              components={{
                                DropdownIndicator: () => (
                                  <RxTriangleDown color="#000000" />
                                ),
                                IndicatorSeparator: null,
                              }}
                              placeholder="Choose an Option"
                              menuPlacement="auto"
                              menuPosition="fixed"
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
}

export default CreateTaskModal;
