import { RxTriangleDown } from "react-icons/rx";
import "./AddResource.css";
import { Field, Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { useContext, useEffect, useState } from "react";
import { axiosService } from "services/axios.service";
import { toast } from "react-toastify";
import { ROLE_URLS } from "modules/user-management/components/role-management/constants/user-role-urls";
import { TIMETABLE_URLS } from "modules/timetable-management/constants/timetable-constants-url";
import { ThemeContext } from "ThemeContext";

function AddResource() {
  const navigate = useNavigate();

  const location = useLocation();
  const Data = location.state;

  const resourceId = Data?.data?.Data?.resourceId;

  const [resourceData, setResourceData] = useState("");
  const { theme, toggleTheme } = useContext(ThemeContext);

  useEffect(() => {
    if (resourceId) {
      axiosService
        .get(`${TIMETABLE_URLS.GET_RESOURCE_BY_RESOURCE_ID}/${resourceId}`)
        .then((response) => {
          if (response.status === 200) {
            const responseData = response.data.data;
            setResourceData(responseData);
            setResourceAdmin(responseData?.resourceAdmin || null);
          }
        });
    }
  }, [location.pathname, resourceId]);

  const [resourceAdmin, setResourceAdmin] = useState("");

  let initialValues = {
    resourceName: resourceData?.resourceName || "",
    resourceType: resourceData?.resourceType || "",
    campus: resourceData?.campus || "",
    resourceAdmin: resourceData?.resourceAdmin || "",
    seatCapacity: resourceData?.seatCapacity || "",
    blockName: resourceData?.blockName || "",
    floor: resourceData?.floor || "",
    onlineFacility: resourceData?.onlineFacility || "",
    comments: resourceData?.comments || "",
  };

  const ResourceType = [
    { value: "CLASSROOM", label: "ClassRoom" },
    { value: "AUDITORIUM", label: "Auditorium" },
    { value: "TRANSPORT", label: "Transport" },
    { value: "ONLINE", label: "Online" },
  ];
  const Campus = [
    { value: "BRAMPTON", label: "Brampton" },
    { value: "MISSISSAUGA", label: "Mississauga" },
  ];
  const OnlineFacility = [
    { value: "YES", label: "YES" },
    { value: "NO", label: "NO" },
  ];
  const BlockName = [
    { value: "BLOCK1", label: "Block 1" },
    { value: "BLOCK2", label: "Block 2" },
    { value: "BLOCK3", label: "Block 3" },
    { value: "BLOCK4", label: "Block 4" },
    { value: "BLOCK5", label: "Block 5" },
  ];
  const Floor = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
  ];
  const [resourceAdminOptions, setResourceAdminOptions] = useState("");

  useEffect(() => {
    axiosService.get(`${ROLE_URLS.GET_FACULTY_URL}`).then((response) => {
      const user = response?.data?.data;

      const resourceAdminOptions = user?.map((user) => ({
        value: user,
        label: user.firstName,
        resourceAdminObject: user,
      }));
      if (resourceAdminOptions) {
        setResourceAdminOptions(resourceAdminOptions);
      }
    });
  }, []);

  const isSaveButtonDisabled = (values) => {
    if (values.resourceType !== "TRANSPORT") {
      return (
        values.resourceName === "" ||
        values.resourceType === "" ||
        values.campus === "" ||
        resourceAdmin === "" ||
        values.seatCapacity === "" ||
        values.blockName === "" ||
        values.floor === "" ||
        values.onlineFacility === ""
      );
    } else {
      return (
        values.resourceName === "" ||
        values.resourceType === "" ||
        values.campus === "" ||
        resourceAdmin === "" ||
        values.seatCapacity === ""
      );
    }
  };

  const onSubmit = async (formValues) => {
    const requestData = {
      resourceName: formValues.resourceName,
      resourceType: formValues.resourceType,
      campus: formValues.campus,
      resourceAdmin: formValues.resourceAdmin,
      seatCapacity: formValues.seatCapacity,
      comments: formValues.comments,
      blockName: null,
      floor: "",
      onlineFacility: null,
    };
    if (formValues.resourceType === "TRANSPORT") {
      requestData.blockName = null;
      requestData.floor = "";
      requestData.onlineFacility = null;
    } else {
      requestData.blockName = formValues.blockName;
      requestData.floor = formValues.floor;
      requestData.onlineFacility = formValues.onlineFacility;
    }

    try {
      if (resourceData) {
        requestData.resourceId = resourceData.resourceId;
        const response = await axiosService.put(
          `${TIMETABLE_URLS.ADD_RESOURCES}`,
          requestData
        );

        if (response.status === 200) {
          toast.success("Updated successfully");
          navigate("/timetable-management/all-resources");
        } else {
          toast.error("update failed");
        }
      } else {
        const response = await axiosService.post(
          `${TIMETABLE_URLS.ADD_RESOURCES}`,
          requestData
        );
        if (response.status === 200) {
          toast.success("Saved successfully");
          navigate("/timetable-management/all-resources");
        } else {
          toast.error("failed");
        }
      }
    } catch (error) {
      toast.error(error);
    }
  };
  return (
    <div className="add-resoure-main-container custom-scrollbar">
      <div className="student-portal-header-container sticky-header top-0 flex-row gap-2">
        <span className="lead-table-title-text">Add New Resources</span>
      </div>

      <div className="mt-2">
        <span style={{ fontSize: "18px", color: "#F69F3A", fontWeight: 400 }}>
          Resource Details
        </span>

        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          {({ values, resetForm, setFieldValue }) => (
            <Form>
              <div className=" pb-4 ps-0">
                <div className="d-flex flex-column gap-3">
                  <div className="column d-flex gap-3 flex-wrap">
                    <div className="add-resoure-input-con">
                      <label className="lead-last-name">Resource Name *</label>
                      <Field
                        className="resource-input w-100"
                        placeholder="Enter Resource Name"
                        name="resourceName"
                      />
                    </div>
                    <div className="add-resoure-input-con">
                      <label className="lead-first-name">Resource Type *</label>
                      <Field
                        className="resource-input w-100"
                        name="resourceType"
                        render={({ field, form }) => (
                          <Select
                            id="resourceType"
                            value={
                              field.value
                                ? ResourceType.find(
                                    (option) => option.value === field.value
                                  )
                                : null
                            }
                            onChange={(option) =>
                              form.setFieldValue(field.name, option.value)
                            }
                            options={ResourceType}
                            isSearchable={false}
                            classNamePrefix="react-select-big"
                            components={{
                              DropdownIndicator: () => (
                                <RxTriangleDown
                                  color={
                                    theme === "light" ? "#000000" : "#ffffff"
                                  }
                                />
                              ),
                              IndicatorSeparator: null,
                            }}
                            placeholder="Choose an Option"
                            menuPlacement="auto"
                            menuPosition="fixed"
                          />
                        )}
                      />
                    </div>
                    <div className="add-resoure-input-con">
                      <label className="lead-first-name">Campus *</label>
                      <Field
                        className=" resource-input w-100"
                        name="campus"
                        render={({ field, form }) => (
                          <Select
                            id="campus"
                            value={
                              field.value
                                ? Campus.find(
                                    (option) => option.value === field.value
                                  )
                                : null
                            }
                            onChange={(option) =>
                              form.setFieldValue(field.name, option.value)
                            }
                            options={Campus}
                            isSearchable={false}
                            classNamePrefix="react-select-big"
                            components={{
                              DropdownIndicator: () => (
                                <RxTriangleDown
                                  color={
                                    theme === "light" ? "#000000" : "#ffffff"
                                  }
                                />
                              ),
                              IndicatorSeparator: null,
                            }}
                            placeholder="Choose an Option"
                            menuPlacement="auto"
                            menuPosition="fixed"
                          />
                        )}
                      />
                    </div>
                    <div className="add-resoure-input-con">
                      <label className="lead-last-name">Resource Admin *</label>
                      <Field
                        className="resource-input w-100"
                        name="resourceAdmin"
                        render={({ field, form }) => (
                          <Select
                            value={
                              field.value
                                ? resourceAdminOptions.find(
                                    (option) =>
                                      option.resourceAdminObject?.firstName ===
                                      field.value?.firstName
                                  )
                                : null
                            }
                            onChange={(option) => {
                              form.setFieldValue(
                                field.name,
                                option?.resourceAdminObject
                              );
                              setResourceAdmin(option?.resourceAdminObject);
                            }}
                            options={resourceAdminOptions}
                            isSearchable={false}
                            classNamePrefix="react-select-big"
                            components={{
                              DropdownIndicator: () => (
                                <RxTriangleDown
                                  color={
                                    theme === "light" ? "#000000" : "#ffffff"
                                  }
                                />
                              ),
                              IndicatorSeparator: null,
                            }}
                            placeholder="Choose an option"
                            menuPlacement="auto"
                            menuPosition="fixed"
                          />
                        )}
                      />
                    </div>
                    <div className="add-resoure-input-con">
                      <label className="lead-last-name">Seat Capacity *</label>
                      <Field
                        className="resource-input w-100"
                        placeholder="Enter capacity"
                        name="seatCapacity"
                      />
                    </div>

                    {values.resourceType != "TRANSPORT" && (
                      <div className="add-resoure-input-con">
                        <label className="lead-first-name">
                          Building/Block Name *
                        </label>
                        <Field
                          className="resource-input w-100"
                          name="blockName"
                          render={({ field, form }) => (
                            <Select
                              id="blockName"
                              value={
                                field.value
                                  ? BlockName.find(
                                      (option) => option.value === field.value
                                    )
                                  : null
                              }
                              onChange={(option) =>
                                form.setFieldValue(field.name, option.value)
                              }
                              options={BlockName}
                              isSearchable={false}
                              classNamePrefix="react-select-big"
                              components={{
                                DropdownIndicator: () => (
                                  <RxTriangleDown
                                    color={
                                      theme === "light" ? "#000000" : "#ffffff"
                                    }
                                  />
                                ),
                                IndicatorSeparator: null,
                              }}
                              placeholder="Choose an Option"
                              menuPlacement="auto"
                              menuPosition="fixed"
                            />
                          )}
                        />
                      </div>
                    )}
                    {values.resourceType != "TRANSPORT" && (
                      <div className="add-resoure-input-con">
                        <label className="lead-first-name">Floor *</label>
                        <Field
                          className="resource-input w-100"
                          name="floor"
                          render={({ field, form }) => (
                            <Select
                              id="floor"
                              value={
                                field.value
                                  ? Floor.find(
                                      (option) => option.value === field.value
                                    )
                                  : null
                              }
                              onChange={(option) =>
                                form.setFieldValue(field.name, option.value)
                              }
                              options={Floor}
                              isSearchable={false}
                              classNamePrefix="react-select-big"
                              components={{
                                DropdownIndicator: () => (
                                  <RxTriangleDown
                                    color={
                                      theme === "light" ? "#000000" : "#ffffff"
                                    }
                                  />
                                ),
                                IndicatorSeparator: null,
                              }}
                              placeholder="Choose an Option"
                              menuPlacement="auto"
                              menuPosition="fixed"
                            />
                          )}
                        />
                      </div>
                    )}
                    {values.resourceType != "TRANSPORT" && (
                      <div className="add-resoure-input-con">
                        <label className="lead-first-name">
                          Online Facility *
                        </label>
                        <Field
                          className="resource-input w-100"
                          name="onlineFacility"
                          render={({ field, form }) => (
                            <Select
                              id="onlineFacility"
                              value={
                                field.value
                                  ? OnlineFacility.find(
                                      (option) => option.value === field.value
                                    )
                                  : null
                              }
                              onChange={(option) =>
                                form.setFieldValue(field.name, option.value)
                              }
                              options={OnlineFacility}
                              isSearchable={false}
                              classNamePrefix="react-select-big"
                              components={{
                                DropdownIndicator: () => (
                                  <RxTriangleDown
                                    color={
                                      theme === "light" ? "#000000" : "#ffffff"
                                    }
                                  />
                                ),
                                IndicatorSeparator: null,
                              }}
                              placeholder="Choose an Option"
                              menuPlacement="auto"
                              menuPosition="fixed"
                            />
                          )}
                        />
                      </div>
                    )}
                    <div className="add-resoure-comment-input-con d-flex flex-column">
                      <label className="lead-last-name">Comments/Remarks</label>
                      <Field
                        className="resource-comment-input"
                        placeholder="Example"
                        name="comments"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-wrap gap-2">
                <div className="d-flex align-items-center gap-1">
                  <div className="d-flex align-items-center gap-3">
                    <button
                      type="button"
                      className="user-head-button"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>

                    <button
                      type="submit"
                      // className="user-save-head-button"
                      className={`user-save-head-button ${
                        isSaveButtonDisabled(values)
                          ? "user-save-head-button-disabled"
                          : ""
                      }`}
                      disabled={isSaveButtonDisabled(values)}
                    >
                      {resourceData ? "Update" : "Save"}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default AddResource;
