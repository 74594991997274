import { Field, Form, Formik } from "formik";
import { useEffect, useState, useContext } from "react";
import { RxTriangleDown } from "react-icons/rx";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { axiosService } from "services/axios.service";
import { ADMISSION_MANAGEMENT_URL } from "../constants/admission-management-urls";
import { toast } from "react-toastify";
import "./CreatePersonalData.css";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { RiCloseCircleFill } from "react-icons/ri";
import AdmissionSideBarFaculty from "../admission-side-bar-faculty/AdmissionSideBarFaculty";
import sessionService from "services/sessionstorage.service";
import { ThemeProvider, ThemeContext } from "../../../../../../ThemeContext";

function CreatePersonalData() {
  const navigation = useNavigate();
  const location = useLocation();
  const data = location?.state?.data;
  const [refresh, setRefresh] = useState(false);
  const [admissionData, setAdmissionData] = useState(null);

  const { theme, toggleTheme } = useContext(ThemeContext);

  const [showRejectButton, setShowRejectButton] = useState(false);
  const [showApproveButton, setShowApproveButton] = useState(false);
  const admissionId = data?.admissionId;
  const userData = sessionService.getUserData();
  const [studentType, setStudentType] = useState(null);
  useEffect(() => {
    if (admissionId) {
      axiosService
        .get(
          `${ADMISSION_MANAGEMENT_URL.GET_ADMISSION_URL_BY_ID}${admissionId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setAdmissionData(data);
          setShowRejectButton(data?.studentPayments[0]?.isApproved != true);
          setShowApproveButton(data?.personalData?.isApproved || false);
          setStudentType(data?.user?.studentType);
        });
    }
  }, [location.pathname, refresh, admissionId]);
  const isApproved = admissionData?.personalData?.isApproved;
  const initialValues = {
    studentId: admissionData?.personalData?.studentId || "",
    email: admissionData?.personalData?.email || "",
    password: admissionData?.personalData?.password || "",
    title: admissionData?.personalData?.title || "",
    firstName: admissionData?.personalData?.firstName || "",
    middleName: admissionData?.personalData?.middleName || "",
    lastName: admissionData?.personalData?.lastName || "",
    sex: admissionData?.personalData?.sex || "",
    maritalStatus: admissionData?.personalData?.maritalStatus || "",
    dateOfBirth: admissionData?.personalData?.dateOfBirth || "",
    nationality: admissionData?.personalData?.nationality || "",
    passportNumber: admissionData?.personalData?.passportNumber || "",
    expiryDate: admissionData?.personalData?.expiryDate || "",
    passportIssuer: admissionData?.personalData?.passportIssuer || "",
    nationalIdNumber: admissionData?.personalData?.nationalIdNumber || "",
    drivingLicenseNumber:
      admissionData?.personalData?.drivingLicenseNumber || "",
    personalId: admissionData?.personalData?.personalId,
    otherMaritalStatus: admissionData?.personalData?.otherMaritalStatus || "",
    osapAccessNumber: admissionData?.personalData?.osapAccessNumber || "",
    studentStatus: admissionData?.personalData?.studentStatus || null,
    prCardNumber: admissionData?.personalData?.prCardNumber || "",
    refugeesDocumentNumber:
      admissionData?.personalData?.refugeesDocumentNumber || "",

    onSubmit: async (values) => {
      navigation("/admission-management/create-mailing-address", {
        state: { data },
      });
    },
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "0 !important",
      width: "230px !important",
    }),
  };

  const MaritalStatusOptions = [
    { value: "MARRIED", label: "Married" },
    { value: "SINGLE", label: "Single" },
    { value: "DIVORCED", label: "Divorced" },
  ];
  const MaritalStatusOSAPOptions = [
    { value: "MARRIED", label: "Married" },
    { value: "SINGLE", label: "Single" },
    { value: "DIVORCED", label: "Divorced" },
    { value: "COMMONLAW", label: "Common Law" },
    { value: "OTHER", label: "Other" },
  ];
  const statusOptions = [
    { value: "CITIZEN", label: "Citizen" },
    { value: "PERMANENTRESIDENT", label: "Permanent Resident" },
    { value: "REFUGEE", label: "Refugee" },
  ];
  const TitleOptions = [
    { value: "Mr", label: "Mr" },
    { value: "Mrs", label: "Mrs" },
    { value: "Ms", label: "Ms" },
    { value: "Dr", label: "Dr" },
  ];

  const sexOption = [
    { value: "MALE", label: "Male" },
    { value: "FEMALE", label: "Female" },
    { value: "PREFERNOTTOSAY", label: "Prefer not to say" },
  ];

  const [showRejectInput, setShowRejectInput] = useState(false);

  const handleApprove = async () => {
    let requestData = admissionData?.personalData;
    requestData.isApproved = true;
    requestData.isRejected = false;

    try {
      const response = await axiosService.put(
        `${ADMISSION_MANAGEMENT_URL.PUT_PERSONAL_DATA}`,
        requestData
      );

      if (response.status === 200) {
        setRefresh(!refresh);
        toast.success("Personal Data Approved Successfully");
        navigation("/admission-management/create-mailing-address", {
          state: { data },
        });
      } else {
      }
    } catch (error) {}
  };
  const [rejectComments, setRejectComments] = useState("");

  const handleReject = async () => {
    let requestData = admissionData?.personalData;
    requestData.isApproved = false;
    requestData.isRejected = true;
    requestData.rejectComments = rejectComments;

    try {
      const response = await axiosService.put(
        `${ADMISSION_MANAGEMENT_URL.PUT_PERSONAL_DATA}`,
        requestData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.status === 200) {
        setRefresh(!refresh);
        toast.error("Personal Data Rejected");
        setShowRejectInput(false);
      } else {
      }
    } catch (error) {}
  };

  const dateString = new Date(admissionData?.personalData?.updatedOn);

  const formattedDate =
    dateString instanceof Date && !isNaN(dateString)
      ? dateString.toLocaleDateString()
      : "Invalid Date";

  const handleRejectButtonClick = () => {
    setShowRejectInput(true);
    setTimeout(() => {
      const commentsContainer = document.getElementById(
        "rejection-comments-container"
      );
      if (commentsContainer) {
        commentsContainer.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 100);
  };
  return (
    <div className="create-admission-main-container">
      <div className="progress-container">
        <div className="create-admission-head-text">
          Application #{admissionData?.programDetails[0].applicationNo}
        </div>
      </div>
      <div className="row create-admission-container g-0">
        <div className="create-admission-inner-left-container col-12 col-lg-3 pt-2">
          <AdmissionSideBarFaculty data={data} />
        </div>
        <div className="create-admission-inner-right-container col-12 col-lg-9 py-2">
          <div className="create-text-title ">Personal Data</div>

          <Formik
            initialValues={admissionData?.personalData || initialValues}
            enableReinitialize={true}
          >
            {() => (
              <Form>
                <div>
                  <div className="create-personal-data-main-input-container">
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">Email</label>

                      <Field
                        disabled={true}
                        className="create-admission-input-big"
                        name="email"
                      />
                    </div>
                  </div>
                  <div className="create-personal-data-main-input-container">
                    <div className="create-admission-inner-first-input-container">
                      <label className="label-text-active">Title </label>
                      <Field
                        disabled={true}
                        name="title"
                        component={({ field, form }) => (
                          <Select
                            isDisabled={true}
                            value={TitleOptions.find(
                              (option) => option.value === field.value
                            )}
                            onChange={(option) =>
                              form.setFieldValue(field.name, option.value)
                            }
                            options={TitleOptions}
                            isSearchable={false}
                            classNamePrefix="react-select"
                            styles={selectStyles}
                            components={{
                              DropdownIndicator: () => (
                                <RxTriangleDown
                                  color={
                                    theme === "light" ? "#000000" : "#ffffff"
                                  }
                                />
                              ),
                              IndicatorSeparator: null,
                            }}
                            placeholder="Choose"
                            menuPlacement="auto"
                            menuPosition="fixed"
                          ></Select>
                        )}
                      />
                    </div>
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">First name *</label>

                      <Field
                        disabled={true}
                        className="create-admission-input-big"
                        name="firstName"
                      />
                    </div>
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">Middle name</label>

                      <Field
                        disabled={true}
                        className="create-admission-input-big"
                        name="middleName"
                      />
                    </div>
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">Last name</label>

                      <Field
                        disabled={true}
                        className="create-admission-input-big"
                        name="lastName"
                      />
                    </div>
                  </div>
                  <div className="create-personal-data-main-input-container">
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">Gender *</label>
                      <Field
                        disabled={true}
                        name="sex"
                        component={({ field, form }) => (
                          <Select
                            isDisabled={true}
                            value={sexOption.find(
                              (option) => option.value === field.value
                            )}
                            onChange={(option) =>
                              form.setFieldValue(field.name, option.value)
                            }
                            options={sexOption}
                            isSearchable={false}
                            classNamePrefix="react-select"
                            styles={selectStyles}
                            components={{
                              DropdownIndicator: () => (
                                <RxTriangleDown
                                  color={
                                    theme === "light" ? "#000000" : "#ffffff"
                                  }
                                />
                              ),
                              IndicatorSeparator: null,
                            }}
                            placeholder="Choose"
                            menuPlacement="auto"
                            menuPosition="fixed"
                          ></Select>
                        )}
                      />
                    </div>
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">
                        Marital Status {studentType === "OSAP" && "*"}
                      </label>

                      <Field
                        name="maritalStatus"
                        disabled={true}
                        component={({ field, form }) => (
                          <>
                            <Select
                              isDisabled={true}
                              options={
                                studentType === "OSAP"
                                  ? MaritalStatusOSAPOptions
                                  : MaritalStatusOptions
                              }
                              value={
                                MaritalStatusOptions.find(
                                  (option) => option.value === field.value
                                ) ||
                                MaritalStatusOSAPOptions.find(
                                  (option) => option.value === field.value
                                )
                              }
                              onChange={(option) =>
                                form.setFieldValue(field.name, option.value)
                              }
                              isSearchable={false}
                              classNamePrefix="react-select"
                              styles={selectStyles}
                              components={{
                                DropdownIndicator: () => (
                                  <RxTriangleDown
                                    color={
                                      theme === "light" ? "#000000" : "#ffffff"
                                    }
                                  />
                                ),
                                IndicatorSeparator: null,
                              }}
                              placeholder="Choose"
                              menuPlacement="auto"
                              menuPosition="fixed"
                            ></Select>
                          </>
                        )}
                      />
                    </div>
                    {admissionData?.personalData?.maritalStatus === "OTHER" && (
                      <div className="create-admission-inner-first-input-container ">
                        <label className="label-text-active">Others *</label>

                        <Field
                          className="create-admission-input-big pre-onboard-create-admission-input-big-lname"
                          name="otherMaritalStatus"
                        />
                      </div>
                    )}
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">
                        Date of Birth *
                      </label>

                      <Field
                        disabled={true}
                        type="date"
                        className="create-admission-input-big"
                        name="dateOfBirth"
                      />
                    </div>
                  </div>
                  {studentType === "OSAP" && (
                    <div className="create-personal-data-main-input-container">
                      <div className="create-admission-inner-first-input-container ">
                        <label className="label-text-active">
                          Access Number
                        </label>
                        <Field
                          name="osapAccessNumber"
                          disabled={true}
                          className="create-admission-input-big pre-onboard-create-admission-input-big-mname"
                        />
                      </div>

                      <div className="create-admission-inner-first-input-container ">
                        <label className="label-text-active">Status *</label>

                        <Field
                          name="studentStatus"
                          disabled={true}
                          component={({ field, form }) => (
                            <>
                              <Select
                                isDisabled={true}
                                options={statusOptions}
                                value={statusOptions.find(
                                  (option) => option.value === field.value
                                )}
                                onChange={(option) =>
                                  form.setFieldValue(field.name, option.value)
                                }
                                isSearchable={false}
                                classNamePrefix="react-select"
                                styles={selectStyles}
                                components={{
                                  DropdownIndicator: () => (
                                    <RxTriangleDown
                                      color={
                                        theme === "light"
                                          ? "#000000"
                                          : "#ffffff"
                                      }
                                    />
                                  ),
                                  IndicatorSeparator: null,
                                }}
                                placeholder="Choose"
                                menuPlacement="auto"
                                menuPosition="fixed"
                              ></Select>
                            </>
                          )}
                        />
                      </div>

                      {admissionData?.personalData?.studentStatus ===
                        "PERMANENTRESIDENT" && (
                        <div className="create-admission-inner-first-input-container ">
                          <label className="label-text-active">
                            PR Number *
                          </label>

                          <Field
                            disabled={true}
                            className="create-admission-input-big pre-onboard-create-admission-input-big-lname"
                            name="prCardNumber"
                          />
                        </div>
                      )}
                      {admissionData?.personalData?.studentStatus ===
                        "REFUGEE" && (
                        <div className="create-admission-inner-first-input-container ">
                          <label className="label-text-active">
                            Refugee Document No. *
                          </label>

                          <Field
                            disabled={true}
                            className="create-admission-input-big pre-onboard-create-admission-input-big-lname"
                            name="refugeesDocumentNumber"
                          />
                        </div>
                      )}
                    </div>
                  )}

                  <div className="create-personal-data-main-input-container">
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">
                        Nationality{" "}
                        {!(
                          studentType === "OSAP" &&
                          (admissionData?.personalData?.studentStatus ===
                            "PERMANENTRESIDENT" ||
                            admissionData?.personalData?.studentStatus ===
                              "REFUGEE")
                        ) && "*"}
                      </label>
                      <Field
                        disabled={true}
                        name="nationality"
                        className="create-admission-input-big"
                      />
                    </div>
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">
                        Passport Number{" "}
                        {!(
                          studentType === "OSAP" &&
                          (admissionData?.personalData?.studentStatus ===
                            "PERMANENTRESIDENT" ||
                            admissionData?.personalData?.studentStatus ===
                              "REFUGEE")
                        ) && "*"}
                      </label>

                      <Field
                        disabled={true}
                        className="create-admission-input-big"
                        name="passportNumber"
                      />
                    </div>
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">
                        Expiry Date{" "}
                        {!(
                          studentType === "OSAP" &&
                          (admissionData?.personalData?.studentStatus ===
                            "PERMANENTRESIDENT" ||
                            admissionData?.personalData?.studentStatus ===
                              "REFUGEE")
                        ) && "*"}
                      </label>

                      <Field
                        disabled={true}
                        type="date"
                        className="create-admission-input-big"
                        name="expiryDate"
                      />
                    </div>
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">
                        Passport Issuer
                      </label>

                      <Field
                        disabled={true}
                        className="create-admission-input-big"
                        name="passportIssuer"
                      />
                    </div>
                  </div>

                  <div className="create-personal-data-main-input-container">
                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">
                        National ID Number
                      </label>

                      <Field
                        disabled={true}
                        className="create-admission-input-big"
                        name="nationalIdNumber"
                      />
                    </div>

                    <div className="create-admission-inner-first-input-container ">
                      <label className="label-text-active">
                        Driving License Number
                      </label>

                      <Field
                        disabled={true}
                        className="create-admission-input-big"
                        name="drivingLicenseNumber"
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex gap-3 my-3 flex-wrap">
                  <button
                    type="submit"
                    className="create-admission-save-button"
                    onClick={() =>
                      navigation(
                        "/admission-management/create-mailing-address",
                        {
                          state: { data },
                        }
                      )
                    }
                  >
                    Next
                  </button>
                  {showRejectButton && (
                    <button
                      type="button"
                      className="create-admission-previous-button"
                      onClick={handleRejectButtonClick}
                    >
                      Reject
                    </button>
                  )}
                  {!isApproved && (
                    <button
                      type="button"
                      className="create-admission-save-button"
                      onClick={handleApprove}
                    >
                      Approve & Next
                    </button>
                  )}
                </div>
                <div>
                  {admissionData?.personalData?.isApproved && (
                    <div className="row approve-reject-comments-container">
                      <span className="admission-comments-head-text">
                        Comments
                      </span>
                      <span className="approve-textarea">
                        <IoIosCheckmarkCircle className="admission-approve-check-icon" />
                        Approved
                      </span>
                      <span className="approve-date-text">
                        on {formattedDate}
                      </span>
                    </div>
                  )}
                  {admissionData?.personalData?.isRejected && (
                    <div className="row approve-reject-comments-container">
                      <span className="admission-comments-head-text">
                        Comments
                      </span>
                      <div className="rejected-container p-0 mt-2">
                        <div className="rejcted-head-text">
                          <RiCloseCircleFill className="admission-reject-icon" />
                          Rejected
                        </div>
                        <div className="inner-reject-text">
                          {admissionData?.personalData?.rejectComments}
                        </div>
                      </div>
                      <span className="approve-date-text">{formattedDate}</span>
                    </div>
                  )}
                  {showRejectInput && (
                    <div
                      className="row approve-reject-comments-container"
                      id="rejection-comments-container"
                    >
                      <span className="admission-comments-head-text">
                        Comments
                      </span>
                      <textarea
                        className="reject-textarea"
                        onChange={(event) =>
                          setRejectComments(event.target.value)
                        }
                      />
                      <div className=" d-flex gap-2 p-0 pt-2">
                        <button
                          type="button"
                          className="create-admission-previous-button"
                          onClick={() => setShowRejectInput(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="create-admission-save-button"
                          onClick={handleReject}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default CreatePersonalData;
