import React, { useContext } from "react";
import "./EditEmployeePopup.css";
import { useState } from "react";
import Modal from "react-modal";
import Select from "react-select";
import { IoClose } from "react-icons/io5";
import { RxTriangleDown } from "react-icons/rx";
import Dropzone from "react-dropzone";
import { UserConstants } from "modules/user-management/constants/user-constants";
import { ADMISSION_MANAGEMENT_URL } from "modules/admission-management/admission-management/components/create-admission/constants/admission-management-urls";
import { toast } from "react-toastify";
import sessionService from "services/sessionstorage.service";
import { axiosService } from "services/axios.service";
import { HR_MODULE_URLS } from "modules/hr-module/constants/hr-module-urls";
import { ThemeContext } from "ThemeContext";

const EditEmployeePopup = ({
  open,
  onClose,
  employeeData,
  cycle,
  setCycle,
}) => {
  const [employeeId, setEmployeeId] = useState(
    employeeData?.employee?.employeeId
  );
  const [employeeType, setEmployeeType] = useState(
    employeeData?.employee?.employeeType
  );
  const [employeeFirstName, setEmployeeFirstName] = useState(
    employeeData?.employee?.employeeFirstName
  );
  const [employeeLastName, setEmployeeLastName] = useState(
    employeeData?.employee?.employeeLastName
  );
  const [gender, setGender] = useState(employeeData?.employee?.gender);
  const [socialStatus, setSocialStatus] = useState(
    employeeData?.employee?.socialStatus
  );
  const [dateOfBirth, setDateOfBirth] = useState(
    employeeData?.employee?.dateOfBirth
  );
  const [age, setAge] = useState(employeeData?.employee?.age);
  const [contact1, setContact1] = useState(
    employeeData?.employee?.contactNumber1
  );
  const [contact2, setContact2] = useState(
    employeeData?.employee?.contactNumber2
  );
  const [emailId, setEmailId] = useState(employeeData?.employee?.email);
  const [residentialAddress, setResidentialAddress] = useState(
    employeeData?.employee?.residentialAddress
  );
  const [postalAddress, setPostalAddress] = useState(
    employeeData.postalAddress
  );

  const [resumeName, setResumeName] = useState(
    employeeData?.employee?.resumeName
  );
  const [resume, setResume] = useState(employeeData?.employee?.resume);

  const [document1Name, setDocument1Name] = useState(
    employeeData?.employee?.documentName
  );
  const [document1, setDocument1] = useState(employeeData?.employee?.document);
  const { theme, toggleTheme } = useContext(ThemeContext);

  const employeeTypeOptions = [
    { value: "OFFICESTAFF", label: "OFFICESTAFF" },
    { value: "FACULTY", label: "FACULTY" },
    { value: "ADMIN", label: "ADMIN" },
    { value: "HR", label: "HR" },
  ];

  const genderOptions = [
    { value: "MALE", label: "MALE" },
    { value: "FEMALE", label: "FEMALE" },
    { value: "PREFERNOTTOSAY", label: "PREFERNOTTOSAY" },
  ];

  const socialStatusOptions = [
    { value: "SINGLE", label: "SINGLE" },
    { value: "MARRIED", label: "MARRIED" },
    { value: "DIVORCED", label: "DIVORCED" },
  ];

  const handleSubmit = async () => {
    const Data = {
      ...employeeData.employee,
    };
    let user = {
      userId: employeeData?.employee?.user?.userId,
    };
    if (employeeData?.reportsTo) {
      let reportsTo = {
        empId: employeeData?.employee?.reportsTo?.empId,
      };
      Data.reportsTo = reportsTo;
    }

    Data.employeeId = employeeId;
    Data.employeeType = employeeType;
    Data.employeeFirstName = employeeFirstName;
    Data.employeeLastName = employeeLastName;
    Data.gender = gender;
    Data.socialStatus = socialStatus;
    Data.dateOfBirth = dateOfBirth;
    Data.age = age;
    Data.contactNumber1 = contact1;
    Data.contactNumber2 = contact2;
    Data.email = emailId;
    Data.residentialAddress = residentialAddress;
    Data.postalAddress = postalAddress;

    Data.user = user;
    Data.resume = resume;
    Data.resumeName = resumeName;
    Data.degree = document1;
    Data.degreeName = document1Name;

    try {
      const response = await axiosService.put(
        `${HR_MODULE_URLS.UPDATE_EMPLOYEE}`,
        Data
      );

      if (response.status === 200) {
        toast.success("Employee Updated successfully!");
        onClose();
        setCycle(!cycle);
      } else {
        toast.error("Failed");
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 500) {
          toast.error(`${error.response.data.message.split(":")[1]?.trim()}`);
        } else {
          toast.error(`Error: ${error.response.data.message}`);
        }
      } else {
        toast.error("An unexpected error occurred");
      }
    }
  };

  const onDrop = async (acceptedFiles) => {
    if (!acceptedFiles[0]) {
      toast.error("Please select a image file less than 2MB.");
    } else if (!acceptedFiles[0].type.startsWith("application/pdf")) {
      toast.error(" Invalid file format,Please upload .pdf files");
    } else {
      try {
        if (acceptedFiles[0]?.name?.length > 150) {
          toast.error(UserConstants.IMAGE_LENGTH);
          return;
        }
        setResumeName(acceptedFiles[0]?.name);
        sessionService.isLoading("true");
        const response = await axiosService.postFile(
          `${ADMISSION_MANAGEMENT_URL.FILE_UPLOAD_URL}`,
          acceptedFiles[0],
          true
        );

        if (response.status === 200) {
          setResume(response.data.data);
          setResumeName(acceptedFiles[0]?.name);
        } else {
          setResumeName(null);
        }
      } catch (error) {
        setResumeName(null);
      }
    }
  };

  const onDrop1 = async (acceptedFiles) => {
    if (!acceptedFiles[0]) {
      toast.error("Please select a image file less than 2MB.");
    } else if (!acceptedFiles[0].type.startsWith("application/pdf")) {
      toast.error(" Invalid file format,Please upload .pdf files");
    } else {
      try {
        if (acceptedFiles[0]?.name?.length > 150) {
          toast.error(UserConstants.IMAGE_LENGTH);
          return;
        }
        setDocument1Name(acceptedFiles[0]?.name);
        sessionService.isLoading("true");
        const response = await axiosService.postFile(
          `${ADMISSION_MANAGEMENT_URL.FILE_UPLOAD_URL}`,
          acceptedFiles[0],
          true
        );

        if (response.status === 200) {
          setDocument1(response.data.data);
          setDocument1Name(acceptedFiles[0]?.name);
        } else {
          setDocument1Name(null);
        }
      } catch (error) {
        setDocument1Name(null);
      }
    }
  };

  return (
    <div>
      <Modal
        className="crm-modal"
        isOpen={open}
        onRequestClose={() => {
          onClose();
        }}
        shouldCloseOnEsc={true}
        shouldFocusAfterRender={false}
        shouldCloseOnOverlayClick={true}
      >
        <div className="modal-Con">
          <div
            className="col col-md-11 custom-scrollbar"
            style={{
              height: "auto",
              maxHeight: "90vh",
              backgroundColor: theme === "light" ? "#ffffff" : "#212226",
            }}
          >
            <div className="new-conversation-popup-header sticky-header  top-0 px-3 py-2">
              <span className="new-conversation-popup-title-text">
                Employee information
              </span>
              <span
                className="d-flex justify-content-end align-items-center"
                onClick={onClose}
                style={{ cursor: "pointer" }}
              >
                <IoClose
                  color={theme === "light" ? "#000000" : "#ffffff"}
                  size={"22px"}
                />
              </span>
            </div>

            <div className=" row g-0 px-1 py-2">
              <div className="col-12 col-sm-6 col-lg-4 px-2 mt-2">
                <label className="employee-popup-label">Employee ID</label>

                <input
                  type="text"
                  placeholder="Enter employee ID"
                  value={employeeId}
                  onChange={(event) => {
                    setEmployeeId(event.target.value);
                  }}
                  className="new-conversation-popup-input Add-contact-popup-number"
                />
              </div>
              <div className="col-12 col-sm-6 col-lg-4 px-2 mt-2">
                <label className="employee-popup-label">Employee Type</label>

                <Select
                  value={
                    employeeType
                      ? { value: employeeType, label: employeeType }
                      : null
                  }
                  onChange={(option) => {
                    setEmployeeType(option?.value);
                  }}
                  options={employeeTypeOptions}
                  isSearchable={false}
                  style={{
                    height: "33px",
                    borderRadius: "3px",
                    fontSize: "12px",
                    backgroundColor: "#333333 !important",
                  }}
                  classNamePrefix="react-select"
                  className="new-employee-react-select"
                  components={{
                    DropdownIndicator: () => (
                      <RxTriangleDown
                        color={theme === "light" ? "#000000" : "#ffffff"}
                      />
                    ),
                    IndicatorSeparator: null,
                  }}
                  placeholder="Select a type"
                  menuPlacement="auto"
                  menuPosition="fixed"
                />
              </div>

              <div className="col-12 col-sm-6 col-lg-4 px-2 mt-2">
                <label className="employee-popup-label">Employee Name</label>

                <input
                  type="text"
                  value={employeeFirstName}
                  placeholder="First name"
                  maxlength="11"
                  onChange={(event) => {
                    setEmployeeFirstName(event.target.value);
                  }}
                  className="new-conversation-popup-input Add-contact-popup-number"
                />
              </div>

              <div className="col-12 col-sm-6 col-lg-4 px-2 mt-2">
                <label className="employee-popup-label">Employee Name</label>

                <input
                  value={employeeLastName}
                  placeholder="Last name"
                  type="text"
                  onChange={(event) => {
                    setEmployeeLastName(event.target.value);
                  }}
                  className="new-conversation-popup-input"
                />
              </div>

              <div className="col-12 col-sm-6 col-lg-4 px-2 mt-2">
                <label className="employee-popup-label">Gender</label>

                <Select
                  value={gender ? { value: gender, label: gender } : null}
                  onChange={(option) => {
                    setGender(option?.value);
                  }}
                  options={genderOptions}
                  isSearchable={false}
                  style={{
                    height: "33px",
                    borderRadius: "3px",
                    fontSize: "12px",
                    backgroundColor: "#333333 !important",
                  }}
                  classNamePrefix="react-select"
                  className="new-employee-react-select"
                  components={{
                    DropdownIndicator: () => (
                      <RxTriangleDown
                        color={theme === "light" ? "#000000" : "#ffffff"}
                      />
                    ),
                    IndicatorSeparator: null,
                  }}
                  placeholder="Select a type"
                  menuPlacement="auto"
                  menuPosition="fixed"
                />
              </div>

              <div className="col-12 col-sm-6 col-lg-4 px-2 mt-2">
                <label className="employee-popup-label">Social Status</label>

                <Select
                  value={
                    socialStatus
                      ? { value: socialStatus, label: socialStatus }
                      : null
                  }
                  onChange={(option) => {
                    setSocialStatus(option?.value);
                  }}
                  options={socialStatusOptions}
                  isSearchable={false}
                  style={{
                    height: "33px",
                    borderRadius: "3px",
                    fontSize: "12px",
                    backgroundColor: "#333333 !important",
                  }}
                  classNamePrefix="react-select"
                  className="new-employee-react-select"
                  components={{
                    DropdownIndicator: () => (
                      <RxTriangleDown
                        color={theme === "light" ? "#000000" : "#ffffff"}
                      />
                    ),
                    IndicatorSeparator: null,
                  }}
                  placeholder="Select a type"
                  menuPlacement="auto"
                  menuPosition="fixed"
                />
              </div>

              <div className="col-12 col-sm-6 col-lg-4 px-2 mt-2">
                <label className="employee-popup-label">Date of birth</label>

                <input
                  value={dateOfBirth}
                  type="date"
                  onChange={(event) => {
                    setDateOfBirth(event.target.value);
                  }}
                  className="new-conversation-popup-input"
                />
              </div>

              <div className="col-12 col-sm-6 col-lg-4 px-2 mt-2">
                <label className="employee-popup-label">Age</label>

                <input
                  type="number"
                  placeholder="Auto-calculated on DoB"
                  value={age}
                  maxlength="11"
                  onChange={(event) => {
                    setAge(event.target.value);
                  }}
                  className="new-conversation-popup-input Add-contact-popup-number"
                />
              </div>

              <div className="col-12 col-sm-6 col-lg-4 px-2 mt-2">
                <label className="employee-popup-label">Contact Number 1</label>

                <input
                  type="number"
                  placeholder="Enter valid contact number"
                  value={contact1}
                  maxlength="11"
                  onChange={(event) => {
                    if (event.target.value <= 11) {
                      setContact1(event.target.value);
                    } else {
                      setContact1(event.target.value.slice(0, 11));
                    }
                  }}
                  className="new-conversation-popup-input Add-contact-popup-number"
                />
              </div>

              <div className="col-12 col-sm-6 col-lg-4 px-2 mt-2">
                <label className="employee-popup-label">Contact Number 2</label>

                <input
                  type="number"
                  placeholder="Enter valid contact number"
                  value={contact2}
                  maxlength="11"
                  onChange={(event) => {
                    if (event.target.value <= 11) {
                      setContact2(event.target.value);
                    } else {
                      setContact2(event.target.value.slice(0, 11));
                    }
                  }}
                  className="new-conversation-popup-input Add-contact-popup-number"
                />
              </div>

              <div className="col-12 col-sm-6 col-lg-4 px-2 mt-2">
                <label className="employee-popup-label">Email ID</label>

                <input
                  value={emailId}
                  placeholder="Enter valid email ID"
                  type="text"
                  onChange={(event) => {
                    setEmailId(event.target.value);
                  }}
                  className="new-conversation-popup-input"
                />
              </div>

              <div className="col-12 col-md-6 px-2 mt-2">
                <label className="employee-popup-label">
                  Residential Address
                </label>

                <input
                  type="text"
                  placeholder="Enter full address"
                  value={residentialAddress}
                  onChange={(event) => {
                    setResidentialAddress(event.target.value);
                  }}
                  className="new-conversation-popup-input Add-contact-popup-number"
                />
              </div>

              <div className="col-12 col-md-6 px-2 mt-2">
                <label className="employee-popup-label">Upload Resume/CV</label>

                <Dropzone onDrop={onDrop} multiple={false} maxSize={2097152}>
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps()}
                      id="edit-employee-document-upload-field"
                    >
                      <input {...getInputProps()} />
                      <span
                        className={`asps-upload-input ps-1  ${
                          resumeName ? "text-white" : ""
                        }`}
                        style={{
                          background: "#333333",
                          border: "none",
                        }}
                      >
                        {resumeName ? resumeName : "No file selected"}
                      </span>

                      <span
                        className="create-list-upload-input-choose"
                        style={{
                          borderRight: "0px ",
                          background: "#000000",
                          color: theme === "light" ? "#ffffff" : "#000000",
                        }}
                      >
                        Choose file
                      </span>
                    </div>
                  )}
                </Dropzone>
              </div>

              <div className="col-12 col-md-6 px-2 mt-2">
                <label className="employee-popup-label">Degree *</label>

                <Dropzone onDrop={onDrop1} multiple={false} maxSize={2097152}>
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps()}
                      id="edit-employee-document-upload-field"
                    >
                      <input {...getInputProps()} />
                      <span
                        className={`asps-upload-input ps-1  ${
                          document1Name ? "text-white" : ""
                        }`}
                        style={{
                          background: "#333333",
                          border: "none",
                        }}
                      >
                        {document1Name ? document1Name : "No file selected"}
                      </span>

                      <span
                        className="create-list-upload-input-choose"
                        style={{
                          borderRight: "0px ",
                          background: "#000000",
                          color: theme === "light" ? "#ffffff" : "#000000",
                        }}
                      >
                        Choose file
                      </span>
                    </div>
                  )}
                </Dropzone>
              </div>
            </div>

            <div
              className="d-flex gap-3 px-3 py-3 mt-2"
              style={{ borderTop: "1px solid #7A7A7A" }}
            >
              <button
                className="communication-management-cancel-button"
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </button>

              <button
                disabled={!employeeId || !employeeType}
                className={`communication-management-submit-button ${
                  (!employeeId || !employeeType) && "opacity-50"
                }`}
                onClick={() => {
                  // onClose()
                  handleSubmit();
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EditEmployeePopup;
