import { AgGridReact } from "ag-grid-react";
import { CAMPAIGN_URLS } from "modules/campaigns/constants/Campaign-urls";
import React, { useContext, useEffect, useMemo } from "react";

import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosService } from "services/axios.service";
import { ThemeContext } from "ThemeContext";

function ViewCampaign() {
  const location = useLocation();
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const { theme, toggleTheme } = useContext(ThemeContext);
  useEffect(() => {
    axiosService.get(`${CAMPAIGN_URLS.GET_ALL_CAMPAIGN}`).then((response) => {
      if (response.status === 200) {
        const responseData = response.data.data;

        setData(responseData);
      }
    });
  }, [location.pathname]);

  const retry = (id) => {
    try {
      axiosService
        .post(`${CAMPAIGN_URLS.RETRY_EMAIL}${id}`)
        .then((response) => {
          if (response.status === 200) {
            // toast.success("Saved successfully");
            navigate("/campaigns/all-campaigns");
          }
        })
        .catch((error) => {
          if (error.response) {
            toast.error("Failed");
          } else if (error.request) {
            toast.error("No response received from server");
          } else {
            toast.error(`${error.response.data.message.split(":")[1]?.trim()}`);
          }
        });
    } catch (error) {
      toast.error("Failed");
    }
  };

  const [columnDefs] = useState([
    {
      headerName: "Campaign Name",
      field: "campaignName",
      // cellStyle: {
      //     whiteSpace: "normal",
      //     wordWrap: "break-word",
      //     overflow: "hidden",
      // },
    },
    {
      headerName: "Message",
      valueGetter: (params) =>
        params.data.targetList[0]?.message?.messageName || "-",
    },

    {
      headerName: "Date & Time",
      field: "scheduleDateTime",
    },

    {
      headerName: "Status",
      // field: "Status",
      cellRenderer: function (params) {
        const rowData = params.data;

        return (
          <span>
            {rowData.campaignStatus === "FAILED"
              ? "Failed"
              : rowData.campaignStatus === "COMPLETED"
              ? "Completed"
              : rowData.campaignStatus === "SCHEDULED"
              ? "Scheduled"
              : rowData.campaignStatus === "INPROGRESS"
              ? "In Progress"
              : "-"}
          </span>
        );
      },
    },
    {
      field: "Actions",
      cellRenderer: function (params) {
        const rowData = params.data;

        function checkFailedMsg(value) {
          const totalFailureCount = value.reduce(
            (total, item) => total + item.failureCount,
            0
          );

          if (totalFailureCount >= 1) {
            return "Retry";
          } else {
            return "-";
          }
        }

        return (
          <span>
            {/* {rowData.campaignStatus === "FAILED" ? "Failed" : rowData.campaignStatus === "COMPLETED" ? "Completed" : rowData.campaignStatus === "SCHEDULED" ? "Scheduled" : rowData.campaignStatus === "INPROGRESS" ? "In Progress" : '-'} */}

            {checkFailedMsg(rowData.targetList) === "Retry" ? (
              <span onClick={() => retry(rowData?.campaignId)}>Retry</span>
            ) : (
              "-"
            )}
          </span>
        );
      },
    },
  ]);
  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowHeight: 38,
  };

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);
  //   const onRowClicked = (event) => {
  //     navigate("/campaigns/create-campaign", {
  //       state: {
  //         data: {
  //           Data: event.data,
  //         },
  //       },
  //     });
  //   };

  return (
    <div className="view-leads-table-container">
      <div className="lead-table-head-container align-items-center sticky-header flex-row flex-wrap gap-2">
        <span className="communication-management-title-text">
          All Campaigns
        </span>

        <button
          className="communication-save-head-button"
          onClick={() => {
            navigate("/campaigns/create-campaign");
          }}
        >
          Create New Campaign
        </button>
      </div>
      <div
        className={
          theme === "light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"
        }
      >
        <AgGridReact
          columnDefs={columnDefs}
          rowData={data}
          rowSelection="single"
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          suppressRowClickSelection
          gridOptions={gridOptions}
          // onRowClicked={onRowClicked}
        />
      </div>
    </div>
  );
}
export default ViewCampaign;
