import React, { useContext } from "react";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
import { CAMPAIGN_URLS } from "modules/campaigns/constants/Campaign-urls";
import { axiosService } from "services/axios.service";
import { toast } from "react-toastify";
import { ThemeContext } from "ThemeContext";

const AddContactListPopUp = ({
  open,
  add,
  onClose,
  leadId,
  leadStatus,
  setLeadStatus,
  setLeadId,
}) => {
  const [listName, setListName] = useState(null);

  const { theme, toggleTheme } = useContext(ThemeContext);

  useEffect(() => {
    if (!add && leadStatus) {
      setListName(leadStatus);
    }
  }, [leadStatus]);

  function addContactList() {
    try {
      axiosService
        .post(`${CAMPAIGN_URLS.ADD_CONTACT_LIST}`, { listName: listName })
        .then((response) => {
          if (response.status === 200) {
            onClose();
            toast.success("Contact List added Successfully");
          }
        })
        .catch((error) => {
          onClose();

          if (error.response) {
            toast.error(`${error.response.data.message.split(":")[1]?.trim()}`);
          } else if (error.request) {
            toast.error("No response received from server");
          } else {
            toast.error(`${error.response.data.message.split(":")[1]?.trim()}`);
          }
        });
    } catch (error) {
      toast.error(`${error.response.data.message.split(":")[1]?.trim()}`);
    }
  }

  function editContactList() {
    let Data = {
      listId: leadId,
      listName: listName,
    };

    axiosService
      .put(`${CAMPAIGN_URLS.EDIT_CONTACT_LIST}`, Data)
      .then((response) => {
        if (response.status === 200) {
          onClose();
          setLeadStatus(null);
          setLeadId(null);
        }
      });
  }

  return (
    <div>
      <Modal
        className="crm-modal"
        isOpen={open}
        onRequestClose={() => onClose()}
        shouldReturnFocusAfterClose={false}
        shouldFocusAfterRender={false}
      >
        <div className="modal-Con">
          <div
            className="col-11 col-md-5 p-3"
            style={{
              height: "auto",
              backgroundColor: theme === "light" ? "#ebebeb" : "#212226",
            }}
          >
            <div className="new-conversation-popup-header">
              <span className="new-conversation-popup-title-text">
                {add ? "Add List" : "Edit List"}
              </span>
              <span
                className="d-flex justify-content-end align-items-center"
                onClick={onClose}
                style={{ cursor: "pointer" }}
              >
                <IoClose
                  color={theme === "light" ? "#000000" : "#FFFFFF"}
                  size={"22px"}
                />
              </span>
            </div>
            <div className="d-flex flex-column gap-2 mt-2">
              <div>
                <label className="new-conversation-popup-label">
                  List Name *
                </label>

                <input
                  value={listName}
                  onChange={(event) => {
                    setListName(event.target.value);
                  }}
                  className="new-conversation-popup-input"
                />
              </div>

              <div className="d-flex gap-3 mt-2">
                <button
                  className="communication-management-cancel-button"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  disabled={!listName}
                  className={`communication-management-submit-button ${
                    !listName && "opacity-50"
                  }`}
                  onClick={() => {
                    add ? addContactList() : editContactList();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddContactListPopUp;
