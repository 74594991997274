import React, { useContext, useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import Modal from "react-modal";
import Select from "react-select";
import { RxTriangleDown } from "react-icons/rx";
import { axiosService } from "services/axios.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { HOLIDAY_LEAVES_CALENDAR_URLS } from "modules/hr-module/constants/holiday-leaves-calendar-urls";
import { ThemeContext } from "ThemeContext";

const AddNewHolidayPopup = ({ open, onClose, type, setType, selectedData }) => {
  const navigate = useNavigate();

  const [holidayName, setHolidayName] = useState();
  const [holidayDate, setHolidayDate] = useState();
  const [holidayType, setHolidayType] = useState();
  const { theme, toggleTheme } = useContext(ThemeContext);

  const holidayTypeOptions = [
    { value: "GOVERNMENTHOLIDAY", label: "Government Holiday" },
    { value: "OPTIONALHOLIDAY", label: "Optional Holiday" },
  ];

  useEffect(() => {
    setHolidayName(selectedData?.holidayName);
    setHolidayDate(selectedData?.holidayDate);
    setHolidayType(selectedData?.holidayType);
  }, []);

  const onSubmit = async () => {
    const Data = {
      holidayName,
      holidayDate,
      holidayType,
    };

    try {
      const response = await axiosService.post(
        `${HOLIDAY_LEAVES_CALENDAR_URLS.SAVE_HOLIDAY}`,
        Data
      );
      if (response.status === 200) {
        toast.success("Holiday added successfully");
        navigate("/hr-module/holiday-and-leaves");
        onClose();
      } else {
        toast.error("failed");
      }
    } catch (error) {
      if (error.response) {
        toast.error(`${error.response.data.message.split(":")[1]?.trim()}`);
      }
    }
  };

  const onEdit = async () => {
    const Data = {
      holidayId: selectedData?.holidayId,
      holidayName,
      holidayDate,
      holidayType,
    };

    try {
      const response = await axiosService.put(
        `${HOLIDAY_LEAVES_CALENDAR_URLS.UPDATE_HOLIDAY}`,
        Data
      );
      if (response.status === 200) {
        toast.success("Holiday updated successfully");
        navigate("/hr-module/holiday-and-leaves");
        onClose();
      } else {
        toast.error("failed");
      }
    } catch (error) {
      if (error.response) {
        toast.error(`${error.response.data.message.split(":")[1]?.trim()}`);
      }
    }
  };

  return (
    <div>
      <Modal
        className="crm-modal"
        isOpen={open}
        onRequestClose={() => onClose()}
        shouldReturnFocusAfterClose={false}
        shouldFocusAfterRender={false}
      >
        <div className="modal-Con">
          <div
            className="col-11 col-md-4 p-3"
            style={{
              height: "auto",
              backgroundColor: theme === "light" ? "#ebebeb" : "#212226",
            }}
          >
            <div className="new-conversation-popup-header">
              <span className="new-conversation-popup-title-text">
                {type ? "Add new holiday" : "Edit holiday"}
              </span>
              <span
                className="d-flex justify-content-end align-items-center"
                onClick={() => {
                  onClose();
                }}
                style={{ cursor: "pointer" }}
              >
                <IoClose
                  color={theme === "light" ? "#000000" : "#ffffff"}
                  size={"22px"}
                />
              </span>
            </div>
            <div className="d-flex flex-column gap-2 mt-2">
              <div>
                <label className="new-conversation-popup-label">
                  Holiday Name
                </label>

                <input
                  value={holidayName}
                  onChange={(event) => {
                    setHolidayName(event.target.value);
                  }}
                  className="new-conversation-popup-input"
                />
              </div>
              <div>
                <label className="new-conversation-popup-label">
                  Holiday date
                </label>

                <input
                  id="birthdayInput"
                  value={holidayDate}
                  type="date"
                  onChange={(event) => {
                    setHolidayDate(event.target.value);
                  }}
                  className="new-conversation-popup-input"
                />
              </div>
              <div>
                <label className="new-conversation-popup-label">
                  Holiday Type
                </label>

                <Select
                  value={
                    holidayType
                      ? { value: holidayType, label: holidayType }
                      : null
                  }
                  onChange={(option) => {
                    setHolidayType(option?.value);
                  }}
                  options={holidayTypeOptions}
                  isSearchable={false}
                  style={{
                    height: "33px",
                    borderRadius: "3px",
                    fontSize: "12px",
                    backgroundColor: "#333333 !important",
                  }}
                  classNamePrefix="react-select"
                  className="new-employee-react-select"
                  components={{
                    DropdownIndicator: () => (
                      <RxTriangleDown
                        color={theme === "light" ? "#000000" : "#ffffff"}
                      />
                    ),
                    IndicatorSeparator: null,
                  }}
                  placeholder="Select a type"
                  menuPlacement="auto"
                  menuPosition="fixed"
                />
              </div>

              <div className="d-flex gap-3 mt-2">
                <button
                  className="communication-management-cancel-button"
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancel
                </button>

                {type ? (
                  <button
                    className={`communication-management-submit-button`}
                    onClick={() => onSubmit()}
                  >
                    Submit
                  </button>
                ) : (
                  <button
                    className={`communication-management-submit-button`}
                    onClick={() => onEdit()}
                  >
                    Update
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddNewHolidayPopup;
