import { useContext, useEffect, useState } from "react";
import { FaEye, FaPen, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { axiosService } from "services/axios.service";
import Swal from "sweetalert2";
import { ROLE_URLS } from "../../constants/user-role-urls";
import "./RoleList.css";
import sessionstorageService from "services/sessionstorage.service";
import { ThemeContext } from "ThemeContext";

function RoleList() {
  const [roleData, setRoleData] = useState([]);

  const { theme, toggleTheme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const userData = sessionstorageService.getUserData("userData");
  useEffect(() => {
    axiosService
      .get(`${ROLE_URLS.USER_GET_URL}`)
      .then((response) => {
        setRoleData(response.data.data);
      })
      .catch(() => {});
  }, []);

  const handleDelete = (roleId) => {
    if (roleId === userData?.role?.roleId) {
      Swal.fire({
        title: "Warning!!",
        text: "Can't delete signin role",
        icon: "warning",
        customClass: {
          popup: "my-custom-class",
        },
      });
    } else {
      Swal.fire({
        title: "Confirm Delete",
        text: "Are you sure you want to delete this role?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete",
        cancelButtonText: "No, cancel",
        customClass: {
          popup: "my-custom-class",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          axiosService
            .delete(`${ROLE_URLS.USER_ROLE_ID}${roleId}`)
            .then((response) => {
              if (response.status === 200) {
                Swal.fire({
                  icon: "success",
                  title: "Deleted!",
                  text: "Role has been Deleted!",
                  customClass: {
                    popup: "my-custom-class",
                  },
                });
                setRoleData((prevData) =>
                  prevData.filter((role) => role.roleId !== roleId)
                );
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Error!",
                  text: "Failed to Delete this Role",
                  customClass: {
                    popup: "my-custom-class",
                  },
                });
              }
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Error!",
                text: "Users are mapped to this role. Please delete the users or remove the role access to them to delete this role.",
                customClass: {
                  popup: "my-custom-class",
                },
              });
            });
        }
      });
    }
  };

  return (
    <div className="user-main-container">
      <div className="user-head-container sticky-header flex-row gap-2">
        <div className="d-flex align-items-center gap-1">
          <span className="user-title-text">Roles Lists</span>
        </div>
        <div className="d-flex align-items-center gap-3">
          <button
            className="user-head-button"
            onClick={() => navigate("/user-management/add-role")}
          >
            Add Role
          </button>
        </div>
      </div>
      <div className="custom-scrollbar" style={{ marginLeft: "10px" }}>
        <table className="table table-responsive mt-3">
          <thead className="table">
            <tr style={{ borderBottom: "0.5px solid rgba(51, 51, 51, 1)" }}>
              <th className="role-list-t-head">S.NO</th>
              <th className="role-list-t-head">ROLE NAME</th>
              <th className="role-list-t-head">PRIORITY</th>
              <th className="role-list-t-head">ACTION</th>
            </tr>
          </thead>

          <tbody>
            {roleData.map((role, index) => (
              <tr key={role.roleId}>
                <td>{index + 1}</td>
                <td>{role.name}</td>
                <td>{role.priority}</td>
                <td>
                  <FaEye
                    className="roll-list-fa-eye"
                    style={{ color: theme === "light" ? "black" : "white" }}
                    onClick={() =>
                      navigate("/user-management/add-role", {
                        state: { roleId: role.roleId, view: true },
                      })
                    }
                  />

                  <a
                    onClick={() =>
                      navigate("/user-management/add-role", {
                        state: { roleId: role.roleId },
                      })
                    }
                  >
                    <FaPen className="roll-list-fa-pen" />
                  </a>
                  <FaTrash
                    className="roll-list-fa-trash"
                    onClick={() => handleDelete(role.roleId)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default RoleList;
