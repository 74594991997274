import { RegisterConstants } from "core/auth/constants/auth-constants";
import { useState } from "react";
import { toast } from "react-toastify";
import crmlogo from "../../../../assets/images/crm-logo.svg";
import logingroup from "../../../../assets/images/login-group.png";
import { axiosService } from "../../../../services/axios.service";
import "../forgot-password/ForgotPassword.css";
import { useNavigate } from "react-router-dom";
import OTPInput from "react-otp-input";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { AUTH_URLS } from "core/auth/constants/auth-urls";

function ForgotPassword() {
  const navigation = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [email, setEmail] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const [otp, setOtp] = useState("");
  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError("");
  };

  const handleConfirmPasswordChange = (e) => {
    const newValue = e.target.value;
    setConfirmPassword(newValue);
    validateConfirmPassword(newValue);
  };
  const tooltipContent = (
    <span>
      <h6>Password Must</h6>
      Have one number
      <br />
      Have one uppercase character
      <br />
      Have one lowercase character
      <br />
      Have one special character
      <br />
      Have 8 characters minimum
    </span>
  );

  const validateConfirmPassword = (newValue) => {
    if (password !== newValue) {
      setConfirmPasswordError(RegisterConstants.PASSWORD_NOT_MATCH);
    } else {
      setConfirmPasswordError("");
    }
  };
  const [userId, setUserId] = useState("");
  const [verified, setVerified] = useState(false);
  const [otpsent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSendOTP = async () => {
    setLoading(true);
    try {
      const response = await axiosService.post(
        `${AUTH_URLS.FORGOT_PASSWORD_URL}?email=${email}`
      );
      if (response.status === 200) {
        const userIdFromResponse = response.data.data.userId;
        toast.success("OTP sent successfully!");
        setUserId(userIdFromResponse);
        setOtpSent(true);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Failed to send OTP. Please try again.");
      }
    } catch (error) {
      setLoading(false);
      toast.error(`${error?.response?.data?.message.split(":")[1]?.trim()}`);
    }
  };
  const handleReSendOTP = async () => {
    setLoading(true);
    try {
      const response = await axiosService.post(
        `${AUTH_URLS.RESEND_OTP_URL}?email=${email}`
      );
      if (response.status === 200) {
        toast.success("OTP Resended successfully!");
        const userIdFromResponse = response.data.data.userId;
        setUserId(userIdFromResponse);
        setOtpSent(true);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Failed to send OTP. Please try again.");
      }
    } catch (error) {
      setLoading(false);
      toast.error(`${error?.response?.data?.message.split(":")[1]?.trim()}`);
    }
  };

  const handleVerifyOTP = async () => {
    setLoading(true);
    const verifyData = {
      userId: userId,
      verifyOtp: otp,
    };
    try {
      const response = await axiosService.post(
        `${AUTH_URLS.VERIFY_OTP_URL}`,
        verifyData
      );
      if (response.status === 200) {
        toast.success("OTP verified successfully!");
        setVerified(true);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Failed to verify OTP. Please try again.");
      }
    } catch (error) {
      setLoading(false);
      toast.error(`${error?.response?.data?.message.split(":")[1]?.trim()}`);
    }
  };

  const handleUpdatePassword = async () => {
    setLoading(true);
    const verifyData = {
      userId: userId,
      password: password,
    };
    try {
      const response = await axiosService.post(
        `${AUTH_URLS.UPDATE_PASSWORD_URL}`,
        verifyData
      );
      if (response.status === 200) {
        navigation("/");
        toast.success("Password Changed successfully!");
        setLoading(false);
      } else {
        setLoading(false);

        toast.error("Failed to change Password. Please try again.");
      }
    } catch (error) {
      setLoading(false);
      toast.error(`${error?.response?.data?.message.split(":")[1]?.trim()}`);
    }
  };

  return (
    <div className="row g-0 auth-main-container ">
      <div className="d-none d-md-block col-6 auth-img-container d-none d-md-block ">
        <img
          src={logingroup}
          alt="img"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </div>
      {!verified ? (
        <div className="col-12 col-md-6 auth-container auth-text-animation">
          <span className="login-logo-container">
            <img src={crmlogo} alt="logo" className="login-logo-image" />
          </span>
          <div
            className="d-flex row g-0 gap-2 flex-column align-items-center justify-content-center"
            style={{ width: "100%" }}
          >
            <span className="email-con">
              <div className="login-head-text-container col-10  col-lg-6">
                <span className="auth-title-text">Forget Password</span>
              </div>

              <div className="login-input-main-container col-10  col-lg-6">
                <div className="auth-input-container-password ">
                  <label className="auth-label-text-1">Email Address</label>
                  <input
                    value={email}
                    onChange={handleEmailChange}
                    className="auth-input"
                    placeholder="john@gmail.com"
                    readOnly={otpsent}
                  />
                </div>
              </div>
              {!otpsent && (
                <div className="login-button-container col-10  col-lg-6 col-10  col-lg-6">
                  <button
                    onClick={handleSendOTP}
                    className={`auth-button mt-3 ${
                      email === "" ? "disabled-button" : ""
                    }`}
                    disabled={email === ""}
                  >
                    {loading ? (
                      <div class="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    ) : (
                      "Send OTP"
                    )}
                  </button>
                </div>
              )}
            </span>
            {otpsent && (
              <div className="login-button-container col-10  col-lg-6 col-10  col-lg-6">
                <div className="d-flex justify-content-between w-100 otpcon">
                  <OTPInput
                    style={{ width: "100%", justifyContent: "center" }}
                    value={otp}
                    onChange={(otpValue) => {
                      if (/^[0-9]*$/.test(otpValue)) {
                        setOtp(otpValue);
                      }
                    }}
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                    inputStyle={{
                      width: "35px",
                      height: "35px",
                      fontSize: "18px",
                      textAlign: "center",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                    }}
                  />
                </div>
                <button className="auth-button mt-4" onClick={handleVerifyOTP}>
                  {loading ? (
                    <div class="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  ) : (
                    "Verify OTP"
                  )}
                </button>
                <div className="d-flex justify-content-between">
                  <span
                    className="resend-link"
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={() => {
                      setOtpSent(false);
                    }}
                  >
                    Change Email Address
                  </span>
                  <span
                    className="resend-link"
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={handleReSendOTP}
                  >
                    Resend OTP
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="policy-text-container"></div>
        </div>
      ) : (
        <div className="col-12 col-md-6 auth-container auth-text-animation">
          <span className="login-logo-container">
            <img src={crmlogo} alt="logo" className="login-logo-image" />
          </span>
          <div
            className="d-flex row g-0 gap-2 flex-column align-items-center justify-content-center"
            style={{ width: "100%" }}
          >
            <div className="login-head-text-container col-10  col-lg-6">
              <span className="auth-title-text"> Set Password</span>
            </div>

            <div className="login-input-main-container col-10  col-lg-6">
              <div className="signup-input-container">
                <label className="auth-label-text">New password*</label>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip id="password-tooltip" className="custom-tooltip">
                      {tooltipContent}
                    </Tooltip>
                  }
                  show={showTooltip}
                >
                  <input
                    className="auth-input"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    value={password}
                    onFocus={toggleTooltip}
                    onBlur={toggleTooltip}
                    onChange={handlePasswordChange}
                  />
                </OverlayTrigger>
                <span className="auth-login-error-text">{passwordError}</span>
                {showPassword ? (
                  <AiFillEye
                    className="signup-eye-icon"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                ) : (
                  <AiFillEyeInvisible
                    className="signup-eye-icon"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                )}
              </div>

              <div className="signup-input-container ">
                <label className="auth-label-text">Confirm password*</label>
                <input
                  className="auth-input"
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                />
                {confirmPasswordError && (
                  <span className="auth-login-error-text">
                    {confirmPasswordError}
                  </span>
                )}
                {showConfirmPassword ? (
                  <AiFillEye
                    className="signup-eye-icon"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  />
                ) : (
                  <AiFillEyeInvisible
                    className="signup-eye-icon"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  />
                )}
              </div>
              <button
                className={`auth-button mt-3 ${
                  password !== confirmPassword || password === ""
                    ? "disabled-button"
                    : ""
                }`}
                disabled={password !== confirmPassword || password === ""}
                onClick={handleUpdatePassword}
              >
                Change Password
              </button>
            </div>
          </div>
          <div className="policy-text-container"></div>
        </div>
      )}
    </div>
  );
}

export default ForgotPassword;
