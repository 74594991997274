import { Field, FieldArray, Form, Formik } from "formik";
import { RxTriangleDown } from "react-icons/rx";
import Select from "react-select";
import { axiosService } from "services/axios.service";
import * as Yup from "yup";
import "./AddStartDateProgram.css";
import { ADMISSION_URL_FOR_STUDENT } from "../../../../../pre-onboarding/components/constants/admission-application-student-urls";
import { useContext, useEffect, useState } from "react";
import DeleteIcon from "assets/image-components/DeleteIcon";
import { USER_URL } from "modules/user-management/constants/user-urls";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { ThemeContext } from "ThemeContext";
function AddStartDateProgram() {
  const navigate = useNavigate();
  const location = useLocation();
  const Data = location.state;

  const programDateId = Data?.data?.Data?.programDateId;
  const [startDate, setStartDate] = useState([""]);

  const { theme, toggleTheme } = useContext(ThemeContext);

  const addDateInput = () => {
    setStartDate([...startDate, ""]);
  };

  const removeDateInput = (indexToRemove) => {
    setStartDate(startDate.filter((date, index) => index !== indexToRemove));
  };

  const [diplomaOptions, setDiplomaOptions] = useState([]);
  const [programDataEdit, setProgramDataEdit] = useState("");
  const [programEdit, setProgramEdit] = useState("");
  const [campusEdit, setCampusEdit] = useState("");
  useEffect(() => {
    if (programDateId) {
      axiosService
        .get(`${USER_URL.POST_STARTDATE_PROGRAM}/${programDateId}`)
        .then((response) => {
          if (response.status === 200) {
            const responseData = response.data.data;
            setStartDate(responseData?.startDates);
            setProgramFeeMasterId(
              responseData?.programFeeMaster?.programFeeMasterId
            );
            setProgramDataEdit(responseData);
            getCampusOptions(responseData?.programFeeMaster?.program);
            setProgramEdit(responseData?.programFeeMaster?.program);
            setCampusEdit(responseData?.programFeeMaster?.campus);
          }
        });
    }
  }, [location.pathname, programDateId]);

  useEffect(() => {
    const fetchDiplomaOptions = async () => {
      axiosService
        .get(`${ADMISSION_URL_FOR_STUDENT.GET_PROGRAM_OPTIONS}`)
        .then((response) => {
          setDiplomaOptions(response.data.data);
        });
    };

    fetchDiplomaOptions();
  }, []);

  const [campusOptions, setCampusOptions] = useState(null);
  const [programFeeMasterId, setProgramFeeMasterId] = useState("");

  const initialValues = {
    program: programDateId ? programEdit : "",
    campus: programDateId ? campusEdit : "",
    startDates: [],
  };

  const validationSchema = Yup.object().shape({});

  const onSubmit = async (values) => {
    const requestData = {
      startDates: startDate,
      programFeeMaster: { programFeeMasterId: programFeeMasterId },
    };

    try {
      if (programDateId) {
        requestData.programDateId = programDataEdit.programDateId;
        const response = await axiosService.put(
          `${USER_URL.POST_STARTDATE_PROGRAM}`,
          requestData
        );

        if (response.status === 200) {
          toast.success("Program Date  updated successfully");
          navigate("/academics-management/lookup");
        }
      } else {
        const response = await axiosService.post(
          `${USER_URL.POST_STARTDATE_PROGRAM}`,
          requestData
        );

        if (response.status === 200) {
          toast.success("Program Date Saved Successfully");
          navigate("/academics-management/lookup");
        }
      }
    } catch (error) {
      toast.error(`${error?.response?.data?.message.split(":")[1]?.trim()}`);
    }
  };

  const getValuesOfProgramAndCampus = (form, campusValue) => {
    axiosService
      .getForUrl(
        `${ADMISSION_URL_FOR_STUDENT.GET_PROGRAM_AND_FEE_POPULATED_VALUES}?program=${form.values.program}&campus=${campusValue}`
      )
      .then((response) => {
        const data = response?.data?.data;
        setProgramFeeMasterId(data?.programFeeMasterId);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const getCampusOptions = (programValue) => {
    axiosService
      .getForUrl(
        `${ADMISSION_URL_FOR_STUDENT.GET_PROGRAM_CAMPUS_OPTIONS}${programValue}`
      )
      .then((response) => {
        const data = response?.data?.data;
        setCampusOptions(data);
      })
      .catch((error) => {});
  };
  const selectStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "0 !important",
      width: "210px !important",
    }),
  };
  const selectStylesProgram = {
    control: (provided) => ({
      ...provided,
      borderRadius: "0 !important",
      width: "445px !important",
    }),
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {(formikProps) => (
        <Form>
          <div className="create-admission-main-container px-3">
            <div className="progress-container p-0 py-2">
              <div className="create-admission-head-text">Add Dates</div>
            </div>

            <div className="container-fluid g-0">
              <div className="create-program-main-input-container">
                <div className="create-admission-inner-first-input-container">
                  <label className="label-text-active width-label-program">
                    Program *
                  </label>
                  <Field
                    name="program"
                    render={({ field, form }) => (
                      <Select
                        id="program"
                        value={
                          field.value
                            ? diplomaOptions.find(
                                (option) => option.value === field.value
                              )
                            : null
                        }
                        onChange={(option) => {
                          form.setFieldValue(field.name, option.value);
                          form.setFieldTouched(field.name, null);
                          getCampusOptions(option.value);
                          form.setFieldValue("campus", "");
                        }}
                        options={diplomaOptions}
                        isSearchable={false}
                        classNamePrefix="react-select"
                        styles={selectStylesProgram}
                        components={{
                          DropdownIndicator: () => (
                            <RxTriangleDown
                              color={theme === "light" ? "#000000" : "#ffffff"}
                            />
                          ),
                          IndicatorSeparator: null,
                        }}
                        placeholder="Choose an Option"
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                    )}
                  />
                </div>
                <div className="create-admission-inner-first-input-container">
                  <label className="label-text-active width-label-program">
                    Campus *
                  </label>
                  <Field
                    name="campus"
                    render={({ field, form }) => (
                      <Select
                        isDisabled={!form.values.program}
                        defaultValue={field.value}
                        value={
                          field.value
                            ? campusOptions?.find(
                                (option) => option.value === field.value
                              )
                            : null
                        }
                        onChange={(option) => {
                          form.setFieldValue(field.name, option.value);
                          getValuesOfProgramAndCampus(form, option.value);
                        }}
                        options={campusOptions}
                        isSearchable={false}
                        classNamePrefix="react-select"
                        styles={selectStyles}
                        components={{
                          DropdownIndicator: () => (
                            <RxTriangleDown
                              color={theme === "light" ? "#000000" : "#ffffff"}
                            />
                          ),
                          IndicatorSeparator: null,
                        }}
                        placeholder="Choose an Option"
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                    )}
                  />
                </div>
              </div>
              <FieldArray name="startDates">
                {(arrayHelpers) => (
                  <div>
                    {startDate.map((date, index) => (
                      <div className="row" key={index}>
                        <div className="col-md-6">
                          {index === 0 && (
                            <label
                              className="label-text-active width-label-program"
                              style={{ marginBottom: "5px" }}
                            >
                              Start Date *
                            </label>
                          )}
                          <div
                            style={{ marginTop: index === 0 ? "0" : "10px" }}
                          >
                            <Field
                              type="date"
                              className="form-control3"
                              name={`startDates.${index}`}
                              value={date}
                              onChange={(e) => {
                                const updatedStartDate = [...startDate];
                                updatedStartDate[index] = e.target.value;
                                setStartDate(updatedStartDate);
                              }}
                            />
                            {index === 0 ? null : (
                              <button
                                type="button"
                                onClick={() => removeDateInput(index)}
                                className="delete-btn"
                                style={{ marginLeft: "5px" }}
                              >
                                <DeleteIcon />
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={addDateInput}
                      className="create-admission-add-row"
                    >
                      Add Start Dates
                    </button>
                  </div>
                )}
              </FieldArray>
              <div className=" d-flex align-items-center gap-3 mt-3">
                <button
                  type="button"
                  className="communication-management-cancel-button"
                  onClick={() => navigate("/academics-management/lookup")}
                >
                  Back
                </button>

                <button
                  className="communication-management-submit-button"
                  type="submit"
                >
                  {programDateId ? "Update" : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default AddStartDateProgram;
