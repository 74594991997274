import useravatar from "../../../../../assets/images/user-avatar.svg";
import useravatarblack from "../../../../../assets/images/user-avatar-black.svg";
import { useContext, useEffect, useState } from "react";
import { RxTriangleDown } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { axiosService } from "services/axios.service";
import sessionstorageService from "services/sessionstorage.service";
import { FORUM_DISCUSSION_URLS } from "../../constants/forum-discussion-urls";
import CreateForum from "../create-forum-popup/CreateForum";
import "./ViewForums.css";
import { ThemeContext } from "ThemeContext";

function ViewForums() {
  function hasPermission(permission) {
    let userPermissions =
      sessionstorageService.hasPermissionByButton(permission);
    return userPermissions;
  }

  const navigation = useNavigate();
  const [forumData, setForumData] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState({ 0: true });
  const [openCreateForum, setOpenCreateForum] = useState(false);
  const [forumStatData, setForumStatData] = useState([]);

  const { theme, toggleTheme } = useContext(ThemeContext);

  const toggleAccordion = (index) => {
    setIsCollapsed((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  useEffect(() => {
    axiosService
      .get(`${FORUM_DISCUSSION_URLS.GET_ALL_FORUM}`)
      .then((response) => {
        const data = response?.data?.data;
        setForumData(data);
      });
  }, [openCreateForum]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };

    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const fetchData = () => {
    axiosService
      .get(`${FORUM_DISCUSSION_URLS.GET_FORUM_STATS}`)
      .then((response) => {
        const data = response?.data?.data;
        setForumStatData(data);
      })
      .catch((error) => {
        console.error("Error fetching forum statistics:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="communication-management-main-container custom-scrollbar">
      <div className="communication-management-head-container sticky-header-communication flex-row gap-2">
        <div className="d-flex align-items-center gap-3">
          <span className="communication-management-title-text">Forums</span>
        </div>
        <div className="d-flex align-items-center gap-3">
          {hasPermission("Forums: create") && (
            <button
              className="communication-save-head-button"
              onClick={() => setOpenCreateForum(true)}
            >
              Create New Forum
            </button>
          )}
        </div>
      </div>
      <div className="forum-discussion-main-container flex-column flex-lg-row">
        <div className="view-forum-discussion-left-container  custom-scrollbar">
          {forumData
            ?.slice()
            .reverse()
            ?.map((data, index) => (
              <div className="forum-accordian-container" key={index}>
                <div className="forum-accordian-title-container">
                  <span className="forum-accordian-title-text">
                    {data?.forumName}
                  </span>
                  <span className="d-flex align-items-center gap-1 cursor-poniter-event">
                    <span
                      className="forum-accordian-view-all-text"
                      onClick={() =>
                        navigation(
                          "/communication-management/view-forum-discussion",
                          {
                            state: {
                              forumId: data?.forumId,
                            },
                          }
                        )
                      }
                    >
                      view all{" "}
                    </span>
                    <span
                      className={`forum-dropdown-icon ${
                        isCollapsed[index] ? "rotate-180" : ""
                      }`}
                      onClick={() => toggleAccordion(index)}
                    >
                      <RxTriangleDown />
                    </span>
                  </span>
                </div>
                <div className="forum-discription-text">
                  {data?.forumDescription}
                </div>
                <div
                  className={`accordian-closed ${
                    isCollapsed[index] && "accordian-open"
                  }`}
                >
                  <table className="d-none d-lg-table forum-table">
                    <thead>
                      <tr className="forum-table-header">
                        <th className="forum-table-header-text forum-table-header-topic">
                          Topic
                        </th>
                        <th className="forum-table-header-text forum-table-header-Replies">
                          Replies
                        </th>
                        {/* <th className="forum-table-header-text forum-table-header-Views">
                          Views
                        </th> */}
                        <th className="forum-table-header-text forum-table-header-Latest">
                          Latest
                        </th>
                      </tr>
                    </thead>

                    {data?.discussions
                      ?.slice()
                      .reverse()
                      ?.map((discussion) => (
                        <tbody>
                          <tr>
                            <td className="forum-td forum-topic-td">
                              <button
                                className="forum-topic-inner-td-line-1"
                                onClick={() =>
                                  navigation(
                                    "/communication-management/view-discussion",
                                    {
                                      state: {
                                        discussionId: discussion?.discussionId,
                                      },
                                    }
                                  )
                                }
                              >
                                {discussion?.title}
                              </button>
                              <span className="forum-topic-inner-td-line-2">
                                <span className="forum-topic-poster-name">
                                  {discussion?.createdUser?.firstName}{" "}
                                  {discussion?.createdUser?.lastName}
                                </span>
                                <span className="forum-topic-poster-date">
                                  {formatDate(discussion?.addedOn)}
                                </span>
                              </span>
                            </td>
                            <td className="forum-td forum-td-text">
                              {discussion?.posts?.length || 0}
                            </td>

                            {/* <td className="forum-td forum-td-text">
                              {discussion?.views}
                            </td> */}
                            <td className="forum-td">
                              <div className="forum-td-latest">
                                {discussion?.posts
                                  ?.slice(0, 3)
                                  .reverse()
                                  .map((post, index) => (
                                    <img
                                      key={index}
                                      alt="img"
                                      src={
                                        post.user?.profileImage || useravatar
                                      }
                                      className={`forum-td-latest-user-icon-${
                                        index + 1
                                      }`}
                                    />
                                  ))}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                  </table>
                  {data?.discussions
                    ?.slice()
                    .reverse()
                    ?.map((discussion, index) => (
                      <div className="forum-table-card-container-mobile d-block d-lg-none">
                        {index === 0 && (
                          <p className="forum-discussion-table-head-mobile m-0 mb-1">
                            Discussions
                          </p>
                        )}
                        <p className="forum-discussion-title-mobile">
                          {discussion?.title}
                        </p>
                        <div className="d-flex justify-content-between">
                          <span className="forum-discussion-poster-mobile">
                            {" "}
                            {discussion?.createdUser?.firstName}{" "}
                            {discussion?.createdUser?.lastName}
                          </span>
                          <span className="forum-discussion-post-time">
                            {formatDate(discussion?.addedOn)}
                          </span>
                        </div>
                        <div className="d-flex gap-5 mt-3">
                          <div className="d-flex flex-column gap-2">
                            <span className="forum-discussion-table-head-mobile">
                              Replies
                            </span>
                            <span className="forum-discussion-table-data-mobile">
                              {discussion?.posts?.length || 0}
                            </span>
                          </div>

                          <div className="d-flex flex-column gap-2 ps-2">
                            <span className="forum-discussion-table-head-mobile">
                              Latest
                            </span>
                            <div className="forum-td-latest ps-0 text-white">
                              {discussion?.posts?.length > 0
                                ? discussion?.posts
                                    ?.slice(0, 3)
                                    .reverse()
                                    .map((post, index) => (
                                      <img
                                        key={index}
                                        alt="img"
                                        src={
                                          post.user?.profileImage || useravatar
                                        }
                                        className={`forum-td-latest-user-icon-${
                                          index + 1
                                        }`}
                                      />
                                    ))
                                : "-"}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  {data?.discussions.length === 0 && (
                    <div className="no-discussion-show-text">
                      No discussion to display
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>

        <div className="view-forum-discussion-right-container custom-scrollbar">
          <div className="forum-discussion-online-staff-container">
            <div className="forum-discussion-online-staff-head">
              Forum Moderator ({forumStatData?.forumModerators?.length})
            </div>
            {forumStatData?.forumModerators
              ?.slice()
              .reverse()
              ?.slice(0, 4)
              ?.map((forum) => (
                <div className="forum-discussion-online-staff-profile-container">
                  <span className="discussion-poster-container">
                    <img
                      alt="img"
                      src={
                        forum?.profileImage || theme === "light"
                          ? useravatarblack
                          : useravatar
                      }
                      className="poster-or-staff-profile-image"
                    />
                    <span className="discussion-poster-staff-name-container">
                      <div className="forum-online-staff-profile-name">
                        {forum?.profileName}
                      </div>
                      <div className="forum-online-staff-profile-designation">
                        {forum?.designation}
                      </div>
                    </span>
                  </span>
                  <button className="forum-online-staff-profile-button">
                    {forum?.forumRole}
                  </button>
                </div>
              ))}
          </div>

          <div className="forum-discussion-online-staff-container">
            <div className="forum-discussion-online-staff-head">
              Forum Stats
            </div>
            <div className="forum-discussion-stats-text-container">
              <span className="forum-stats-label">Total Discussions</span>
              <span className="forum-stats-value">
                {forumStatData?.totalDiscussions}
              </span>
            </div>
            <div className="forum-discussion-stats-text-container">
              <span className="forum-stats-label">Total Forum</span>
              <span className="forum-stats-value">
                {forumStatData?.totalForum}
              </span>
            </div>
            <div className="forum-discussion-stats-text-container">
              <span className="forum-stats-label">Members</span>
              <span className="forum-stats-value">
                {forumStatData?.members}
              </span>
            </div>
            <div className="forum-discussion-stats-text-container">
              <span className="forum-stats-label">Newest Members</span>
              <span className="forum-three-user-container">
                <img
                  alt="img"
                  src={
                    forumStatData?.newestMembers?.[0]?.profileImage ||
                    useravatar
                  }
                  className="forum-td-latest-user-icon-1"
                />
                <img
                  alt="img"
                  src={
                    forumStatData?.newestMembers?.[1]?.profileImage ||
                    useravatar
                  }
                  className="forum-td-latest-user-icon-2"
                />
                <img
                  alt="img"
                  src={
                    forumStatData?.newestMembers?.[2]?.profileImage ||
                    useravatar
                  }
                  className="forum-td-latest-user-icon-3"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      {openCreateForum && (
        <CreateForum
          open={openCreateForum}
          onClose={() => {
            setOpenCreateForum(false);
            fetchData();
          }}
        />
      )}
    </div>
  );
}

export default ViewForums;
