import React, { useContext, useEffect, useState } from "react";
import "./ViewProgram.css";
import { useLocation, useNavigate } from "react-router-dom";
import { PROGRAM_URLS } from "modules/academic-management/constants/program-constants-urls";
import { axiosService } from "services/axios.service";
import { RxTriangleUp } from "react-icons/rx";
import { IoIosArrowBack } from "react-icons/io";
import sessionService from "services/sessionstorage.service";
import { ThemeContext } from "ThemeContext";

function ViewProgram() {
  const location = useLocation();
  const navigate = useNavigate();

  const Data = location.state;
  const eventData = Data?.data?.Data;

  const userData = sessionService.getUserData("userData");
  const studentId = userData?.admissionManagement?.personalData?.studentId;

  const { theme, toggleTheme } = useContext(ThemeContext);

  const [programData, setProgramData] = useState("");

  const programId = eventData?.programFeeMasterId;
  const [selectedModules, setSelectedModules] = useState([]);

  useEffect(() => {
    if (programId) {
      axiosService
        .get(`${PROGRAM_URLS.GET_PROGRAM_BY_PROGRAM_ID}/${programId}`)
        .then((response) => {
          if (response.status === 200) {
            const responseData = response.data.data;
            setProgramData(responseData?.master);
            setSelectedModules(responseData?.response);
          }
        });
    } else if (studentId) {
      axiosService
        .get(`${PROGRAM_URLS.GET_PROGRAM_BY_STUDENT_ID}/${studentId}`)
        .then((response) => {
          if (response.status === 200) {
            const responseData = response.data.data;
            setProgramData(responseData?.master);
            setSelectedModules(responseData?.response);
          }
        });
    }
  }, [location.pathname, programId, studentId]);

  const [isAllSemestersExpanded, setIsAllSemestersExpanded] = useState(true);
  const [isSemesterCollapsed, setIsSemesterCollapsed] = useState(
    new Array(selectedModules?.length).fill(false)
  );

  const toggleAllSemesters = () => {
    setIsAllSemestersExpanded(!isAllSemestersExpanded);
    setIsSemesterCollapsed(
      new Array(selectedModules?.length).fill(!isAllSemestersExpanded)
    );
  };
  const toggleAccordion = (semesterIndex) => {
    const newCollapsedState = [...isSemesterCollapsed];
    newCollapsedState[semesterIndex] = !newCollapsedState[semesterIndex];
    setIsSemesterCollapsed(newCollapsedState);
  };
  const semesterModules = selectedModules.map(
    (semesterModules, semesterIndex) => (
      <div key={semesterIndex}>
        <div
          style={{
            borderBottom:
              theme === "light" ? "2px solid #ffffff" : "2px solid #333333",
          }}
        >
          <div className="add-program-title-container-sem">
            <span className="forum-accordian-sub-text">
              Semester {semesterIndex + 1}
            </span>
            <span
              className="d-flex align-items-center gap-1 cursor-poniter-event"
              onClick={() => toggleAccordion(semesterIndex)}
            >
              <span className="add-program-view-all-text">
                Total Credits
                <span className="semester-counts">
                  {semesterModules.reduce(
                    (acc, module) => acc + module.betaCredits,
                    0
                  )}
                </span>
                <span
                  className={`program-dropdown-icon ${
                    isSemesterCollapsed[semesterIndex] ? "rotate-180" : ""
                  }`}
                >
                  <RxTriangleUp />
                </span>
              </span>
            </span>
          </div>
          {semesterModules.map((module, moduleIndex) => (
            <div
              key={moduleIndex}
              className="modules-view-container row"
              style={{
                borderBottom:
                  theme === "light" ? "2px solid #ffffff" : "2px solid #333333",
                marginBottom: "5px",
                marginLeft: "2px",
                display: isSemesterCollapsed[semesterIndex] ? "none" : "block",
              }}
            >
              <span
                className="module-name col-12 d-flex justify-content-start"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/academics-management/my-module-view", {
                    state: {
                      data: {
                        Data: module,
                      },
                    },
                  });
                }}
              >
                {module.moduleName}
              </span>
              <div className="column d-flex justify-content-between">
                <span className="-">{module.moduleCode}</span>
                <span className="beta-credits">{module.betaCredits}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  );

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="academics-main-container">
      <div className="sticky-header lead-table-head-container align-items-center justify-content-sm-start flex-row flex-wrap gap-2">
        <div className="d-flex gap-1 align-items-center">
          {(userData?.role?.name === "faculty" ||
            userData?.role?.name === "super admin") && (
            <span onClick={handleGoBack} style={{ cursor: "pointer" }}>
              <IoIosArrowBack size={20} />
            </span>
          )}

          <h6 className="lead-table-title-text">
            {programData.program || "-"} ({programData.programCode || "-"})
          </h6>
        </div>

        <div className="d-flex ms-sm-auto align-items-center gap-3">
          <div className="academic-all-program-module-count">
            Pending Requests<span>0</span>
          </div>
          <div className="academic-all-program-module-count border-0 pe-0 pe-md-3">
            Open Requests<span>0</span>
          </div>
        </div>
        {(userData?.role?.name === "faculty" ||
          userData?.role?.name === "super admin") && (
          <div className="d-flex align-items-center flex-wrap">
            <button
              className="user-head-button"
              onClick={() =>
                navigate("/academics-management/add-program", {
                  state: { programId: programData?.programFeeMasterId },
                })
              }
            >
              Edit Program
            </button>
          </div>
        )}
      </div>

      <div className="row g-0 d-flex justify-content-between mt-2 ">
        <div className="col-12 col-md-7 col-lg-9 pe-lg-2 custom-scrollbar ">
          <div className="view-program-sub-header">Program Details</div>
          <div className="col-12 row g-0">
            <div className="program-info-sub-view col-12 col-lg-6">
              <div className="inner-info-view row g-0">
                <span className="program-info-left col-5">Program Name</span>
                <span className="program-info-right col-7 ps-4 ps-md-3 ">
                  {programData.program || "-"}
                </span>
              </div>
              <div className="inner-info-view row g-0">
                <span className="program-info-left col-5">Program Code</span>
                <span className="program-info-right col-7 ps-4 ps-md-3 ">
                  {programData.programCode || "-"}
                </span>
              </div>
              <div className="inner-info-view row g-0">
                <span className="program-info-left col-5">Academic Status</span>
                <span className="program-info-right col-7 ps-4 ps-md-3 ">
                  {programData.academicStatus || "-"}
                </span>
              </div>
              <div className="inner-info-view row g-0">
                <span className="program-info-left col-5">
                  Program Duration
                </span>
                <span className="program-info-right col-7 ps-4 ps-md-3 ">
                  {programData.programDuration || "-"}
                </span>
              </div>
              <div className="inner-info-view row g-0">
                <span className="program-info-left col-5">
                  Exchange Program
                </span>
                <span className="program-info-right col-7 ps-4 ps-md-3 ">
                  {programData.exchangeProgram || "-"}
                </span>
              </div>
              <div className="inner-info-view row g-0">
                <span className="program-info-left col-5">Type of Program</span>
                <span className="program-info-right col-7 ps-4 ps-md-3 ">
                  {programData.programType || "-"}
                </span>
              </div>
              <div className="inner-info-view row g-0">
                <span className="program-info-left col-5">Study Level</span>
                <span className="program-info-right col-7 ps-4 ps-md-3 ">
                  {programData.studyLevel || "-"}
                </span>
              </div>
              <div className="inner-info-view row g-0">
                <span className="program-info-left col-5">Schlorship</span>
                <span className="program-info-right col-7 ps-4 ps-md-3 ">
                  {programData.scholarship || "-"}
                </span>
              </div>
            </div>
            <div className="program-info-sub-view col-12 col-lg-6">
              <div className="inner-info-view row g-0">
                <span className="program-info-left col-5">
                  Work Practicum (Hours)
                </span>
                <span className="program-info-right col-7 ps-4 ps-md-3 ">
                  {programData.workPracticum || "-"}
                </span>
              </div>
              <div className="inner-info-view row g-0">
                <span className="program-info-left col-5">Hours per week</span>
                <span className="program-info-right col-7 ps-4 ps-md-3 ">
                  {programData.hoursPerWeek || "-"}
                </span>
              </div>
              <div className="inner-info-view row g-0">
                <span className="program-info-left col-5">
                  No. of Semesters
                </span>
                <span className="program-info-right col-7 ps-4 ps-md-3 ">
                  {programData.noOfSemesters || "-"}
                </span>
              </div>
              <div className="inner-info-view row g-0">
                <span className="program-info-left col-5">
                  Max. modules / Semester
                </span>
                <span className="program-info-right col-7 ps-4 ps-md-3 ">
                  {programData.semester || "-"}
                </span>
              </div>
              <div className="inner-info-view row g-0">
                <span className="program-info-left col-5">Campus</span>
                <span className="program-info-right col-7 ps-4 ps-md-3 ">
                  {programData.campus || "-"}
                </span>
              </div>
              <div className="inner-info-view row g-0">
                <span className="program-info-left col-5">
                  Program Coordinator
                </span>
                <span className="program-info-right col-7 ps-4 ps-md-3 ">
                  {programData?.programCoordinator?.firstName || "-"}
                </span>
              </div>
              <div className="inner-info-view row g-0">
                <span className="program-info-left col-5">Student Advisor</span>
                <span className="program-info-right col-7 ps-4 ps-md-3 ">
                  {programData.studentAdvisor?.firstName || "-"}
                </span>
              </div>
              <div className="inner-info-view row g-0">
                <span className="program-info-left col-5">Class Timings</span>
                <span className="program-info-right col-7 ps-4 ps-md-3  d-flex flex-wrap">
                  {programData?.isMorning && <span>Morning</span>}
                  {programData?.isMorning && programData?.isAfternoon && ", "}
                  {programData?.isAfternoon && <span>Afternoon</span>}
                  {(programData?.isMorning || programData?.isAfternoon) &&
                    programData?.isEvening &&
                    ", "}
                  {programData?.isEvening && <span>Evening</span>}
                  {!(
                    programData?.isMorning ||
                    programData?.isAfternoon ||
                    programData?.isEvening
                  ) &&
                    programData?.startTime &&
                    programData?.endTime && (
                      <span>
                        {programData.startTime} - {programData.endTime}
                      </span>
                    )}
                </span>
              </div>
              <div className="inner-info-view row g-0">
                <span className="program-info-left col-5">
                  Mode of Learning
                </span>
                <span className="program-info-right col-7 ps-4 ps-md-3 ">
                  {programData?.modeOfLearning || "-"}
                </span>
              </div>
            </div>
          </div>
          <div className="program-info-sub-view col-12 mt-2">
            <div className="view-program-sub-header">Program Overview</div>
            <span
              className="program-view-text col-12 "
              dangerouslySetInnerHTML={{
                __html: programData.programOverview || "-",
              }}
            ></span>
          </div>
          <div className="program-info-sub-view col-12 mt-2">
            <div className="view-program-sub-header">Career Path</div>
            <span
              className="program-view-text col-12"
              dangerouslySetInnerHTML={{
                __html: programData.careerPath || "-",
              }}
            ></span>
          </div>
          <div className="program-info-sub-view col-12 mt-2">
            <div className="view-program-sub-header">Admission Requirement</div>

            <span
              className="program-view-text col-12"
              dangerouslySetInnerHTML={{
                __html: programData.admissionRequirements || "-",
              }}
            ></span>
          </div>
          <div className="program-info-sub-view col-12 mt-2">
            <div className="view-program-sub-header">
              English Proficiency Requirement
            </div>

            <span
              className="program-view-text col-12"
              dangerouslySetInnerHTML={{
                __html: programData.englishProficiencyRequirement || "-",
              }}
            ></span>
          </div>

          <div className="program-info-sub-view col-12 mt-2">
            <div className="view-program-sub-header">Course Outcome</div>

            <span
              className="program-view-text col-12"
              dangerouslySetInnerHTML={{
                __html: programData.courseOutcome || "-",
              }}
            ></span>
          </div>
        </div>

        <div className="col-12 col-md-4 col-lg d-flex flex-column ps-lg-2">
          <div className="add-program-title-container">
            <span className="view-program-sub-header">Module Details</span>
            <span className="d-flex align-items-center gap-1 cursor-poniter-event">
              <span
                className="forum-accordian-view-all-text"
                onClick={toggleAllSemesters}
              >
                {isAllSemestersExpanded ? "Expand all" : "Collapse all"}
              </span>
            </span>
          </div>
          {selectedModules?.length === 0 ? (
            <div className="forum-discussion-online-staff-container">
              <div className="academics-module-details-view">
                <span>Number of semesters is empty.</span>
              </div>
            </div>
          ) : (
            <div>{semesterModules}</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewProgram;
