import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { EXAMS_URL } from "modules/exams-marksheets/constants/exams-url";
import { axiosService } from "services/axios.service";
import { toast } from "react-toastify";
import "./FacultyExams.css";
import sessionService from "services/sessionstorage.service";
import Swal from "sweetalert2";
import { ThemeContext } from "ThemeContext";

function FacultyAnswerCorrection() {
  const [section, setSection] = useState(1);
  const { theme, toggleTheme } = useContext(ThemeContext);
  const location = useLocation();
  const Data = location.state;
  const data = Data?.answer;
  const questionData = Data?.examModule;
  const totalSections = data.sections.length;
  const totalQuestions = data.sections.reduce(
    (acc, sec) => acc + sec.questions.length,
    0
  );
  const userData = sessionService.getUserData("");
  const userId = userData?.userId;
  const [mark, setMark] = useState(0);

  const [remarks, setRemarks] = useState("");
  const navigation = useNavigate();
  const Options = ["True", "False"];
  const handleUpdateSubmit = () => {
    Swal.fire({
      title: "Confirm Submit",
      html: "Are you sure you want to Submit?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Submit",
      cancelButtonText: "No, cancel",
      customClass: {
        popup: "my-custom-class",
        content: "custom-swal-text",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleSubmit(false);
      }
    });
  };
  const handleUpdateNotify = () => {
    Swal.fire({
      title: "Confirm Submit",
      html: "Are you sure you want to Submit?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Submit",
      cancelButtonText: "No, cancel",
      customClass: {
        popup: "my-custom-class",
        content: "custom-swal-text",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleSubmit(true);
      }
    });
  };
  const isSaveButtonDisabled = () => {
    return mark === "", remarks === "";
  };
  const handleSubmit = async (isNotify) => {
    const requestData = {
      ...Data,
      signedFaculty: { userId: userId },
      marks: mark,
      isEvaluated: true,
      remarks: remarks,
      duration: Data?.examModule?.duration,
      markPercentage: percentage,
    };
    try {
      const response = await axiosService.put(
        `${EXAMS_URL.UPDATE_STUDENT_MARKS}?isNotify=${isNotify}`,
        requestData
      );

      if (response.status === 200) {
        toast.success("Exam Marks Added Successfully");
        navigation("/exam-marksheets/all-student-answers");
      } else {
        toast.error("Error Occurred");
      }
    } catch (error) {
      toast.error(`Error: ${error.response.data.message}`);
    }
  };
  const getSectionStartIndex = (sectionIndex) => {
    return data.sections
      .slice(0, sectionIndex)
      .reduce((acc, sec) => acc + sec.questions.length, 0);
  };
  function getOptionColor(option, q) {
    const isSelected = (q.selectedOptions || []).includes(option);
    const isCorrect = (q.correctedOptions || []).includes(option);

    if (isSelected && isCorrect) {
      return "green";
    } else if (isSelected && !isCorrect) {
      return "red";
    } else if (!isSelected && isCorrect) {
      return "green";
    } else if (theme === "light") {
      return "#000000";
    } else {
      return "#ffffff";
    }
  }
  const handleFinalEvaluationClick = () => {
    setSection(data.sections.length + 1);
  };
  const totalMarks = data.sections.reduce(
    (total, sec) => total + (sec.marks || 0),
    0
  );
  const [userMarks, setUserMarks] = useState([]);

  const handleInputChange = (questionId, value) => {
    setUserMarks((prevMarks) => {
      const updatedMarks = {
        ...prevMarks,
        [questionId]: value,
      };
      updateTotalMarks(updatedMarks);
      return updatedMarks;
    });
  };

  const updateTotalMarks = (marks) => {
    const total = Object.values(marks)
      .map((mark) => Number(mark) || 0)
      .reduce((acc, curr) => acc + curr, 0);

    setMark(total);
  };

  useEffect(() => {
    updateTotalMarks(userMarks);
  }, [userMarks]);
  const marksData = (questionId) => {
    return section <= (questionData.sections?.length || 0)
      ? questionData.sections[section - 1].questions
          .filter((ques) => ques.questionId === questionId)
          .map((ques, index) => {
            const marks = ques.marks || "-";
            const userInput = userMarks[questionId] || "";
            const handleChange = (event) => {
              const value = event.target.value;
              if (
                /^\d*$/.test(value) &&
                (value === "" || Number(value) <= marks)
              ) {
                handleInputChange(questionId, value);
              }
            };

            return (
              <div
                key={ques.questionId}
                className="question-mark"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "15px",
                }}
              >
                <span>
                  <label
                    style={{
                      paddingRight: "5px",
                      color: theme === "light" ? "#000000" : "#ffffff",
                    }}
                  >
                    Marks Scored
                  </label>
                  <input
                    className="my-exam-question-input-mark"
                    value={userInput}
                    onChange={handleChange}
                  />
                </span>
                <div
                  style={{ color: theme === "light" ? "#000000" : "#ffffff" }}
                >
                  Marks: {marks}
                </div>
              </div>
            );
          })
      : null;
  };

  const calculatePercentage = (given, total) => {
    if (total === 0) return 0;
    return (given / total) * 100;
  };
  const percentage = calculatePercentage(mark, totalMarks).toFixed(2);
  return (
    <div className="exam-management-main-container">
      <div className="sticky-section-container">
        <div className="communication-management-head-container sticky-header-communication flex-sm-row gap-2">
          <div className="d-flex align-items-center gap-3">
            <span className="communication-management-title-text">
              My Exams
            </span>
          </div>
          <div className="d-flex align-items-center">
            <div
              style={{
                height: "36px",
                padding: "0 10px",
                borderRight: "1px solid #FFFFFF",
              }}
            >
              <div
                style={{
                  fontSize: "14px",
                  color: "#F69F3A",
                  fontWeight: "700",
                }}
              >
                Total Sections
              </div>
              <div
                style={{
                  fontSize: "14px",
                  color: theme === "light" ? "#000000" : "#ffffff",
                  fontWeight: "400",
                }}
              >
                {totalSections}
              </div>
            </div>
            <div
              style={{
                height: "36px",
                padding: "0 10px",
              }}
            >
              <div
                style={{
                  fontSize: "14px",
                  color: "#F69F3A",
                  fontWeight: "700",
                }}
              >
                Total Questions
              </div>
              <div
                style={{
                  fontSize: "14px",
                  color: theme === "light" ? "#000000" : "#ffffff",
                  fontWeight: "400",
                }}
              >
                {totalQuestions}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="my-exam-questions-section-heading-holder">
            {data.sections.map((sec, index) => (
              <button
                key={sec.sectionListId}
                onClick={() => setSection(index + 1)}
                className={
                  section === index + 1
                    ? "my-exam-questions-selected-section"
                    : ""
                }
              >
                Section {index + 1}
              </button>
            ))}
            <button
              className={
                section > totalSections
                  ? "my-exam-questions-selected-section"
                  : ""
              }
              onClick={handleFinalEvaluationClick}
            >
              Final Evaluation
            </button>
          </div>
        </div>
      </div>
      {/* {section <= totalSections && (
        <div className="mark-container">
          <div style={{ color: "#ffffff", paddingTop: "10px" }}>
            Marks Given:{" "}
            <input
              className="my-exam-question-input-mark"
              type="text"
              value={marksGiven[section]}
              // onChange={handleMarksChange}
            />
          </div>
          <div className="" style={{ color: "#ffffff", paddingTop: "10px" }}>
            Marks: {data.sections[section - 1]?.marks || "-"}
          </div>
        </div>
      )} */}
      <div className="questions-container">
        {section <= data.sections.length ? (
          data.sections[section - 1].questions.map((q, qIndex) => {
            const currentSectionStartIndex = getSectionStartIndex(section - 1);
            const currentQuestionNumber = currentSectionStartIndex + qIndex + 1;

            return (
              <div key={q.questionId}>
                <div style={{ color: "#ffffff" }}>
                  <div
                    style={{
                      borderRadius: "10px",
                      color: "white",
                      backgroundColor:
                        theme === "light" ? "#ffffff" : "#333333",
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      className="my-exam-question-question-no-div"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>
                        {" "}
                        Question {currentQuestionNumber}/{totalQuestions}
                      </span>

                      <p style={{ color: "#ffffff" }}>
                        {marksData(q.questionId)}
                      </p>
                    </div>

                    <div
                      className="my-exam-question-question-div"
                      dangerouslySetInnerHTML={{ __html: q.question }}
                      style={{ margin: "0px" }}
                    ></div>

                    <div style={{ padding: "10px 3px" }}>
                      {q.questionType === "Multi-choice questions (MCQ)" && (
                        <div style={{ margin: "10px 3px 10px 10px" }}>
                          <span className="my-exam-select-div">
                            Select an answer:
                          </span>
                          <div>
                            {q.options.map((option, index) => (
                              <div key={index} style={{ padding: "2px 0px" }}>
                                <input
                                  style={{ marginRight: "5px" }}
                                  type="radio"
                                  name={`mcq-${q.questionId}`}
                                  value={option}
                                  checked={q.selectedOption === option}
                                />
                                <label
                                  style={{
                                    color:
                                      q.selectedOption === option
                                        ? q.selectedOption === q.correctedOption
                                          ? "green"
                                          : "red"
                                        : option === q.correctedOption
                                        ? "green"
                                        : theme === "light"
                                        ? "#000000"
                                        : "#ffffff",
                                  }}
                                >
                                  {option}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                      {q.questionType === "True or False" && (
                        <div style={{ margin: "10px 3px 10px 10px" }}>
                          <span className="my-exam-select-div">
                            Select an answer:
                          </span>
                          <div>
                            {Options.map((option, index) => (
                              <div key={index} style={{ padding: "2px 0px" }}>
                                <input
                                  style={{ marginRight: "5px" }}
                                  type="radio"
                                  name={`mcq-${q.questionId}`}
                                  value={option}
                                  checked={q.selectedBooleanOption === option}
                                />
                                <label
                                  style={{
                                    color:
                                      q.selectedBooleanOption === option
                                        ? q.selectedBooleanOption ===
                                          q.correctedBooleanOption
                                          ? "green"
                                          : "red"
                                        : option === q.correctedBooleanOption
                                        ? "green"
                                        : theme === "light"
                                        ? "#000000"
                                        : "#ffffff",
                                  }}
                                >
                                  {option}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      {q.questionType === "Multi-select questions (MSQ)" && (
                        <div style={{ margin: "10px 3px 10px 10px" }}>
                          <span className="my-exam-select-div">
                            Select an answer:
                          </span>
                          <div>
                            {q.multipleOptions.map((option, index) => (
                              <div key={index} style={{ padding: "2px 0px" }}>
                                <input
                                  style={{ marginRight: "5px" }}
                                  type="checkbox"
                                  name={`msq-${q.questionId}`}
                                  value={option}
                                  checked={(q.selectedOptions || []).includes(
                                    option
                                  )}
                                />
                                <label
                                  style={{ color: getOptionColor(option, q) }}
                                >
                                  {option}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      {q.questionType ===
                        "Write short notes on the following" && (
                        <div
                          className="d-flex row m-0"
                          style={{ padding: "0px 10px" }}
                        >
                          <span
                            className="my-exam-select-div "
                            style={{ padding: "0px 0px 7px 0px" }}
                          >
                            Type Your answer
                          </span>
                          <textarea
                            maxLength={q.maxWords}
                            className="fill-textarea-box"
                            value={q?.textParaAnswer || ""}
                          />
                        </div>
                      )}
                      {q.questionType === "Essay Writing" && (
                        <div
                          className="d-flex row m-0"
                          style={{ padding: "0px 10px" }}
                        >
                          <span
                            className="my-exam-select-div "
                            style={{ padding: "0px 0px 7px 0px" }}
                          >
                            Type Your answer
                          </span>
                          <textarea
                            className="fill-textarea-box"
                            value={q?.textEssayAnswer || ""}
                          />
                        </div>
                      )}
                      {q.questionType === "Match the following" && (
                        <div>
                          <div style={{ marginLeft: "10px" }}>
                            <span className="my-exam-select-div">
                              Match the answer:
                            </span>
                            <div className="d-flex">
                              <div style={{ width: "40%" }}>
                                {q.studentAnsweredMatchOptions.map(
                                  (option, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        border: "1px solid #ccc",
                                        margin: "4px",
                                        padding: "8px",
                                        backgroundColor:
                                          theme === "light"
                                            ? "#ffffff"
                                            : "#333333",
                                        color:
                                          theme === "light"
                                            ? "#000000"
                                            : "#ffffff",
                                      }}
                                    >
                                      {option.question}
                                    </div>
                                  )
                                )}
                              </div>
                              <div style={{ width: "40%", marginLeft: "5%" }}>
                                <div>
                                  <div
                                    id={`rearrange-drop-items-${q.questionId}`}
                                    style={{ minHeight: "100px" }}
                                  >
                                    {q.studentAnsweredMatchOptions.map(
                                      (answer, index) => {
                                        const option = q.leftSideOptions[index];

                                        const isCorrect =
                                          answer.answer === option.answer;

                                        return (
                                          <div
                                            className="rearrange-drop-card"
                                            key={index}
                                          >
                                            <div
                                              style={{
                                                border: "1px solid #ccc",
                                                margin: "4px",
                                                padding: "8px",
                                                backgroundColor:
                                                  theme === "light"
                                                    ? "#ffffff"
                                                    : "#333333",

                                                color: isCorrect
                                                  ? "green"
                                                  : "red",
                                              }}
                                            >
                                              {answer.answer}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style={{ marginLeft: "10px" }}>
                            <span className="my-exam-select-div">
                              Correct Answer:
                            </span>
                            <div className="d-flex">
                              <div style={{ width: "40%" }}>
                                {q.leftSideOptions.map((option, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      border: "1px solid #ccc",
                                      margin: "4px",
                                      padding: "8px",
                                      backgroundColor:
                                        theme === "light"
                                          ? "#ffffff"
                                          : "#333333",
                                      color:
                                        theme === "light"
                                          ? "#000000"
                                          : "#ffffff",
                                    }}
                                  >
                                    {option.question}
                                  </div>
                                ))}
                              </div>
                              <div style={{ width: "40%", marginLeft: "5%" }}>
                                <div>
                                  <div
                                    id={`rearrange-drop-items-${q.questionId}`}
                                    style={{ minHeight: "100px" }}
                                  >
                                    {q.leftSideOptions.map((answer, index) => (
                                      <div
                                        className="rearrange-drop-card"
                                        key={index}
                                      >
                                        <div
                                          style={{
                                            border: "1px solid #ccc",
                                            margin: "4px",
                                            padding: "8px",
                                            backgroundColor:
                                              theme === "light"
                                                ? "#ffffff"
                                                : "#333333",
                                            color:
                                              theme === "light"
                                                ? "#000000"
                                                : "#ffffff",
                                          }}
                                        >
                                          {answer.answer}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {q.questionType === "Fill in the blanks" && (
                        <div>
                          <div
                            className="d-flex row m-0"
                            style={{ padding: "0px 10px" }}
                          >
                            <span
                              className="my-exam-select-div "
                              style={{ padding: "0px 0px 7px 0px" }}
                            >
                              Student Answer
                            </span>
                            <input
                              value={q.textAnswer || ""}
                              className="fill-input-box"
                            />
                          </div>
                          <div
                            className="d-flex row m-0"
                            style={{ padding: "0px 10px" }}
                          >
                            <span
                              className="my-exam-select-div "
                              style={{ padding: "10px 0px 7px 0px" }}
                            >
                              Correct answer :{" "}
                              <span>{q.correctedAnswer || "-"}</span>
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div style={{ paddingTop: "10px" }}>
            <div
              style={{
                borderRadius: "10px",
                color: "white",
                backgroundColor: theme === "light" ? "#ffffff" : "#333333",
                padding: "10px 5px",
              }}
            >
              <div className="my-exam-question-question-no-div">
                Final Evaluation
              </div>
              <div
                className="my-exam-question-question-div"
                style={{ border: "none" }}
              >
                <span>
                  <label>Total Marks</label>
                  <br />
                  <input
                    type="text"
                    className="my-exam-question-input-20"
                    value={totalMarks}
                    readOnly
                  />
                </span>
                <br />
                <span>
                  <label>Marks for student </label>
                  <br />
                  <input
                    type="text"
                    name="percentage"
                    className="my-exam-question-input-20"
                    value={mark}
                    onChange={(e) => setMark(e.target.value)}
                  />
                </span>
                <br />
                <span>
                  <label>Marks Percentage (%) </label>
                  <br />
                  <input
                    type="text"
                    name="percentage"
                    className="my-exam-question-input-20"
                    value={percentage}
                    readOnly
                  />
                </span>
                <br />

                <span>
                  <label>Remarks</label>
                  <br />
                  <input
                    type="text"
                    name="remarks"
                    className="my-exam-question-input-50"
                    value={remarks}
                    onChange={(e) => setRemarks(e.target.value)}
                  />
                </span>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="navigation-buttons-container sticky-nav-container">
        {section !== 1 && (
          <button
            className="communication-management-cancel-button"
            onClick={() => setSection(section - 1)}
          >
            Back
          </button>
        )}
        {section <= totalSections && (
          <button
            className="communication-management-cancel-button"
            onClick={() => setSection(section + 1)}
          >
            Next
          </button>
        )}
        {section > totalSections && (
          <button
            className={`user-save-head-button ${
              isSaveButtonDisabled() ? "user-save-head-button-disabled" : ""
            }`}
            disabled={isSaveButtonDisabled()}
            onClick={handleUpdateSubmit}
            style={{ height: "33px" }}
          >
            Submit
          </button>
        )}
        {section > totalSections && (
          <button
            className={`user-save-head-button ${
              isSaveButtonDisabled() ? "user-save-head-button-disabled" : ""
            }`}
            disabled={isSaveButtonDisabled()}
            onClick={handleUpdateNotify}
            style={{ height: "33px" }}
          >
            Submit & Notify
          </button>
        )}
      </div>
    </div>
  );
}

export default FacultyAnswerCorrection;
