import { useContext, useState } from "react";
import { FaEye } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import "./Policies.css";
import Modal from "react-modal";
import { isDesktop } from "react-device-detect";
import { ThemeContext } from "ThemeContext";

function Policies() {
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [activeItem, setActiveItem] = useState(null);

  const { theme, toggleTheme } = useContext(ThemeContext);

  const openModal = (policy) => {
    setSelectedPolicy(policy);
    document.body.style.overflow = "hidden";
    setActiveItem(policy === activeItem ? null : policy);
  };

  const closeModal = () => {
    setSelectedPolicy(null);
    setActiveItem(null);
    document.body.style.overflow = "auto";
  };

  const academic_and_attendance_policy = (
    <div className="policy custom-scrollbar">
      <div className="policy-inner-container">
        <button className="textStyles">Purpose / Rationale</button>
        <div className="ps-3" style={isDesktop ? { whiteSpace: "nowrap" } : {}}>
          <span>
            The principle of this policy is to increase the success of the
            learner and to create an environment where they can
            <br></br>
            use the College resources effectively. This policy defines the
            academic grounds and establishes protocols to <br></br> assist
            students, the instructors and administrative staff to observe and
            intervene when a<br></br>
            student is at risk.{" "}
          </span>
        </div>
      </div>
      <div className="policy-inner-container ">
        <button className="textStyles">Academic Progress Policy</button>
        <div className="ps-3">
          <h6 className="rights-heading">Academic Alert</h6>
          <span className="">
            If at any time the student is at risk academically, the instructor
            and student will set a meeting to discuss improvement strategies.
            Extra support will be<br></br> given where it is applicable.
          </span>
          <h6 className="rights-heading">Academic Probation</h6>
          <span className="">
            Student failing to achieve the set grade point average required to
            pass will be written to, indicating the academic probation and the
            consequences of<br></br> not improving the academic standing.
          </span>
          <br></br>
          <br></br>
          <p>Penalties for Academic Honesty Offences </p>
          <p className="ps-4">
            <span className="rights-heading">• Academic Honesty Policy</span> –
            Students are expected by the Beta College of Business and Technology
            to carry themselves in an honest and trustworthy manner in all
            aspects of their academia in and outside of the College. Breaking
            the Academic Honesty Policy includes, but are not limited to,
            cheating, plagiarism, falsification, impersonation,
            misrepresentation and procurement.
          </p>
          <p className="ps-4">
            <span className="rights-heading">• Plagiarism</span> – using someone
            else’s work as one’s own without giving credit to the source.
          </p>
          <div
            style={{
              marginLeft: "-1rem",
              fontFamily: "Poppins",
              fontSize: "16px",
            }}
          >
            <button className="textStyles">Penalty Chart</button>
          </div>
          <br></br>
          <table class="custom-table-policy">
            <tbody>
              <tr>
                <td>First Offence :</td>
                <td>
                  Results in a grade of “0” on the work in which the offence
                  occurred; without the opportunity of redoing the assignment
                  and having to redeem the grade on other assignments within the
                  course.
                </td>
              </tr>
              <tr>
                <td>Second Offence: </td>
                <td>
                  Results in an “F” in the subject where the offence occurred;
                  therefore failing the subject and having to retake the subject
                  at the student’s own extra cost.{" "}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  Further Violations:
                </td>

                <td>
                  Any further violation of the Academic Honesty Policy will
                  result in suspension.
                </td>
              </tr>
            </tbody>
          </table>
          <br></br>
          <br></br>
          <div
            style={{
              marginLeft: "-1rem",
              fontFamily: "Poppins",
              fontSize: "16px",
            }}
          >
            <button className="textStyles">
              Standards of Academic Progress
            </button>
          </div>
          <br></br>
          <p className="ps-4">
            <span className="rights-heading">• Grade Changes:</span> – Depending
            on the circumstance, there are certain situations for which grade
            changes are permissible. The student is required to meet the
            Director of Admissions to further discuss and to get a final
            approval.
          </p>
          <p className="ps-4">
            <span className="rights-heading">• Grade Notification:</span> The
            Instructors are held responsible to notify students of their grades
            midway before the course is over.
          </p>
          <p className="ps-4">
            <span className="rights-heading">• Final Examination:</span> All
            final exams will take place during the final exam period. Students
            are required to write the tests and final examinations at the
            scheduled time and place. In emergency circumstances, student may
            write a test or final examination before or after the scheduled time
            with the Director of Admissions approval.
          </p>
          <br></br>
          <span className="rights-heading">
            Students will be informed of any rule which may apply to the conduct
            of specific examination:
          </span>
          <br></br>
          <br></br>
          <p className="ps-4">
            • late entry to the final examination room thirty minutes after the
            scheduled commencement;
          </p>
          <p className="ps-4">
            • the use of calculators, cell phones, terminals or other
            information processing devices;
          </p>
          <p className="ps-4">
            • the use of supplementary books, manuals or reference resources;
          </p>
          <p className="ps-4">
            • the use of previously completed course material
          </p>
          <div
            style={{
              marginLeft: "-1rem",
              fontFamily: "Poppins;",
              fontSize: "16px",
            }}
          >
            <button className="textStyles">
              Attendance and Participation Policy
            </button>
          </div>
          <br></br>
          <br></br>
          <p className="ps-4">
            • Non-Attendance: Students who have not attended classes during the
            first half of the study period are assumed to have withdrawn from
            the course/College.
          </p>
          <p className="ps-4">
            • Attendance and participation are required for courses to
            successfully complete. Due to the nature, certain subjects need a
            high level of attendance and participation as a subject objective.
            Students will be reminded prior to starting classes.
          </p>
          <p className="ps-4">
            • If the student fails to have a consistent attendance, he/she will
            be required to meet the Director of Admissions to further discuss
            this before it leads to suspension.
          </p>
        </div>
      </div>
    </div>
  );
  const code_of_conduct = (
    <div className="policy custom-scrollbar">
      <div className="policy-inner-container">
        <button className="textStyles">Introduction & Purpose</button>
        <div className="ps-3" style={isDesktop ? { whiteSpace: "nowrap" } : {}}>
          {selectedPolicy === "Code of Conduct" && (
            <span>
              The core purpose of the Student Code is to ensure that students
              know their rights and responsibilities that they<br></br> bear as
              a member in the College community
            </span>
          )}
        </div>
      </div>
      <div className="policy-inner-container ">
        <button className="textStyles">Rights and Responsibilities</button>
        <div className="ps-3">
          <span className="rights-heading">
            All students enrolled in Beta College of Business & Technology
            become an integral part of the College community, and therefore are
            <br></br> expected to conform to the policies and procedures already
            set in place. This conduct highlights the rights and
            responsibilities that are to<br></br> be followed at all times.
            These rights and responsibilities reflect the College’s commitment
            to delivering quality education and to ensure<br></br> that there is
            a fair treatment and appropriate behavior by the students.
            <br></br>
            <br></br>
            <p className="ps-4"> • Student Rights</p>
          </span>
          <h6 className="rights-heading">
            The Code presents students with the right to:
          </h6>
          <p className="ps-4">
            • exercise their rights as citizens individually under the
            protection of the Ontario Human Rights Code and the Canadian Charter
            of Rights and Freedom.
            <p>
              • express themselves individually or as part of a group, except
              where such exercise is prohibited under the following Codes:
              Collage Violence Prevention Policy, Ontario Human Rights Code,
              Municipal, Provincial or Federal Law or other College Policies in
              effect.
              <p>
                • be free from discrimination, threats, assaults and harassment.
                <p>
                  • confidentially and access to personal records under Freedom
                  of Information and Protection of Privacy Act.
                  <p>
                    • to make reasonable complaints and take part without treat
                    of reprisal.
                    <p>
                      • access facilities and equipment intended for student
                      use.
                      <p>
                        • have a positive learning environment.
                        <p>
                          • be treated with fairness and equality in all matters
                          relevant to their performance, marks, and attendance
                          at the College.
                          <p>•Responsibilities</p>
                        </p>
                      </p>
                    </p>
                  </p>
                </p>
              </p>
            </p>
          </p>
          <br></br>
          <br></br>
          <h6 className="rights-heading">The Code requires the students to:</h6>
          <p className="ps-4">
            • uphold respects for others and their properties.
            <p>
              • use facilities of the College only if permission has been
              granted.
              <p>
                • carry themselves in a courteous and non-threatening manner.
                <p>
                  • behave in a way that is consistent with an environment that
                  is free from harassment, discrimination or hate and that
                  refrains from any conduct which infringes on the rights of
                  others.
                  <p>
                    • use information and technology (cell phone, e-mail,
                    internet, etc) in a non-threatening and non-discriminating
                    way.
                    <p>
                      • refrain from using threats or acting against a person’s
                      physical or mental well being that endangers or threatens
                      the safety, health, life, dignity or freedom of any
                      person.
                      <br></br>
                      <span className="rights-heading ps-2">
                        • example::
                      </span>{" "}
                      assault, verbal/non-verbal aggression, physical abuse,
                      intimidation, stalking or harassing.
                      <p>
                        • refrain from building unfounded complaints with
                        ulterior motives to use against another member of the
                        College community.
                        <p>
                          • perform with honestly in all academia and College
                          matters.
                          <p>
                            • refrain from activities that may cause damage to
                            the College property.
                            <p>
                              • refrain from taking or using property that is
                              not the student’s own or authorized to use.
                              <p>
                                • refrain from attempting to enter closed
                                College facilities and exit the College facility
                                when asked to do so.
                                <p>
                                  • participate in an investigation when
                                  requested to do so, if they were to arise
                                  under this policy.
                                </p>
                              </p>
                            </p>
                          </p>
                        </p>
                      </p>
                    </p>
                  </p>
                </p>
              </p>
            </p>
          </p>

          <p className="rights-heading">•Appliance of the Code</p>
          <p className="rights-heading">
            The conduct provision of the Code applies to following possible
            situations:
          </p>
          <p className="ps-4">
            • actions on/in the property within the physical boundaries of the
            College.
            <p>
              • actions on the internet or via the use of information
              technologies, on and off campus, directed at college community
              members
              <p>
                • carry themselves in a courteous and non-threatening manner.
                <p>
                  • actions on student placement sites.
                  <p>
                    • actions off campus against other college community
                    members.
                  </p>
                </p>
              </p>
            </p>
          </p>
          <br />
          <p className="rights-heading">
            Steps to process the Student Code of Conduct:
          </p>
          <p className="ps-4">
            1.If a disputes arises and a complaint is made, every effort should
            be made to have the party with the concern speak directly to the
            other party as<br></br> soon as possible with the attempt to solve
            the issue; if parties agree, this time may be extended over a
            certain timeline.
            <p>
              2. If the meeting is not successful, a written complaint is sent
              from the complainant party to the Office Administrator.
              <p>
                3. If there is no settlement following the complaint, there will
                be a meeting arranged for the complainant to meet the Director
                of Admissions.
                <p>
                  4.Letters will be sent outlining the discussion and final
                  decision to both parties.
                </p>
              </p>
            </p>
          </p>
        </div>
      </div>
    </div>
  );
  const expulsion_policy = (
    <div className="policy custom-scrollbar">
      <div className="policy-inner-container">
        <button className="textStyles">(WITHDRAWAL AND TERMINATION)</button>
        <div className="ps-3" style={isDesktop ? { whiteSpace: "nowrap" } : {}}>
          {selectedPolicy === "Expulsion Policy" && (
            <span>
              Beta College of Business and Technology is committed to taking all
              reasonable steps to ensure the students
              <br></br> have the opportunity to successfully complete their
              programs. Beta College of Business and Technology has <br></br>a
              commitment to ensure that within this general framework that all
              students are treated fairly and equitably. <br></br> Students who
              do not support the academic and ethical goals of the College for
              themselves and
              <br></br> their fellow students <br></br> may be subject to
              penalties, up to and including expulsion.
            </span>
          )}
        </div>
      </div>
      <div className="policy-inner-container ">
        <div className="ps-3">
          <span>
            In general, the College will attempt to resolve a situation without
            expulsion. Verbal warning, written warnings and suspension may
            precede this<br></br> final and most serious of actions. Where the
            College deems the integrity, safety or well-being of the College,
            students, staff, clients, visitors and <br></br>other guests is in
            ganger then expulsion may be applied at the College’s discretion at
            any point in the process.
            <br></br>
            <br></br>
            In conjunction with this policy, the college will have to ensure
            that students receive and are aware of its code of conduct, its
            academic policy, and its attendance policy.
          </span>
          <h6 className="rights-heading">
            The following outlines the conditions under which a student may be
            expelled with cause:
          </h6>
          <p className="ps-4">
            1. <span className="rights-heading">Academic Dishonesty -</span>{" "}
            students may be subject to expulsion at the discretion of the
            College for academic dishonesty. Academic dishonesty
            <br></br>
            is any word, action or deed performed alone, or with others for the
            direct or indirect intention of providing an unfair advantage or
            benefit to <br></br>self or other student(s) including:
            <br></br>
            <br></br>
            <p className="ps-5">
              a. cheating
              <p>
                b. plagiarism
                <p>
                  c. unapproved collaboration
                  <p>
                    d. alteration of records
                    <p>
                      e. bribery
                      <p>
                        f. lying
                        <p>g. misrepresentations</p>
                      </p>
                    </p>
                  </p>
                </p>
              </p>
            </p>
          </p>
          <span className="rights-heading">2. Outstanding Fees -</span> Failure
          to pay overdue accounts owing to the college within the specified
          period may be grounds for expulsion after a written<br></br> warning
          has been given.
          <br></br>
          <br></br>
          <span className="rights-heading">3. Code of Conduct –</span> All
          students are required to adhere to the College’s published code of
          conduct. Where the violations do not have the potential<br></br> to
          result in physical harm to persons or property the College may expel a
          student who has received suspension for failure to comply and has
          since<br></br> violated any of the terms of the College’s code of
          conduct. Students who are found under the influence of drugs and /or
          alcohol or carrying weapons
          <br></br> will be subject to immediate expulsion.
          <br></br>
          <br></br>
          <span className="rights-heading">
            4. Significant Omissions or Errors in Admissions Documentation –
          </span>{" "}
          The college has a responsibility to ensure students have been admitted
          in <br></br>accordance with the registration requirements for the
          program. Students who knowingly misrepresent their applications are
          subject to immediate
          <br></br>
          expulsion.
          <br></br>
          <br></br>
          <span className="rights-heading">5. Academic Failure –</span> Students
          who fail to achieve the required academic standing in their programs
          may be expelled from the program. The
          <br></br>
          College may at its discretion offer alternatives to a student and
          these are outlined in the academic policies for the program of study.
          <br></br>
          <br></br>
          <span className="rights-heading">6. Attendance –</span> Students who
          do not achieve the required attendance as stated in College policy are
          subject to expulsion.
          <br></br>
          <br></br>
          <span className="rights-heading">
            7. Harassment or Discrimination –
          </span>{" "}
          The College does not condone harassment or discrimination of any
          student, staff, client or visitor of the College.
          <br></br>
          Students participating in harassing or discriminatory activities, may
          be expelled at the discretion of the college, depending on the
          severity of the <br></br> activity and pending investigation.
          <br></br>
          <br></br>
          <p>
            Any student, who is deemed by the investigation to have engaged in
            severe harassing or discriminatory activities, may be expelled at
            the discretion of the college, depending on the severity of the
            activity.
          </p>
          <p>
            Racial harassment means bothering, threatening, or treating someone
            unfairly because of their race, colour, ancestry, birthplace,
            religious belief,<br></br> ethnic background, citizenship, or
            language. Sexual harassment means bothering someone by saying or
            doing unwanted or unwelcome things of a<br></br> sexual or
            gender-related nature including touching inappropriately, making
            offensive jokes about women and men, making sexual suggestions or{" "}
            <br></br>requests, starting at or making unwelcome comments about
            one’s body, displaying sexually offensive pictures, or being
            verbally abusive because of
            <br></br>
            one’s gender.
          </p>
          <p>
            Sexual orientation harassment means treating someone unequally
            because they are gay, lesbian, heterosexual, bisexual, or living in
            a same-sex relationship. This could include making a hurtful comment
            or action to an individual that is known or ought to be known to be
            unwelcome, making Homophobic jokes or hints about a person’s sexual
            orientation or same-sex partnership status, or displaying or
            disrespectful signs, caricatures, <br></br>
            cartoons or graffiti.
          </p>
          <p>
            In determining what constitutes harassment or discrimination, the
            College refers to The Ontario Human Rights Code. Students requiring
            more <br></br> specific information may refer to the specific code
            as posted on the Provincial web site
            (http://www/ohrc.on.ca/english/code/index.shtml).
          </p>
          <span className="rights-heading">
            8. Misuse of College Property –
          </span>{" "}
          College property is for the provision of College services. Students
          who damage, misuse, steal or otherwise use the
          <br></br>
          property in a way that is prohibited may be expelled and required to
          make restitution.
          <br></br>
          <br></br>
          <span className="rights-heading">
            9. Endangerment of Staff or Students –
          </span>{" "}
          The College is committed to the right of all College staff, students,
          clients and visitors to be safe. Students <br></br> who by action or
          neglect in any way endanger the safety of themselves or others may be
          expelled. Prior to expulsion, depending on the severity and <br></br>{" "}
          nature of the situation, the College may take intermediate steps at
          its discretion including:
          <br></br>
          <br></br>
          <p>a. verbal writing</p>
          <p>b. written warning</p>
          <p>c. suspension</p>
          <p>d. expulsion</p>
          <div
            style={{
              marginLeft: "-1rem",
              fontFamily: "Poppins",
              fontSize: "16px",
            }}
          >
            <button className="textStyles">Notification:</button>
          </div>
          <br></br>
          <p>
            Students who are subject to expulsion for any reason will be
            notified in writing, either hand delivered or by registered mail
            with return receipt. The<br></br> College is not responsible for
            non-delivery by registered mail if the student has not provided a
            valid home address where the student currently<br></br> resides. The
            notification will contain a description of the basis for expulsion
            and the effective date. Expelled students who dispute the facts of
            the<br></br> expulsion and wish to appeal must appeal the decision
            in writing within one week of the notification by following the
            college`s student complaint
            <br></br>
            procedure provided to the student and by providing sufficient proof
            to support the complaint.
          </p>
          A student whose expulsion is upheld after having followed the
          college`s student complaint and appeal procedure, may file a further
          appeal through<br></br> the Complaints process of the Superintendent
          of Career Colleges, provided the students are attending a program
          approved under the Ontario Career Colleges Act, 2005. If a student`s
          appeal is successful <br /> and he/she is eventually reinstated as
          part of the internal college or Ministry appeal
          <br></br> processes, then the college will arrange for the student to
          make up the training time that he/she had missed since the date of
          expulsion specified in<br></br> the written notification.
          <p></p>
          <div
            style={{
              marginLeft: "-1rem",
              fontFamily: "Poppins",
              fontSize: "16px",
            }}
          >
            <button className="textStyles">Fees:</button>
          </div>
          <br></br>
          <p>
            Settlement of student`s accounts, for students that have expelled,
            will be completed under the College`s Fee Refund Policy, using the
            effective date <br></br>of expulsion as the final day of attendance
            in their program of study.
          </p>
          <div
            style={{
              marginLeft: "-1rem",
              fontFamily: "Poppins",
              fontSize: "16px",
            }}
          >
            <button className="textStyles">Return of Property:</button>
          </div>
          <br></br>
          <p>
            A student who is expelled is responsible for the return of any
            College property in his/her own possession within 10 days of the
            expulsion and will be <br></br> held financially responsible for any
            property not returned in good condition or as outlined in the
            student contract.
          </p>
        </div>
      </div>
    </div>
  );
  const sexual_violence_and_harassment_policy = (
    <div className="policy custom-scrollbar">
      <div className="policy-inner-container">
        <div className="ps-3" style={isDesktop ? { whiteSpace: "nowrap" } : {}}>
          {selectedPolicy === "Sexual Violence and Harassment Policy" && (
            <span className="rights-heading">
              Sexual violence and assault policy
            </span>
          )}
        </div>
      </div>
      <div className="policy-inner-container ">
        <div className="ps-3">
          <span>
            The policy of sexual violence and assault will apply to all college
            communities like students, employees, contractors, instructors, and
            every single<br></br> person who is directly associated with college
            as a visitor or volunteer.
            <br></br>
            <br></br>
            The policy of violence and sexual assault is a guide or blueprint
            only. The Ontario human rights code and the criminal code (R.S.C)
            1985c. c-46 are mentioned here as precedence and need to be referred
            to all the cases.
          </span>
          <h6 className="rights-heading">Definitions</h6>
          <span className="rights-heading">
            Sexual violence and sexual assault
          </span>
          <br />
          <div style={{ marginTop: ".8rem" }}>
            <span className="rights-heading"> Sexual assault:</span> The
            criminal code of Canada defines the sexual assault as any sort of
            unwanted sexual activity by a person that infringes the<br></br>{" "}
            personal and sexual integrity of another person and includes acts
            like unwanted touch or physical penetration. Sexual assault or
            assailing sexual <br /> consists of a vast range of activities and
            behavior. Such as threats, force, abduction, or controlling another
            person that makes him/her distressed,
            <br /> anxious, deter, or any sexual activity which is done under
            the circumstances in which another person is unable to decide or not
            show his/her
            <br /> consent.
            <br></br>
            <br></br>
            <span className="rights-heading"> Sexual violence:</span> It is an
            extensive and comprehensive term to understand. Any means of
            assailing that includes physical and psychological violence{" "}
            <br></br>for sexual pleasure, or sexual favor is considered as
            sexual violence. Sexual violence has various forms, like sexual
            assault or abuse.
            <br></br>
            <br></br>
            <span className="rights-heading"> Consent:</span> The voluntary
            agreement to engage in the sexual activity in question. There are
            certain situations where no permission is obtained, where
            <br></br>
            <br></br>
            <p>
              1) The person with the inability to make a decision or
              non-communication cannot be considered as consent.
            </p>
            <p>
              2) A person who is unable to show approval in the state of
              unconsciousness, asleep, or incapable of communicating is also no
              rule of consent.
            </p>
            <p>
              3) An individual who has been deterred from indulging in sexual
              activity.
            </p>
            <p>4) A drugged person who is unable to show approval.</p>
            <p>
              5) A person is also not able to give consent during the
              consumption of alcohol or drugs.
            </p>
            <p>
              6) A mentally disabled person who is not entirely known to sexual
              knowledge.
            </p>
            <p>
              7) Any approval that had been in the past, during the
              relationship, dating for sexual activity, it doesn’t mean it will
              have existed the same in the future for the sexual act.
            </p>
            <p>
              8) A person can also withdraw its consent at any time while having
              sexual intercourse too.
            </p>
            <p>
              9) Permission cannot be allowed or given on behalf of the third
              person.
            </p>
            <p>
              10) Consent cannot be considered against the person who is in
              power and authority like an advancement from the teacher for their
              student or high post administrator concerning the junior member.
            </p>
            <p>
              It is essential to understand the fact that the initiator must
              communicate all the sexual acts or advancements at all levels or
              stages. And this is ought
              <br></br>
              of the initiator to know or ensure that the person with whom they
              are indulging the sexual activity is not minor.
            </p>
            <span className="rights-heading"> Note:</span> For information only,
            the Criminal Code describes “consent” as:
            <span className="rights-heading"> Consent:</span> The voluntary
            agreement to engage in the sexual activity in question. There are
            certain situations where no permission is obtained, where
            <br></br>
            <br></br>
            <p>
              1.a) Where the words or conduct deliver the approval of a person
              other than the complainant;
            </p>
            <p>
              1. b) Where the complainant is incapable of consenting to the
              activity;
            </p>
            <p>
              1. c) Where the accused induces the complainant to engage in the
              action by abusing a position of trust, power, or authority;
            </p>
            <p>
              1. d) Where the complainant expresses, by words or conduct, a lack
              of agreement to engage in the activity; or
            </p>
            <p>
              1. e) Where the complainant, having consented to engage in sexual
              activity, expresses, by words or conduct, a lack of agreement to
              continue to <br></br>
              participate in the event.
            </p>
            <span className="rights-heading">Some Relevant Terms</span>
            <br></br>
            <br></br>
            <span className="rights-heading">
              Acquaintance sexual assault:
            </span>{" "}
            Any sexual contact or touch that is done by force or without your
            consent by friend or partner or acquaintance.
            <br></br>
            <br></br>
            <span className="rights-heading">
              Minimum age of consent for sexual activity:
            </span>{" "}
            There is a certain age decided at which an individual can legally
            indulge in sexual activity. In Canada,
            <br></br> Sixteen is the age where a person can indulge in a sexual
            activity legally. And below 12 age is strictly not allowed to have
            such acts. Though still
            <br></br> there are some variations in the consent law. Like
            children who are 12 or 13 years old can have sex only with a person
            who is 1 or 2 years old from<br></br> their age. Those who are 14 or
            15 years old may have sex with a person who is less than five years
            old of their age. And above 16 are legally allowed
            <br></br>
            to have sexual acts according to their wish and who is not in
            authority or power.
            <br></br>
            <br></br>
            <span className="rights-heading">Coercion:</span> In terms of sexual
            assault or violence, it means to make someone pressurize for gaining
            sex favor or activity. It includes the use of threats<br></br> to
            known, blackmail, emotional manipulation, any false promises or
            rewards assurance to entice or lure someone for sexual activity.
            <br></br>
            <br></br>
            <span className="rights-heading">
              Drug-facilitated sexual assault:{" "}
            </span>{" "}
            <br></br>
            <br></br>
            <p>
              To make someone in control by the use of alcohol or drugs for
              sexual assault activity.
            </p>
            <span className="rights-heading">Stalking:</span>This is one of the
            inferior forms of sexual harassment that is forbidden by the
            criminal code. It includes the behavior in which a person or
            <br></br> victim is incessantly threatened through mental harassing
            and continuous gazing and staring by another person. Any indirect
            assault or act like <br></br>staring, threats to family and friends,
            vigilance, or cyberstalking, are considered as stalking.
            <br></br>
            <br></br>
            <span className="rights-heading">Survivor:</span>This can be
            conceived as a positive term. We call a person ‘survivor’ who has
            survived something deadliest or overcome from some lousy<br></br>{" "}
            incident that happened to them. Those who have faced sexual assault
            or any violence and have overcome from the instance are termed as
            <br></br>
            survivors.
            <br></br>
            <br></br>
            <span className="rights-heading">
              2) Intent and Purpose of the Policy
            </span>
            <br></br>
            <br></br>
            <p>
              All college members, administration staff, and students have the
              right to study and work in a healthy and sexual violence-free
              atmosphere. This set<br></br> of document will make you thoroughly
              understand our process against sexual crime and our procedure. The
              policy states that whosoever have<br></br> faced sexual assault
              are believed, and their rights will be respected, the college
              administration has a particular process of work against such
              complaints.
            </p>
            <br></br>
            <br></br>
            <span className="rights-heading">3) Policy Statement</span>
            <p>
              <br></br>
              Sexual crimes are heinous and cannot be tolerated in any
              circumstances. We are working and purely committed to eradicating
              any sexual offense chances and working towards making a safe and
              healthy environment for those who have ever faced these sorts of
              crimes. The college is purely observed to be reliable and fit for
              the working of its employees and students.
            </p>
            <p>
              The reported sexual related incidents will be scrutinized and
              solved with the best of the capability of administration in a
              proper manner.
            </p>
            <p>
              We understand that sexual crime or assault may occur between two
              or a person regardless of gender, sexual orientation, relationship
              status, or identity, as mentioned in the Ontario Human Rights
              Code. A person who suffered from sexual violence can face
              difficulties in their academics, mentally, and emotionally.
            </p>
            <p>The College will work towards:</p>
            <br></br>
            <p>
              3.1 Full assistance for those who have suffered from the sexual
              offense by providing them information regarding legal, medical
              counseling, and <br />
              support for better accommodation in academics.
            </p>
            <p>
              3.2 The victim will be believed, and his/her dignity will be
              protected throughout the investigation and institutional process.
            </p>
            <p>
              3.3 Addressing and monitoring harmful and contending behaviors
              (like blame against the victim or the victim is solely responsible
              for the assault).
            </p>
            <p>
              3.4 A behavioral full of mercy and compassion towards the person
              who discloses the sexual offense and understanding that they are
              only the best
              <br />
              decision-makers in their lives.
            </p>
            <p>
              3.5 On-campus process must be there for the sexual crime cases
              though the victim denies filing a police complaint.
            </p>
            <p>
              3.6 Making and carrying out the investigation and punishment
              process under the rules and regulations of the College with
              fairness.
            </p>
            <p>
              3.7 Proper communication between the departments who are involved
              in the on-campus sexual offense investigation.
            </p>
            <p>
              3.8 Spreading knowledge of sexual violence policies and rules
              among the college community.
            </p>
            <p>3.9 Indulging public prevention and education work.</p>
            <p>
              3.10 Providing proper training and appropriate education to the
              community of College about responding to the victim and sexual
              crime’s <br />
              disclosure.
            </p>
            <p>
              3.11 Making efforts in the creation of a sexual crime-free
              environment.
            </p>
            <p>
              3.12 Updating and monitoring protocols and policies to make great
              sets of rules for a healthy atmosphere.
            </p>
            <p>
              3.13 Ensuring that the person has the right to have a family
              member or friend to stay with her during the investigation or
              decision-making process.
            </p>
            <br></br>
            <span className="rights-heading">
              4) Reporting and Responses to Sexual offense
            </span>
            <br></br>
            <br></br>
            <p>
              4.1 All the members should react to the report as soon as possible
              after they come to know about the incident or they witness, or
              they have a strong <br />
              warning that the event can occur. Members who have faced sexual
              violence must come forward to report such incidents.
            </p>
            <p>
              4.2 Persons in authority or power must take immediate action after
              getting information regarding sexual assault.
            </p>
            <p>
              4.3 The college administration should take the necessary steps
              after acquiring information about sexual offense. It doesn’t
              matter that the incidents <br /> have happened in College or
              outside areas. If it is posing a threat to the security of the
              college community, then steps must be taken by the College.
            </p>
            <br></br>
            <span className="rights-heading">
              5) Complaint and Investigation process
            </span>
            <br />
            <br />
            <p>
              A complaint regarding sexual crime can be submitted under the
              mentioned policy by any staff or member of the College.
            </p>
            <p>
              The college needs to achieve fairness in procedural regarding the
              complaint. If someone has alleged for infringement of polices,
              then he/she will be
              <br /> served with notice time to answer the allegation gathered
              against him/her.
            </p>
            <br></br>
            <span className="rights-heading">
              5.1 Right to Withdraw (Complaint)
            </span>
            <br></br>
            <br></br>
            <p>
              A complainer has the full right to withdraw or take back their
              proceeding during any stage. The College may continue their work
              to satisfy their
              <br /> college policies and rules.
              <br></br>
              <br></br>
              <span className="rights-heading">
                5.2 Protection from Retaliation and Threats
              </span>
              <br></br>
              <br></br>
              <p>
                It is contrary to the present policy for anyone to take revenge,
                entangle in reprisals and deter to retaliate and strive against
                a litigator or other for:
              </p>
              <p>
                1) Having pursued rights under this Policy or the Ontario Human
                Rights Code;
              </p>
              <p>
                2) Having indulged or indirectly participated in an
                investigation beneath this policy.
              </p>
              <p>
                3) Any association with an individual who has pursued rights
                under the Ontario Human Rights Code.
              </p>
              <p>
                If any person indulges in any sort of mentioned activity, then
                he/she may be considered for sanctions and punishments.
              </p>
              <br></br>
              <span className="rights-heading">5.3 Vexatious Complaints</span>
              <br />
              <br></br>
              <p>
                If a person files (real purpose) a sexual complaint but the
                evidences are not enough to support his/her side, then the
                application or plea will be
                <br /> dismissed.
              </p>
              <p>
                If a person files (Evil motive) complaint, and it has found that
                the application is filled to defame or threaten someone, then
                the applicant may face
                <br />
                sanctions.
              </p>
            </p>
            <br></br>
            <span className="rights-heading">6) Confidentiality</span>
            <p>
              Confidentiality truly matters for those who have revealed the
              information regarding the sexual offense. The people related to
              cases and reports must be respected and observed strictly for
              their confidentiality purpose.
            </p>
            <p>
              Though confidentiality may not be assured during such
              circumstances:
            </p>
            <br></br>
            <p>1) A person who is at the brim of self-harm</p>
            <p>2) A person who is highly at risk of threatening another.</p>
            <p>
              3) Or it is believed that College and outside the community are at
              risk by the person.
            </p>
            <p>
              In some cases, where college administration becomes aware of an
              allegation that has been tainted by one college member to another,
              college<br></br> administration will work on their grounds to
              solve the issue under the rules and regulation of College. And
              only a few members who will be the <br></br> heads of
              investigating the problem get to know about the identities of
              persons.
            </p>
            <br></br>
            <span className="rights-heading">
              7) References to Policies or Legal Requirements
            </span>
            <br></br>
            <br></br>
            <p>The Criminal Code R.S.C., 1985 c. C-46,</p>
            <p>The Ontario Human Rights Code</p>
            <p>
              Ontario Network of Sexual Assault/Domestic Violence Treatment
              Centers at 76 Grenville Street, Toronto, ON M5S 1B2, Tel. (416)
              323-7327
            </p>
            <span className="rights-heading">
              SEXUAL VIOLENCE AND ASSAULT PROTOCOL
            </span>
            <br></br>
            <br></br>
            <span className="rights-heading">
              If you have suffered or experienced (sexual) violence, contact on
              college president numbers quickly at ­______.
            </span>
            <br></br>
            <br></br>
            <p>
              It is quite challenging to come up with sexual offenses and
              report, but it’s up to you. As an engaged community, we always
              motivate you to not shy<br></br> away with this sort of heinous
              crime.
            </p>
            <span className="rights-heading">
              You can call sexual (Assault/Domestic) Violence Treatment Centre
            </span>
            <br></br>
            <br></br>
            <p>
              Sexual assault/domestic violence treatment centers are in Ontario
              and assure with 24/7 services related to women, child, men, and
              all those who<br></br> are sexually assaulted, survivors, or
              victims of domestic violence. They offer services:
            </p>
            <br></br>
            <p>1) Counseling</p>
            <p>2) Medical care</p>
            <p>3) Crisis intervention</p>
            <p>4) Referral (community resources)</p>
            <p>5) Access to necessary treatment for S.T.D.</p>
            <p>6) Follow up (Medical)</p>
            <br></br>
            <p>
              Here, you can have a physical examination, can take medical care
              or collect essential tangible forensic testaments of an imminent
              sexual abuse. It<br></br> will be your final decision to make the
              police complaint or not after attaining the evidence. And if you
              considered yourself as drunk or under the <br></br> influence of
              alcohol during the sexual assault, then you can also get the test
              to determine the drug’s presence.
            </p>
            <span className="rights-heading">To contact:</span>
            <br></br>
            <br></br>
            <p>
              <span className="rights-heading">
                Ontario Network of Sexual Assault/Domestic Violence Treatment
                Centres,
              </span>{" "}
              76 Grenville Street, Toronto, ON M5S 1B2, Tel. (416) 323-732
            </p>
          </div>
        </div>
      </div>
    </div>
  );
  const deferral_policy = (
    <div className="policy custom-scrollbar">
      <div className="policy-inner-container">
        <button className="textStyles">POLICY</button>
        <div className="ps-3" style={isDesktop ? { whiteSpace: "nowrap" } : {}}>
          {selectedPolicy === "Deferral Policy" && (
            <span>
              • The following policy outlines the conditions under which a
              first-semester student enrolled with<br></br>
              Beta College of Business and Technology, here in after Beta
              College may defer to the intake.<br></br> A deferral is the
              postponement of the start date of a program of study for<br></br>{" "}
              which the initial Letter of Admission (LOA) was issued.
            </span>
          )}
        </div>
      </div>
      <div className="policy-inner-container ">
        <div className="ps-3">
          <span>
            • Any deferral approved by Beta College is strictly for
            institutional enrolment purposes and does not supersede Immigration,
            and Citizenship<br></br> Canada (IRCC)regulations as pertaining to
            the enforcement of Study Permits, Student Visas, Canada Border
            Services Agency (CBSA), or the <br></br>Immigration and Refugee
            Protection Act, 2001.
            <br></br>
            <br></br>
          </span>
        </div>
        <button className="textStyles">PROCEDURES</button>
        <div className="ps-3" style={isDesktop ? { whiteSpace: "nowrap" } : {}}>
          {selectedPolicy === "Deferral Policy" && <span></span>}
        </div>
      </div>
      <div className="policy-inner-container ">
        <div className="ps-3">
          <span>
            <p>
              • Students who cannot start classes on the intake indicated in
              their application may request deferment of admission to a later
              intake.{" "}
              <p>
                • Beta College advises students wishing to discontinue their
                studies (for any reason) to first consult with a licensed
                Immigration Consultant and
                <br></br> visit the IRCC website
                (https://www.canada.ca/en/services/immigration-citizenship.html
                ) for bylaws and regulations for international
                <br></br>
                students and study permits.
                <p>
                  • Students wishing to defer their acceptance to the next
                  intake must receive prior approval from the Director of
                  Academics or Admissions<br></br> Manager.{" "}
                </p>
              </p>
            </p>
          </span>
        </div>
        <button className="textStyles">DEFERRAL REQUEST APPROVALS</button>
        <div className="ps-3" style={isDesktop ? { whiteSpace: "nowrap" } : {}}>
          {selectedPolicy === "Deferral Policy" && <span></span>}
        </div>
      </div>
      <div className="policy-inner-container ">
        <div className="ps-3">
          <span>
            <p>
              • Deferral approval would constitute the student a Letter of
              Admission and a reserved seat in the corresponding intake.
              <p>
                • Deferrals may be granted in exceptional cases only and are
                assessed on a case-by-case basis.
                <p>
                  • Deferral request approvals are also subject to capacity and
                  availability in the corresponding semester.
                  <br></br>
                  <span className="rights-heading">
                    • To be eligible to request a deferral, students must have :
                  </span>{" "}
                  <br></br>
                  <p className="ps-4">
                    <p>
                      • Be an incoming semester one student and deferring for
                      the first time;
                      <p>
                        {" "}
                        •Paid all tuition fees in full; and
                        <p>
                          • Signed and completed the Deferral Request Form
                          (available at the Student Support Services office on
                          the Brampton campus).
                        </p>
                      </p>
                    </p>
                  </p>
                  <p>
                    • Deferral Request Form must be completed and submitted to
                    the Student Services 1 week prior to the commencement of the
                    semester for<br></br> which the student was issued the
                    Letter of Admission.
                    <p>
                      • The student will be notified whether the deferral
                      request was approved or not within three (3) days of
                      receiving the request.
                    </p>
                  </p>
                </p>
              </p>
            </p>
          </span>
        </div>
        <button className="textStyles">SUBSEQUENT DEFERRAL REQUESTS</button>
        <div className="ps-3" style={isDesktop ? { whiteSpace: "nowrap" } : {}}>
          {selectedPolicy === "Deferral Policy" && <span></span>}
        </div>
      </div>
      <div className="policy-inner-container ">
        <div className="ps-3">
          <span>
            <p>
              • Students can only apply and receive approval for deferral once.
              <p>
                • Any student that wishes to defer for the second time must
                officially withdraw and resubmit their application for
                re-admission to the college.
                <br></br> Admission requirements and procedures will still
                apply.
                <p>
                  • Application is subject to availability and the student will
                  incur additional application fees.
                  <br></br>
                </p>
              </p>
            </p>
          </span>
        </div>
        <button className="textStyles">
          STUDENTS WHO RECEIVE APPROVAL TO DEFER FORGO THE RIGHT FOR A REFUND IN
          THE CORRESPONDING INTAKE.
        </button>
        <div className="ps-3" style={isDesktop ? { whiteSpace: "nowrap" } : {}}>
          {selectedPolicy === "Deferral Policy" && <span></span>}
        </div>
      </div>
      <div className="policy-inner-container ">
        <div className="ps-3">
          <span>
            <p>
              • Students approved for deferral will be guaranteed enrollment in
              the corresponding intake and will therefore forgo any rights to a
              refund as<br></br> outlined in the Beta College Refund Policy.
            </p>
          </span>
        </div>
      </div>
    </div>
  );
  const student_complaint_grievance_procedure = (
    <div className="policy custom-scrollbar">
      <div className="policy-inner-container">
        <div className="ps-3" style={isDesktop ? { whiteSpace: "nowrap" } : {}}>
          {selectedPolicy === "Student Complaint Grievance Procedure" && (
            <span>
              Beta College of Business and Technology is committed to the prompt
              and equitable resolution <br /> of student conflict issues to the
              satisfaction of both the student and the School. The Student
              Complaint Procedure <br /> is designed to provide students with
              both an informal and formal process whereby a student may request{" "}
              <br />
              the review and resolution of a concern if satisfactory resolution
              has not been reached by way of the <br />
              daily problem-solving activities between staff and students which,
              in most cases, result in immediate resolution. <br />
              The student has the right to present his/her case and be
              accompanied by an individual
              <br /> of his/her choice at all times during the process, who make
              oral submissions on the students behalf.
            </span>
          )}
        </div>
      </div>
      <div className="policy-inner-container ">
        <div className="ps-3">
          <span>
            Students are encouraged to address any concerns immediately; please
            do not let a minor problem develop into a major one. Should you have
            any
            <br /> problems or concerns during your training period, we
            encourage you to discuss them promptly with the staff member
            directly involved. Should the
            <br /> resolution to your issue require further involvement, the
            staff member will arrange a meeting with the appropriate personnel,
            up to and including <br />
            the Campus Director.
          </span>
          <br />
          <br />
          <span className="rights-heading">Formal Procedure</span> <br></br>
          <br></br>
          <p>
            {" "}
            1. In the case where a student is unable to achieve a satisfactory
            resolution using the informal direct discussion approach recommended
            above, the <br /> student can request that a more formal complaint
            procedure be launched. To do so, a student complaint must be made in
            written by completing and <br />
            signing a Student Complaint Form, recording the concern and
            documenting the student’s desired resolution. Once signed, the form
            is given to the <br /> student, a copy is submitted to the Campus
            Director.
          </p>
          <br></br>
          <span className="rights-heading">The Campus Director is:</span>{" "}
          <br></br>
          <br></br>
          <span className="rights-heading">Name: </span>
          Ram Sivaharan
          <br></br>
          <br></br>
          <span className="rights-heading">Position: </span>
          Administrator
          <br></br>
          <br></br>
          <span className="rights-heading">Address: </span>
          Suite 301-2347 Kennedy Road, Toronto, ON M1T 3T8
          <br></br>
          <br></br>
          <span className="rights-heading">Telephone: </span>
          (416) 449-2382
          <br></br>
          <br></br>
          <span className="rights-heading">E-mail: </span>
          info@betacollege.ca
          <br></br>
          <br></br>
          <span className="rights-heading">Fax: </span>
          (416) 449-2380
          <br></br>
          <br />
          <p>
            2. The Campus Director will arrange to meet with the student within
            two working days of the date of the written complaint. If, as a
            result of that <br />
            meeting, the student and the Campus Director decide to implement a
            mutually agreed to complaint resolution plan, then that decision,
            the reason
            <br /> for the decision, and a description of the resolution plan
            must be recorded by the Campus Director in Part B on the student`s
            original complaint
            <br /> form. The original form must be co-signed by the student and
            the staff member in sub-section i) of Part B of the form. The
            original must be returned
            <br /> to the student and a copy must be filed in the student`s
            academic file.
          </p>
          <br></br>
          <p>
            3. If the student and the Campus Director reach a mutually agreed to
            resolution in step 2 above then the plan must be implemented and the{" "}
            <br /> Campus Director must follow up to ensure the resolution plan
            satisfactorily resolves the concern. Upon mutually satisfactory
            resolution of the
            <br />
            student concern, the Campus Director will meet briefly with the
            student to close the concern and record in Part B of the original
            Student Complaint <br />
            Form a confirmation that the concern has been satisfactorily
            resolved. The student and the Campus Director will sign the
            declaration at the bottom <br /> of Part B. The original form will
            be given back to the student and a copy will be filed in the
            student’s academic file. A second copy will be filed in the
            <br /> Campus Student Complaint Binder and kept for 3 years.
          </p>
          <br></br>
          <span className="rights-heading">Review Process</span> <br />
          <br />
          <p>
            1. Where a student concern has arisen that could not be resolved to
            the student’s satisfaction, through the formal procedure outlined
            above, the
            <br /> student may initiate an appeal. The student must complete and
            sign Part C (Request for a Review) of the original Student Complaint
            Form setting <br /> out the reasons why the formal procedure
            resolution was unsatisfactory. The student will again keep the
            original form and a copy will be submitted
            <br /> to the Director of Administration.
          </p>
          <p>
            2. The Campus Director will form a Student Complaint Committee to
            investigate the student’s concern and meet with the student within
            two (2) <br /> working days of the request for a review to discuss
            resolution. The committee will consist of a minimum of 3 people
            including an Academic Advisor, <br />
            Staff Member and the Campus Director.
          </p>
          <p>
            3. The Committee will report to the student by completing and
            signing Part D of the Student Complaint form within five (5) days of
            the conclusion of <br />
            its resolution investigation process. The written report will
            include a summary of the investigation findings and the committee’s
            complaint
            <br /> resolution decision including the reasons for arriving at
            that decision.
          </p>
          <p>
            4. The student will be given the original signed copy of the student
            complaint form. A copy of the student complaint form will be
            retained in the <br /> student’s academic file and a second copy
            will be placed in the Campus Student Complaint Binder, where it will
            remain for a minimum period of <br /> three (3) years. The Campus
            Director will maintain this binder on site for possible annual
            inspection.
          </p>
          <p>
            5. If the student is not satisfied with the college’s decision after
            the review process, he/she can file a complaint with the
            Superintendent of career <br /> colleges, provided the student is
            attending a program approved under the Ontario Career Colleges Act,
            2005. The Superintendent’s Student <br /> Complaint Form can be
            obtained from the Ministry’s web site
            http://www.edu.gov.on.ca/eng/general/private.html.
          </p>
          <br></br>
        </div>
      </div>
    </div>
  );
  const privacy_policy = (
    <div className="policy custom-scrollbar">
      <div className="policy-inner-container">
        <div className="ps-3" style={isDesktop ? { whiteSpace: "nowrap" } : {}}>
          {selectedPolicy === "Privacy Policy" && (
            <span>
              Beta College of Business and Technology (BCBT) is a career college
              founded in 2009and registered with
              <br /> the Ministry of Colleges and Universities (MCU) under the
              Ontario Career Colleges Act, 2005. The college
              <br></br> offers several qualifications in the form of Awards,
              certificates, and Recognized Diploma and Post Graduate <br></br>{" "}
              Diploma programs.
              <br></br>
              Different study modes are available for students within Ontario,
              Canada, and internationally.
            </span>
          )}
        </div>
      </div>
      <div className="policy-inner-container ">
        <div className="ps-3">
          <span className="rights-heading">Terms & Conditions:</span> <br></br>
          <br></br>
          <p>
            Awards, courses, programs, certificates, and diplomas are offered
            through the college and partner bodies, and their acceptance is
            subject to change.
          </p>
          <p>
            Schedule, affiliations, mode of delivery, campus location, etc., are
            subject to change.
          </p>
          <p>BCBT reserves all the ultimate rights to make changes.</p>
          <p className="ps-5">
            • We have an age restriction for users/minors below 18 years. They
            are not liable to register as users shall not conduct on or use the
            site.
            <p>
              • Security competencies and policy for sharing payment card
              details must be described on their site.
            </p>
          </p>
          <span className="rights-heading">Privacy Policy</span> <br></br>
          <br></br>
          <p>
            This privacy policy is for{" "}
            <a
              style={{ color: "#f69f3a" }}
              href="http://www.mybeta.ca"
              target="_blank"
            >
              www.mybeta.ca
            </a>
            , managed by Beta College of Business and Technology, Ontario,
            Canada. We use the data shared by you to
            <br /> provide and improve the services. Using our service(s), you
            agree to collect and use information compliant with this policy. The
            policy shows the <br /> different areas where user privacy is
            involved and defines the requirements and obligations of the users,
            website holders, and website. Moreover,
            <br /> how the collected user data and information is stored,
            processed, and protected will be specified in the policy.
          </p>
          <p>
            From time to time, we may update our privacy policy to company and
            user feedback. Stay informed of our practices and changes by
            periodically <br /> checking out this privacy policy.
          </p>
          <span className="rights-heading">Use of Cookies</span> <br />
          <p>
            This website employs cookies to enhance the users’ experience when
            visiting the site. Where relevant, this site utilizes a cookie
            control system when
            <br /> first visiting the site asking users to allow or deny cookies
            usage on their device.
          </p>
          <p>
            This meets the terms with the most up-to-date legislation
            prerequisites for sites to acquire open agreement from users ahead
            of going or examining
            <br /> files like cookies on the visitor’s device
            (computer/PC/mobile)
          </p>
          <p>
            Cookies are small files that are downloaded to the hard drive of the
            user’s computer and monitor, download, and store information about
            the user’s <br /> interactions and use of the website. This enables
            the website to provide users with a customized experience inside the
            site through its server.
          </p>
          <p>
            Users are advised to take the required measures inside their web
            browser’s security settings to block all cookies from this site and
            its external <br />
            partners if they wish to refuse the use and to store cookies from
            this site on their PC’s hard drive.
          </p>
          <p>
            This website employs tracking software to track its users to
            understand better how they interact with it. Google Analytics
            provides this software,
            <br /> which uses cookies to monitor visitor use. The software will
            store a cookie on your PC’s hard drive to monitor and control your
            interaction and use of <br />
            the site but will not save, save, or collect user data.
          </p>
          <p>
            For more details, see Google’s privacy rules{" "}
            <a
              href="https://www.google.com/privacy.html"
              target="_blank"
              style={{ color: theme === "light" ? "#000000" : "#FFFFFF" }}
            >
              here
            </a>
            . Google’s privacy page can be found at{" "}
            <a
              style={{ color: "#f69f3a" }}
              href="https://www.google.com/privacy.html"
              target="_blank"
            >
              https://www.google.com/privacy.html
            </a>
            .
          </p>
          <p>
            When this website contains supported links, referral programs, or
            advertisements, external vendors can place cookies on your pc /
            laptop. These <br />
            cookies monitor conversions and referrals and usually last for 30
            days, but some can last longer. There is no personal data processed,
            stored, <br /> saved, or collected.
          </p>
          <span className="rights-heading">Contact and Communication</span>{" "}
          <br />
          <br />
          <p>
            Users who contact this website or its owners should do it at their
            own risk and share any personal data requested at their own choice.
            Your data is <br />
            kept private and safe until it is no longer accurate. While we
            strive hard to provide a secure and safe form for the email
            submission process, users <br /> should be aware that they do so at
            their own risk. This site will use any information you provide, and
            its owners will continue providing you with <br /> additional
            information related to the goods and services they provide, as well
            as to help you answer the questions or concerns you might have{" "}
            <br />
            requested. Use of your data to subscribe to any email newsletter
            service (the website performs) is also included, but only if this
            was stated clearly to <br /> you and your special permission was
            provided when providing any application to the email provider.
            Alternatively, whether the customer (you) has <br /> previously
            bought or communicated regarding the company’s offered products or
            services relevant to the email newsletter. Since your information is{" "}
            <br />
            not shared with any third party, this is in no way a violation of
            your user rights in accessing and utilizing email marketing content.
          </p>
          <span className="rights-heading">Email Newsletter</span> <br />
          <br />
          <p>
            This site has an email newsletter program that keeps subscribers up
            to date on new products and services. Users may choose to subscribe
            at any time <br /> via an integrated online process of their own
            choice. Any subscriptions can be manually handled through prior
            written agreements with the user.
          </p>
          <p>
            All subscription-related personal information is kept secure.
            Personal information is not shared or passed on to businesses or
            individuals outside the
            <br /> organization that operates this site. This website’s or its
            owners’ email marketing strategies include tracking capabilities
            inside the email. Subscriber
            <br />
            behavior is recorded and maintained in a database for review and
            assessment in the future. Opening emails, forwarding emails,
            clicking links inside <br />
            email messages, dates, times, and frequency of operation are all
            examples of monitored activity. This is by no means an all-inclusive
            list.
          </p>
          <p>
            This data is utilized to enrich future email campaigns and provide
            the users with more appropriate content depending on their past
            activity.
          </p>
          <span className="rights-heading">External Links</span> <br></br>
          <br></br>
          <p>
            Even though this site only provides high-quality, secure, and
            appropriate external links, users are suggested to pay attention
            before visiting or <br /> clicking any of the external links
            contained on this website. (External links, such as Folded Book Art
            or Used Model Trains for Sale, are clickable
            <br /> content/graphic/picture links to other web pages.)
          </p>
          <p>
            Despite their best efforts, the administrators of this site cannot
            ensure or check the contents of the websites that are externally
            linked. As a result,
            <br /> users should be aware that they do so at their own risk, and
            this site and its owners cannot be held responsible for any losses
            or consequences <br /> resulting from accessing any of the external
            links listed
          </p>
          <p>
            Advertisements and Sponsorship Links. There are no paid links or
            advertisements on this website. Social networking platform’s
            communications, <br />
            engagement, and actions are done through external social media
            platforms that this site and its administrators participate on being
            custom to the
            <br /> terms and conditions and the privacy policies liable with
            different social media channels, respectively.
          </p>
          <p>
            Users are recommended to use social media channels sensibly and
            interact or connect with them with utmost care and attention to
            securing their
            <br /> personal information and privacy. The owners of this website
            will never ask for any sensitive or confidential details via social
            media platforms and <br />
            boost users by requiring them to consider sensitive information to
            interact with them via principal communication channels like email
            or telephone
          </p>
          <p>
            This website might have social sharing buttons that assist directly
            sharing the web content from web pages to the social media platform
            unsettled.
            <br /> Users are directed before clicking on such social sharing
            buttons that doing so is their only choice. Also, the social media
            platform may track and save <br />
            your request to share a web page individually through your social
            media platform account.
          </p>
          <br></br>
          <br></br>
          <span className="rights-heading">
            Shortened Links in social media
          </span>{" "}
          <br></br>
          <br></br>
          <p>
            This website and its owners might share web links to related web
            pages via their social media network accounts. Some social
            networking sites make long URLs [web addresses] shorter by default.
            Before clicking any shortened URLs shared on social media sites
            through this site and its owners, users are suggested to be
            attentive and alert and make decisions wisely. Despite making the
            best efforts to publish only genuine URLs, many social networking
            sites are vulnerable to fraud and piracy. As a result, this website
            and its owners cannot be held responsible for any losses or
            consequences resulting from accessing any shortened links.
          </p>
          <span className="rights-heading">
            Resources and further information
          </span>{" "}
          <br></br>
          <br></br>
          <p className="ps-4">
            <p>
              <a
                style={{ color: "#f69f3a" }}
                href="https://www.facebook.com/privacy/policy"
                target="_blank"
              >
                <span
                  style={{
                    color: theme === "light" ? "black" : "white",
                    fontSize: "20px",
                  }}
                >
                  •
                </span>{" "}
                Facebook Privacy Policy
              </a>
            </p>
            <p>
              <a
                style={{ color: "#f69f3a" }}
                href="https://policies.google.com/privacy"
                target="_blank"
              >
                <span
                  style={{
                    color: theme === "light" ? "black" : "white",
                    fontSize: "20px",
                  }}
                >
                  •
                </span>{" "}
                Google Privacy Policy
              </a>
            </p>
            <p>
              <a
                style={{ color: "#f69f3a" }}
                href="https://www.linkedin.com/legal/privacy-policy"
                target="_blank"
              >
                <span
                  style={{
                    color: theme === "light" ? "black" : "white",
                    fontSize: "20px",
                  }}
                >
                  •
                </span>{" "}
                {""}
                LinkedIn Privacy Policy
              </a>
            </p>
            <p>
              <a
                style={{ color: "#f69f3a" }}
                href="https://mailchimp.com/legal/prior-privacy-policy/"
                target="_blank"
              >
                <span
                  style={{
                    color: theme === "light" ? "black" : "white",
                    fontSize: "20px",
                  }}
                >
                  •
                </span>{" "}
                Mailchimp Privacy Policy
              </a>
            </p>

            <p>
              <a
                style={{ color: "#f69f3a" }}
                href="https://twitter.com/en/privacy"
                target="_blank"
              >
                <span
                  style={{
                    color: theme === "light" ? "black" : "white",
                    fontSize: "20px",
                  }}
                >
                  •
                </span>{" "}
                Twitter Privacy Policy
              </a>
            </p>
          </p>
          <p>
            “We will not store, sell, share, rent, or lease any credit/debit
            card data or personal details to third parties.”
          </p>
          <p>
            “As follow the specifications and requirements, the Website Terms &
            Conditions and policies can be revised or updated from time to time.
            <br /> Customers/Students are encouraged to check these pages
            periodically to stay aware of updates to the site. Changes will take
            effect on the day they <br />
            are uploaded.”
          </p>
          <br></br>
          <br></br>
          <span className="rights-heading">Products/ Services/ Items</span>{" "}
          <br></br>
          <br></br>
          <p>
            The course fee payment overview is as reported in an Admissions
            Document. It is also available on the Ministry of Colleges and
            Universities Private
            <br /> College Search Service Website, which is subject to change
            without notice. BCBT is not liable for any differences in fees
            listed on other sites. There is <br /> no way to combine two
            different ‘offers,’ Bursaries and Scholarships. Offers are only
            valid for the duration of the request. The whole pricing strategy
            <br /> and subjective charges are at the discretion of the
            management of BCBT.
          </p>
          <p>
            Beta College of Business and Technology (BCBT) is a vocational
            college registered with the Ministry of Colleges and Universities
            (MCU) under the
            <br /> Ontario Career Colleges Act, 2005. The College provides short
            and long-term certificates leading to awards, certifications, and
            recognized
            <br /> Diploma/PG Diploma Programs tailored to meet working
            professionals’ needs. Partners or other governing
            bodies/institutions are not under the
            <br />
            supervision of BCBT (Beta College of
            <br /> Business and Technology). A Force major is not something that
            students should hold BCBT (Beta College of Business and Technology)
            responsible for.
          </p>
          <div
            style={{
              marginLeft: "-1rem",
              fontFamily: "Poppins",
              fontSize: "16px",
            }}
          >
            <button className="textStyles">
              Payment Methods, Accepted Card Types, and Currency :
            </button>
          </div>
          <br></br>
          <div
            style={{
              marginLeft: "-1rem",
              fontFamily: "Poppins",
              fontSize: "16px",
            }}
          >
            <button className="textStyles">Online</button>
          </div>
          <div
            className="ps-3"
            style={isDesktop ? { whiteSpace: "nowrap" } : {}}
          >
            {selectedPolicy === "Privacy Policy" && <span></span>}
          </div>
        </div>
        <div className="policy-inner-container ">
          <div className="ps-3">
            <span>
              <p>
                Go to Beta College website{" "}
                <a
                  style={{ color: "#f69f3a" }}
                  href="https://www.mybeta.ca"
                  target="_blank"
                >
                  www.mybeta.ca{" "}
                </a>
                and click on the pay online tab (Visa or MasterCard are
                accepted. Debit/Interac is not accepted as an online payment
                method).
              </p>
              <p>
                Please note that payments can be made using a Visa, Mastercard,
                Maestro, and American Express. Effective October, 2022, a
                non-refundable service fee will <br />
                apply. The service fee is billed at a rate of 5 % and is paid
                directly to and retained by Credit Card, an online payment
                service provider.
              </p>
            </span>
            <div
              style={{
                marginLeft: "-1rem",
                fontFamily: "Poppins",
                fontSize: "16px",
              }}
            >
              <button className="textStyles">Bank Wire Transfer</button>
            </div>
            <br></br>
            <p>
              Fees can be paid through all major Financial Institutions. The
              Bank Information is as given below:
            </p>
            <br></br>
            <table class="custom-table">
              <tbody>
                <tr>
                  <td
                    style={{
                      whiteSpace: "nowrap",
                      fontWeight: "bold",
                    }}
                  >
                    Bank Name{" "}
                  </td>
                  <td>TD Canada Trust</td>
                </tr>
                <tr>
                  <td
                    style={{
                      whiteSpace: "nowrap",
                      fontWeight: "bold",
                    }}
                  >
                    Swift Code
                  </td>
                  <td>TDOMCATTTOR</td>
                </tr>
                <tr>
                  <td
                    style={{
                      whiteSpace: "nowrap",
                      fontWeight: "bold",
                    }}
                  >
                    Beneficiary Bank Address
                  </td>

                  <td>1571 Sandhurst Circle, Scarborough, Ontario, M1V 1V2</td>
                </tr>
                <tr>
                  <td
                    style={{
                      whiteSpace: "nowrap",
                      fontWeight: "bold",
                    }}
                  >
                    Beneficiary Account Name
                  </td>

                  <td>2198859 Ontario Ltd</td>
                </tr>

                <tr>
                  <td
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  ></td>

                  <td>o/a Beta College of Business and Technology</td>
                </tr>
                <tr>
                  <td
                    style={{
                      whiteSpace: "nowrap",
                      fontWeight: "bold",
                    }}
                  >
                    Beneficiary Bank Account Number
                  </td>

                  <td>5227407</td>
                </tr>
                <tr>
                  <td
                    style={{
                      whiteSpace: "nowrap",
                      fontWeight: "bold",
                    }}
                  >
                    Beneficiary Address
                  </td>

                  <td>
                    2347 Kennedy Road, Unit 301, Scarborough Ontario, M1T 3T8
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      whiteSpace: "nowrap",
                      fontWeight: "bold",
                    }}
                  >
                    Transit Number
                  </td>

                  <td>03302</td>
                </tr>
                <tr>
                  <td
                    style={{
                      whiteSpace: "nowrap",
                      fontWeight: "bold",
                    }}
                  >
                    Financial Institution Number
                  </td>

                  <td>004</td>
                </tr>
              </tbody>
            </table>
            <br></br>
            <p>
              Please use the correct bank information (for Beta College of
              Business and Technology) and verify the details before you deposit
              the fees. Please allow
              <br /> ten working days for your payment to be recorded and
              processed.
            </p>
            <span className="rights-heading">
              BCBT is not responsible for any fee(s) lost in transit.{" "}
            </span>
          </div>
          <div
            className="ps-3"
            style={isDesktop ? { whiteSpace: "nowrap" } : {}}
          >
            {selectedPolicy === "Privacy Policy" && <span></span>}
          </div>
        </div>
        <div className="policy-inner-container ">
          <div className="ps-3">
            <span></span>
            <div
              style={{
                marginLeft: "-1rem",
                fontFamily: "Poppins",
                fontSize: "16px",
              }}
            >
              <button className="textStyles">Debit Card</button>
            </div>
            <br></br>
            <p>
              Fees can be paid <u>in person</u> using a valid debit card at the
              finance office on the <u>Brampton Campus</u>.
            </p>
            <p>
              Please note that most debit cards have a default spending limit
              for students who intend to <br></br> use a debit card. Suppose the
              payment you wish to make is significantly more extensive than{" "}
              <br></br> this limit. In that case, you may want to contact the
              issuing bank beforehand to determine if <br></br> you can
              temporarily increase your daily spending limit while trying to
              meet the fee payment <br></br> deadline. If your bank does not do
              so, you must be prepared to make an alternative payment <br></br>{" "}
              method.
            </p>
            <div
              className="ps-3"
              style={isDesktop ? { whiteSpace: "nowrap" } : {}}
            >
              {selectedPolicy === "Privacy Policy" && <span></span>}
            </div>
          </div>
          <div className="policy-inner-container ">
            <div className="ps-3">
              <span></span>
              <div
                style={{
                  marginLeft: "-1rem",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                }}
              >
                <button className="textStyles">
                  Money Order, Certified Cheque, or Bank Draft
                </button>
              </div>
              <br></br>
              <p>
                Fees can be paid via cheque, bank draft, or money order payable
                to the <u>Beta College of Business and Technology</u>
              </p>
              <p>Payment can be dropped at the finance office or mailed to:</p>
              <span className="rights-heading">
                Finance Office (Beta College),
              </span>
              <br></br>
              <p>Suite 200A, 1 Nelson Street West, Brampton L6X 3E4, Canada</p>
              <span className="rights-heading">Note: </span> Please ensure you
              include your name and student number on all cheques, drafts, or
              <br></br> money orders. Receipts are issued once the physical
              cheques/drafts or money orders have<br></br>
              been received. The final receipt will be issued once the payment
              has been reflected in our <br></br>bank account.
              <br></br>
              <br></br>
              If you have difficulties paying online or at your bank, please
              phone us at 647-573-3660 during regular office hours. Visa or
              MasterCard are accepted.
              <br></br> We are unable to take Debit/Interac cards over the
              phone.
              <br></br>
              <br></br>
              <p>
                Students should keep a copy of the payment receipt and inform
                the staff. The accounts manager or management team are the only
                ones with <br></br> authority to provide the final transaction
                approval.
              </p>
              <br></br>
              <span className="rights-heading">
                Primary Currency of Payments:
              </span>
              <br></br>
              <p>CAD (Canadian Dollar).</p>
              <br></br>
              <span className="rights-heading">
                Admission and Certification Policy
              </span>
              <br></br>
              <br></br>
              <u>CAD $550</u> mandatory, <u>non-refundable</u> tuition deposit
              is required to secure a place in the program. Admission and the
              awarding of qualification or<br></br>
              certificate are subject to meeting the eligibility rules, paying
              all course dues, and submitting the appropriate paperwork. If this
              does not happen,
              <br></br> Beta College of Business and Technology and its partners
              will not be responsible for giving any documentation claiming
              program completion and<br></br> attendance. Beta College of
              Business and Technology and its partners do not promise the
              authenticity of qualification or any documents provided<br></br>{" "}
              through the third-party validity, accreditation, or recognition as
              required by different nations, educational bodies, or validating
              organizations.
              <br></br>
              <br></br>
              <p>
                The College does not send out invoices. The student’s
                responsibility is to ensure they have no outstanding debt to the
                college.
              </p>
              <br></br>
              <p>
                This is to state that, in compliance with the provincial law of
                Ontario, Beta College of Business and Technology does not deal
                with or offer products or services to any sanctioned countries.
              </p>
              <br></br>
              <span className="rights-heading">
                Policy on Refunds and Cancellations
              </span>
              <br></br>
              <br></br>
              <p>
                The student must become familiar with the policies and
                procedures which outline course and program fee withdrawals and
                refunds.
              </p>
              <p>
                Students considering a program or course withdrawal are strongly
                advised to consult with their program coordinator in addition to
                the records office.{" "}
              </p>
              <span className="rights-heading">Important : </span> Not attending
              classes or notifying only your professor or program coordinator of
              your intent to withdraw are NOT acceptable methods <br></br> for
              formally withdrawing from a course or program.
              <br></br>
              <br></br>
              <p>
                A student may not withdraw from their program by dropping
                courses. Program withdrawals must be submitted to the Office of
                the Registrar on or
                <br></br> before the tenth official day of the session by
                submitting a completed Withdrawal Form. There are no refunds
                after the tenth official day of the start
                <br></br> of the term, and students are liable for payment of
                outstanding fees. There are no exceptions to the refund policy
                for students who register late. Full-<br></br>time program fees
                are a package cost, and dropping a course(s) does not qualify
                for a refund if a student remains in full-time status (this
                includes <br></br> students approved for transfer credits).
                Unused student contact hours are not transferable from one term
                to another.
              </p>
              <span className="rights-heading">Please note:</span> A $550
              withdrawal fee is automatically withheld once payment is made.
              Late fees are non-refundable. Fees paid by wire transfer, web
              banking, and debit card will be refunded by Interac e-Transfer or
              an official Bank account. Fees paid by credit card will be
              refunded to the credit card used for payment.
              <br></br>
              <br></br>
              <span className="rights-heading">
                Missed Session and Repeat Policy
              </span>{" "}
              <br></br>
              <p>
                Beta College of Business and Technology will not have any
                repeats of previously conducted sessions. Students are
                encouraged to refer to the <br></br>
                recorded sessions, which are available for free from Student
                Support.
              </p>
              <span className="rights-heading">
                How to Request a Refund
              </span>{" "}
              <br></br>
              <p>
                1.To request a refund, you will email{" "}
                <a
                  style={{ color: "#f69f3a" }}
                  href="mailto:admin@betacollege.ca"
                  target="_blank"
                >
                  admin@betacollege.ca
                </a>{" "}
                with CC to{" "}
                <a
                  style={{ color: "#f69f3a" }}
                  href="mailto:finance@mybeta.ca"
                  target="_blank"
                >
                  finance@mybeta.ca
                </a>{" "}
                with a valid reason to refund. Do mention and attach <br></br>
                the correct program details, student registration number, and
                fees receipt. Without accurate information and attachments, a
                refund, if any, will <br></br>not be issued. If you select Visa
                Refusal (International students only), you must upload a copy of
                your visa refusal letter with other mandatory <br></br>{" "}
                information.
                <p>
                  2. You will receive an email notification that your request
                  has either been approved or denied.
                  <p>
                    3. Students will be charged an administrative fee of CAD 550
                    for any refund application.
                    <p>
                      4. Please refer to the Withdrawal and Refund Policy
                      carefully at{" "}
                      <a
                        style={{ color: "#f69f3a" }}
                        href="https://mybeta.ca/withdrawal-and-refund-policies/"
                        target="_blank"
                      >
                        https://mybeta.ca/withdrawal-and-refund-policies/
                      </a>
                      .
                    </p>
                  </p>
                </p>
              </p>
              <span className="rights-heading">Contact Details</span> <br></br>
              <p>
                For any questions, suggestions, or complaints, please contact:{" "}
              </p>
              <p className="ps-4">
                • Email:{" "}
                <a
                  style={{ color: "#f69f3a" }}
                  href="mailto:admin@betacollege.ca"
                  target="_blank"
                >
                  admin@betacollege.ca
                </a>{" "}
                /{" "}
                <a
                  style={{ color: "#f69f3a" }}
                  href="mailto:finance@mybeta.ca"
                  target="_blank"
                >
                  finance@mybeta.ca
                  <p>
                    <span className="rights-heading">• Address 1:</span> – using
                    someone else’s work as one’s own without giving credit to
                    the source.
                    <p>
                      <span className="rights-heading">• Address 2:</span> –
                      using someone else’s work as one’s own without giving
                      credit to the source.
                    </p>
                  </p>
                </a>
              </p>
              <span className="rights-heading">
                Jurisdiction and Governing Law
              </span>{" "}
              <br></br>
              <br></br>
              “Any purchase, claim, or dispute matters relating to this website
              shall be handled and construed in compliance with Law of Province
              of Ontario.”
              <br></br>
              If the dispute is not resolved within a reasonable period, then
              any or all outstanding issues may be submitted to mediation by any
              statutory rules. <br></br> Suppose mediation is not successful in
              resolving the entire dispute or is unavailable. In that case, any
              outstanding issues will be submitted to final and <br></br>
              binding arbitration following the laws of the Province of Ontario.
              The arbitrator’s award will be final, and judgment may be entered
              upon it by any <br></br>court having jurisdiction within the
              Province of Ontario.
              <br></br>
              <br></br>
              <div
                style={{
                  marginLeft: "-1rem",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                }}
              >
                <button className="textStyles">
                  Withdrawal and Refund Policies
                </button>
              </div>
              <br></br>
              <br></br>
              <u className="rights-heading">Before You Withdraw</u>
              <br></br>
              <br></br>
              <p>
                The following options are available for our students at Beta
                College:
              </p>
              <br></br>
              <br></br>
              <p>
                Before withdrawal from your program, you are encouraged to
                explore the below options that may support your personal,
                academic, and career success.
              </p>
              <p>
                The following options are available for our students at Beta
                College:
              </p>
              <p className="ps-4">
                1.Speak to the Student Success Officer.{" "}
                <p>
                  2. Contact the Directors at Beta College, who may guide you to
                  the best of their abilities.{" "}
                  <p>
                    3. To seek an appointment, please email us at{" "}
                    <a
                      style={{ color: "#f69f3a" }}
                      href="mailto:hello@mybeta.ca"
                    >
                      hello@mybeta.ca
                    </a>{" "}
                    or{" "}
                    <a
                      style={{ color: "#f69f3a" }}
                      href="mailto:admin@betacollege.ca"
                    >
                      admin@betacollege.ca
                    </a>
                  </p>
                </p>
              </p>
              <u className="rights-heading">Withdrawal Process</u> <br></br>
              <br></br>
              <p>
                By the Ministry of Colleges and Universities procedures,
                students must submit a formal written notification of withdrawal
                within ten business days of the beginning of a term to receive a
                refund of fees for the current time. The process to academically
                withdraw and submit this formal written notification is as
                follows:
              </p>
              <p className="ps-4">
                1.Make an appointment with the Student Support Officer
                (Currently available at the Brampton Campus){" "}
                <p>
                  2. Complete the Academic Withdrawal Form
                  <p>
                    3. Submit the Form in Person to the Student Success Officer
                    at the Brampton Campus
                  </p>
                </p>
              </p>
              <p>
                Please note that{" "}
                <span className="rights-heading">
                  not attending classes or notifying only your professor or any
                  other office staff of your intent to withdraw are NOT
                  acceptable methods for formally withdrawing from a class or
                  program.
                </span>
              </p>
              <p className="rights-heading">
                Please bring the following documents with you:
              </p>
              <p>
                1.Copy of your Beta College letter of acceptance
                <p>
                  2.Copy of your Study Permit
                  <p>
                    3.Copy of your valid passport (photo page and address page,
                    if any)
                    <p>
                      4.Copy of Letter of Admission for other designated
                      learning institute*
                      <p>
                        5.Bank wire receipt showing payment from your home
                        country
                      </p>
                    </p>
                  </p>
                </p>
              </p>
              <br></br>
              <p>
                (Evidence that the student has deposited the Actual Fees with
                the college, in the form of Bank deposit, transfer, Advice, or
                TT copy, must be produced to claim withdrawals and refunds.)
              </p>
              <br></br>
              <p>
                1) If you return to your home country, we require a copy of your
                boarding pass once you arrive home.{" "}
              </p>
              <span className="rights-heading">Please note:</span> We require
              original (for validation) and photocopies of all of these
              documents to process your withdrawal. If you do not bring these
              items with you will be required to come at the appointed, agreed
              day to complete your withdrawal. <br></br>
              <span className="rights-heading">REFUNDS</span>
              <br></br>
              <br></br>
              <p>
                • Please ensure that you understand our refund process, terms &
                conditions, and exclusions and read our as well as our Refund
                Policy.
              </p>
              <p className="ps-4">
                • Beta College encourages all international students to
                carefully choose their program of studies before selecting and
                making payments to Beta college.
                <p>
                  • Beta College’s student refund policy is at par and in
                  compliance with the PCC Act 2005.
                  <p>
                    • Beta College advises all students to make payments
                    directly to the institution’s Bank account or per the
                    payment options mentioned on the website. Do not make
                    payment through an agent or any agency.
                    <p>
                      • Beta College will only return funds to the original
                      payer and payer’s account by industry best practices and
                      by abiding by the guidelines. The funds will not be
                      transferred to another account or institution. Students
                      who withdraw after the specified deadlines will not be
                      eligible for a refund.
                      <p>
                        • Any wire transfer fees, bank charges, or other
                        administrative charges associated with returning the
                        funds will be charged to the student and subtracted from
                        the amount returned. Please refer to our Privacy Policy
                        section for more information.
                        <p>
                          • Valid reason and relevant proofs required to process
                          the refund. Delay in submitting this important
                          information will delay the refund process. The
                          authenticity of documents submitted may be verified.
                          <p>
                            • Beta College may require up to 90 days to complete
                            the return of funds once all necessary documentation
                            to support the refund request has been received.
                            <p>
                              • Beta College bears no responsibility for funds
                              that do not reach their destination due to the
                              student’s inaccurate or obsolete financial
                              information. Applicants/students are responsible
                              for all banking and foreign exchange charges
                              associated with their payment/refund.
                              <p>
                                • Immigration, Refugees, and Citizenship Canada
                                and Canada Border Services Agency will be
                                notified of the student’s withdrawal or transfer
                                from Beta College.
                              </p>
                            </p>
                          </p>
                        </p>
                      </p>
                    </p>
                  </p>
                </p>
              </p>
              <u className="rights-heading">NON-VOCATIONAL PROGRAMS</u>
              <br></br>
              <br></br>
              <p>(CERTIFICATES, AWARDS, AND OTHERS)</p>
              <p>REFUND POLICY FOR NON-VOCATIONAL PROGRAMS</p>
              <p className="ps-4">
                • Tuition fees are due before or on the program start date. To
                be considered for a refund of tuition fees, a student must
                submit to the college a written notice of withdrawal that gives
                sufficient reason for withdrawing from the program. The
                registration fee, acceptance, and material fee are
                non-refundable.
                <p>
                  • The College will return 100% of the tuition fees paid if the
                  college cancels the course under the circumstances beyond the
                  college’s control.
                  <p>
                    • If a student withdraws for serious health reasons, up to
                    50% of the incomplete portion of the course may be refunded
                    at the discretion of the college administrator(s). The
                    student must provide a valid doctor’s written notice within
                    a reasonable time from the date of last attendance.
                    <p>
                      •If a student withdraws for relocation reasons, up to 50%
                      of the incomplete portion of the course may be refunded at
                      the discretion of the college administrator. The student
                      must provide documentary evidence that the new residence
                      is not in the commuting zone of the college within a
                      reasonable time from the date of last attendance.
                      <p>
                        • If a student wishes to withdraw from a course, they
                        should fill out the “Intent to Withdraw Form” and file
                        it with the Brampton office.
                        <p>
                          • There will be no refund for special offers.
                          <p>
                            • There would be no refund for any other reason for
                            abandoning the courses.
                            <p>
                              • There would be no refund for online programs
                              after students receive their orientation and
                              commencement dates.
                              <p>
                                • All the mentioned points, terms, and
                                conditions related to withdrawal returns apply.
                              </p>
                            </p>
                          </p>
                        </p>
                      </p>
                    </p>
                  </p>
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  const withdrawal_refund_policy = (
    <div className="policy custom-scrollbar">
      <div className="policy-inner-container">
        <div className="ps-3" style={isDesktop ? { whiteSpace: "nowrap" } : {}}>
          {selectedPolicy === "Withdrawal & Refund Policy" && (
            <span>
              INFORMATION ON WITHDRAWAL, TRANSFER AND REFUND POLICIES AT BETA
              COLLEGE OF BUSINESS AND TECHNOLOGY.
            </span>
          )}
        </div>
        <div className="policy-inner-container ">
          <div className="ps-3">
            <span></span>
            <div
              style={{
                marginLeft: "-1rem",
                fontFamily: "Poppins",
                fontSize: "16px",
              }}
            >
              <button className="textStyles">
                WITHDRAWAL & TRANSFER INFORMATION
              </button>
            </div>
            <br></br>
            <u>Before You Withdraw & Transfer</u>
            <br></br>
            <br></br>
            <p>
              The following options are available for our students at Beta
              College:
            </p>
            <p className="ps-4">
              1.Speak to the Student Success Officer.{" "}
              <p>
                2. Contact the Directors at Beta College, who may guide you to
                the best of their abilities.{" "}
                <p>
                  3. To seek an appointment, please email us at{" "}
                  <a style={{ color: "#f69f3a" }} href="mailto:hello@mybeta.ca">
                    hello@mybeta.ca
                  </a>{" "}
                  or{" "}
                  <a
                    style={{ color: "#f69f3a" }}
                    href="mailto:admin@betacollege.ca"
                  >
                    admin@betacollege.ca
                  </a>
                </p>
              </p>
            </p>
            <br></br>
            <u>Withdrawal & Transfer Process</u>
            <br></br>
            <br></br>
            <p>
              By the Ministry of Colleges and Universities procedures, students
              must submit a formal written notification of withdrawal within ten
              business days of the beginning of a term to receive a refund of
              fees for the current time. The process to academically withdraw
              and submit this formal written notification is as follows:
            </p>
            <p className="ps-4">
              1.Make an appointment with the Student Support Officer (Currently
              available at the Brampton Campus){" "}
              <p>
                2. Complete the Academic Withdrawal Form.{" "}
                <p>
                  3. Submit the Form in Person to the Student Success Officer at
                  the Brampton Campus{" "}
                </p>
              </p>
            </p>
            Please Note that{""}
            <span className="rights-heading ps-1">
              {""}not attending classes or notifying only your professor or any
              other office staff of your intent to withdraw are NOT acceptable{" "}
              <br></br> methods for formally withdrawing from a class or
              program.
            </span>{" "}
            <br></br>
            <span className="rights-heading">
              A. REFUND POLICY – VOCATIONAL PROGRAMS
            </span>
            <br></br>
            <br></br>
            <span className="rights-heading">
              I – STUDY PERMIT REFUSAL REFUND
            </span>
            <br></br>
            <br></br>
            <p className="ps-4">
              1.A full tuition fee refund (minus the <strong>CAD 500</strong>
              administrative fee and the applicable Bank/financial
              institution(s) charges) will be granted if Immigration, Refugees,
              and Citizenship Canada IRCC) does not issue the study permit, or
              there is a denial. To obtain a full tuition fee refund, the
              student must submit the following documents within twenty{" "}
              <strong>(20) </strong>
              calendars days of the issue date of the original refusal letter
              from IRCC:
              <p>
                2. a copy of the original letter of refusal received from IRCC;
                <p>
                  3. a copy of the official Beta College of Business and
                  Technology letter of acceptance and a copy of the original
                  receipt of tuition payment.
                  <p>
                    4. a copy of the Bank Transfer copy of the Tuition fee
                    deposit; and
                    <p>
                      5. a completed and signed Refund Request Form from the
                      applicant
                    </p>
                  </p>
                </p>
              </p>
            </p>
            <p>
              Refunds will only be issued if the student submits all of the
              above Thirty (30) days from the issue date of the original refusal
              letter from IRCC.
            </p>
            <br></br>
            <span className="rights-heading">II – PARTIAL REFUND</span>
            <br></br>
            <br></br>
            <p>
              If a student withdraws before the commencement of the program as
              prescribed in the Beta College of Business and Technology official
              Letter of Acceptance,
              <strong>
                {" "}
                the paid tuition fee minus the international student fee and the
                CAD 500 administration; balance Tuition fee will be refunded to
                the student
              </strong>
              . The following documents are required:
            </p>
            <p className="ps-4">
              1.a copy of the official Beta College of Business and Technology
              letter of acceptance;
              <p>
                2. a copy of the original receipt of tuition payment; and
                <p>
                  3. a copy of the official Beta College of Business and
                  Technology letter of acceptance and a copy of the original
                  receipt of tuition payment.
                  <p>
                    4. a copy of the Bank Transfer copy of the Tuition fee
                    deposit; and
                    <p>
                      5. a completed and signed Refund Request Form from the
                      applicant
                    </p>
                  </p>
                </p>
              </p>
            </p>
            <p>
              Suppose a student withdraws on the day of or after the
              commencement of the program as prescribed in the official Beta
              College of Business and Technology Firm letter of acceptance. In
              that case, the paid fee minus the international student fee, the
              CAD 500 administration fee and the applicable Bank/financial
              institution(s) charges, the prorated tuition, the total lab fee,
              and the international student fee based on the numbers of
              instruction hours elapsed after the program start day,{" "}
              <strong>will be refunded to the student</strong>. The following
              documents are required:
            </p>
            <p className="ps-4">
              1.a copy of the official Beta College of Business and Technology
              letter of acceptance;
              <p>
                2. a copy of the original receipt of tuition payment; and
                <p>
                  3. a copy of the official Beta College of Business and
                  Technology letter of acceptance and a copy of the original
                  receipt of tuition payment.
                  <p>
                    4.a copy of the Bank Transfer copy of the Tuition fee
                    deposit; and
                    <p>
                      5. a completed and signed Refund Request Form from the
                      applicant
                    </p>
                  </p>
                </p>
              </p>
            </p>
            <br></br>
            <span className="rights-heading">III. NO REFUND</span>
            <br></br>
            <br></br>
            <p>
              There will be NO refund of the tuition fee in the following
              circumstances:
            </p>
            <p className="ps-4">
              1.If the student withdraws after 50% of course hours pass from the
              program’s commencement date as outlined in the official Beta
              College of Business and Technology letter of acceptance.
              <p>
                2. If the student violates Beta College of Business and
                Technology’s policies and procedures.
                <p>
                  3. The lab and software license fees are not refundable.
                  <p>
                    4.If false or fraudulent information was provided, including
                    providing incorrect medical information or non-disclosure of
                    medical conditions or prescribed medications, in the Student
                    Application Form.
                  </p>
                </p>
              </p>
            </p>
            <br></br>
            <span className="rights-heading">
              B – Transfer to Another College
            </span>
            <br></br>
            <br></br>
            <p>
              • Requests must be completed <strong>in person</strong> at the
              applicable Beta College of Business and Technology Brampton
              campus. Transfer refunds cannot be entertained or processed via
              email. No exceptions.
              <p>
                • A copy of the valid offer letter (LOA) from the new college
                with the student number and proof of tuition deposit must be
                provided for our records and validated.{" "}
                <p>
                  • LOAs, fee receipts, and any other document submitted must be
                  validated as authentic before a refund is approved.
                  <p>
                    • CAQ – Quebec Acceptance Certificate must be provided to
                    students requesting a Quebec school transfer.
                    <p>
                      •Must transfer to DLI approved school only
                      <p>
                        • See important details below regarding semester one
                        transfer requests.
                        <p>
                          • An administration Fee of{" "}
                          <strong>
                            CAD 500.00 and any other applicable charges
                          </strong>{" "}
                          will be deducted from each refund, withdrawal, and
                          Transfer request.
                        </p>
                      </p>
                    </p>
                  </p>
                </p>
              </p>
            </p>
            <br></br>
            <span className="rights-heading">
              C – Please bring the following mandatory documents with you:
            </span>
            <br></br>
            <br></br>
            <p className="ps-4">
              1.Copy of your Beta College letter of acceptance
              <p>
                2. Copy of your Study Permit
                <p>
                  3. Copy of your valid passport (photo page and address page,
                  if any)
                  <p>
                    4.Copy of your valid passport (photo page and address page,
                    if any)
                    <p>
                      5. Valid Letter of Acceptance (LOA) from a DLI-recognized
                      institution where the student has now enrolled
                      <p>
                        6.Valid Letter of Enrollment/ Attendance Record from a
                        DLI-recognized institution where the student has now
                        enrolled
                        <p>
                          Valid Fee Paid Receipt from a DLI-recognized
                          institution where the student is now enrolled.
                        </p>
                      </p>
                    </p>
                  </p>
                </p>
              </p>
            </p>
            <br></br>
            <span className="rights-heading">D – Please also note:</span>
            <br></br>
            <br></br>
            <p className="ps-4">
              • An administration Fee of CAD 500.00, any other applicable
              fees/charges, and the Bank/financial institution(s) transfer
              charges (if any) will be deducted from each refund, transfer, or
              withdrawal request.
              <p>
                • Refund will only be issued to the next DLI-approved and
                recognized college where the student has gained admission or to
                the same channel/account from where the Tuition fees were
                deposited.
                <p>
                  • The Beta College of Business and Technology shall not be
                  held liable for losses or expenses due to Beta College of
                  Business and Technology’s inability to provide education owing
                  to labor disputes or other causes beyond its control.
                  <p>
                    • Conditional Offer Letter, Letter of Acceptance, and other
                    documentation(s) Fees paid directly to the college are
                    <strong> Non-Refundable</strong>.
                    <p>
                      • The college will not entertain or process any request(s)
                      where any fees have been paid to the agents or
                      consultants. Please get in touch with your agent or
                      consultant for the same.
                      <p>
                        • Evidence that the student has deposited the Actual
                        Fees with Beta College of Business and Technology in the
                        form of Bank deposit, transfer, Advice, or TT copy, must
                        be produced to claim any withdrawals, Transfers, and
                        refunds.
                        <p>
                          • The college requires original (for validation) and
                          photocopies of all of these documents to process your
                          withdrawal. If you do not bring these items, you will
                          be required to come on the appointed, agreed day to
                          complete your withdrawal.
                          <p>
                            • All the documents submitted for withdrawal,
                            refunds and Transfer are subject to compulsory
                            verification and pass through a validation
                            processes.
                          </p>
                        </p>
                      </p>
                    </p>
                  </p>
                </p>
              </p>
            </p>
            <br></br>
            <u className="rights-heading">NON-VOCATIONAL PROGRAMS</u>
            <br></br>
            <br></br>
            <p>(Micro Credentials to certificate, awards and others)</p>
            <span className="rights-heading">
              REFUND POLICY FOR NON-VOCATIONAL PROGRAMS
            </span>
            <br></br>
            <p className="ps-3">
              • Tuition fees are due before or on the program start date. To be
              considered for a refund of tuition fees, a student must submit to
              the college a written notice of withdrawal that gives sufficient
              reason for withdrawing from the program. The registration fee,
              acceptance, and material fee are non-refundable.
              <p>
                • The College will return 100% of the tuition fees paid if the
                college cancels the course under the circumstances beyond the
                college’s control.
                <p>
                  • If a student withdraws for serious health reasons, up to 50%
                  of the incomplete portion of the course may be refunded at the
                  discretion of the college administrator(s). The student must
                  provide a valid doctor’s written notice within a reasonable
                  time from the date of last attendance.
                  <p>
                    • If a student withdraws for relocation reasons, up to 50%
                    of the incomplete portion of the course may be refunded at
                    the discretion of the college administrator. The student
                    must provide documentary evidence that the new residence is
                    not in the commuting zone of the college within a reasonable
                    time from the date of last attendance.
                    <p>
                      • If a student wishes to withdraw from a course, they
                      should fill out the “Intent to Withdraw Form” and file it
                      with the Brampton office.
                      <p>
                        • There would be no refund for a course or program a
                        student has taken a complete session or after the course
                        or program has started.
                        <p>
                          • There would be no refund for any other reason for
                          abandoning the courses.
                          <p>
                            • All refund claims must be made within one month
                            from the course start date by filling out the
                            “Intent to Withdrawal” form (available in the
                            Brampton office).
                            <p>
                              • There will be no refund for special offers.
                              <p>
                                • There would be no refund for online programs
                                after students receive their orientation and
                                commencement dates.
                                <p>
                                  • All the mentioned points, terms, and
                                  conditions related to withdrawal returns
                                  apply.
                                  <p>
                                    • A full refund will be given up to 30
                                    business days prior to the training
                                    session/workshop start date.
                                    <p>
                                      • A 50% refund will be given for
                                      cancellations made 29 – 15 business days
                                      prior to the training session/workshop
                                      start date.
                                      <p>
                                        • The Beta College of Business and
                                        Technology incurs significant
                                        administrative costs related to your
                                        registration before a training
                                        session/workshop. As a result, a CAD
                                        $750 administrative fee will be applied
                                        to all processed refunds.
                                        <p>
                                          • No refund will be granted for
                                          cancellations made within 14 business
                                          days of the training session/workshop
                                          start date.
                                          <p>
                                            • No refunds will be given for No
                                            Shows (except medical, with valid
                                            proof) absence from the training.
                                            <p>
                                              • No refunds will be given to
                                              participants receiving an
                                              Incomplete/Fail or do not complete
                                              the program, course.
                                              <p>
                                                • A full refund will be provided
                                                in the event a training
                                                session/workshop has been
                                                cancelled by the BETA COLLEGE OF
                                                BUSINESS AND TECHNOLOGY. The
                                                administrative fee will be
                                                waived only in this case.
                                                <p>
                                                  • Refund for training
                                                  sessions/workshops paid via
                                                  group pricing: if any group of
                                                  participants withdraw within
                                                  the timelines indicated above,
                                                  a refund will be granted as
                                                  described. However, if the
                                                  cancellation results in the
                                                  group being reduced to less
                                                  than 10 participants, the
                                                  group pricing will be
                                                  invalidated, and the refund
                                                  will be adjusted based on the
                                                  full training fees for the
                                                  remaining participants. Please
                                                  contact the Beta College of
                                                  Business and Technology for
                                                  further details.
                                                  <p>
                                                    • For Visa Rejection of any
                                                    short course application,
                                                    course fee will be refunded
                                                    minus/less the
                                                    administration charge of CAD
                                                    750/-
                                                    <p>
                                                      • Refunds are issued by
                                                      the Beta College of
                                                      Business and Technology
                                                      and may take up to 6 weeks
                                                      after the refund
                                                      application has been
                                                      completed and processed.
                                                      Refunds are issued either
                                                      by cheque or directly to
                                                      the original method of
                                                      payment, when possible.
                                                    </p>
                                                  </p>
                                                </p>
                                              </p>
                                            </p>
                                          </p>
                                        </p>
                                      </p>
                                    </p>
                                  </p>
                                </p>
                              </p>
                            </p>
                          </p>
                        </p>
                      </p>
                    </p>
                  </p>
                </p>
              </p>
            </p>
            <br></br>
            <span className="rights-heading">
              Failure to Complete Course Requirements
            </span>
            <br></br>
            <br></br>
            <p className="ps-4">
              • To promote self-directed learning and accountability, all
              participants must complete all course requirements as outlined in
              the course syllabus to receive a certificate of completion. No
              refund will be granted if the participant fails to complete all
              course requirements.
              <p>
                • If the participant does not attempt any of the course
                activities or withdraws from the course more than 15 business
                days after registering for a course, no refund will be made.
                <p>
                  • The Beta College of Business and Technology does not allow
                  extensions of deadlines for course requirement completion; it
                  is the responsibility of participants, when registering for a
                  course, to be fully aware of their ability to meet course
                  expectations and deadlines.
                  <p>
                    • If you are unable to complete the course due to
                    extenuating circumstances, please contact the Student
                    Support officer at Beta College of Business and Technology
                    to discuss the possibility of extending the subscription and
                    access to your course.
                  </p>
                </p>
              </p>
            </p>
            <br></br>
            <span className="rights-heading">
              Changes to Withdrawal and Refund Policy
            </span>
            <br></br>
            <br></br>
            <p className="ps-4">
              • We reserve the right to change this refund policy terms and
              conditions at any time. Any such changes will take effect when
              posted on the website.
              <p>• Effective September 30, 2021</p>
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="create-admission-main-container">
      <div className="progress-container">
        <div className="create-admission-head-text">
          College Policies and Procedures
        </div>
      </div>
      <div className="w-100 d-flex justify-content-between">
        <div style={isDesktop ? { width: "35%" } : { width: "auto" }}>
          <ul className="textStyle">
            <li
              className={`textStyleForHeading ${
                activeItem === "Academic and Attendance Policy"
                  ? "policy-list-active"
                  : ""
              }`}
              onClick={() => openModal("Academic and Attendance Policy")}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <FaEye className="iconStyle" />
                <div>Academic Attendance Policy</div>
              </div>
            </li>
            <li
              className={`textStyleForHeading ${
                activeItem === "Code of Conduct" ? "policy-list-active" : ""
              }`}
              onClick={() => openModal("Code of Conduct")}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <FaEye className="iconStyle" />
                <div>Code of Conduct</div>
              </div>
            </li>
            <li
              className={`textStyleForHeading ${
                activeItem === "Expulsion Policy" ? "policy-list-active" : ""
              }`}
              onClick={() => openModal("Expulsion Policy")}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <FaEye className="iconStyle" />
                <div>Expulsion Policy</div>
              </div>
            </li>
            <li
              className={`textStyleForHeading ${
                activeItem === "Sexual Violence and Harassment Policy"
                  ? "policy-list-active"
                  : ""
              }`}
              onClick={() => openModal("Sexual Violence and Harassment Policy")}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <FaEye className="iconStyle" />
                <div>Sexual Violence and Harassment Policy</div>
              </div>
            </li>
            <li
              className={`textStyleForHeading ${
                activeItem === "Student Complaint Grievance Procedure"
                  ? "policy-list-active"
                  : ""
              }`}
              onClick={() => openModal("Student Complaint Grievance Procedure")}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <FaEye className="iconStyle" />
                <div>Student Complaint Grievance Procedure</div>
              </div>
            </li>
            <li
              className={`textStyleForHeading ${
                activeItem === "Privacy Policy" ? "policy-list-active" : ""
              }`}
              onClick={() => openModal("Privacy Policy")}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <FaEye className="iconStyle" />
                <div>Privacy Policy</div>
              </div>
            </li>
            <li
              className={`textStyleForHeading ${
                activeItem === "Withdrawal & Refund Policy"
                  ? "policy-list-active"
                  : ""
              }`}
              onClick={() => openModal("Withdrawal & Refund Policy")}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <FaEye className="iconStyle" />
                <div>Withdrawal & Refund Policy</div>
              </div>
            </li>
            <li
              className={`textStyleForHeading ${
                activeItem === "Deferral Policy" ? "policy-list-active" : ""
              }`}
              onClick={() => openModal("Deferral Policy")}
            >
              <FaEye className="iconStyle" /> Deferral Policy
            </li>
          </ul>
        </div>

        {isDesktop ? (
          selectedPolicy && (
            <div className="modalContent" onClick={(e) => e.stopPropagation()}>
              <div className="progress-container-policy">
                <span className="create-admission-head-text">
                  {selectedPolicy}
                </span>
                <span onClick={closeModal} className="policy-close-icon">
                  <IoClose />
                </span>
              </div>
              {selectedPolicy === "Academic and Attendance Policy" &&
                academic_and_attendance_policy}
              {selectedPolicy === "Code of Conduct" && code_of_conduct}
              {selectedPolicy === "Expulsion Policy" && expulsion_policy}
              {selectedPolicy === "Sexual Violence and Harassment Policy" &&
                sexual_violence_and_harassment_policy}
              {selectedPolicy === "Deferral Policy" && deferral_policy}
              {selectedPolicy === "Student Complaint Grievance Procedure" &&
                student_complaint_grievance_procedure}
              {selectedPolicy === "Privacy Policy" && privacy_policy}
              {selectedPolicy === "Withdrawal & Refund Policy" &&
                withdrawal_refund_policy}
            </div>
          )
        ) : (
          <Modal
            className="crm-modal"
            onRequestClose={() => setSelectedPolicy(null)}
            isOpen={selectedPolicy}
            shouldFocusAfterRender={false}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
          >
            <div className="modal-Con">
              <div
                className="col-11 col-md-4 p-3 d-flex flex-column"
                style={{
                  height: "auto",
                  backgroundColor: "#212226",
                  width: "100%",
                  maxHeight: "90vh",
                  position: "relative",
                }}
              >
                <div
                  className="d-flex justify-content-end"
                  // style={{ position: "absolute", top: "10px", right: "10px" }}
                >
                  <IoClose
                    color="#ffffff"
                    size={"23px"}
                    onClick={() => {
                      setSelectedPolicy(null);
                    }}
                  />
                </div>

                {selectedPolicy === "Academic and Attendance Policy" &&
                  academic_and_attendance_policy}
                {selectedPolicy === "Code of Conduct" && code_of_conduct}
                {selectedPolicy === "Expulsion Policy" && expulsion_policy}
                {selectedPolicy === "Sexual Violence and Harassment Policy" &&
                  sexual_violence_and_harassment_policy}
                {selectedPolicy === "Deferral Policy" && deferral_policy}
                {selectedPolicy === "Student Complaint Grievance Procedure" &&
                  student_complaint_grievance_procedure}
                {selectedPolicy === "Privacy Policy" && privacy_policy}
                {selectedPolicy === "Withdrawal & Refund Policy" &&
                  withdrawal_refund_policy}
              </div>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
}

export default Policies;
