import "./AddModule.css";
import { Field, Formik, Form, ErrorMessage } from "formik";
import React, { useContext } from "react";
import * as Yup from "yup";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
import { ThemeContext } from "ThemeContext";

function TeachingActivityModal({ isOpen, onClose, onSubmit, editValues }) {
  const initialValues = {
    learningActivities: "",
    hours: "",
    description: "",
  };

  const { theme, toggleTheme } = useContext(ThemeContext);

  const validationSchema = Yup.object().shape({
    learningActivities: Yup.string().required("Learning activity is required"),
  });

  return (
    <Modal
      className="crm-modal"
      isOpen={isOpen}
      onRequestClose={(values) => onClose(values)}
      shouldReturnFocusAfterClose={false}
    >
      <div className="modal-Con">
        <div
          className="col-11 col-md-5 p-3"
          style={{
            height: "auto",
            backgroundColor: theme === "light" ? "#ffffff" : "#212226",
          }}
        >
          <Formik
            validationSchema={validationSchema}
            initialValues={editValues || initialValues}
            enableReinitialize={true}
            onSubmit={(values) => {
              onSubmit(values);
              onClose();
            }}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <div>
                  <div className="flex-sm-row justify-content-between">
                    <div className="new-conversation-popup-header">
                      <span className="add-news-current-affair-title-text">
                        Breakdown Learning and Teaching Activities
                      </span>

                      <span className="d-flex justify-content-center align-items-center">
                        <IoClose
                          color={theme === "light" ? "#212226" : "#ffffff"}
                          size={"23px"}
                          onClick={() => {
                            onClose();
                          }}
                        />
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "12px",
                      marginTop: "10px",
                    }}
                  >
                    <label className="add-news-current-affair-label-text">
                      Learning Activities
                    </label>
                    <Field
                      name="learningActivities"
                      className="new-conversation-popup-input rounded-0"
                      value={values.learningActivities || ""}
                    />
                    <ErrorMessage
                      name="learningActivities"
                      component="div"
                      className="add-news-current-affair-error-message"
                    />
                  </div>

                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "12px",
                    }}
                  >
                    <label className="add-news-current-affair-label-text">
                      Hours
                    </label>
                    <Field
                      name="hours"
                      className="new-conversation-popup-input rounded-0"
                      value={values.hours || ""}
                    />
                  </div>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "12px",
                    }}
                  >
                    <label className="add-news-current-affair-label-text">
                      Description
                    </label>
                    <Field
                      as="textarea"
                      className="new-conversation-popup-textare-input rounded-0 custom-scrollbar"
                      name="description"
                      style={{ verticalAlign: "top" }}
                      maxLength={135}
                      value={values.description || ""}
                    />
                  </div>

                  <div
                    className="paymentupdate-document-upload"
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "12px",
                    }}
                  ></div>
                </div>
                <div className=" d-flex align-items-center gap-3 mt-4">
                  <button
                    className="communication-management-cancel-button"
                    type="button"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="communication-management-submit-button "
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
}
export default TeachingActivityModal;
