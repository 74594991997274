import { AgGridReact } from "ag-grid-react";
import { HR_MODULE_URLS } from "modules/hr-module/constants/hr-module-urls";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosService } from "services/axios.service";
import { ThemeContext } from "ThemeContext";

const AllFaculty = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const { theme, toggleTheme } = useContext(ThemeContext);
  useEffect(() => {
    axiosService.get(`${HR_MODULE_URLS.GET_ALL_EMPLOYEES}`).then((response) => {
      if (response.status === 200) {
        const responseData = response.data.data;
        setData(responseData);
      }
    });
  }, []);

  const onRowClicked = (event) => {
    const rowData = event.data;
    navigate("/hr-module/view-faculty", {
      state: {
        empId: rowData.empId,
      },
    });
  };

  const [columnDefs] = useState([
    {
      headerName: "Faculty Code",
      field: "employeeId",
    },
    {
      headerName: "Faculty Name",
      cellRenderer: function (params) {
        const rowData = params.data;

        return (
          <span>
            {rowData?.employeeFirstName + " " + rowData?.employeeLastName}
          </span>
        );
      },
    },

    {
      headerName: "Position",
      field: "position",
    },

    {
      headerName: "Campus Location",
      field: "campusLocation",
    },

    {
      headerName: "Status",
      field: "status",
    },

    {
      headerName: "Reports To",
      cellRenderer: function (params) {
        const rowData = params.data;

        return (
          <span>
            {rowData.reportsTo
              ? rowData.reportsTo.firstName +
                " " +
                (rowData.reportsTo.lastName ? rowData.reportsTo.lastName : "")
              : "-"}
          </span>
        );
      },
    },

    {
      headerName: "Gender",
      field: "gender",
    },

    // {
    //     headerName: "Attendance",
    //     // field: "Status",
    //     cellRenderer: function (params) {

    //         const rowData = params.data;

    //         return (
    //             <span style={{ textDecoration: 'underline' }} onClick={() => {
    //                 navigate("/hr-module/view-faculty", {
    //                     state: {
    //                         empId: rowData.empId
    //                     }
    //                 }
    //                 );

    //             }}>
    //                 {/* {rowData.campaignStatus === "FAILED" ? "Failed" : rowData.campaignStatus === "COMPLETED" ? "Completed" : "-"} */}

    //                 View
    //             </span>
    //         );

    //     },
    // }
  ]);

  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowHeight: 38,
  };

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);

  return (
    <div>
      <div className="communication-management-main-container">
        <div className="communication-management-head-container sticky-header-communication align-items-center flex-row flex-wrap gap-2">
          <span className="communication-management-title-text">
            All Faculty
          </span>

          <div className="d-flex align-items-center gap-3">
            <button
              className="communication-save-head-button"
              onClick={() => {
                navigate("/hr-module/new-employee");
              }}
            >
              On-board New Employee
            </button>
          </div>
        </div>
        <div
          className={
            theme === "light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"
          }
        >
          <AgGridReact
            columnDefs={columnDefs}
            rowData={data}
            rowSelection="single"
            domLayout="autoHeight"
            defaultColDef={defaultColDef}
            suppressRowClickSelection
            gridOptions={gridOptions}
            onRowClicked={onRowClicked}
          />
        </div>
      </div>
    </div>
  );
};

export default AllFaculty;
