import React, { useEffect, useState, useContext } from "react";
import Modal from "react-modal";
import "./NewConversationPopup.css";
import { IoClose } from "react-icons/io5";
import Select from "react-select";
import SearchIcon from "assets/image-components/SearchIcon";
import { USER_URL } from "modules/user-management/constants/user-urls";
import { axiosService } from "services/axios.service";
import sessionstorageService from "services/sessionstorage.service";
import { QUERY_FEEDBACK_URLS } from "../../constants/query-feedback-urls";
import { NEW_CONVERSATION_CONSTANTS } from "../../constants/query-feedback-constants";
import { toast } from "react-toastify";
import { ThemeContext } from "ThemeContext";

function NewConversationPopup({ open, onClose }) {
  const [receivingUser, setReceivingUser] = useState(null);
  const [receivingUserOptions, setReceivingUserOptions] = useState([]);
  const [officeSatffUser, setOfficeStaffUser] = useState(null);
  const [officeStaffUserOptions, setOfficeStaffUserOptions] = useState([]);
  const [studentUser, setStudentUser] = useState(null);
  const [studentUserOptions, setStudentUserOptions] = useState([]);
  const [superAdminUser, setSuperAdminUser] = useState(null);
  const [superAdminUserOptions, setSuperAdminUserOptions] = useState([]);
  const [description, setDescription] = useState();
  const userData = sessionstorageService.getUserData("userData");
  const role = userData?.role?.name;
  const { theme, toggleTheme } = useContext(ThemeContext);
  useEffect(() => {
    const roleName = "faculty";
    axiosService
      .get(`${USER_URL.GET_USER_BY_ROLENAME}?roleName=${roleName}`)
      .then((response) => {
        const data = response?.data?.data;
        const options = data
          ?.filter((datas) => datas.userId !== userData?.userId)
          ?.map((datas) => ({
            value:
              datas.firstName +
              " " +
              (datas.lastName !== undefined ? datas.lastName : ""),
            label:
              datas.firstName +
              " " +
              (datas.lastName !== undefined ? datas.lastName : ""),
            receivingObject: datas,
          }));
        if (options) {
          setReceivingUserOptions(options);
        }
      });
  }, []);
  useEffect(() => {
    const roleName = "office-staff";
    axiosService
      .get(`${USER_URL.GET_USER_BY_ROLENAME}?roleName=${roleName}`)
      .then((response) => {
        const data = response?.data?.data;
        const options = data
          ?.filter((datas) => datas.userId !== userData?.userId)
          ?.map((datas) => ({
            value:
              datas.firstName +
              " " +
              (datas.lastName !== undefined ? datas.lastName : ""),
            label:
              datas.firstName +
              " " +
              (datas.lastName !== undefined ? datas.lastName : ""),
            receivingObject: datas,
          }));
        if (options) {
          setOfficeStaffUserOptions(options);
        }
      });
  }, []);
  useEffect(() => {
    const roleName = "super admin";
    axiosService
      .get(`${USER_URL.GET_USER_BY_ROLENAME}?roleName=${roleName}`)
      .then((response) => {
        const data = response?.data?.data;
        const options = data
          ?.filter((datas) => datas.userId !== userData?.userId)
          ?.map((datas) => ({
            value:
              datas.firstName +
              " " +
              (datas.lastName !== undefined ? datas.lastName : ""),
            label:
              datas.firstName +
              " " +
              (datas.lastName !== undefined ? datas.lastName : ""),
            receivingObject: datas,
          }));
        if (options) {
          setSuperAdminUserOptions(options);
        }
      });
  }, []);
  useEffect(() => {
    const roleName = "student";
    axiosService
      .get(`${USER_URL.GET_USER_BY_ROLENAME}?roleName=${roleName}`)
      .then((response) => {
        const data = response?.data?.data;
        const options = data
          ?.filter((datas) => datas.userId !== userData?.userId)
          ?.map((datas) => ({
            value:
              datas.firstName +
              " " +
              (datas.lastName !== undefined ? datas.lastName : ""),
            label:
              datas.firstName +
              " " +
              (datas.lastName !== undefined ? datas.lastName : ""),
            receivingObject: datas,
          }));
        if (options) {
          setStudentUserOptions(options);
        }
      });
  }, []);

  const onSubmit = async () => {
    try {
      let sendData = {
        receiver:
          receivingUser || officeSatffUser || superAdminUser || studentUser,
        description: description,
        sender: userData,
      };
      const response = await axiosService.post(
        `${QUERY_FEEDBACK_URLS.CREATE_NEW_CONVERSATION}`,
        sendData
      );

      if (response.status === 200) {
        toast.success(NEW_CONVERSATION_CONSTANTS.SUCCESS);
        onClose();
      } else {
        toast.error(NEW_CONVERSATION_CONSTANTS.FAILED);
      }
    } catch (error) {
      toast.error(NEW_CONVERSATION_CONSTANTS.ERROR_OCCUR);
    }
  };

  return (
    <Modal
      className="crm-modal"
      isOpen={open}
      onRequestClose={() => onClose()}
      shouldReturnFocusAfterClose={false}
    >
      <div className="modal-Con">
        <div
          className="col-11 col-md-5 p-3"
          style={{
            height: "auto",
            backgroundColor: theme === "light" ? "#ffffff" : "#212226",
          }}
        >
          <div className="new-conversation-popup-header">
            <span className="new-conversation-popup-title-text">
              Create New Conversation
            </span>
            <span
              className="d-flex justify-content-end align-items-center"
              onClick={onClose}
              style={{ cursor: "pointer" }}
            >
              <IoClose
                color={theme === "light" ? "#000000" : "#ffffff"}
                size={"22px"}
              />
            </span>
          </div>
          <div className="d-flex flex-column gap-2 mt-2">
            {role != "faculty" && (
              <div>
                <label className="new-conversation-popup-label">
                  Select a faculty
                </label>

                <Select
                  noOptionsMessage={() => "No faculty found"}
                  onChange={(option) => {
                    setReceivingUser(option.receivingObject);
                  }}
                  options={receivingUserOptions}
                  isSearchable={true}
                  classNamePrefix="react-select"
                  components={{
                    DropdownIndicator: () => (
                      <SearchIcon
                        color={theme === "light" ? "#000000" : "#ffffff"}
                      />
                    ),
                    IndicatorSeparator: null,
                  }}
                  placeholder="Search"
                  menuPlacement="auto"
                  menuPosition="fixed"
                />
              </div>
            )}
            {role != "office-staff" && (
              <div>
                <label className="new-conversation-popup-label">
                  Select a OfficeStaff
                </label>

                <Select
                  noOptionsMessage={() => "No officeStaff found"}
                  onChange={(option) => {
                    setOfficeStaffUser(option.receivingObject);
                  }}
                  options={officeStaffUserOptions}
                  isSearchable={true}
                  classNamePrefix="react-select"
                  components={{
                    DropdownIndicator: () => (
                      <SearchIcon
                        color={theme === "light" ? "#000000" : "#ffffff"}
                      />
                    ),
                    IndicatorSeparator: null,
                  }}
                  placeholder="Search"
                  menuPlacement="auto"
                  menuPosition="fixed"
                />
              </div>
            )}
            {role != "super admin" && (
              <div>
                <label className="new-conversation-popup-label">
                  Select Superadmin
                </label>

                <Select
                  noOptionsMessage={() => "No faculty found"}
                  onChange={(option) => {
                    setSuperAdminUser(option.receivingObject);
                  }}
                  options={superAdminUserOptions}
                  isSearchable={true}
                  classNamePrefix="react-select"
                  components={{
                    DropdownIndicator: () => (
                      <SearchIcon
                        color={theme === "light" ? "#000000" : "#ffffff"}
                      />
                    ),
                    IndicatorSeparator: null,
                  }}
                  placeholder="Search"
                  menuPlacement="auto"
                  menuPosition="fixed"
                />
              </div>
            )}
            {role != "student" && (
              <div>
                <label className="new-conversation-popup-label">
                  Select a Student
                </label>

                <Select
                  noOptionsMessage={() => "No faculty found"}
                  onChange={(option) => {
                    setStudentUser(option.receivingObject);
                  }}
                  options={studentUserOptions}
                  isSearchable={true}
                  classNamePrefix="react-select"
                  components={{
                    DropdownIndicator: () => (
                      <SearchIcon
                        color={theme === "light" ? "#000000" : "#ffffff"}
                      />
                    ),
                    IndicatorSeparator: null,
                  }}
                  placeholder="Search"
                  menuPlacement="auto"
                  menuPosition="fixed"
                />
              </div>
            )}
            <div>
              <label className="new-conversation-popup-label">
                Title{" "}
                <span className="new-conversation-popup-label-small">
                  (max 100 chars)
                </span>
              </label>

              <textarea
                maxLength={100}
                onChange={(event) => {
                  setDescription(event.target.value);
                }}
                className="new-conversation-popup-textare-input"
              />
            </div>
            <div className="d-flex gap-3">
              <button
                className="communication-management-cancel-button"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                disabled={
                  !(
                    receivingUser ||
                    officeSatffUser ||
                    superAdminUser ||
                    studentUser
                  ) || !description
                }
                className={`communication-management-submit-button ${
                  (!(
                    receivingUser ||
                    officeSatffUser ||
                    superAdminUser ||
                    studentUser
                  ) ||
                    !description) &&
                  "opacity-50"
                }`}
                onClick={() => onSubmit()}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default NewConversationPopup;
