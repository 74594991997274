import React, { useContext } from "react";
import "./ViewCurrentaffairs.css";
import { useEffect, useState } from "react";
import deafult_image from "assets/images/current-affair-icons/deafult_image.png";
import AddCurrentaffairs from "../add-currentaffairs/AddCurrentaffairs";
import { axiosService } from "services/axios.service";
import sessionstorageService from "services/sessionstorage.service";
import { toast } from "react-toastify";
import editicon from "assets/images/current-affair-icons/cam-edit-icon.png";
import bluetag from "assets/images/current-affair-icons/cm-ccam-tag.png";
import { NEWS_AFFAIR_URLS } from "../../constants/news-affair-urls";
import { FaRegEdit } from "react-icons/fa";
import { ThemeContext } from "ThemeContext";

function hasPermission(permission) {
  let userPermissions = sessionstorageService.hasPermissionByButton(permission);
  return userPermissions;
}

function formatDate(inputDate) {
  if (!inputDate) {
    return "";
  }
  const dateObject = new Date(inputDate);
  const day = dateObject.getDate();
  const monthAbbreviation = dateObject.toLocaleString("default", {
    month: "short",
  });
  const year = dateObject.getFullYear();
  return `${day} ${monthAbbreviation} ${year}`;
}

function ViewCurrentaffairs() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [selectedNewsId, setSelectedNewsId] = useState(null);
  const { theme, toggleTheme } = useContext(ThemeContext);

  const fetchData = async () => {
    try {
      const response = await axiosService.get(
        `${NEWS_AFFAIR_URLS.GET_NEWS_CURRENT_AFFAIR_ALL}`
      );

      if (response.status === 200) {
        const responseData = response.data.data.reverse();
        setData(responseData);
      } else {
        toast.error("Error fetching data:", response.status);
      }
    } catch (error) {
      toast.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleEditClick = (news) => {
    setSelectedNewsId(news?.newsId);
    setIsModalOpen(true);
  };

  return (
    <div>
      <div className="communication-management-main-container">
        <div className="communication-management-head-container sticky-header-communication flex-row gap-2">
          <div className="d-flex align-items-center gap-3">
            <span className="communication-management-title-text">
              Current Affairs
            </span>
          </div>
          {hasPermission("News: create") && (
            <div className="d-flex align-items-center gap-3">
              <button
                className="communication-save-head-button"
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                Add New
              </button>
            </div>
          )}
        </div>

        <div
          className="my-3 currentaffairs-container-main-body custom-scrollbar"
          style={data.length > 0 ? { border: "1px solid #525356" } : null}
        >
          {data?.map((news) => (
            <div className="cac-body-firstboard-link" key={news?.newsId}>
              <a
                href={
                  news?.articleLink.startsWith("http")
                    ? news?.articleLink
                    : `http://${news?.articleLink}`
                }
                target="_blank"
                rel="noopener noreferrer"
                className="cac-body-firstboard"
                key={news?.newsId}
              >
                <div className="cac-body-firstboard-left">
                  <div
                    style={{
                      width: "156px",
                      height: "106px",
                      borderRadius: "10px",
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      className="cac-body-firstboard-image"
                      src={news?.uploadImage || deafult_image}
                      alt="cac"
                    />
                  </div>
                </div>
                <div className="cac-body-firstboard-right">
                  <div>
                    <h4>{news?.title}</h4>
                    <p>{news?.articleDescription}</p>
                    <span>{formatDate(news?.articleDate)}</span>
                  </div>
                </div>
              </a>
              <img className="cm-cam-right-tag" src={bluetag} />
              {hasPermission("News: edit") && news?.isExternal == false && (
                <>
                  {/* <img
                
                  className="cam-body-firstboadr-edit"
                  src={editicon}
                  alt="edit-icon"
                  onClick={() => {
                    handleEditClick(news);
                  }}
                /> */}
                  <FaRegEdit
                    className="cam-body-firstboadr-edit"
                    onClick={() => {
                      handleEditClick(news);
                    }}
                    color={theme === "light" ? "#000000" : "#ffffff"}
                  />
                </>
              )}
            </div>
          ))}
        </div>
      </div>

      <AddCurrentaffairs
        open={isModalOpen}
        onClose={(data) => {
          setIsModalOpen(false);
          fetchData();
          setSelectedNewsId(null);
        }}
        selectedNewsId={selectedNewsId}
      />
    </div>
  );
}
export default ViewCurrentaffairs;
