import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { useContext, useEffect, useMemo, useState } from "react";
import { RxTriangleDown } from "react-icons/rx";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosService } from "services/axios.service";
import { toast } from "react-toastify";
import { ATTENDANCE_MANAGEMENT_URLS } from "../constants/attendance-management-urls";
import Select from "react-select";
import sessionService from "services/sessionstorage.service";
import { ADMISSION_URL_FOR_STUDENT } from "modules/pre-onboarding/components/constants/admission-application-student-urls";
import { ATTENDANCE_CONSTANTS } from "../constants/attendance-management-constants";
import Swal from "sweetalert2";
import PresentIcon from "assets/images/attendance-icons/PresentIcon";
import AbsentIcon from "assets/images/attendance-icons/AbsentIcon";
import { Dropdown } from "react-bootstrap";
import { ThemeContext } from "ThemeContext";
function FacultyAttendance() {
  const location = useLocation();
  const navigate = useNavigate();
  const [classData, setClassData] = useState("");

  const { theme, toggleTheme } = useContext(ThemeContext);

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      border: "none !important",
      height: "36px !important",
      width: "123px !important",
      backgroundColor: "#f69f3a !important",
      borderRadius: "6px !important",
      color: "#212226 !important",
      boxShadow: "none !important",
    }),
  };

  const [program, setProgram] = useState({ value: "", label: "ALL" });
  const [diplomaOptions, setDiplomaOptions] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  function onSelectionChanged(event) {
    var selectedNodes = event.api.getSelectedNodes();
    var selectedData = selectedNodes.map((node) => node.data);

    setSelectedData(selectedData);
  }
  useEffect(() => {
    const fetchDiplomaOptions = async () => {
      axiosService
        .get(`${ADMISSION_URL_FOR_STUDENT.GET_PROGRAM_OPTIONS}`)
        .then((response) => {
          const options = response.data.data;
          const allOption = { value: "", label: "ALL" };
          const updatedOptions = [allOption, ...options];
          setDiplomaOptions(updatedOptions);
        });
    };

    fetchDiplomaOptions();
  }, []);
  const [campus, setCampus] = useState({ value: "", label: "ALL" });
  const CampusOptions = [
    { value: "", label: "ALL" },
    { value: "BRAMPTON", label: "Brampton" },
    { value: "MISSISSAUGA", label: "Mississauga" },
  ];
  const [days, setDays] = useState({ value: 7, label: "Last 7 days" });
  const DaysOption = [
    { value: 7, label: "Last 7 days" },
    { value: 14, label: "Last 14 days" },
    { value: 30, label: "Last 30 days" },
    { value: 60, label: "Last 60 days" },
    { value: 90, label: "Last 90 days" },
    { value: 120, label: "Last 120 days" },
  ];
  const handleCampusChange = (selectedOption) => {
    setCampus(selectedOption);
  };
  const handleProgramChange = (selectedOption) => {
    setProgram(selectedOption);
  };
  const handleDaysChange = (selectedOption) => {
    setDays(selectedOption);
  };

  const fetchData = () => {
    let url = `${ATTENDANCE_MANAGEMENT_URLS.GET_ALL_FACULTY}?days=${days.value}`;

    if (campus.value) {
      url += `&campus=${campus.value}`;
    }

    if (program.value) {
      url += `&program=${program.value}`;
    }

    axiosService
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          const responseData = response?.data?.data;
          setClassData(responseData);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [location.pathname, days.value, program.value, campus.value]);

  const CustomIconRenderer = (props) => {
    const handleAttendance = async (type) => {
      const facultyData = sessionService.getUserData("userData");
      const dataToSend = {
        startTime: props.data.startTime,
        endTime: props.data.endTime,
        markInDate: props.data.startDate,
        facultyMarked: {
          userId: facultyData.userId,
        },
        faculty: {
          userId: props.data?.facultyAssigned?.userId,
        },
        schedule: {
          facultyAttendanceId: props.data.facultyAttendanceId,
        },
      };

      const url =
        type === "Present"
          ? `${ATTENDANCE_MANAGEMENT_URLS.POST_FACULTY_PRESENT_ATTENDANCE}`
          : `${ATTENDANCE_MANAGEMENT_URLS.POST_FACULTY_ABSENT_ATTENDANCE}`;

      try {
        const response = await axiosService.post(url, dataToSend);
        if (response.status === 200) {
          toast.success(ATTENDANCE_CONSTANTS.SUCCESS);
          fetchData();
        } else {
          toast.success(response?.data?.message);
        }
      } catch (error) {
        toast.error(ATTENDANCE_CONSTANTS.ERROR_OCCUR);
      }
    };

    const handleClick = (type) => {
      Swal.fire({
        title: `Are you sure?`,
        text: `Do you want to mark this attendee as ${type}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `Yes, mark as ${type}`,
        cancelButtonText: "No, cancel",
        customClass: {
          popup: "my-custom-class",
          content: "custom-swal-text",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          handleAttendance(type);
        }
      });
    };
    const isDateInFuture = new Date(props.data.startDate) > new Date();
    return (
      <div>
        {!props.data?.isAttendanceMarked ? (
          <div className="d-flex gap-3">
            <div
              style={{
                cursor: isDateInFuture ? "not-allowed" : "pointer",
                // opacity: isDateInFuture ? 0.5 : 1,
              }}
              onClick={() => !isDateInFuture && handleClick("Present")}
            >
              <PresentIcon />
            </div>
            <div
              style={{
                cursor: isDateInFuture ? "not-allowed" : "pointer",
                // opacity: isDateInFuture ? 0.5 : 1,
              }}
              onClick={() => !isDateInFuture && handleClick("Absent")}
            >
              <AbsentIcon />
            </div>
          </div>
        ) : (
          <div
            style={{ textDecoration: "underline", cursor: "pointer" }}
            className={`d-flex gap-1
          ${
            props.data?.attendances[0].attendanceType === "PRESENT" &&
            "student-portal-table-status-approved"
          }
          ${
            props.data?.attendances[0].attendanceType === "ABSENT" &&
            "student-portal-table-status-pending"
          }`}
          >
            {props.data?.attendances[0].attendanceType === "PRESENT"
              ? "Present"
              : "Absent"}
          </div>
        )}
      </div>
    );
  };

  const [columnDefs] = useState([
    {
      headerName: "",
      field: "select",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      width: 40,
      floatingFilter: false,
      filter: false,
    },
    {
      headerName: "Faculty Name",
      field: "facultyAssigned.firstName",
      width: 160,
    },
    {
      headerName: "Class Name",
      field: "module.module.moduleName",
      width: 150,
    },
    {
      headerName: "Program Name",
      field: "module.programFeeMaster.program",
      width: 300,
      cellStyle: {
        whiteSpace: "normal",
        wordWrap: "break-word",
        overflow: "hidden",
      },
    },
    {
      headerName: "Campus",
      field: "module.programFeeMaster.campus",
      width: 130,
    },
    {
      headerName: "Date",
      field: "startDate",
      width: 140,
    },
    {
      headerName: "Time",
      valueGetter: mergeStartEndTime,
      width: 150,
    },

    {
      headerName: "Semester",
      field: "module.semester",
      width: 130,
    },

    {
      headerName: "Present/Absent",
      width: 170,
      cellRenderer: CustomIconRenderer,
    },
  ]);

  function mergeStartEndTime(params) {
    const startTime = params.data.startTime;
    const endTime = params.data.endTime;
    return `${startTime} - ${endTime}`;
  }

  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    checkboxSelection: function (params) {
      return (
        params.columnApi.getAllDisplayedColumns().indexOf(params.column) !== 0
      );
    },
    onSelectionChanged: onSelectionChanged,
  };

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);

  const handleMarkAsPresent = () => {
    Swal.fire({
      title: `Are you sure?`,
      text: `Do you want to mark this attendee as Present?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes, mark as Present`,
      cancelButtonText: "No, cancel",
      customClass: {
        popup: "my-custom-class",
        content: "custom-swal-text",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const facultyData = sessionService.getUserData("userData");
        const dataToSend = selectedData.map((attendance) => ({
          startTime: attendance.startTime,
          endTime: attendance.endTime,
          markInDate: attendance.startDate,
          facultyMarked: {
            userId: facultyData.userId,
          },
          schedule: {
            facultyAttendanceId: attendance.facultyAttendanceId,
          },
          faculty: {
            userId: attendance.facultyAssigned.userId,
          },
        }));

        const url = `${ATTENDANCE_MANAGEMENT_URLS.POST_BULK_PRESENT}`;

        try {
          const response = await axiosService.post(url, dataToSend);
          if (response.status === 200) {
            toast.success("Attendances marked Successfully!");
            fetchData();
          } else {
            toast.error(response.data.message);
          }
        } catch (error) {
          toast.error(
            `${error?.response?.data?.message.split(":")[1]?.trim()}`
          );
        }
      }
    });
  };
  const handleMarkAsAbsent = () => {
    Swal.fire({
      title: `Are you sure?`,
      text: `Do you want to mark this attendee as Absent?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes, mark as Absent`,
      cancelButtonText: "No, cancel",
      customClass: {
        popup: "my-custom-class",
        content: "custom-swal-text",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const facultyData = sessionService.getUserData("userData");
        const dataToSend = selectedData.map((attendance) => ({
          startTime: attendance.startTime,
          endTime: attendance.endTime,
          markInDate: attendance.startDate,
          facultyMarked: {
            userId: facultyData.userId,
          },
          schedule: {
            facultyAttendanceId: attendance.facultyAttendanceId,
          },
          faculty: {
            userId: attendance.facultyAssigned.userId,
          },
        }));

        const url = `${ATTENDANCE_MANAGEMENT_URLS.POST_BULK_ABSENT}`;

        try {
          const response = await axiosService.post(url, dataToSend);
          if (response.status === 200) {
            toast.success("Attendances marked Successfully!");
            fetchData();
          } else {
            toast.error(response.data.message);
          }
        } catch (error) {
          toast.error(
            `${error?.response?.data?.message.split(":")[1]?.trim()}`
          );
        }
      }
    });
  };

  const [isFiltersVisible, setIsFiltersVisible] = useState(false);

  const handleToggleFilters = () => {
    setIsFiltersVisible(!isFiltersVisible);
  };

  const filterSelects = (
    <>
      <div className="d-flex align-items-center gap-1">
        <span class="ag-header-cell-text">Campus </span>
        <Select
          id="campus"
          value={campus}
          onChange={handleCampusChange}
          options={CampusOptions}
          styles={selectStyles}
          isSearchable={true}
          classNamePrefix="react-select-att"
          components={{
            DropdownIndicator: () => <RxTriangleDown color="#000000" />,
            IndicatorSeparator: null,
          }}
          placeholder="ALL"
          menuPlacement="auto"
          menuPosition="fixed"
        />
      </div>
      <div className="d-flex align-items-center gap-1">
        <span class="ag-header-cell-text">Program Name </span>
        <Select
          id="program"
          value={program}
          onChange={handleProgramChange}
          options={diplomaOptions}
          styles={selectStyles}
          isSearchable={true}
          classNamePrefix="react-select-att"
          components={{
            DropdownIndicator: () => <RxTriangleDown color="#000000" />,
            IndicatorSeparator: null,
          }}
          placeholder="ALL"
          menuPlacement="auto"
          menuPosition="fixed"
        />
      </div>
      <div className="d-flex align-items-center gap-1">
        <span class="ag-header-cell-text">Date </span>
        <Select
          id="days"
          value={days}
          onChange={handleDaysChange}
          options={DaysOption}
          styles={selectStyles}
          isSearchable={false}
          classNamePrefix="react-select-att"
          components={{
            DropdownIndicator: () => <RxTriangleDown color="#000000" />,
            IndicatorSeparator: null,
          }}
          placeholder="ALL"
          menuPlacement="auto"
          menuPosition="fixed"
        />
      </div>
    </>
  );
  return (
    <div className="view-leads-table-container">
      <div className="d-flex sticky-header flex-column gap-1">
        <div className="lead-table-head-container d-flex flex-column flex-md-row justify-content-between flex-wrap gap-2">
          <div className=" d-flex align-items-center gap-2">
            <button
              className="d-md-none p-0"
              style={{ border: "none", background: "transparent" }}
              onClick={handleToggleFilters}
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.4369 9H8.56315C8.06351 9 7.8114 9.60623 8.16542 9.96026L12.5 14.2955V19.125C12.5 19.3085 12.5896 19.4805 12.7399 19.5858L14.6149 20.8979C14.9848 21.1568 15.5 20.8944 15.5 20.437V14.2955L19.8347 9.96026C20.188 9.60694 19.9376 9 19.4369 9Z"
                  fill="#F69F3A"
                />
                <circle cx="14" cy="14" r="13.5" stroke="#F69F3A" />
              </svg>
            </button>
            <span className="lead-table-title-text">
              All Faculty Attendance
            </span>
          </div>

          <div
            className="d-flex d-md-none align-items-center gap-3 flex-wrap"
            style={
              isFiltersVisible
                ? { height: "auto" }
                : { height: 0, overflow: "hidden" }
            }
          >
            {filterSelects}
          </div>
          <div className="d-none d-md-flex align-items-center gap-3 flex-wrap">
            {filterSelects}
          </div>
        </div>
        <div className="d-flex justify-content-end align-items-center py-1 flex-wrap gap-2">
          <span class="ag-header-cell-text">Bulk Actions </span>

          <div className="d-none d-md-flex gap-2">
            <button
              className={`lead-table-head-button ${
                selectedData.length === 0 && "user-head-button-disabled"
              }`}
              onClick={handleMarkAsPresent}
              disabled={selectedData.length === 0}
            >
              Mark as Present
            </button>
            <button
              className={`lead-table-head-button ${
                selectedData.length === 0 && "user-head-button-disabled"
              }`}
              onClick={handleMarkAsAbsent}
              disabled={selectedData.length === 0}
            >
              Mark as Absent
            </button>
          </div>

          <Dropdown className="d-flex d-md-none p-0">
            <Dropdown.Toggle
              id="dropdown-basic"
              disabled={selectedData.length === 0}
              className={`lead-table-head-button ${
                selectedData.length === 0 && "opacity-50 "
              }`}
            >
              Actions
            </Dropdown.Toggle>

            <Dropdown.Menu className="py-0">
              <Dropdown.Item onClick={handleMarkAsPresent}>
                Mark as Present
              </Dropdown.Item>
              <Dropdown.Item onClick={handleMarkAsAbsent}>
                Mark as Absent
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <div
        className={
          theme === "light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"
        }
      >
        <AgGridReact
          columnDefs={columnDefs}
          rowData={classData}
          rowSelection="multiple"
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          suppressRowClickSelection
          gridOptions={gridOptions}
        />
      </div>
    </div>
  );
}

export default FacultyAttendance;
