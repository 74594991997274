import { Box, Step, StepLabel, Stepper } from "@mui/material";
import "./NewForm.css";
import React, { useContext, useEffect, useState } from "react";
import { RxTriangleDown } from "react-icons/rx";
import Select from "react-select";
import { toast } from "react-toastify";
import { axiosService } from "services/axios.service";
import { useLocation, useNavigate } from "react-router-dom";
import { PROGRAM_URLS } from "modules/academic-management/constants/program-constants-urls";
import { EXAM_MODULE_URLS } from "modules/exam-module/constants/exam-modules-urls";
import Section from "assets/images/exam-module-imgs/Section";
import Close from "assets/images/exam-module-imgs/Close";
import { IoIosAddCircleOutline } from "react-icons/io";
import QuestionModal from "./QuestionModal";
import sessionService from "services/sessionstorage.service";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { TbLayoutBottombarCollapseFilled } from "react-icons/tb";
import { TbLayoutNavbarCollapseFilled } from "react-icons/tb";
import Swal from "sweetalert2";
import { ThemeContext } from "ThemeContext";

const NewForm = () => {
  const navigate = useNavigate();
  const { theme, toggleTheme } = useContext(ThemeContext);
  const selectStyles = {
    control: (provided) => ({
      ...provided,
      // backgroundColor: "#212226 !important",
      backgroundColor:
        theme === "light" ? "#EBEBEB !important" : "#212226 !important",
      border: "none !important",
    }),
  };

  const steps = [
    { id: 1, label: "Exam Paper Details", step: "step 1" },
    { id: 2, label: "Sections & Questions", step: "step 2" },
    { id: 3, label: "Submit for Review", step: "step 3" },
  ];

  const [active, setActive] = useState(0);

  const [program, setProgram] = useState("");
  const [programCode, setProgramCode] = useState("");
  const [module, setModule] = useState("");
  const [moduleCode, setModuleCode] = useState("");
  const [examDescription, setExamDescription] = useState("");
  const [scheduledDate, setScheduledDate] = useState("");
  const [scheduledTime, setScheduledTime] = useState("");
  const [passPercentage, setPassPercentage] = useState("");
  const [comments, setComments] = useState("");
  const [programModuleId, setProgramModuleId] = useState("");
  const [diplomaOptions, setDiplomaOptions] = useState([]);
  const [selectedProgramModules, setSelectedProgramModules] = useState([]);
  const [programId, setProgramId] = useState("");
  const [moduleId, setModuleId] = useState("");
  const location = useLocation();

  const handleTimeChange = (newValue) => {
    const formattedTime = newValue ? dayjs(newValue).format("HH:mm:ss") : "";
    setScheduledTime(formattedTime);
  };

  useEffect(() => {
    const fetchDiplomaOptions = async () => {
      axiosService.get(`${PROGRAM_URLS.GET_ALL_PROGRAMS}`).then((response) => {
        const options = response?.data?.data?.programModel.map((program) => ({
          value: program.master.program,
          label: program.master.program,
          programId: program?.master?.programFeeMasterId,
          programCode: program.master.programCode,
          modules: program.response.flat().map((module) => ({
            value: module.moduleName,
            label: module.moduleName,
            moduleCode: module.moduleCode,
            moduleId: module.moduleId,
          })),
        }));
        setDiplomaOptions(options);
      });
    };

    fetchDiplomaOptions();
  }, []);
  useEffect(() => {
    if (programId && moduleId) {
      axiosService
        .get(
          `${EXAM_MODULE_URLS.GET_PROGRAM_MODULE_ID}${programId}/${moduleId}`
        )
        .then((response) => {
          if (response.status === 200) {
            const responseData = response.data.data;
            setProgramModuleId(responseData[0]?.programModuleId);
          }
        });
    }
  }, [location.pathname, moduleId, programId]);
  useEffect(() => {
    if (programModuleId !== "") {
      setProgramModuleId(programModuleId);
    }
  }, [programModuleId]);
  const userData = sessionService.getUserData("userData");
  const onSubmit = async () => {
    const Data = {
      module: {
        programModuleId: programModuleId,
      },
      createdFaculty: {
        userId: userData?.userId,
      },
      examDescription: examDescription,
      sections: sections,
      scheduledDate: scheduledDate,
      scheduledTime: scheduledTime,
      passPercentage: passPercentage,
      comments: comments,
    };
    try {
      const response = await axiosService.post(
        `${EXAM_MODULE_URLS.ADD_EXAMS}`,
        Data
      );
      if (response.status === 200) {
        toast.success("Exam saved successfully");
        navigate("/exam-module/all-exams");
      } else {
        toast.error("failed");
      }
    } catch (error) {
      if (error.response) {
        toast.error(`${error.response.data.message.split(":")[1]?.trim()}`);
      }
    }
  };
  const handleAlert = () => {
    Swal.fire({
      title: "Confirm Submit",
      html: "Are you sure you want to Submit the Exam Questions?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Submit",
      cancelButtonText: "No, cancel",
      customClass: {
        popup: "my-custom-class",
        content: "custom-swal-text",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        onSubmit();
      }
    });
  };
  const [isExpanded, setIsExpanded] = useState(false);
  const [questionModalOpen, setQuestionModalOpen] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [sections, setSections] = useState([
    {
      sectionTitle: "",
      duration: "",
      marks: "",
      questions: [],
    },
  ]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const [currentSectionIndex, setCurrentSectionIndex] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const openEditorModal = (
    sectionIndex,
    question = null,
    editing = false,
    qIndex = null
  ) => {
    setCurrentSectionIndex(sectionIndex);
    setCurrentQuestionIndex(qIndex);
    setIsEditing(editing);
    setEditorContent(question ? question : "");
    setQuestionModalOpen(true);
  };

  const closeQuestionModal = () => {
    setQuestionModalOpen(false);
  };

  const handleSave = (question) => {
    const updatedSections = [...sections];
    const currentSection = updatedSections[currentSectionIndex];

    if (isEditing && currentQuestionIndex !== null) {
      currentSection.questions[currentQuestionIndex] = {
        ...currentSection.questions[currentQuestionIndex],
        ...question,
      };
    } else {
      currentSection.questions.push({
        ...question,
      });
    }

    const totalMarks = currentSection.questions.reduce(
      (sum, question) => sum + (parseInt(question.marks, 10) || 0),
      0
    );

    currentSection.marks = totalMarks;

    setSections(updatedSections);
    setQuestionModalOpen(false);
  };

  const addSection = () => {
    setSections([
      ...sections,
      { sectionTitle: "", duration: "", marks: "", questions: [] },
    ]);
  };

  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleExpandIndex = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  const removeSection = (index) => {
    if (index === 0) return;
    const updatedSections = [...sections];
    updatedSections.splice(index, 1);
    setSections(updatedSections);
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else if (index < expandedIndex) {
      setExpandedIndex(expandedIndex - 1);
    }
  };
  const handleSectionChange = (index, field, value) => {
    const updatedSections = [...sections];
    updatedSections[index][field] = value;
    setSections(updatedSections);
  };
  const LeftContainer = () => {
    return (
      <div className="col-12 col-md-3 section-left-container p-0">
        <div className="section-left-inner-con">
          <div className="section-left-head-text">Program name</div>

          <div className="container">
            <div className="section-left-text-program">{program}</div>
            <div className="program-name">{program}</div>
          </div>
        </div>
        <div className="section-left-inner-con">
          <div className="section-left-head-text">Module name</div>
          <div className="section-left-text">{module}</div>
        </div>
        <div
          className="section-left-inner-con"
          onClick={toggleExpand}
          style={{ cursor: "pointer" }}
        >
          <div className="section-toggle-dropdown">
            <div className="section-left-head-text">Sections</div>
            <span
              className={`section-dropdown-icon ${
                isExpanded ? "rotate-180" : ""
              }`}
            >
              <RxTriangleDown />
            </span>
          </div>
          {isExpanded && (
            <div>
              {sections.map((section, index) => (
                <div key={index}>
                  <div className="section-left-inner-dd-con">
                    <div className="section-left-dd-head-text">
                      {section.sectionTitle}
                    </div>
                    <div className="section-dropdown-inner-flex">
                      <span className="section-left-text-dd">
                        Duration:{" "}
                        <span className="section-left-text-dd-right">
                          {section.duration}
                        </span>
                      </span>
                      <span className="section-left-text-dd">
                        Questions:{" "}
                        <span className="section-left-text-dd-right">
                          {section.questions.length}
                        </span>
                      </span>
                      <span className="section-left-text-dd">
                        Total Marks:{" "}
                        <span className="section-left-text-dd-right">
                          {section.marks}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div style={{ display: "flex", gap: "15px" }}>
            <span className="section-left-text">
              Total Sections: {sections.length}
            </span>
            <span className="section-left-text">
              Total Marks: {overallTotalMarks}
            </span>
          </div>
        </div>
        <div className="section-left-inner-con">
          <div className="section-left-head-text">Submit for Review</div>
          <div className="section-left-text">Status: -NA-</div>
        </div>
        <div className="section-left-inner-con">
          <div className="section-left-head-text">Publish Exam</div>
          <div className="section-left-text">Status: -NA-</div>
        </div>
      </div>
    );
  };
  const overallTotalMarks = sections.reduce((overallSum, section) => {
    const totalMarks = section.questions.reduce((sum, question) => {
      return sum + (parseInt(question.marks, 10) || 0);
    }, 0);
    return overallSum + totalMarks;
  }, 0);
  return (
    <div>
      <div className="wizard-container user-main-container">
        <div className="user-head-container sticky-header flex-row flex-wrap gap-2">
          <div className="d-flex align-items-center gap-1">
            <span className="user-title-text">Create New Exam</span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            {(active === 1 || active === 2 || active === 3) && (
              <button
                className="hr-module-new-employee-back-button"
                onClick={() => {
                  setActive(active - 1);
                }}
              >
                Back
              </button>
            )}
            {active === 0 && (
              <button
                className={`communication-management-cancel-button ${
                  program &&
                  programCode &&
                  module &&
                  moduleCode &&
                  programModuleId &&
                  examDescription
                    ? "opacity-100"
                    : "opacity-50"
                }`}
                onClick={() => {
                  if (
                    active < 3 &&
                    program &&
                    programCode &&
                    module &&
                    moduleCode &&
                    programModuleId &&
                    examDescription
                  ) {
                    setActive(active + 1);
                  } else if (active === 2) {
                    handleAlert();
                  }
                }}
              >
                Save & Next
              </button>
            )}
            {active === 1 && (
              <button
                className={`communication-management-cancel-button ${
                  sections.length >= 1 ? "opacity-100" : "opacity-50"
                }`}
                onClick={() => {
                  if (sections.length >= 1) {
                    setActive(active + 1);
                  } else if (active === 2) {
                    handleAlert();
                  }
                }}
              >
                Save & Next
              </button>
            )}
            {active === 2 && (
              <button
                className={`communication-management-cancel-button ${
                  scheduledDate && scheduledTime && passPercentage
                    ? "opacity-100"
                    : "opacity-50"
                }`}
                onClick={() => {
                  if (
                    active < 3 &&
                    scheduledDate &&
                    scheduledTime &&
                    passPercentage
                  ) {
                    handleAlert();
                  } else if (active === 2) {
                    handleAlert();
                  }
                }}
              >
                Save
              </button>
            )}
          </div>
        </div>

        <div className="create-exam-stepper-container">
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={active} alternativeLabel>
              {steps.map((label) => (
                <Step key={label.id}>
                  <StepLabel>
                    <span className="create-exam-step-text">
                      {label?.label}
                    </span>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          <div>
            {active === 0 && (
              <div className="exam-main-container col-12 col-sm-10 col-md-7 col-lg-6">
                <div
                  className="col-12 col-md-5"
                  style={{ height: "auto", width: "100%" }}
                >
                  <div className="exam-details-header py-2 px-3">
                    Enter the details for exam paper
                  </div>
                  <div className="py-3 px-1 d-flex flex-column gap-2">
                    <div className="create-financial-main-input-container col-12">
                      <div className="exam-modal-inner-container col-6 px-2">
                        <div className="label-text-exam">
                          Select a Program *
                        </div>

                        <Select
                          value={
                            program ? { value: program, label: program } : null
                          }
                          onChange={(option) => {
                            const selectedProgram = diplomaOptions.find(
                              (program) => program.value === option.value
                            );
                            if (selectedProgram) {
                              setProgramId(selectedProgram.programId);
                              setProgramCode(selectedProgram?.programCode);
                            }
                            setProgram(selectedProgram?.value);
                            setSelectedProgramModules(
                              selectedProgram?.modules || []
                            );
                            setModule("");
                            setModuleCode("");
                          }}
                          options={diplomaOptions}
                          isSearchable={false}
                          classNamePrefix="react-select"
                          styles={selectStyles}
                          style={{
                            height: "33px",
                            borderRadius: "3px",
                            fontSize: "12px",
                            backgroundColor: "#212226",
                          }}
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown
                                color={
                                  theme === "light" ? "#000000" : "#ffffff"
                                }
                              />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose an option"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      </div>
                      <div className="exam-modal-inner-container col-6 px-2">
                        <label className="label-text-exam">
                          Program code *
                        </label>
                        <input
                          value={programCode ? programCode : null}
                          name="programCode"
                          className="input-exam"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="create-financial-main-input-container col-12">
                      <div className="exam-modal-inner-container col-6 px-2">
                        <div className="label-text-exam">
                          Select a module/subject *
                        </div>

                        <Select
                          id="module"
                          value={
                            module ? { value: module, label: module } : null
                          }
                          onChange={(module) => {
                            if (module) {
                              setModuleId(module?.moduleId);
                              setModuleCode(module?.moduleCode);
                            }

                            setModule(module?.value);
                          }}
                          options={selectedProgramModules}
                          isSearchable={false}
                          classNamePrefix="react-select"
                          styles={selectStyles}
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown
                                color={
                                  theme === "light" ? "#000000" : "#ffffff"
                                }
                              />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose an Option"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      </div>
                      <div className="exam-modal-inner-container col-6 px-2">
                        <label className="label-text-exam">Module code *</label>
                        <input
                          value={moduleCode ? moduleCode : ""}
                          name="moduleCode"
                          className="input-exam"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="create-financial-main-input-container">
                      <div className="exam-modal-inner-container col-12 px-2">
                        <label className="label-text-exam">
                          Exam Description *
                        </label>
                        <input
                          onChange={(e) => setExamDescription(e.target.value)}
                          value={examDescription || ""}
                          className="input-exam"
                          component="textarea"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {active === 1 && (
              <>
                <div className="row text-white g-0" style={{ rowGap: "20px" }}>
                  <LeftContainer />
                  <div className="col-12 col-md-9 ps-md-3">
                    {sections.map((section, index) => {
                      {
                        /* const totalMarks = section.questions.reduce(
                        (sum, question) => {
                          return sum + (parseInt(question.marks, 10) || 0);
                        },
                        0
                      ); */
                      }
                      return (
                        <div
                          className="section-right-container gap-3"
                          key={index}
                        >
                          <div className="section-head">
                            <span className="section-head-start">
                              <Section />
                              <span
                                style={{
                                  display: "flex",
                                  gap: "40px",
                                }}
                              >
                                Section {index + 1}
                                <span>{section.sectionTitle}</span>
                              </span>
                            </span>
                            <div className="section-header-end">
                              <span
                                onClick={() => toggleExpandIndex(index)}
                                style={{ cursor: "pointer", fontSize: "20px" }}
                              >
                                {expandedIndex === index ? (
                                  <TbLayoutNavbarCollapseFilled />
                                ) : (
                                  <TbLayoutBottombarCollapseFilled />
                                )}
                              </span>

                              {index > 0 && (
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => removeSection(index)}
                                >
                                  <Close />
                                </span>
                              )}
                            </div>
                          </div>

                          {expandedIndex === index && (
                            <div>
                              <div className="section-sub-head-container">
                                <div className="section-sub-head col-6">
                                  <label className="section-sub-text">
                                    Section Title *
                                  </label>
                                  <input
                                    className="input-exam"
                                    value={section.sectionTitle}
                                    onChange={(e) =>
                                      handleSectionChange(
                                        index,
                                        "sectionTitle",
                                        e.target.value
                                      )
                                    }
                                    type="text"
                                  />
                                </div>
                                <div className="section-sub-head col-3">
                                  <label className="section-sub-text">
                                    Duration (mins) *
                                  </label>
                                  <input
                                    className="input-exam"
                                    value={section.duration}
                                    onChange={(e) =>
                                      handleSectionChange(
                                        index,
                                        "duration",
                                        e.target.value
                                      )
                                    }
                                    type="text"
                                  />
                                </div>
                                <div className="section-sub-head col-3">
                                  <label className="section-sub-text">
                                    Total Marks *
                                  </label>
                                  <input
                                    className="input-exam"
                                    value={section?.marks}
                                    type="number"
                                    readOnly
                                  />
                                </div>
                              </div>
                              <div>
                                <span className="section-question-text">
                                  Questions
                                </span>
                                <div className="col-12 d-flex flex-wrap">
                                  {section.questions.map((question, qIndex) => (
                                    <div
                                      className="question-container-data"
                                      key={qIndex}
                                    >
                                      <div
                                        style={{ textAlign: "left" }}
                                        dangerouslySetInnerHTML={{
                                          __html: question?.question,
                                        }}
                                      />

                                      <div className="question-bottom-container">
                                        <span>
                                          Type:
                                          <span className="question-bottom-text-color">
                                            {question?.questionType}
                                          </span>
                                        </span>
                                        <span>
                                          Marks
                                          <span className="question-bottom-text-color">
                                            {question?.marks}
                                          </span>
                                        </span>
                                        <span
                                          className="question-bottom-text-color"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            openEditorModal(
                                              index,
                                              question,
                                              true,
                                              qIndex
                                            )
                                          }
                                        >
                                          View/Edit
                                        </span>{" "}
                                      </div>
                                    </div>
                                  ))}
                                  <div
                                    className="question-container-text"
                                    onClick={() => openEditorModal(index)}
                                  >
                                    Add a question
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="add-section-con" onClick={addSection}>
                  <IoIosAddCircleOutline
                    style={{ width: "16px", height: "16px" }}
                  />
                  <span className="add-section-text"> Add Sections</span>
                </div>
                {questionModalOpen && (
                  <QuestionModal
                    isOpen={openEditorModal}
                    onClose={closeQuestionModal}
                    onSave={handleSave}
                    sectionIndex={currentSectionIndex}
                    initialHtmlContent={editorContent}
                    isEditing={isEditing}
                  />
                )}
              </>
            )}

            {active === 2 && (
              <>
                <div className="row text-white g-0" style={{ rowGap: "20px" }}>
                  <LeftContainer />
                  <div className="col-12 col-md-9 ps-md-3 ">
                    <div className="section-left-container">
                      <div className="section-head ps-2 py-3">
                        <span className="review-header">
                          Fill below details before submitting for review
                        </span>
                      </div>
                      <div className="py-3">
                        <div
                          className="d-flex flex-wrap"
                          style={{ rowGap: "10px" }}
                        >
                          <div className="section-sub-head col-12 col-md-6 col-xl-4 px-2">
                            <span className="label-text-question">
                              Set pass percentage (%) *
                            </span>
                            <input
                              className="input-exam"
                              onChange={(e) => {
                                const value = e.target.value;
                                if (
                                  !isNaN(value) &&
                                  Number(value) >= 0 &&
                                  Number(value) <= 100
                                ) {
                                  setPassPercentage(value);
                                }
                              }}
                              value={passPercentage || ""}
                            />
                          </div>
                          <div className="section-sub-head col-12 col-md-6 col-xl-4 px-2">
                            <span className="label-text-question">
                              Scheduled Date *
                            </span>
                            <input
                              className="input-exam"
                              type="date"
                              onChange={(e) => setScheduledDate(e.target.value)}
                              value={scheduledDate || ""}
                            />
                          </div>
                          <div className="section-sub-head col-12 col-md-6 col-xl-4 px-2">
                            <span className="label-text-question">
                              Scheduled Time *
                            </span>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["TimePicker"]}>
                                <TimePicker
                                  value={
                                    scheduledTime
                                      ? dayjs(scheduledTime, "HH:mm:ss")
                                      : null
                                  }
                                  onChange={handleTimeChange}
                                  renderInput={(params) => (
                                    <input
                                      {...params}
                                      className="input-exam-time-picker"
                                    />
                                  )}
                                  viewRenderers={{
                                    hours: renderTimeViewClock,
                                    minutes: renderTimeViewClock,
                                    seconds: renderTimeViewClock,
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </div>
                        </div>
                        <div className="section-sub-head col-12 px-2">
                          <span className="label-text-question">
                            Notes/Comments (optional)
                          </span>
                          <input
                            className="input-exam"
                            onChange={(e) => setComments(e.target.value)}
                            value={comments || ""}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewForm;
