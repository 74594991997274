import React, { useContext, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import "./ContactList.css";
import { RxTriangleDown } from "react-icons/rx";
import { AgGridReact } from "ag-grid-react";
import AddContactListPopUp from "../Add-contact-list-popup/AddContactListPopUp";
import { FaEye, FaPen, FaTrash } from "react-icons/fa";
import AddContactPopUp from "../add-contact-popup/AddContactPopUp";
import { axiosService } from "services/axios.service";
import { CAMPAIGN_URLS } from "modules/campaigns/constants/Campaign-urls";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Dropdown } from "react-bootstrap";
import { ThemeContext } from "ThemeContext";

const ContactList = () => {
  const [data, setData] = useState([]);
  const [openAddContactListPopUp, setOpenAddContactListPopUp] = useState(false);
  const [openAddContactPopUp, setOpenAddContactPopUp] = useState(false);
  const [leadStatus, setLeadStatus] = useState(null);
  const [leadId, setLeadId] = useState(null);
  const [contactUpdates, setContactUpdates] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);

  const [contactModalType, setContactModalType] = useState("");
  const [add, setAdd] = useState(true);
  const [contactListOptions, SetContactListOptions] = useState([]);

  const { theme, toggleTheme } = useContext(ThemeContext);

  function getAllContactList() {
    try {
      axiosService
        .get(`${CAMPAIGN_URLS.GET_ALL_CONTACT_LIST}`)
        .then((response) => {
          if (response.status === 200) {
            // SetContactListOptions(response?.data?.data)
            let Data = response?.data?.data.map((option) => ({
              value: option.listId,
              label: option.listName,
            }));
            SetContactListOptions(Data);
          }
        })
        .catch((error) => {
          // if (error.response) {
          //     toast.error("Failed");
          // } else if (error.request) {
          //     toast.error('No response received from server');
          // } else {
          toast.error(`${error.response.data.message.split(":")[1]?.trim()}`);
          // }
        });
    } catch (error) {
      console.error(error);
    }
  }

  function getAllContactUsingListID() {
    axiosService
      .get(`${CAMPAIGN_URLS.GET_CONTACTS_FOR_SELECTED_LIST}${leadId}`)
      .then((response) => {
        if (response.status === 200) {
          setData(response?.data?.data);
        }
      });
  }

  function deleteContactList() {
    Swal.fire({
      title: "Confirm Delete",
      text: "Are you sure you want to delete this Contact List?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete",
      cancelButtonText: "No, cancel",
      customClass: {
        popup: "my-custom-class",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axiosService
          .delete(`${CAMPAIGN_URLS.DELETE_CONTACT_LIST}${leadId}`)
          .then((response) => {
            if (response.status === 200) {
              getAllContactList();
              setLeadStatus(null);
              setLeadId(null);
              setData([]);
            }
          });
      }
    });
  }

  function deleteContact(contactId) {
    Swal.fire({
      title: "Confirm Delete",
      text: "Are you sure you want to delete this Contact?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete",
      cancelButtonText: "No, cancel",
      customClass: {
        popup: "my-custom-class",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axiosService
          .delete(`${CAMPAIGN_URLS.Delete_CONTACT_BY_CONTACT_ID}${contactId}`)
          .then((response) => {
            if (response) {
              setContactUpdates(new Date());
            }
          });
      }
    });
  }

  useEffect(() => {
    getAllContactList();
  }, [openAddContactListPopUp]);

  useEffect(() => {
    if (leadId) {
      getAllContactUsingListID();
    }
  }, [leadId]);

  useEffect(() => {
    if (leadId) {
      getAllContactUsingListID();
    }
  }, [contactUpdates]);

  const columnDefs = useMemo(
    () => [
      {
        headerName: "Contact Id",
        field: "contactId",
      },
      {
        headerName: "Contact Name",
        field: "contactName",
      },
      {
        headerName: "EmailId",
        field: "email",
      },

      {
        headerName: "Contact Number",
        field: "contactNumber",
      },
      // {
      //     headerName: "Action",
      //     field: "action",
      // }
      {
        field: "Actions",
        cellRenderer: function (params) {
          const rowData = params.data;

          const handleEditClick = (rowData) => {
            setSelectedContact({ ...rowData });
            setOpenAddContactPopUp(true);
          };

          return (
            <span>
              <FaEye
                style={{
                  marginRight: "20px",
                  height: "1em",
                  width: "1em",
                  color: theme === "light" ? "#000000" : "#ffffff",
                }}
                onClick={() => {
                  setContactModalType("view");
                  handleEditClick(rowData);
                }}
              />
              <FaPen
                style={{
                  marginRight: "20px",
                  height: "1em",
                  width: "1em",
                  color: "#0d3585",
                }}
                onClick={() => {
                  setContactModalType("edit");
                  handleEditClick(rowData);
                }}
              />
              <FaTrash
                style={{ height: "1em", width: "1em", color: "#f69f3a" }}
                onClick={() => {
                  deleteContact(rowData.contactId);
                }}
              />
            </span>
          );
        },
      },
    ],
    [theme]
  );
  const gridOptions = {
    pagination: true,
    paginationPageSize: 5,
  };

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);

  return (
    <div className="view-leads-table-container">
      <div className="lead-table-head-container align-items-center sticky-header flex-row flex-wrap gap-2">
        <span className="communication-management-title-text">
          Contact List
        </span>
      </div>
      <div className="user-head-container flex-row flex-wrap gap-2 align-items-end">
        <div className=" d-flex flex-column" style={{ width: "200px" }}>
          <label className="lead-first-name">Select a Contact List</label>
          <Select
            value={leadStatus ? { value: leadStatus, label: leadStatus } : null}
            // onChange={(option) => {
            //     setLeadStatus(option?.value);
            // }}
            onChange={(option) => {
              setLeadStatus(option ? option.label : null);
              setLeadId(option ? option.value : null);
            }}
            options={contactListOptions}
            isSearchable={false}
            classNamePrefix="react-select"
            components={{
              DropdownIndicator: () => (
                <RxTriangleDown
                  color={theme === "light" ? "#000000" : "#ffffff"}
                />
              ),
              IndicatorSeparator: null,
            }}
            placeholder="Choose an option"
            menuPlacement="auto"
            menuPosition="fixed"
          />
        </div>

        <div className="d-none d-md-flex gap-4">
          <div className="d-flex align-items-end">
            <button
              className="contact-list-create-button"
              onClick={() => {
                setAdd(true);
                setOpenAddContactListPopUp(true);
              }}
            >
              Add ContactList
            </button>
          </div>
          <div className="d-flex align-items-end">
            <button
              className={`contact-list-create-button ${
                !leadStatus && !leadId && "opacity-50"
              }`}
              disabled={!leadStatus && !leadId}
              onClick={() => {
                setAdd(false);
                setOpenAddContactListPopUp(true);
              }}
            >
              Rename ContactList
            </button>
          </div>
          <div className="d-flex align-items-end">
            <button
              className={`contact-list-delete-button ${
                !leadStatus && !leadId && "opacity-50"
              }`}
              disabled={!leadStatus && !leadId}
              onClick={() => {
                deleteContactList();
              }}
            >
              Delete ContactList
            </button>
          </div>
          <div className="d-flex align-items-end">
            <button
              className={`contact-list-create-button ${
                !leadStatus && !leadId && "opacity-50"
              }`}
              disabled={!leadStatus && !leadId}
              onClick={() => {
                setOpenAddContactPopUp(true);
              }}
            >
              Add Contact
            </button>
          </div>
        </div>
        <Dropdown className="d-md-none">
          <Dropdown.Toggle
            className="lead-table-head-button"
            id="dropdown-basic"
          >
            Actions
          </Dropdown.Toggle>

          <Dropdown.Menu className="py-0">
            <Dropdown.Item
              onClick={() => {
                setAdd(true);
                setOpenAddContactListPopUp(true);
              }}
            >
              Add ContactList
            </Dropdown.Item>
            <Dropdown.Item
              className={`${!leadStatus && !leadId && "opacity-50"}`}
              disabled={!leadStatus && !leadId}
              onClick={() => {
                setAdd(false);
                setOpenAddContactListPopUp(true);
              }}
            >
              Rename ContactList
            </Dropdown.Item>

            <Dropdown.Item
              className={`${!leadStatus && !leadId && "opacity-50"}`}
              disabled={!leadStatus && !leadId}
              onClick={() => {
                deleteContactList();
              }}
            >
              Delete ContactList
            </Dropdown.Item>
            <Dropdown.Item
              className={`${!leadStatus && !leadId && "opacity-50"}`}
              disabled={!leadStatus && !leadId}
              onClick={() => {
                setOpenAddContactPopUp(true);
              }}
            >
              Add Contact
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div
        className={
          theme === "light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"
        }
      >
        <AgGridReact
          columnDefs={columnDefs}
          rowData={data}
          rowSelection="single"
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          suppressRowClickSelection
          gridOptions={gridOptions}
          // onRowClicked={onRowClicked}
        />
      </div>
      {openAddContactListPopUp && (
        <AddContactListPopUp
          open={openAddContactListPopUp}
          add={add}
          leadId={leadId}
          leadStatus={leadStatus}
          setLeadStatus={setLeadStatus}
          setLeadId={setLeadId}
          onClose={() => {
            setOpenAddContactListPopUp(false);
          }}
        />
      )}
      {openAddContactPopUp && (
        <AddContactPopUp
          open={openAddContactPopUp}
          contactModalType={contactModalType}
          leadId={leadId}
          leadStatus={leadStatus}
          setLeadStatus={setLeadStatus}
          setLeadId={setLeadId}
          getAllContactUsingListID={getAllContactUsingListID}
          selectedContact={selectedContact}
          onClose={() => {
            setOpenAddContactPopUp(false);
            setSelectedContact(null);
          }}
        />
      )}
    </div>
  );
};

export default ContactList;
