import React from "react";

function Close({ color }) {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 16.2357C6.14348 16.2357 4.36301 15.4524 3.05025 14.0582C1.7375 12.664 1 10.773 1 8.80124C1 6.82949 1.7375 4.9385 3.05025 3.54427C4.36301 2.15003 6.14348 1.36676 8 1.36676C9.85652 1.36676 11.637 2.15003 12.9497 3.54427C14.2625 4.9385 15 6.82949 15 8.80124C15 10.773 14.2625 12.664 12.9497 14.0582C11.637 15.4524 9.85652 16.2357 8 16.2357ZM8 17.2978C10.1217 17.2978 12.1566 16.4026 13.6569 14.8092C15.1571 13.2158 16 11.0547 16 8.80124C16 6.54781 15.1571 4.38668 13.6569 2.79327C12.1566 1.19986 10.1217 0.304688 8 0.304688C5.87827 0.304688 3.84344 1.19986 2.34315 2.79327C0.842855 4.38668 0 6.54781 0 8.80124C0 11.0547 0.842855 13.2158 2.34315 14.8092C3.84344 16.4026 5.87827 17.2978 8 17.2978Z"
        fill={color}
      />
      <path
        d="M4.64494 5.24195C4.69139 5.1925 4.74656 5.15326 4.80731 5.12649C4.86805 5.09972 4.93317 5.08594 4.99894 5.08594C5.06471 5.08594 5.12983 5.09972 5.19057 5.12649C5.25132 5.15326 5.30649 5.1925 5.35294 5.24195L7.99894 8.05325L10.6449 5.24195C10.6914 5.19258 10.7466 5.15341 10.8074 5.12669C10.8681 5.09997 10.9332 5.08622 10.9989 5.08622C11.0647 5.08622 11.1298 5.09997 11.1905 5.12669C11.2513 5.15341 11.3065 5.19258 11.3529 5.24195C11.3994 5.29132 11.4363 5.34994 11.4615 5.41445C11.4866 5.47896 11.4996 5.5481 11.4996 5.61792C11.4996 5.68775 11.4866 5.75689 11.4615 5.8214C11.4363 5.88591 11.3994 5.94452 11.3529 5.99389L8.70594 8.80413L11.3529 11.6144C11.3994 11.6637 11.4363 11.7224 11.4615 11.7869C11.4866 11.8514 11.4996 11.9205 11.4996 11.9903C11.4996 12.0602 11.4866 12.1293 11.4615 12.1938C11.4363 12.2583 11.3994 12.3169 11.3529 12.3663C11.3065 12.4157 11.2513 12.4548 11.1905 12.4816C11.1298 12.5083 11.0647 12.522 10.9989 12.522C10.9332 12.522 10.8681 12.5083 10.8074 12.4816C10.7466 12.4548 10.6914 12.4157 10.6449 12.3663L7.99894 9.55501L5.35294 12.3663C5.30645 12.4157 5.25126 12.4548 5.19052 12.4816C5.12978 12.5083 5.06468 12.522 4.99894 12.522C4.9332 12.522 4.8681 12.5083 4.80736 12.4816C4.74662 12.4548 4.69143 12.4157 4.64494 12.3663C4.59845 12.3169 4.56158 12.2583 4.53642 12.1938C4.51126 12.1293 4.49831 12.0602 4.49831 11.9903C4.49831 11.9205 4.51126 11.8514 4.53642 11.7869C4.56158 11.7224 4.59845 11.6637 4.64494 11.6144L7.29194 8.80413L4.64494 5.99389C4.59838 5.94456 4.56143 5.88596 4.53623 5.82145C4.51102 5.75693 4.49805 5.68777 4.49805 5.61792C4.49805 5.54807 4.51102 5.47891 4.53623 5.41439C4.56143 5.34988 4.59838 5.29128 4.64494 5.24195Z"
        fill={color}
      />
    </svg>
  );
}

export default Close;
