import { Field, FieldArray, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { RxTriangleDown } from "react-icons/rx";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { axiosService } from "services/axios.service";
import "./CreateEmployementWorkExperience.css";
import sessionstorageService from "services/sessionstorage.service";
import { MdDelete } from "react-icons/md";
import { ADMISSION_CONSTANTS_FOR_STUDENTS } from "../../constants/admission-application-student-constants";
import { ADMISSION_URL_FOR_STUDENT } from "../../constants/admission-application-student-urls";
import { RiCloseCircleFill } from "react-icons/ri";
import { IoIosCheckmarkCircle } from "react-icons/io";
import * as Yup from "yup";
import AdmissionSideBar from "../admission-side-bar/AdmissionSideBar";
import sessionService from "services/sessionstorage.service";
import { ThemeContext } from "ThemeContext";

function CreateEmployementWorkExperience() {
  const navigation = useNavigate();
  const location = useLocation();
  const programId = location?.state?.data;

  const [admissionData, setAdmissionData] = useState(null);
  const [approved, setApproved] = useState(false);
  const [rejected, setRejected] = useState(false);
  const admissionId = sessionstorageService.getAdmissionId("admissionId");
  const [isFeeData, setIsFeeData] = useState(false);
  const { theme, toggleTheme } = useContext(ThemeContext);

  useEffect(() => {
    if (admissionId) {
      axiosService
        .get(
          `${ADMISSION_URL_FOR_STUDENT.GET_ADMISSION_URL_FOR_STUDENT}${admissionId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setAdmissionData(data);
          setApproved(data?.employments[0]?.isApproved);
          setRejected(data?.employments[0]?.isRejected);
          setIsFeeData(data?.fees[0]?.isCompleted);
        });
    }
  }, [location.pathname]);

  let employmentsInitialValues;

  if (admissionData?.employments?.length > 0) {
    employmentsInitialValues = admissionData.employments?.map((emp) => ({
      postHeld: emp.postHeld || "",
      companyName: emp.companyName || "",
      companyAddress: emp.companyAddress || "",
      type: emp.type || undefined,
      joiningDate:
        emp.joiningDate !== undefined
          ? new Date(emp.joiningDate)?.toISOString()?.split("T")[0]
          : "",
      relievingDate:
        emp.relievingDate !== undefined
          ? new Date(emp.relievingDate)?.toISOString()?.split("T")[0]
          : "",
      employmentId: emp?.employmentId,
    }));
  } else {
    employmentsInitialValues = [
      {
        postHeld: "",
        companyName: "",
        companyAddress: "",
        type: undefined,
        joiningDate: "",
        relievingDate: "",
      },
    ];
  }

  const validationSchema = Yup.object().shape({});

  const initialValues = {
    employment: employmentsInitialValues,
  };

  const navigateToAcademic = () => {
    navigation("/pre-onboarding/create-academic-professional", {
      state: {
        data: programId,
      },
    });
  };
  const userData = sessionService.getUserData("userData");
  const studentType = userData?.studentType;
  const onSubmit = async (values) => {
    const dataType = "employment";
    const saveData = {
      dataType,
      studentType,
      data: {
        admissionId: admissionData?.admissionId,
        personal: admissionData?.personalData,
        mailing: admissionData?.mailingAddress,
        guardian: admissionData?.guardianDetail,
        academics: admissionData?.academics,
        employments: values.employment,
      },
    };

    try {
      const response = await axiosService.post(
        `${ADMISSION_URL_FOR_STUDENT.ADMISSION_URL}`,
        saveData
      );

      if (response.status === 200) {
        toast.success(ADMISSION_CONSTANTS_FOR_STUDENTS.EMPLOYMENT_SUCCESS);
        navigation("/pre-onboarding/create-other-information", {
          state: {
            data: programId,
          },
        });
      } else {
        toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.EMPLOYMENT_FAILURE);
      }
    } catch (error) {
      if (error.response) {
        toast.error(`Error: ${error.response.data.message}`);
      } else {
        toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.EMPLOYMENT_ERROR);
      }
    }
  };
  const onSubmitSkip = async (values) => {
    const dataType = "employment";
    const saveData = {
      dataType,
      studentType,
      data: {
        admissionId: admissionData?.admissionId,
        personal: admissionData?.personalData,
        mailing: admissionData?.mailingAddress,
        guardian: admissionData?.guardianDetail,
        academics: admissionData?.academics,
        employments: values.employment,
      },
    };

    try {
      const response = await axiosService.post(
        `${ADMISSION_URL_FOR_STUDENT.ADMISSION_URL}`,
        saveData
      );

      if (response.status === 200) {
        navigation("/pre-onboarding/create-other-information", {
          state: {
            data: programId,
          },
        });
      } else {
        toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.EMPLOYMENT_FAILURE);
      }
    } catch (error) {
      if (error.response) {
        toast.error(`Error: ${error.response.data.message}`);
      } else {
        toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.EMPLOYMENT_ERROR);
      }
    }
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "0 !important",
      width: "110px !important",
    }),
  };

  const typeOptions = [
    { value: "PARTTIME", label: "Part Time" },
    { value: "FULLTIME", label: "Full Time" },
  ];

  const dateString = new Date(admissionData?.employments[0]?.updatedOn);
  const formattedDate =
    dateString instanceof Date && !isNaN(dateString)
      ? dateString.toLocaleDateString()
      : "Invalid Date";

  const handleDeleteEmployement = async (index, form, remove) => {
    const employmentIdToDelete = form.values.employment[index]?.employmentId;

    if (employmentIdToDelete) {
      try {
        const response = await axiosService.delete(
          `${ADMISSION_URL_FOR_STUDENT.DELETE_EMPLOYMENT}/${employmentIdToDelete}`
        );

        if (response.status === 200) {
          toast.success("Employment entry deleted successfully");
        } else {
          toast.error("Failed to delete academic entry");
        }
      } catch (error) {
        toast.error(`Error: ${error.message}`);
      }
    }
    remove(index);
  };

  return (
    <Formik
      initialValues={initialValues}
      // onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {({ resetForm, values, setFieldValue, setFieldTouched, formik }) => (
        <Form>
          <div className="create-admission-main-container">
            <div className="progress-container">
              <div className="create-admission-head-text">Registration</div>
              <div className="col">
                <ProgressBar
                  now={admissionData?.pagePercentage || 0}
                  label={`${admissionData?.pagePercentage}%`}
                />
              </div>
            </div>
            <div className="row create-admission-container g-0">
              <div className="create-admission-inner-left-container col-12 col-lg-3 pt-2">
                <AdmissionSideBar />
              </div>

              <div className="pre-create-employement-work-inner-right-container col-12 col-lg-9 py-2">
                <div className="create-text-title ">
                  Employments and Work Experience
                </div>
                <div className="scroll-con">
                  <FieldArray name="employment" class="fieldarray">
                    {({ push, remove, form }) => (
                      <div>
                        {form.values?.employment?.map((employment, index) => (
                          <div key={index}>
                            <div className="create-employement-work-main-input-container">
                              <div className="create-admission-inner-first-input-container ">
                                {index === 0 ? (
                                  <label className="label-text-active">
                                    Post Held
                                  </label>
                                ) : null}
                                <Field
                                  className="create-admission-input-small"
                                  name={`employment[${index}].postHeld`}
                                />
                              </div>
                              <div className="create-admission-inner-first-input-container ">
                                {index === 0 ? (
                                  <label className="label-text-active">
                                    Company Name
                                  </label>
                                ) : null}
                                <Field
                                  className="create-admission-input-small create-admission-input-small-companyname"
                                  name={`employment[${index}].companyName`}
                                />
                              </div>
                              <div className="create-admission-inner-first-input-container ">
                                {index === 0 ? (
                                  <label className="label-text-active">
                                    Company Address
                                  </label>
                                ) : null}
                                <Field
                                  className="create-admission-input-big"
                                  name={`employment[${index}].companyAddress`}
                                />
                              </div>
                              <div className="create-admission-inner-first-input-container ">
                                {index === 0 ? (
                                  <label className="label-text-active">
                                    Type
                                  </label>
                                ) : null}
                                <Field
                                  as="select"
                                  name={`employment[${index}].type`}
                                >
                                  {({ field, form }) => (
                                    <Select
                                      value={
                                        field.value
                                          ? typeOptions.find(
                                              (option) =>
                                                option.value === field.value
                                            )
                                          : null
                                      }
                                      onChange={(selectedOption) => {
                                        setFieldValue(
                                          `employment[${index}].type`,
                                          selectedOption.value
                                        );
                                        setFieldTouched(
                                          `employment[${index}].country`,
                                          true
                                        );
                                      }}
                                      options={typeOptions}
                                      isSearchable={false}
                                      classNamePrefix="react-select"
                                      styles={selectStyles}
                                      components={{
                                        DropdownIndicator: () => (
                                          <RxTriangleDown
                                            color={
                                              theme === "light"
                                                ? "#000000"
                                                : "#ffffff"
                                            }
                                          />
                                        ),
                                        IndicatorSeparator: null,
                                      }}
                                      placeholder="Choose"
                                      menuPlacement="auto"
                                      menuPosition="fixed"
                                    ></Select>
                                  )}
                                </Field>
                              </div>
                              <div className="create-admission-inner-first-input-container ">
                                {index === 0 ? (
                                  <label className="label-text-active">
                                    Joining Date
                                  </label>
                                ) : null}
                                <Field
                                  className="create-personal-data-date-input-small-employment"
                                  name={`employment[${index}].joiningDate`}
                                  type="date"
                                />
                              </div>
                              <div className="create-admission-inner-first-input-container ">
                                {index === 0 ? (
                                  <label className="label-text-active">
                                    Relieving Date
                                  </label>
                                ) : null}
                                <Field
                                  className="create-personal-data-date-input-small-employment"
                                  name={`employment[${index}].relievingDate`}
                                  type="date"
                                />
                              </div>

                              {values?.employment.length > 1 && index > 0 && (
                                <button
                                  type="button"
                                  className="create-admission-remove-row"
                                  onClick={() =>
                                    handleDeleteEmployement(index, form, remove)
                                  }
                                >
                                  <MdDelete />
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                        <button
                          type="button"
                          className="create-admission-add-row"
                          onClick={() =>
                            push({
                              postHeld: "",
                              companyName: "",
                              companyAddress: "",
                              type: undefined,
                              joiningDate: "",
                              relievingDate: "",
                            })
                          }
                        >
                          + Add Qualification
                        </button>
                      </div>
                    )}
                  </FieldArray>

                  <div className="d-flex gap-3 my-3 flex-wrap">
                    <button
                      type="button"
                      className="create-admission-previous-button"
                      onClick={navigateToAcademic}
                    >
                      Previous
                    </button>
                    {!admissionData?.employments[0]?.employmentId && (
                      <button
                        className="create-admission-save-button"
                        onClick={() => onSubmitSkip(values)}
                      >
                        Skip & Next
                      </button>
                    )}
                    {!admissionData?.employments[0]?.employmentId ? (
                      <button
                        type="submit"
                        className="create-admission-save-button"
                        // disabled={formik.isSubmitting}
                        onClick={() => onSubmit(values)}
                      >
                        Save & Next
                      </button>
                    ) : (
                      (rejected || (!rejected && !approved && !isFeeData)) && (
                        <button
                          type="submit"
                          onClick={() => onSubmit(values)}
                          className="create-admission-save-button"
                          // disabled={formik.isSubmitting}
                        >
                          Edit & Save
                        </button>
                      )
                    )}
                    {!admissionData?.employments[0]?.employmentId && (
                      <button
                        type="button"
                        className="create-admission-clear-button"
                        onClick={() => {
                          resetForm();
                        }}
                      >
                        Clear
                      </button>
                    )}

                    {(admissionData?.employments[0]?.employmentId ||
                      admissionData?.employments[0]?.isApproved) && (
                      <button
                        type="button"
                        className=" create-admission-next-button"
                        onClick={() =>
                          navigation(
                            "/pre-onboarding/create-other-information",
                            {
                              state: {
                                data: programId,
                              },
                            }
                          )
                        }
                      >
                        Next
                      </button>
                    )}
                  </div>
                  {admissionData?.employments[0]?.isApproved && (
                    <div className="row approve-reject-comments-container">
                      <span className="admission-comments-head-text">
                        Comments
                      </span>
                      <span className="approve-textarea">
                        <IoIosCheckmarkCircle className="admission-approve-check-icon" />
                        Approved
                      </span>
                      <span className="approve-date-text">
                        on {formattedDate}
                      </span>
                    </div>
                  )}
                  {admissionData?.employments[0]?.isRejected && (
                    <div className="row approve-reject-comments-container">
                      <span className="admission-comments-head-text">
                        Comments
                      </span>
                      <div className="rejected-container p-0 mt-2">
                        <div className="rejcted-head-text">
                          <RiCloseCircleFill className="admission-reject-icon" />
                          Rejected
                        </div>
                        <div className="inner-reject-text">
                          {admissionData?.employments[0]?.rejectComments}
                        </div>
                      </div>
                      <span className="approve-date-text">{formattedDate}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
export default CreateEmployementWorkExperience;
