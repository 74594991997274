import { useContext, useEffect, useMemo, useState } from "react";
import "./DocumentRequest.css";
import { AgGridReact } from "ag-grid-react";
import DocumentModal from "./DocumentModal";
import { axiosService } from "services/axios.service";
import { STUDENT_PORTAL_DOCUMENT_FACULTY_URL } from "modules/student-portal/constants/student-portal-urls";
import { ThemeContext } from "ThemeContext";

function DocumentRequest() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [data, setData] = useState(null);

  const [rowData, setRowData] = useState(null);

  const { theme, toggleTheme } = useContext(ThemeContext);

  useEffect(() => {
    axiosService
      .get(
        `${STUDENT_PORTAL_DOCUMENT_FACULTY_URL.GET_ALL_DOCUMENTS_FACULTY_URL}`
      )
      .then((response) => {
        if (response.status === 200) {
          const responseData = response.data.data;
          setData(responseData);
        }
      });
  }, [isModalOpen]);

  const onRowClicked = (event) => {
    // if (event?.data?.status !== "APPROVED") {
    setIsModalOpen(true);
    setRowData(event?.data);
    // }
  };

  const [columnDefs] = useState([
    {
      headerName: "Request Date",
      field: "requestDate",
    },
    {
      headerName: "Name",
      field: "user",
      cellRenderer: function (params) {
        return (
          <span>
            {params.value.firstName} {params.value.lastName}
          </span>
        );
      },
    },

    {
      headerName: "Document Name",
      field: "document.docName",
    },
    // {
    //   headerName: "Approved/Rejected By",
    //   field: "document.docName",
    //   cellRenderer: function (params) {
    //     let nameavail = params?.data?.facultyApproved?.firstName
    //     return (
    //       nameavail ?
    //         <span>

    //           {params?.data?.facultyApproved?.firstName} {params?.data?.facultyApproved?.lastName}

    //         </span> :
    //         <span>
    //           -</span>
    //     );
    //   },
    // },

    {
      headerName: "Status",
      field: "status",
      cellRenderer: function (params) {
        const status = params.value;

        return (
          <span
            className={`
          ${status === "REJECTED" && "student-portal-table-status-pending"}
          ${status === "APPROVED" && "student-portal-table-status-approved"}
          ${status === "PENDING" && "student-portal-table-status-open"}
          ${status === "OPEN" && "student-portal-table-status-open"}
          
          `}
          >
            {status}
          </span>
        );
      },
    },
  ]);

  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);
  return (
    <div className="view-leads-table-container">
      <div
        className="lead-table-head-container sticky-header flex-row flex-wrap gap-2 lg:ms-2 "
        style={{ alignItems: "center" }}
      >
        <div
          className="d-flex align-items-center gap-1 "
          style={{ cursor: "pointer" }}
        >
          <span className="lead-table-title-text">All Requests</span>
        </div>
        <div className="d-flex flex-wrap lg:align-items-end">
          <div className="sps-fv-top-count sps-fv-ha ps-0 md:ps-15">
            Pending Requests<span>{data?.pendingCount}</span>
          </div>
          <div className="sps-fv-top-count sps-fv-amg ps-0 md:ps-15">
            Open Requests<span>{data?.openCount}</span>
          </div>
          <div className="sps-fv-top-count sps-fv-hi ps-0 md:ps-15">
            All Requests<span>{data?.allCount}</span>
          </div>
        </div>
      </div>
      <div className="w-100 d-flex justify-content-between">
        <div style={{ width: "100%", marginTop: "20px" }}>
          <div
            className={
              theme === "light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"
            }
          >
            <AgGridReact
              columnDefs={columnDefs}
              rowData={data?.documentRequests}
              rowSelection="single"
              domLayout="autoHeight"
              defaultColDef={defaultColDef}
              suppressRowClickSelection
              gridOptions={gridOptions}
              onRowClicked={onRowClicked}
            />
          </div>
        </div>

        <DocumentModal
          open={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
          rowData={rowData}
        />
      </div>
    </div>
  );
}

export default DocumentRequest;
