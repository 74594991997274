import React, { useContext, useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import "./CreateNewMessage.css";
import EmailBodyContent from "./EmailBodyContent";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosService } from "services/axios.service";
import { CAMPAIGN_URLS } from "modules/campaigns/constants/Campaign-urls";
import { toast } from "react-toastify";
import { ThemeContext } from "ThemeContext";

function CreateNewMessage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeAccordion, setActiveAccordion] = useState(1);
  const { theme, toggleTheme } = useContext(ThemeContext);

  const type = location.state?.type;
  const messageId = location.state?.data?.messageId;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bodyContent, setBodyContent] = useState("");
  const [initialHtmlContent, setInitialHtmlContent] = useState("");
  const openModal = (initialContent) => {
    setInitialHtmlContent(initialContent);
    setIsModalOpen(true);
  };
  const handleSave = (plainTextContent) => {
    setBodyContent(plainTextContent);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [data, setData] = useState("");
  const initialValues = {
    messageName: data?.messageName || "",
    mailSubject: data?.mailSubject || "",
    bodyContent: data?.body || "",
  };

  useEffect(() => {
    if (messageId) {
      axiosService
        .get(`${CAMPAIGN_URLS.ADD_MEESAGE_CAMPAIGN}/${messageId}`)
        .then((response) => {
          if (response.status === 200) {
            const responseData = response.data.data;
            setBodyContent(responseData?.body);
            setData(responseData);
          }
        });
    }
  }, [location.pathname, messageId]);

  const onSubmit = async (values) => {
    const requestData = {
      messageName: values.messageName,
      mailSubject: values.mailSubject,
      body: bodyContent,
    };
    try {
      if (messageId) {
        requestData.messageId = messageId;
        const response = await axiosService.put(
          `${CAMPAIGN_URLS.ADD_MEESAGE_CAMPAIGN}`,
          requestData
        );

        if (response.status === 200) {
          toast.success("Updated successfully");
          navigate("/campaigns/all-campaign-messages");
        } else {
          toast.error("update failed");
        }
      } else {
        const response = await axiosService.post(
          `${CAMPAIGN_URLS.ADD_MEESAGE_CAMPAIGN}`,
          requestData
        );
        if (response.status === 200) {
          toast.success("Saved successfully");
          navigate("/campaigns/all-campaign-messages");
        } else {
          toast.error("failed");
        }
      }
    } catch (error) {
      toast.error(error);
    }
  };
  return (
    <div className="view-leads-table-container pb-4">
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        <Form>
          <div className="lead-table-head-container align-items-center flex-wrap sticky-header flex-row gap-2">
            <span className="communication-management-title-text">
              {type === "view"
                ? "View Email"
                : data
                ? "Edit Email"
                : "Create Email"}
            </span>

            <div className="d-flex align-items-center gap-3">
              <button
                className="communication-management-cancel-button"
                onClick={() => {
                  navigate("/campaigns/all-campaign-messages");
                }}
              >
                Cancel
              </button>
              {type !== "view" && (
                <button
                  className="communication-save-head-button"
                  type="submit"
                >
                  {data ? "Update" : "Save"}
                </button>
              )}
            </div>
          </div>

          <div className="create-campaign-accordion">
            <div className="create-campaign-accordion-item">
              <div
                className="create-campaign-accordion-title"
                onClick={() => setActiveAccordion(1)}
              >
                <span
                  style={{
                    height: "30px",
                    width: "0px",
                    border: "3px solid #F69F3A",
                    marginRight: "10px",
                  }}
                ></span>
                Message Details
              </div>
              <div
                className={
                  activeAccordion === 1
                    ? "create-campaign-active-accordion create-campaign-accordion-content"
                    : "create-campaign-accordion-content"
                }
              >
                <div className="create-program-main-input-container">
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active width-label-program">
                      Message Title *
                    </label>
                    <Field
                      disabled={type === "view"}
                      type="text"
                      name="messageName"
                      className="campaign-create-new-message-input"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="create-campaign-accordion-item">
              <div
                className="create-campaign-accordion-title"
                onClick={() => setActiveAccordion(2)}
              >
                <span
                  style={{
                    height: "30px",
                    width: "0px",
                    border: "3px solid #F69F3A",
                    marginRight: "10px",
                  }}
                ></span>
                Email Details
              </div>
              <div
                className={
                  activeAccordion === 2
                    ? "row g-0 create-campaign-active-accordion create-campaign-accordion-content create-campaign-accordion-content-flex"
                    : " row g-0 create-campaign-accordion-content create-campaign-accordion-content-flex"
                }
              >
                <div className="col-12 col-md-5">
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active width-label-program">
                      Subject Line *
                    </label>
                    <Field
                      disabled={type === "view"}
                      type="text"
                      name="mailSubject"
                      className="campaign-create-new-message-subject"
                    />
                  </div>
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active width-label-program">
                      Body Content *
                    </label>
                    <Field
                      disabled={type === "view"}
                      type="text"
                      name="bodyContent"
                      className="campaign-create-new-message-subject"
                      onClick={
                        bodyContent
                          ? () => openModal(bodyContent)
                          : () => openModal()
                      }
                      placeholder={bodyContent ? "Click to edit" : ""}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-7">
                  <div className="create-admission-inner-first-input-container">
                    <label
                      className="width-label-program"
                      style={{ color: theme === "light" ? "black" : "white" }}
                    >
                      Email Preview
                    </label>
                    <div className="campaign-create-new-message-preview">
                      <div
                        style={{
                          color: theme === "light" ? "black" : "white",
                          padding: "10px 15px",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: bodyContent,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
      <EmailBodyContent
        isOpen={isModalOpen}
        onClose={closeModal}
        onSave={handleSave}
        initialHtmlContent={initialHtmlContent}
      />
    </div>
  );
}

export default CreateNewMessage;
