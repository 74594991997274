import useravatar from "../../../../../assets/images/user-avatar.svg";
import useravatarblack from "assets/images/user-avatar-black.svg";
import { useContext, useEffect, useState } from "react";
import { BiSolidShare } from "react-icons/bi";
import { BsFillHandThumbsUpFill } from "react-icons/bs";
import { IoTrashOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosService } from "services/axios.service";
import sessionstorageService from "services/sessionstorage.service";
import Swal from "sweetalert2";
import { FORUM_DISCUSSION_CONSTANTS } from "../../constants/forum-discussion-constants";
import { FORUM_DISCUSSION_URLS } from "../../constants/forum-discussion-urls";
import "./ViewDiscussion.css";
import { ThemeContext } from "ThemeContext";

function ViewDiscussion() {
  const location = useLocation();
  const navigation = useNavigate();
  const discussionId = location.state.discussionId;
  const [showReplyInput, setShowReplyInput] = useState(false);
  const [replyText, setReplyText] = useState("");
  const [data, setData] = useState([]);
  const userData = sessionstorageService.getUserData("userData");
  const [currentUserID, setCurrentUserID] = useState(null);
  const [forumModeratorID, setForumModeratorID] = useState(null);

  const { theme, toggleTheme } = useContext(ThemeContext);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axiosService.getByTime(
        `${FORUM_DISCUSSION_URLS.GET_DISCUSSION_BY_ID}${discussionId}`
      );
      const newData = response?.data?.data;
      setData(newData);
      setForumModeratorID(newData?.forum?.forumModerator?.userId);
      setCurrentUserID(userData?.userId);
    };

    fetchData();
    const intervalId = setInterval(fetchData, 30000);
    return () => clearInterval(intervalId);
  }, [discussionId, showReplyInput]);

  const submitPost = async () => {
    try {
      let sendData = {
        content: replyText,
        user: {
          userId: userData?.userId,
        },
        discussion: {
          discussionId: discussionId,
        },
      };

      const response = await axiosService.post(
        `${FORUM_DISCUSSION_URLS.CREATE_POST}`,
        sendData
      );
      if (response.status === 200) {
        setShowReplyInput(false);
        setReplyText("");
      } else {
        setShowReplyInput(false);
      }
    } catch (error) {
      setShowReplyInput(false);
    }
  };

  const handleDeletePost = (postId) => {
    Swal.fire({
      title: "Confirm Delete",
      text: "Are you sure you want to delete this Post ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete",
      cancelButtonText: "No, cancel",
      customClass: {
        popup: "my-custom-class",
        content: "custom-swal-text",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axiosService
          .put(`${FORUM_DISCUSSION_URLS.DELETE_POST}${postId}`)
          .then((response) => {
            if (response.status === 200) {
              toast.success(FORUM_DISCUSSION_CONSTANTS.DELETE_SUCCESS_POST);
              const fetchData = async () => {
                const response = await axiosService.getByTime(
                  `${FORUM_DISCUSSION_URLS.GET_DISCUSSION_BY_ID}${discussionId}`
                );
                const newData = response?.data?.data;
                setData(newData);
              };
              fetchData();
            } else {
              toast.error(FORUM_DISCUSSION_CONSTANTS.DELETE_FAILURE_POST);
            }
          })
          .catch((error) => {
            toast.error(FORUM_DISCUSSION_CONSTANTS.FAILED);
          });
      }
    });
  };
  const handleDeleteDiscussion = (discussionId) => {
    Swal.fire({
      title: "Confirm Delete",
      text: "Are you sure you want to delete this discussion, all the posts under this discussion will be deleted. This action cannot be reversed. Do you want to delete ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete",
      cancelButtonText: "No, cancel",
      customClass: {
        popup: "my-custom-class",
        content: "custom-swal-text",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axiosService
          .delete(`${FORUM_DISCUSSION_URLS.DELETE_DISCUSSION}${discussionId}`)
          .then((response) => {
            if (response.status === 200) {
              toast.success(
                FORUM_DISCUSSION_CONSTANTS.DELETE_SUCCESS_DISCUSSION
              );
              navigation("/communication-management/forums");
            } else {
              toast.error(FORUM_DISCUSSION_CONSTANTS.DELETE_FAILURE_DISCUSSION);
            }
          })
          .catch((error) => {
            toast.error(FORUM_DISCUSSION_CONSTANTS.FAILED);
          });
      }
    });
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }
    const options = {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
      year: "numeric",
      month: "short",
      day: "numeric",
    };

    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  return (
    <div className="communication-management-main-container ">
      <div className="communication-management-head-container sticky-header-communication flex-row gap-2">
        <div className="d-flex align-items-center gap-3">
          <span className="communication-management-title-text">
            Discussions
          </span>
        </div>
        <div className="d-flex align-items-center gap-3">
          {currentUserID === forumModeratorID && (
            <button
              onClick={() => handleDeleteDiscussion(discussionId)}
              className="communication-delete-head-button"
            >
              Delete Discussion
            </button>
          )}
        </div>
      </div>
      <div className="forum-discussion-main-container flex-column flex-lg-row custom-scrollbar">
        <div className="view-discussion-left-container w-100 ">
          <div className="view-discussion-left-header d-flex justify-content-between">
            <div className=" d-flex flex-column">
              <span className="discussion-text-gray">Discussion Title</span>
              <span className="discussion-left-head-text">{data?.title}</span>
            </div>
            <div className=" d-flex gap-4">
              <div className="discussion-replies-view-container">
                <span className="discussion-text-gray">Replies</span>
                <span className="discussion-replies-view-value-text">
                  {data?.posts?.length}
                </span>
              </div>
            </div>
          </div>
          <div className="discussion-message-main-container">
            <div className="discussion-date-time-text">
              {formatDate(data?.addedOn)}
            </div>
            <div className="discussion-text-white-small">
              {data?.description}
            </div>
            <div className="discussion-poster-main-container">
              <div className="discussion-poster-container">
                <img
                  src={
                    data?.createdUser?.profileImage || theme === "light"
                      ? useravatarblack
                      : useravatar
                  }
                  className="poster-or-staff-profile-image-discussion"
                />
                <span className=" discussion-poster-staff-name-container">
                  <div className="discussion-poster-profile-name-discussion">
                    {data?.createdUser?.firstName} {data?.createdUser?.lastName}
                  </div>
                  <div className="discussion-poster-profile-designation-class-discussion">
                    {data?.createdUser?.role?.name}
                  </div>
                </span>
              </div>
              <div className="discussion-like-reply-container">
                <button className="discussion-like-reply-inner-container">
                  <span className="like-icon">
                    <BsFillHandThumbsUpFill />
                  </span>
                  <span className="discussion-text-gray">like</span>
                </button>
                <button
                  onClick={() => setShowReplyInput(true)}
                  className="discussion-like-reply-inner-container"
                >
                  <span className="share-icon">
                    <BiSolidShare />
                  </span>
                  <span className="discussion-text-gray">
                    reply ({data?.posts?.length})
                  </span>
                </button>
              </div>
            </div>

            {showReplyInput && (
              <div className="discussion-poster-inner-container">
                <textarea
                  value={replyText}
                  onChange={(event) => {
                    setReplyText(event.target.value);
                  }}
                  maxLength={250}
                  className="discussion-reply-textarea custom-scrollbar"
                />
                <div className="d-flex justify-content-end gap-3 mt-1">
                  <button
                    onClick={() => {
                      setShowReplyInput(false);
                      setReplyText("");
                    }}
                    className="communication-management-cancel-button"
                  >
                    Cancel
                  </button>
                  <button
                    disabled={!replyText}
                    className={`communication-management-submit-button ${
                      !replyText && "opacity-50"
                    }`}
                    onClick={() => submitPost()}
                  >
                    Post
                  </button>
                </div>
              </div>
            )}
            {data?.posts
              ?.slice()
              .reverse()
              ?.map((post) => (
                <div className="discussion-poster-inner-container">
                  <div className="discussion-date-time-text">
                    {" "}
                    {formatDate(post?.addedOn)}
                  </div>
                  <div
                    className={`discussion-text-white-small ${
                      post?.content ===
                      "This post was removed by the moderator as it does not comply with the forum rules."
                        ? "removed-post"
                        : ""
                    }`}
                  >
                    {post?.content}
                  </div>

                  <div className="discussion-poster-main-container">
                    <div className="discussion-poster-container">
                      <img
                        src={post?.user?.profileImage || useravatar}
                        className="poster-or-staff-profile-image-discussion"
                      />
                      <span className=" discussion-poster-staff-name-container">
                        <div className="discussion-poster-profile-name-discussion">
                          {post?.user?.firstName} {post?.user?.lastName}
                        </div>
                        <div className="discussion-poster-profile-designation-class-discussion">
                          {post?.user?.role?.name}
                        </div>
                      </span>
                    </div>
                    <div className="discussion-like-reply-container">
                      {post.content !==
                        "This post was removed by the moderator as it does not comply with the forum rules." &&
                        currentUserID === forumModeratorID && (
                          <button
                            className="discussion-like-reply-inner-container"
                            onClick={() => handleDeletePost(post?.postId)}
                          >
                            <span className="like-icon">
                              <IoTrashOutline />
                            </span>
                            <span className="discussion-text-gray">delete</span>
                          </button>
                        )}

                      <button className="discussion-like-reply-inner-container">
                        <span className="like-icon">
                          <BsFillHandThumbsUpFill />
                        </span>
                        <span className="discussion-text-gray">like</span>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewDiscussion;
