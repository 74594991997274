import { Box, Step, StepLabel, Stepper } from "@mui/material";
import "./NewEmployee.css";
import React, { useContext, useEffect, useState } from "react";
import { RxTriangleDown } from "react-icons/rx";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import { axiosService } from "services/axios.service";
import { ADMISSION_MANAGEMENT_URL } from "modules/admission-management/admission-management/components/create-admission/constants/admission-management-urls";
import sessionService from "services/sessionstorage.service";
import sessionstorageService from "services/sessionstorage.service";
import { UserConstants } from "modules/user-management/constants/user-constants";
import { HR_MODULE_URLS } from "modules/hr-module/constants/hr-module-urls";
import useravatar from "../../../../assets/images/user-avatar.svg";
import useravatarblack from "../../../../assets/images/user-avatar-black.svg";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { USER_URL } from "modules/user-management/constants/user-urls";
import OfferLetter from "../offer-letter/OfferLetter";
import { ThemeContext } from "ThemeContext";

const NewEmployee = () => {
  const navigate = useNavigate();
  const { theme, toggleTheme } = useContext(ThemeContext);
  const selectStyles = {
    control: (provided) => ({
      ...provided,
      // backgroundColor: "#212226 !important",
      backgroundColor: theme === "light" ? "#ffffff" : "#212226",
      className: "dark-light-custom-select-control",
    }),
  };
  const steps = [
    { id: 1, label: "Pre-Onboarding", step: "step 1" },
    { id: 2, label: "On-boarding", step: "step 2" },
  ];

  const [active, setActive] = useState(0);

  const [employeeId, setEmployeeId] = useState(null);
  const [employeeType, setEmployeeType] = useState(null);
  const [employeeFirstName, setEmployeeFirstName] = useState(null);
  const [employeeLastName, setEmployeeLastName] = useState(null);
  const [gender, setGender] = useState(null);
  const [socialStatus, setSocialStatus] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [age, setAge] = useState(null);
  const [contactNumber1, setContactNumber1] = useState(null);
  const [contactNumber2, setContactNumber2] = useState(null);
  const [emergencyName, setEmergencyName] = useState(null);
  const [emergencyNumber, setEmergencyNumber] = useState(null);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [handlePdf, setHandlePdf] = useState(false);
  const [emailId, setEmailId] = useState(null);
  const [residentialAddress, setResidentialAddress] = useState(null);

  const [resumeName, setResumeName] = useState("");
  const [resume, setResume] = useState("");

  const [document1Name, setDocument1Name] = useState("");
  const [document1, setDocument1] = useState("");

  const [expectedDOJ, setexpectedDOJ] = useState(null);
  const [status, setStatus] = useState(null);
  const [position, setPosition] = useState(null);
  const [reportsTo, setReportsTo] = useState(null);
  const [campusLocation, setCampusLocation] = useState(null);

  const [document2Name, setDocument2Name] = useState("");
  const [document2, setDocument2] = useState("");

  const [document3Name, setDocument3Name] = useState("");
  const [document3, setDocument3] = useState("");

  const [grossSalary, setGrossSalary] = useState(null);
  const [deductions, setDeductions] = useState(null);

  const [accountNumber, setAccountNumber] = useState(null);
  const [bankName, setBankName] = useState(null);
  const [bankCode, setBankCode] = useState(null);
  const [swiftCode, setSwiftCode] = useState(null);

  const [socialInsuranceNumber, setSocialInsuranceNumber] = useState(null);

  const [profileImage, setProfileImage] = useState(null);
  const [profileImageName, setProfileImageName] = useState(null);

  const [reportsList, setReportsList] = useState([]);

  useEffect(() => {
    axiosService
      .get(`${USER_URL.GET_ALL_USERS_EXCEPT_STUDENTS_AND_PRESTUDENTS}`)
      .then((response) => {
        const reports = response.data?.data;

        let filteredValue = reports.map((a) => {
          let b = {
            value: a.userId,
            label: a.firstName + (a?.lastName ? " " + a?.lastName : ""),
          };
          return b;
        });
        setReportsList(filteredValue);
      });
  }, []);

  const employeeTypeOptions = [
    { value: "OFFICESTAFF", label: "OFFICE STAFF" },
    { value: "FACULTY", label: "FACULTY" },
    { value: "ADMIN", label: "ADMIN" },
    { value: "HR", label: "HR" },
    { value: "FINANCE", label: "FINANCE" },
    { value: "OPERATIONS", label: "OPERATIONS" },
    { value: "MARKETING", label: "MARKETING" },
    { value: "ADMISSIONS", label: "ADMISSIONS" },
  ];

  const genderOptions = [
    { value: "MALE", label: "MALE" },
    { value: "FEMALE", label: "FEMALE" },
    { value: "PREFERNOTTOSAY", label: "PREFER NOT TO SAY" },
  ];

  const socialStatusOptions = [
    { value: "SINGLE", label: "SINGLE" },
    { value: "MARRIED", label: "MARRIED" },
  ];
  const StatusOptions = [
    { value: "PARTTIME", label: "PARTTIME" },
    { value: "FULLTIME", label: "FULLTIME" },
  ];

  const CampusOptions = [
    { value: "BRAMPTON", label: "BRAMPTON" },
    { value: "MISSISSAUGA", label: "MISSISSAUGA" },
  ];

  const onDrop = async (acceptedFiles) => {
    if (!acceptedFiles[0]) {
      toast.error("Please select a image file less than 2MB.");
    } else if (!acceptedFiles[0].type.startsWith("application/pdf")) {
      toast.error(" Invalid file format,Please upload .pdf files");
    } else {
      try {
        if (acceptedFiles[0]?.name?.length > 150) {
          toast.error(UserConstants.IMAGE_LENGTH);
          return;
        }
        setResumeName(acceptedFiles[0]?.name);
        sessionService.isLoading("true");
        const response = await axiosService.postFile(
          `${ADMISSION_MANAGEMENT_URL.FILE_UPLOAD_URL}`,
          acceptedFiles[0],
          true
        );

        if (response.status === 200) {
          setResume(response.data.data);
          setResumeName(acceptedFiles[0]?.name);
        } else {
          setResumeName(null);
        }
      } catch (error) {
        setResumeName(null);
      }
    }
  };

  const onDrop1 = async (acceptedFiles) => {
    if (!acceptedFiles[0]) {
      toast.error("Please select a image file less than 2MB.");
    } else if (!acceptedFiles[0].type.startsWith("application/pdf")) {
      toast.error(" Invalid file format,Please upload .pdf files");
    } else {
      try {
        if (acceptedFiles[0]?.name?.length > 150) {
          toast.error(UserConstants.IMAGE_LENGTH);
          return;
        }
        setDocument1Name(acceptedFiles[0]?.name);
        sessionService.isLoading("true");
        const response = await axiosService.postFile(
          `${ADMISSION_MANAGEMENT_URL.FILE_UPLOAD_URL}`,
          acceptedFiles[0],
          true
        );

        if (response.status === 200) {
          setDocument1(response.data.data);
          setDocument1Name(acceptedFiles[0]?.name);
        } else {
          setDocument1Name(null);
        }
      } catch (error) {
        setDocument1Name(null);
      }
    }
  };

  const onDrop2 = async (acceptedFiles) => {
    if (!acceptedFiles[0]) {
      toast.error("Please select a image file less than 2MB.");
    } else if (!acceptedFiles[0].type.startsWith("application/pdf")) {
      toast.error(" Invalid file format,Please upload .pdf files");
    } else {
      try {
        if (acceptedFiles[0]?.name?.length > 150) {
          toast.error(UserConstants.IMAGE_LENGTH);
          return;
        }
        setDocument2Name(acceptedFiles[0]?.name);
        sessionService.isLoading("true");
        const response = await axiosService.postFile(
          `${ADMISSION_MANAGEMENT_URL.FILE_UPLOAD_URL}`,
          acceptedFiles[0],
          true
        );

        if (response.status === 200) {
          setDocument2(response.data.data);
          setDocument2Name(acceptedFiles[0]?.name);
        } else {
          setDocument2Name(null);
        }
      } catch (error) {
        setDocument2Name(null);
      }
    }
  };

  const onDrop3 = async (acceptedFiles) => {
    if (!acceptedFiles[0]) {
      toast.error("Please select a image file less than 2MB.");
    } else if (!acceptedFiles[0].type.startsWith("application/pdf")) {
      toast.error(" Invalid file format,Please upload .pdf files");
    } else {
      try {
        if (acceptedFiles[0]?.name?.length > 150) {
          toast.error(UserConstants.IMAGE_LENGTH);
          return;
        }
        setDocument3Name(acceptedFiles[0]?.name);
        sessionService.isLoading("true");
        const response = await axiosService.postFile(
          `${ADMISSION_MANAGEMENT_URL.FILE_UPLOAD_URL}`,
          acceptedFiles[0],
          true
        );

        if (response.status === 200) {
          setDocument3(response.data.data);
          setDocument3Name(acceptedFiles[0]?.name);
        } else {
          setDocument3Name(null);
        }
      } catch (error) {
        setDocument3Name(null);
      }
    }
  };

  const onDrop4 = async (acceptedFiles) => {
    if (!acceptedFiles[0]) {
      toast.error("Please select a image file less than 2MB.");
    } else if (!acceptedFiles[0].type.startsWith("image/")) {
      toast.error(" Invalid file format,Please upload .png,.jpg,.jpeg files");
    } else {
      try {
        if (acceptedFiles[0]?.name?.length > 150) {
          toast.error(UserConstants.IMAGE_LENGTH);
          return;
        }
        setProfileImageName(acceptedFiles[0]?.name);

        sessionstorageService.isLoading("true");
        const formData = new FormData();
        formData.append("file", acceptedFiles[0]);

        const response = await axiosService.postFile(
          `${ADMISSION_MANAGEMENT_URL.FILE_UPLOAD_URL}`,
          acceptedFiles[0],
          true
        );

        if (response.status === 200) {
          setProfileImage(response.data.data);
        }
      } catch (error) {
        toast.error(`${error?.response?.data?.message.split(":")[1]?.trim()}`);
      }
    }
  };

  function calculateAge(birthDate) {
    const today = new Date();
    const birthDateObj = new Date(birthDate);
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDiff = today.getMonth() - birthDateObj.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDateObj.getDate())
    ) {
      age--;
    }

    setAge(age);
  }
  const onSubmit = async () => {
    setHandlePdf(true);
    const Data = {
      employeeId: employeeId,
      employeeType: employeeType,
      employeeFirstName: employeeFirstName,
      employeeLastName: employeeLastName,
      gender: gender,
      socialStatus: socialStatus,
      dateOfBirth: dateOfBirth,
      age: parseInt(age),
      contactNumber1: contactNumber1,
      contactNumber2: contactNumber2,
      email: emailId,
      emergencyContactName: emergencyName,
      emergencyContactNumber: emergencyNumber,
      residentialAddress: residentialAddress,
      resume: resume,
      resumeName: resumeName,
      degree: document1,
      degreeName: document1Name,
      expectedDateOfJoining: expectedDOJ,
      status: status,
      position: position,
      campusLocation: campusLocation,
      transcript: document2,
      transcriptName: document2Name,
      equalisationTranscript: document3,
      equalisationTranscriptName: document3Name,
      grossSalary: parseFloat(grossSalary),
      deduction: parseFloat(deductions),
      socialInsuranceNumber: socialInsuranceNumber,
      accountNumber: accountNumber,
      bankName: bankName,
      bankCode: bankCode,
      profileImage: profileImage,
      profileImageName: profileImageName,
      swiftCode: swiftCode,
    };

    if (reportsTo != null) {
      Data.reportsTo = {
        userId: reportsTo,
      };
    }

    try {
      const response = await axiosService.post(
        `${HR_MODULE_URLS.SAVE_EMPLOYEE}`,
        Data
      );
      if (response.status === 200) {
        toast.success("Employee added successfully");
        const data = response?.data?.data;
        const respEmpId = data?.empId;
        const respEmail = data?.email;
        if (respEmpId && respEmail) {
          await sendOfferLetter(respEmail, respEmpId);
        } else {
          toast.error("Failed to retrieve employee ID or email");
        }
      } else {
        toast.error("Failed to add employee");
      }
    } catch (error) {
      if (error.response) {
        toast.error(`${error.response.data.message.split(":")[1]?.trim()}`);
      } else {
        console.error(error);
      }
    }
  };

  const sendOfferLetter = async (email, empId) => {
    try {
      const file = new File([pdfBlob], "offerLetter.pdf", {
        type: "application/pdf",
      });
      const response = await axiosService.postFile(
        `${HR_MODULE_URLS.OFFER_LETTER}?email=${email}&empId=${empId}`,
        file,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        toast.success("Offer Letter sent successfully");
        navigate("/hr-module/view-all-faculty");
      }
    } catch (error) {
      if (error.response) {
        toast.error(`${error.response.data.message.split(":")[1]?.trim()}`);
      } else {
        console.error(error);
      }
    }
  };

  return (
    <div>
      <div className="communication-management-main-container">
        <div className="communication-management-head-container sticky-header-communication flex-sm-row gap-2">
          <div className="d-flex align-items-center gap-3">
            <span className="communication-management-title-text">
              On-board New Employee
            </span>
          </div>
        </div>
        <div
          className="d-flex flex-column gap-3"
          style={{
            marginTop: "60px",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={active} alternativeLabel>
              {steps.map((label) => (
                <Step key={label.id}>
                  <StepLabel>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span className="hr-module-stepper-label-1">
                        {label.step}
                      </span>
                      <span className="hr-module-stepper-label-2">
                        {label.label}
                      </span>
                    </div>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          <div
            style={{
              borderRadius: "6px",
              height: "calc(100vh - 275px)",
              backgroundColor: theme === "light" ? "#ffffff" : "#333333",
              alignSelf: "center",
            }}
            className="col-12 col-sm-10 col-lg-9"
          >
            {active === 0 && (
              <>
                <div className="hr-module-new-employee-form-header">
                  <span>Employee Information</span>
                </div>
                <div className="hr-module-new-employee-form-content">
                  <div className="d-flex flex-column-reverse flex-sm-row">
                    <div className="col-12 col-sm-8">
                      <div className="w-100 px-sm-2 d-flex flex-column justify-content-between mt-2 gap-1">
                        <label className="add-user-label-text">
                          Profile Image (max upload size: 2mb)
                        </label>

                        <Dropzone
                          onDrop={onDrop4}
                          multiple={false}
                          maxSize={2097152}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div
                              {...getRootProps()}
                              className="asps-document-upload-field mt-1"
                            >
                              <input {...getInputProps()} />
                              <span
                                className={`asps-upload-input  ${
                                  profileImageName
                                    ? theme === "light"
                                      ? "#000000"
                                      : "text-white"
                                    : ""
                                }`}
                              >
                                {profileImageName
                                  ? profileImageName
                                  : "No file selected"}
                              </span>

                              <span className="create-list-upload-input-choose">
                                Choose file
                              </span>
                            </div>
                          )}
                        </Dropzone>
                      </div>
                      <div className="row g-0 col-12">
                        <div className="d-flex flex-column col-12 col-md-6 px-sm-2 justify-content-between mt-2 gap-1">
                          <label className="new-employee-add-label">
                            Employee ID *
                          </label>
                          <input
                            style={{
                              height: "33px",
                              borderRadius: "3px",
                              fontSize: "12px",
                              backgroundColor:
                                theme === "light" ? "#EBEBEBFF" : "#212226",
                              color: theme === "light" ? "#000000" : "#ffffff",
                              opacity: "1",
                            }}
                            onChange={(e) => setEmployeeId(e.target.value)}
                            type="text"
                            className=" lead-input placeholder col-8 col-md-10"
                            placeholder="Enter employee ID"
                            value={employeeId}
                          />
                        </div>
                        <div className="d-flex flex-column col-12 col-md-6 px-sm-2 justify-content-between mt-2 gap-1">
                          <label className="new-employee-add-label">
                            Employee Type *
                          </label>
                          <Select
                            value={
                              employeeType
                                ? { value: employeeType, label: employeeType }
                                : null
                            }
                            onChange={(option) => {
                              setEmployeeType(option?.value);
                            }}
                            options={employeeTypeOptions}
                            isSearchable={false}
                            styles={selectStyles}
                            style={{
                              height: "33px",
                              borderRadius: "3px",
                              fontSize: "12px",
                              backgroundColor:
                                theme === "light" ? "#ffffff" : "#212226",
                            }}
                            classNamePrefix="react-select"
                            className="new-employee-react-select"
                            components={{
                              DropdownIndicator: () => (
                                <RxTriangleDown
                                  color={
                                    theme === "light" ? "#000000" : "#ffffff"
                                  }
                                />
                              ),
                              IndicatorSeparator: null,
                            }}
                            placeholder="Select a type"
                            menuPlacement="auto"
                            menuPosition="fixed"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4 d-flex flex-column gap-2 align-items-center">
                      <label className="add-user-label-text">
                        Image Preview
                      </label>
                      <div
                        className="add-user-image-preview"
                        style={{ height: "110px", maxWidth: "110px" }}
                      >
                        {profileImage ? (
                          <img
                            src={profileImage}
                            alt="userimage"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          <img
                            src={
                              theme === "light" ? useravatarblack : useravatar
                            }
                            alt="userimage"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row g-0">
                    <div className=" d-flex flex-column col-12 col-sm-6 col-lg-4 px-sm-2 justify-content-between mt-2 gap-1">
                      <label className="new-employee-add-label d-flex flex-wrap d-flex flex-wrap">
                        Employee First Name *
                        <span style={{ fontSize: "10px", opacity: ".5" }}>
                          ( As per passport )
                        </span>
                      </label>
                      <input
                        style={{
                          height: "33px",
                          borderRadius: "3px",
                          fontSize: "12px",
                          backgroundColor:
                            theme === "light" ? "#ebebeb" : "#212226",
                          color: theme === "light" ? "#000000" : "#ffffff",
                          opacity: "1",
                        }}
                        onChange={(e) => setEmployeeFirstName(e.target.value)}
                        type="text"
                        className=" lead-input placeholder col-8 col-md-10 new-employee-input-fields"
                        value={employeeFirstName}
                        placeholder="First name"
                      />
                    </div>
                    <div className=" d-flex flex-column col-12 col-sm-6 col-lg-4 px-sm-2 justify-content-between mt-2 gap-1">
                      <label className="new-employee-add-label d-flex flex-wrap ">
                        Employee Last Name *
                        <span style={{ fontSize: "10px", opacity: ".5" }}>
                          ( As per passport )
                        </span>
                      </label>
                      <input
                        style={{
                          height: "33px",
                          borderRadius: "3px",
                          fontSize: "12px",
                          backgroundColor:
                            theme === "light" ? "#ebebeb" : "#212226",
                          opacity: "1",
                        }}
                        onChange={(e) => setEmployeeLastName(e.target.value)}
                        type="text"
                        className=" lead-input placeholder col-8 col-md-10"
                        value={employeeLastName}
                        placeholder="Last name"
                      />
                    </div>
                    <div className=" d-flex flex-column col-12 col-sm-6 col-lg-4 px-sm-2 justify-content-between mt-2 gap-1">
                      <label className="new-employee-add-label">Gender *</label>
                      <Select
                        value={gender ? { value: gender, label: gender } : null}
                        onChange={(option) => {
                          setGender(option?.value);
                        }}
                        options={genderOptions}
                        isSearchable={false}
                        styles={selectStyles}
                        classNamePrefix="react-select"
                        style={{
                          height: "33px",
                          borderRadius: "3px",
                          fontSize: "12px",
                          backgroundColor:
                            theme === "light" ? "#ffffff" : "#212226",
                        }}
                        components={{
                          DropdownIndicator: () => (
                            <RxTriangleDown
                              color={theme === "light" ? "#000000" : "#ffffff"}
                            />
                          ),
                          IndicatorSeparator: null,
                        }}
                        placeholder="Select a type"
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                    </div>

                    <div className=" d-flex flex-column col-12 col-sm-6 col-lg-4 px-sm-2 justify-content-between mt-2 gap-1">
                      <label className="new-employee-add-label">
                        Social Status *
                      </label>
                      <Select
                        value={
                          socialStatus
                            ? { value: socialStatus, label: socialStatus }
                            : null
                        }
                        onChange={(option) => {
                          setSocialStatus(option?.value);
                        }}
                        options={socialStatusOptions}
                        isSearchable={false}
                        classNamePrefix="react-select"
                        styles={selectStyles}
                        style={{
                          height: "33px",
                          borderRadius: "3px",
                          fontSize: "12px",
                          backgroundColor:
                            theme === "light" ? "#ffffff" : "#212226",
                        }}
                        components={{
                          DropdownIndicator: () => (
                            <RxTriangleDown
                              color={theme === "light" ? "#000000" : "#ffffff"}
                            />
                          ),
                          IndicatorSeparator: null,
                        }}
                        placeholder="Select a type"
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                    </div>
                    <div className=" d-flex flex-column col-12 col-sm-6 col-lg-4 px-sm-2 justify-content-between mt-2 gap-1">
                      <label className="new-employee-add-label">
                        Date of birth *
                      </label>
                      <input
                        style={{
                          height: "33px",
                          borderRadius: "3px",
                          fontSize: "12px",
                          backgroundColor:
                            theme === "light" ? "#ebebeb" : "#212226",
                          opacity: "1",
                        }}
                        onChange={(e) => {
                          setDateOfBirth(e.target.value);
                          calculateAge(e.target.value);
                        }}
                        type="date"
                        className=" lead-input col-8 col-md-10"
                        value={dateOfBirth || ""}
                        placeholder="Select a date"
                      />
                    </div>
                    <div className=" d-flex flex-column col-12 col-sm-6 col-lg-4 px-sm-2 justify-content-between mt-2 gap-1">
                      <label className="new-employee-add-label">Age *</label>
                      <input
                        style={{
                          height: "33px",
                          borderRadius: "3px",
                          fontSize: "12px",
                          backgroundColor:
                            theme === "light" ? "#ebebeb" : "#212226",
                          opacity: "1",
                        }}
                        onChange={(e) => setAge(e.target.value)}
                        type="number"
                        disabled="true"
                        className=" lead-input placeholder col-8 col-md-10 new-employee-input-number"
                        value={age}
                        placeholder="Auto-calculated"
                      />
                    </div>

                    <div className=" d-flex flex-column col-12 col-sm-6 col-lg-4 px-sm-2 justify-content-between mt-2 gap-1">
                      <label className="new-employee-add-label">
                        Contact Number 1 *
                      </label>
                      <PhoneInput
                        inputStyle={{
                          height: "33px",
                          borderRadius: "3px",
                          fontSize: "12px",
                          backgroundColor:
                            theme === "light" ? "#ffffff" : "#212226",
                          width: "100%",
                        }}
                        buttonStyle={{
                          borderRadius: "0px",
                        }}
                        country={"ca"}
                        value={contactNumber1}
                        onChange={(value) => setContactNumber1(value)}
                        containerClass="hr-module-new-employee-contact-input col-8 col-md-10 new-employee-input-number"
                        inputClass="new-employee-input-number"
                        placeholder="Enter valid contact"
                      />
                    </div>
                    <div className=" d-flex flex-column col-12 col-sm-6 col-lg-4 px-sm-2 justify-content-between mt-2 gap-1">
                      <label className="new-employee-add-label">
                        Contact Number 2
                      </label>
                      <PhoneInput
                        inputStyle={{
                          height: "33px",
                          borderRadius: "3px",
                          fontSize: "12px",
                          backgroundColor:
                            theme === "light" ? "#ffffff" : "#212226",
                          width: "100%",
                        }}
                        buttonStyle={{
                          borderRadius: "0",
                        }}
                        country={"ca"}
                        value={contactNumber2}
                        onChange={(value) => setContactNumber2(value)}
                        containerClass="hr-module-new-employee-contact-input col-8 col-md-10 new-employee-input-number"
                        inputClass="new-employee-input-number"
                        placeholder="Enter valid contact"
                      />
                    </div>
                    <div className=" d-flex flex-column col-12 col-sm-6 col-lg-4 px-sm-2 justify-content-between mt-2 gap-1">
                      <label className="new-employee-add-label">
                        Email ID *
                      </label>
                      <input
                        style={{
                          height: "33px",
                          borderRadius: "3px",
                          fontSize: "12px",
                          backgroundColor:
                            theme === "light" ? "#ebebeb" : "#212226",
                          opacity: "1",
                        }}
                        onChange={(e) => setEmailId(e.target.value)}
                        type="email"
                        className=" lead-input placeholder col-8 col-md-10"
                        placeholder="Enter valid email ID"
                        value={emailId || ""}
                      />
                    </div>

                    <div className=" d-flex flex-column col-12 col-sm-6 col-lg-4 px-sm-2 justify-content-between mt-2 gap-1">
                      <label className="new-employee-add-label">
                        Residential/Postal Address *
                      </label>
                      <input
                        style={{
                          height: "33px",
                          borderRadius: "3px",
                          fontSize: "12px",
                          backgroundColor:
                            theme === "light" ? "#ebebeb" : "#212226",
                          opacity: "1",
                        }}
                        onChange={(e) => setResidentialAddress(e.target.value)}
                        type="text"
                        className=" lead-input placeholder col-8 col-md-10"
                        placeholder="Enter full address"
                        value={residentialAddress || ""}
                      />
                    </div>
                    <div className=" d-flex flex-column col-12 col-sm-6 col-lg-4 px-sm-2 justify-content-between mt-2 gap-1">
                      <label className="new-employee-add-label">
                        Emergency Contact Name *
                      </label>
                      <input
                        style={{
                          height: "33px",
                          borderRadius: "3px",
                          fontSize: "12px",
                          backgroundColor:
                            theme === "light" ? "#ebebeb" : "#212226",
                          opacity: "1",
                        }}
                        onChange={(e) => setEmergencyName(e.target.value)}
                        type=""
                        className=" lead-input placeholder col-8 col-md-10"
                        placeholder="Enter Contact Name"
                        value={emergencyName || ""}
                      />
                    </div>
                    <div className=" d-flex flex-column col-12 col-sm-6 col-lg-4 px-sm-2 justify-content-between mt-2 gap-1">
                      <label className="new-employee-add-label">
                        Emergency Contact Number *
                      </label>
                      <PhoneInput
                        inputStyle={{
                          height: "33px",
                          borderRadius: "3px",
                          fontSize: "12px",
                          backgroundColor:
                            theme === "light" ? "#ffffff" : "#212226",
                          width: "100%",
                        }}
                        buttonStyle={{
                          borderRadius: "3px",
                        }}
                        country={"ca"}
                        value={emergencyNumber}
                        onChange={(value) => setEmergencyNumber(value)}
                        containerClass="hr-module-new-employee-contact-input col-8 col-md-10 new-employee-input-number"
                        inputClass="new-employee-input-number"
                        placeholder="Enter valid contact"
                      />
                    </div>

                    <div className=" d-flex flex-column col-12 col-sm-6 col-lg-4 px-sm-2 justify-content-between mt-2 gap-1">
                      <label className="new-employee-add-label">
                        Upload Resume/CV *
                      </label>
                      <Dropzone
                        onDrop={onDrop}
                        multiple={false}
                        maxSize={2097152}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            {...getRootProps()}
                            className="asps-document-upload-field mt-1"
                          >
                            <input {...getInputProps()} />
                            <span
                              className={`asps-upload-input  ${
                                resumeName
                                  ? theme === "light"
                                    ? "#000000"
                                    : "text-white"
                                  : ""
                              }`}
                            >
                              {resumeName ? resumeName : "No file selected"}
                            </span>

                            <span className="create-list-upload-input-choose">
                              Choose file
                            </span>
                          </div>
                        )}
                      </Dropzone>
                    </div>

                    <div className=" d-flex flex-column col-12 col-sm-6 col-lg-4 px-sm-2 justify-content-between mt-2 gap-1">
                      <label className="new-employee-add-label">Degree *</label>

                      <Dropzone
                        onDrop={onDrop1}
                        multiple={false}
                        maxSize={2097152}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            {...getRootProps()}
                            className="asps-document-upload-field mt-1"
                          >
                            <input {...getInputProps()} />
                            <span
                              className={`asps-upload-input  ${
                                document1Name
                                  ? theme === "light"
                                    ? "#000000"
                                    : "text-white"
                                  : ""
                              }`}
                            >
                              {document1Name
                                ? document1Name
                                : "No file selected"}
                            </span>

                            <span className="create-list-upload-input-choose">
                              Choose file
                            </span>
                          </div>
                        )}
                      </Dropzone>
                    </div>

                    <div className=" d-flex flex-column col-12 col-sm-6 col-lg-4 px-sm-2 justify-content-between mt-2 gap-1">
                      <label className="new-employee-add-label">
                        Transcipt *
                      </label>
                      <Dropzone
                        onDrop={onDrop2}
                        multiple={false}
                        maxSize={2097152}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            {...getRootProps()}
                            className="asps-document-upload-field mt-1"
                          >
                            <input {...getInputProps()} />
                            <span
                              className={`asps-upload-input  ${
                                document2Name
                                  ? theme === "light"
                                    ? "#000000"
                                    : "text-white"
                                  : ""
                              }`}
                            >
                              {document2Name
                                ? document2Name
                                : "No file selected"}
                            </span>

                            <span className="create-list-upload-input-choose">
                              Choose file
                            </span>
                          </div>
                        )}
                      </Dropzone>
                    </div>

                    <div className=" d-flex flex-column col-12 col-sm-6 col-lg-4 px-sm-2 justify-content-between mt-2 gap-1">
                      <label className="new-employee-add-label">
                        Equalisation transcript *
                      </label>
                      <Dropzone
                        onDrop={onDrop3}
                        multiple={false}
                        maxSize={2097152}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            {...getRootProps()}
                            className="asps-document-upload-field mt-1"
                          >
                            <input {...getInputProps()} />
                            <span
                              className={`asps-upload-input  ${
                                document3Name
                                  ? theme === "light"
                                    ? "#000000"
                                    : "text-white"
                                  : ""
                              }`}
                            >
                              {document3Name
                                ? document3Name
                                : "No file selected"}
                            </span>

                            <span className="create-list-upload-input-choose">
                              Choose file
                            </span>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                </div>
              </>
            )}

            {active === 1 && (
              <>
                <div className="hr-module-new-employee-form-header">
                  <span>On-boarding Information</span>
                </div>
                <div className="hr-module-new-employee-form-content">
                  <div className="row g-0">
                    <div className=" d-flex flex-column col-12 col-sm-6 col-lg-4 px-sm-2 justify-content-between mt-2 gap-1">
                      <label className="new-employee-add-label">
                        Date of Joining *
                      </label>
                      <input
                        style={{
                          height: "33px",
                          borderRadius: "3px",
                          fontSize: "12px",
                          backgroundColor:
                            theme === "light" ? "#ebebeb" : "#212226",
                        }}
                        onChange={(e) => setexpectedDOJ(e.target.value)}
                        type="date"
                        className=" lead-input col-8 col-md-10"
                        value={expectedDOJ || ""}
                      />
                    </div>
                    <div className=" d-flex flex-column col-12 col-sm-6 col-lg-4 px-sm-2 justify-content-between mt-2 gap-1">
                      <label className="new-employee-add-label">Status *</label>
                      <Select
                        value={status ? { value: status, label: status } : null}
                        onChange={(option) => {
                          setStatus(option?.value);
                        }}
                        options={StatusOptions}
                        isSearchable={false}
                        classNamePrefix="react-select"
                        styles={selectStyles}
                        style={{
                          height: "33px",
                          borderRadius: "3px",
                          fontSize: "12px",
                          // backgroundColor: "#212226",
                          backgroundColor:
                            theme === "light" ? "#ebebeb" : "#212226",
                        }}
                        components={{
                          DropdownIndicator: () => (
                            <RxTriangleDown
                              color={theme === "light" ? "#000000" : "#ffffff"}
                            />
                          ),
                          IndicatorSeparator: null,
                        }}
                        placeholder="Choose an option"
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                    </div>
                    <div className=" d-flex flex-column col-12 col-sm-6 col-lg-4 px-sm-2 justify-content-between mt-2 gap-1">
                      <label className="new-employee-add-label">
                        Position *
                      </label>

                      <input
                        style={{
                          height: "33px",
                          borderRadius: "3px",
                          fontSize: "12px",
                          backgroundColor:
                            theme === "light" ? "#ebebeb" : "#212226",
                        }}
                        onChange={(e) => setPosition(e.target.value)}
                        type="text"
                        className=" lead-input col-8 col-md-10 new-employee-input-number"
                        placeholder="Enter Position"
                        value={position || ""}
                      />
                    </div>

                    <div className=" d-flex flex-column col-12 col-sm-6 col-lg-4 px-sm-2 justify-content-between mt-2 gap-1">
                      <label className="new-employee-add-label">
                        Reports To *
                      </label>
                      <Select
                        value={
                          reportsTo
                            ? reportsList.find(
                                (option) => option?.value === reportsTo
                              )
                            : null
                        }
                        onChange={(option) => {
                          setReportsTo(option?.value);
                        }}
                        options={reportsList}
                        isSearchable={false}
                        classNamePrefix="react-select"
                        styles={selectStyles}
                        style={{
                          height: "33px",
                          borderRadius: "3px",
                          fontSize: "12px",
                          backgroundColor: "#212226",
                        }}
                        components={{
                          DropdownIndicator: () => (
                            <RxTriangleDown
                              color={theme === "light" ? "#000000" : "#ffffff"}
                            />
                          ),
                          IndicatorSeparator: null,
                        }}
                        placeholder="Choose an option"
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                    </div>
                    <div className=" d-flex flex-column col-12 col-sm-6 col-lg-4 px-sm-2 justify-content-between mt-2 gap-1">
                      <label className="new-employee-add-label">
                        Campus Location *
                      </label>
                      <Select
                        value={
                          campusLocation
                            ? { value: campusLocation, label: campusLocation }
                            : null
                        }
                        onChange={(option) => {
                          setCampusLocation(option?.value);
                        }}
                        options={CampusOptions}
                        isSearchable={false}
                        styles={selectStyles}
                        style={{
                          height: "33px",
                          borderRadius: "3px",
                          fontSize: "12px",
                          backgroundColor: "#212226",
                        }}
                        classNamePrefix="react-select"
                        components={{
                          DropdownIndicator: () => (
                            <RxTriangleDown
                              color={theme === "light" ? "#000000" : "#ffffff"}
                            />
                          ),
                          IndicatorSeparator: null,
                        }}
                        placeholder="Choose an option"
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                    </div>
                    <div className=" d-flex flex-column col-12 col-sm-6 col-lg-4 px-sm-2 justify-content-between mt-2 gap-1">
                      <label className="new-employee-add-label">
                        Social Insurance Number *
                      </label>
                      <input
                        style={{
                          height: "33px",
                          borderRadius: "3px",
                          fontSize: "12px",
                          // backgroundColor: "#212226",
                          backgroundColor:
                            theme === "light" ? "#ebebeb" : "#212226",
                        }}
                        onChange={(e) =>
                          setSocialInsuranceNumber(e.target.value)
                        }
                        type="number"
                        className=" lead-input col-8 col-md-10 new-employee-input-number"
                        placeholder="Enter SIN"
                        value={socialInsuranceNumber || ""}
                      />
                    </div>

                    <div
                      style={{
                        fontSize: "14px",
                        color: "#F7931D",
                        marginTop: "10px",
                      }}
                    >
                      Salary details
                    </div>
                    <div className=" d-flex flex-column col-12 col-sm-6 col-lg-4 px-sm-2 justify-content-between mt-2 gap-1">
                      <label className="new-employee-add-label">
                        Gross Salary
                      </label>
                      <input
                        style={{
                          height: "33px",
                          borderRadius: "3px",
                          fontSize: "12px",
                          // backgroundColor: "#212226",
                          backgroundColor:
                            theme === "light" ? "#ebebeb" : "#212226",
                        }}
                        onChange={(e) => setGrossSalary(e.target.value)}
                        type="number"
                        className=" lead-input col-8 col-md-10 new-employee-input-number"
                        placeholder="Enter Gross Salary"
                        value={grossSalary || ""}
                      />
                    </div>
                    <div className=" d-flex flex-column col-12 col-sm-6 col-lg-4 px-sm-2 justify-content-between mt-2  gap-1">
                      <label className="new-employee-add-label">
                        Deductions
                      </label>
                      <input
                        style={{
                          height: "33px",
                          borderRadius: "3px",
                          fontSize: "12px",
                          // backgroundColor: "#212226",
                          backgroundColor:
                            theme === "light" ? "#ebebeb" : "#212226",
                        }}
                        onChange={(e) => setDeductions(e.target.value)}
                        type="number"
                        className=" lead-input col-8 col-md-10 new-employee-input-number"
                        placeholder="Enter Deductions"
                        value={deductions || ""}
                      />
                    </div>

                    <div
                      style={{
                        fontSize: "14px",
                        color: "#F7931D",
                        marginTop: "10px",
                      }}
                    >
                      Bank details
                    </div>
                    <div className=" d-flex flex-column col-12 col-sm-6 col-lg-4 px-sm-2 justify-content-between mt-2 gap-1">
                      <label className="new-employee-add-label">
                        Account Number *
                      </label>
                      <input
                        style={{
                          height: "33px",
                          borderRadius: "3px",
                          fontSize: "12px",
                          // backgroundColor: "#212226",
                          backgroundColor:
                            theme === "light" ? "#ebebeb" : "#212226",
                        }}
                        onChange={(e) => setAccountNumber(e.target.value)}
                        type="number"
                        className=" lead-input col-8 col-md-10 new-employee-input-number"
                        value={accountNumber || ""}
                      />
                    </div>
                    <div className=" d-flex flex-column col-12 col-sm-6 col-lg-4 px-sm-2 justify-content-between mt-2 gap-1">
                      <label className="new-employee-add-label">
                        Bank name *
                      </label>
                      <input
                        style={{
                          height: "33px",
                          borderRadius: "3px",
                          fontSize: "12px",
                          // backgroundColor: "#212226",
                          backgroundColor:
                            theme === "light" ? "#ebebeb" : "#212226",
                        }}
                        onChange={(e) => setBankName(e.target.value)}
                        type="text"
                        className=" lead-input col-8 col-md-10"
                        value={bankName || ""}
                      />
                    </div>
                    <div className=" d-flex flex-column col-12 col-sm-6 col-lg-4 px-sm-2 justify-content-between mt-2 gap-1">
                      <label className="new-employee-add-label">
                        Bank code *
                      </label>
                      <input
                        style={{
                          height: "33px",
                          borderRadius: "3px",
                          fontSize: "12px",
                          // backgroundColor: "#212226",
                          backgroundColor:
                            theme === "light" ? "#ebebeb" : "#212226",
                        }}
                        onChange={(e) => setBankCode(e.target.value)}
                        type="text"
                        className=" lead-input col-8 col-md-10"
                        value={bankCode || ""}
                      />
                    </div>

                    <div className=" d-flex flex-column col-12 col-sm-6 col-lg-4 px-sm-2 justify-content-between mt-2 gap-1">
                      <label className="new-employee-add-label">
                        Swift Code *
                      </label>
                      <input
                        style={{
                          height: "33px",
                          borderRadius: "3px",
                          fontSize: "12px",
                          // backgroundColor: "#212226",
                          backgroundColor:
                            theme === "light" ? "#ebebeb" : "#212226",
                        }}
                        onChange={(e) => setSwiftCode(e.target.value)}
                        type="number"
                        className=" lead-input col-8 col-md-10 new-employee-input-number"
                        value={swiftCode || ""}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="d-flex flex-row justify-content-center gap-2">
            {(active === 1 || active === 2) && (
              <button
                className="hr-module-new-employee-back-button"
                onClick={() => {
                  setActive(active - 1);
                }}
              >
                Back
              </button>
            )}
            {active === 0 && (
              <button
                className={`communication-management-cancel-button ${
                  employeeId &&
                  employeeType &&
                  employeeFirstName &&
                  employeeLastName &&
                  gender &&
                  socialStatus &&
                  dateOfBirth &&
                  age &&
                  contactNumber1 &&
                  emailId &&
                  residentialAddress &&
                  resume &&
                  resumeName &&
                  document1 &&
                  document1Name &&
                  document2 &&
                  document2Name &&
                  document3 &&
                  document3Name
                    ? "opacity-100"
                    : "opacity-50"
                }`}
                onClick={() => {
                  if (
                    active < 2 &&
                    employeeId &&
                    employeeType &&
                    employeeFirstName &&
                    employeeLastName &&
                    gender &&
                    socialStatus &&
                    dateOfBirth &&
                    age &&
                    contactNumber1 &&
                    emailId &&
                    residentialAddress &&
                    resume &&
                    resumeName &&
                    document1 &&
                    document1Name &&
                    document2 &&
                    document2Name &&
                    document3 &&
                    document3Name &&
                    emergencyName &&
                    emergencyNumber
                  ) {
                    setActive(active + 1);
                  } else if (active === 2) {
                    onSubmit();
                  }
                }}
              >
                Save & Next
              </button>
            )}
            {active === 1 && (
              <button
                className={`communication-management-cancel-button ${
                  expectedDOJ &&
                  status &&
                  position &&
                  campusLocation &&
                  accountNumber &&
                  bankName &&
                  bankCode &&
                  swiftCode &&
                  socialInsuranceNumber
                    ? "opacity-100"
                    : "opacity-50"
                }`}
                onClick={() => {
                  if (
                    expectedDOJ &&
                    status &&
                    position &&
                    campusLocation &&
                    accountNumber &&
                    bankName &&
                    bankCode &&
                    swiftCode &&
                    socialInsuranceNumber
                  ) {
                    onSubmit();
                  }
                }}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </div>
      {handlePdf && (
        <OfferLetter
          setPdfBlob={setPdfBlob}
          studentName={"studentName"}
          setHandlePdf={setHandlePdf}
        />
      )}
    </div>
  );
};

export default NewEmployee;
