import { Field, FieldArray, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { RxTriangleDown } from "react-icons/rx";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { axiosService } from "services/axios.service";
import { ADMISSION_MANAGEMENT_URL } from "../constants/admission-management-urls";
import "./CreateEmployementWorkExperience.css";
import { toast } from "react-toastify";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { RiCloseCircleFill } from "react-icons/ri";
import AdmissionSideBarFaculty from "../admission-side-bar-faculty/AdmissionSideBarFaculty";
import { MdDelete } from "react-icons/md";
import { ThemeContext } from "ThemeContext";

function CreateEmployementWorkExperience() {
  const handleRejectButtonClick = () => {
    setShowRejectInput(true);
    setTimeout(() => {
      const commentsContainer = document.getElementById(
        "rejection-comments-container"
      );
      if (commentsContainer) {
        commentsContainer.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 100);
  };
  const navigation = useNavigate();
  const location = useLocation();
  const [refresh, setRefresh] = useState(false);
  const { theme, toggleTheme } = useContext(ThemeContext);
  const data = location?.state?.data;
  const [admissionData, setAdmissionData] = useState();
  const [showRejectButton, setShowRejectButton] = useState(false);
  const [showApproveButton, setShowApproveButton] = useState(false);

  useEffect(() => {
    if (data?.admissionId) {
      axiosService
        .get(
          `${ADMISSION_MANAGEMENT_URL.GET_ADMISSION_URL_BY_ID}${data?.admissionId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setAdmissionData(data);
          setShowRejectButton(data?.studentPayments[0]?.isApproved !== true);
          setShowApproveButton(!data?.employments[0].isApproved);
        });
    }
  }, [location.pathname, refresh]);
  let employmentsInitialValues;

  if (Array.isArray(admissionData?.employments)) {
    employmentsInitialValues = admissionData?.employments.map((employment) => ({
      postHeld: employment.postHeld || "",
      companyName: employment.companyName || "",
      companyAddress: employment.companyAddress || "",
      type: employment.type || "",
      joiningDate:
        employment.joiningDate !== undefined
          ? new Date(employment.joiningDate)?.toISOString()?.split("T")[0]
          : "",
      relievingDate:
        employment.relievingDate !== undefined
          ? new Date(employment.relievingDate)?.toISOString()?.split("T")[0]
          : "",
    }));
  } else {
    employmentsInitialValues = [
      {
        postHeld: "",
        companyName: "",
        companyAddress: "",
        type: "",
        joiningDate: "",
        relievingDate: "",
      },
    ];
  }

  const initialValues = {
    employments: employmentsInitialValues,
  };

  const navigateToAcademic = () => {
    navigation("/admission-management/create-academic-professional", {
      state: { data: data },
    });
  };
  const navigateToOther = () => {
    navigation("/admission-management/create-other-information", {
      state: { data: data },
    });
  };

  const onSubmit = async (values) => {
    navigation("/admission-management/create-other-information", {
      state: { data: data },
    });
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "0 !important",
    }),
  };

  const countryOptions = [
    { value: "PARTTIME", label: "Part Time" },
    { value: "FULLTIME", label: "Full Time" },
  ];

  const [showRejectInput, setShowRejectInput] = useState(false);

  const handleApprove = async () => {
    let requestData = admissionData?.employments;
    const admissionId = {
      admissionId: admissionData?.admissionId,
    };
    for (let i = 0; i < requestData.length; i++) {
      requestData[i].isApproved = true;
      requestData[i].isRejected = false;
      requestData[i].rejectComments = "";
      requestData[i].admissionManagement = admissionId;
    }

    try {
      const response = await axiosService.put(
        `${ADMISSION_MANAGEMENT_URL.PUT_EMPLOYMENT}`,
        requestData
      );

      if (response.status === 200) {
        setRefresh(!refresh);
        toast.success("Employment and Work Experience Approved Successfully");
        navigateToOther();
      } else {
      }
    } catch (error) {}
  };
  const [rejectComments, setRejectComments] = useState("");

  const handleReject = async () => {
    let requestData = admissionData?.employments;

    const admissionId = {
      admissionId: admissionData?.admissionId,
    };
    for (let i = 0; i < requestData.length; i++) {
      requestData[i].isApproved = false;
      requestData[i].isRejected = true;
      requestData[i].rejectComments = rejectComments;
      requestData[i].admissionManagement = admissionId;
    }

    try {
      const response = await axiosService.put(
        `${ADMISSION_MANAGEMENT_URL.PUT_EMPLOYMENT}`,
        requestData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.status === 200) {
        setRefresh(!refresh);
        toast.error("Employment and Work Experience Rejected");
        setShowRejectInput(false);
      } else {
      }
    } catch (error) {}
  };

  const dateString = new Date(admissionData?.employments[0]?.updatedOn);

  const formattedDate =
    dateString instanceof Date && !isNaN(dateString)
      ? dateString.toLocaleDateString()
      : "Invalid Date";
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({ values, setFieldValue, setFieldTouched }) => (
        <Form>
          <div className="create-admission-main-container">
            <div className="progress-container">
              <div className="create-admission-head-text">
                Application #{admissionData?.programDetails[0].applicationNo}
              </div>
            </div>
            <div className="row create-admission-container g-0">
              <div className="create-admission-inner-left-container col-12 col-lg-3 pt-2">
                <AdmissionSideBarFaculty data={data} />
              </div>

              <div className="pre-create-employement-work-inner-right-container col-12 col-lg-9 py-2">
                <div className="create-text-title ">
                  Employments and Work Experience
                </div>
                <div className="scroll-con">
                  <FieldArray name="employments">
                    {({ push, remove }) => (
                      <div>
                        {values.employments.map((employments, index) => (
                          <div key={index}>
                            <div className="create-employement-work-main-input-container">
                              <div className="create-admission-inner-first-input-container ">
                                {index === 0 ? (
                                  <label className="label-text-active">
                                    Post Held
                                  </label>
                                ) : null}
                                <Field
                                  disabled={true}
                                  className="create-admission-input-small"
                                  name={`employments[${index}].postHeld`}
                                />
                              </div>
                              <div className="create-admission-inner-first-input-container ">
                                {index === 0 ? (
                                  <label className="label-text-active">
                                    Company Name
                                  </label>
                                ) : null}
                                <Field
                                  disabled={true}
                                  className="create-admission-input-small"
                                  name={`employments[${index}].companyName`}
                                />
                              </div>
                              <div className="create-admission-inner-first-input-container ">
                                {index === 0 ? (
                                  <label className="label-text-active">
                                    Company Address
                                  </label>
                                ) : null}
                                <Field
                                  disabled={true}
                                  className="create-admission-input-big"
                                  name={`employments[${index}].companyAddress`}
                                />
                              </div>
                              <div className="create-admission-inner-first-input-container ">
                                {index === 0 ? (
                                  <label className="label-text-active">
                                    Type
                                  </label>
                                ) : null}
                                <Field
                                  as="select"
                                  name={`employments[${index}].type`}
                                >
                                  {({ field, form }) => (
                                    <Select
                                      isDisabled={true}
                                      className="create-admission-select-small"
                                      value={countryOptions.find(
                                        (option) => option.value === field.value
                                      )}
                                      onChange={(selectedOption) => {
                                        setFieldValue(
                                          `employments[${index}].type`,
                                          selectedOption.value
                                        );
                                        setFieldTouched(
                                          `employments[${index}].country`,
                                          true
                                        );
                                      }}
                                      options={countryOptions}
                                      isSearchable={false}
                                      classNamePrefix="react-select"
                                      styles={selectStyles}
                                      components={{
                                        DropdownIndicator: () => (
                                          <RxTriangleDown
                                            color={
                                              theme === "light"
                                                ? "#000000"
                                                : "#ffffff"
                                            }
                                          />
                                        ),
                                        IndicatorSeparator: null,
                                      }}
                                      placeholder="Choose"
                                      menuPlacement="auto"
                                      menuPosition="fixed"
                                    ></Select>
                                  )}
                                </Field>
                              </div>
                              <div className="create-admission-inner-first-input-container ">
                                {index === 0 ? (
                                  <label className="label-text-active">
                                    Joining Date
                                  </label>
                                ) : null}
                                <Field
                                  disabled={true}
                                  className="create-personal-data-date-input-small-employment"
                                  name={`employments[${index}].joiningDate`}
                                  type="date"
                                />
                              </div>
                              <div className="create-admission-inner-first-input-container ">
                                {index === 0 ? (
                                  <label className="label-text-active">
                                    Relieving Date
                                  </label>
                                ) : null}
                                <Field
                                  disabled={true}
                                  className="create-personal-data-date-input-small-employment"
                                  name={`employments[${index}].relievingDate`}
                                  type="date"
                                />
                              </div>
                              {values.employments.length > 1 && (
                                <button
                                  disabled={true}
                                  type="button"
                                  className="create-admission-remove-row"
                                  onClick={() => remove(index)}
                                >
                                  <MdDelete />
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </FieldArray>
                </div>
                <div className="d-flex gap-3 my-3 flex-wrap">
                  <button
                    type="button"
                    className="create-admission-previous-button"
                    onClick={navigateToAcademic}
                  >
                    Previous
                  </button>
                  <button
                    type="button"
                    className="create-admission-save-button"
                    onClick={navigateToOther}
                  >
                    Next
                  </button>
                  {showRejectButton && (
                    <button
                      type="button"
                      className="create-admission-previous-button"
                      onClick={handleRejectButtonClick}
                    >
                      Reject
                    </button>
                  )}
                  {showApproveButton && (
                    <button
                      type="button"
                      className="create-admission-save-button"
                      onClick={handleApprove}
                    >
                      Approve & Next
                    </button>
                  )}
                </div>
                <div>
                  {admissionData?.employments[0].isApproved && (
                    <div className="row approve-reject-comments-container">
                      <span className="admission-comments-head-text">
                        Comments
                      </span>
                      <span className="approve-textarea">
                        <IoIosCheckmarkCircle className="admission-approve-check-icon" />
                        Approved
                      </span>
                      <span className="approve-date-text">
                        on {formattedDate}
                      </span>
                    </div>
                  )}
                  {admissionData?.employments[0].isRejected && (
                    <div className="row approve-reject-comments-container">
                      <span className="admission-comments-head-text">
                        Comments
                      </span>
                      <div className="rejected-container p-0 mt-2">
                        <div className="rejcted-head-text">
                          <RiCloseCircleFill className="admission-reject-icon" />
                          Rejected
                        </div>
                        <div className="inner-reject-text">
                          {admissionData?.employments[0]?.rejectComments}
                        </div>
                      </div>
                      <span className="approve-date-text">{formattedDate}</span>
                    </div>
                  )}
                  {showRejectInput && (
                    <div
                      className="row approve-reject-comments-container"
                      id="rejection-comments-container"
                    >
                      <span className="admission-comments-head-text">
                        Comments
                      </span>
                      <textarea
                        className="reject-textarea"
                        onChange={(event) =>
                          setRejectComments(event.target.value)
                        }
                      />
                      <div className=" d-flex gap-2 p-0 pt-2">
                        <button
                          type="button"
                          className="create-admission-previous-button"
                          onClick={() => setShowRejectInput(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="create-admission-save-button"
                          onClick={handleReject}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
export default CreateEmployementWorkExperience;
