import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { axiosService } from "services/axios.service";
import "./ViewAllFee.css";
import AddFeePlan from "../add-fee/AddFeePlan";
import { FEE_URLS } from "modules/fee-finance/constants/fee-finance-urls";
import Dropdown from "react-bootstrap/Dropdown";
import { toast } from "react-toastify";
import ViewFeePlanIndividual from "../add-fee/ViewFeePlanIndividual";
import { ThemeContext } from "ThemeContext";

function ViewAllFee() {
  const location = useLocation();
  const [listFeeData, setListFeeData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFee, setSelectedFee] = useState();

  const [modalType, setModalType] = useState();
  const { theme, toggleTheme } = useContext(ThemeContext);

  useEffect(() => {
    axiosService
      .get(`${FEE_URLS.GET_ALL_FEE_PLANS}`)
      .then((response) => {
        const data = response?.data?.data;

        setListFeeData(data);
      })
      .catch(() => {});
  }, [location.pathname]);

  function onSelectionChanged(event) {
    var selectedNodes = event.api.getSelectedNodes();
    var selectedData = selectedNodes.map((node) => node.data);
    if (selectedData.length > 0) {
      setSelectedFee(selectedData[0]);
    } else {
      setSelectedFee(null);
    }
  }

  const [columnDefs] = useState([
    {
      headerName: "",
      field: "select",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      width: 50,
      floatingFilter: false,
      filter: false,
    },
    {
      headerName: "Program Name",
      valueGetter: (params) => params.data?.programFeeMaster?.program || "-",
      // cellClass: "ag-grid-overflow-wrap",
      width: 320,
      cellStyle: {
        textDecoration: "underline",
        whiteSpace: "normal",
        wordWrap: "break-word",
        overflow: "hidden",
      },
    },
    {
      headerName: "Application Fee",
      field: "applicationFee",
      width: 170,
    },
    {
      headerName: "Tuition Fee",
      field: "tuitionFee",
      width: 140,
    },
    {
      headerName: "Bursaries",
      field: "bursaries",
      width: 130,
    },
    {
      headerName: "Material Fee",
      field: "materialFee",
      width: 140,
    },
    {
      headerName: "Field Trips Fee",
      field: "fieldTripsFee",
      width: 160,
    },
    {
      headerName: "International Fee",
      field: "internationalFee",
      width: 180,
    },
    {
      headerName: "Esl Fee",
      field: "eslFee",
      width: 110,
    },
    {
      headerName: "Bank Fee",
      field: "bankFee",
      width: 120,
    },
    {
      headerName: "Other Fee",
      field: "otherFee",
      width: 120,
    },
  ]);

  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    checkboxSelection: function (params) {
      return (
        params.columnApi.getAllDisplayedColumns().indexOf(params.column) !== 0
      );
    },
    onSelectionChanged: onSelectionChanged,
  };
  const getRowHeight = (params) => {
    if (params.data?.programFeeMaster?.program?.length > 50) {
      return 80;
    } else if (params.data?.programFeeMaster?.program?.length > 100) {
      return 100;
    } else {
      return 41;
    }
  };

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);
  const [feeDataEdit, setFeeDataEdit] = useState(null);

  const onRowClicked = (event) => {
    setFeeDataEdit(event.data);
    setModalType("view");
    setIsModalOpen(true);
  };

  return (
    <div className="view-leads-table-container">
      <div className="lead-table-head-container flex-sm-row gap-2 ms-2">
        <div className="d-flex align-items-center gap-1 ">
          <span className="lead-table-title-text">All Fee Plans </span>
        </div>
        <div className="d-flex align-items-end gap-3">
          <button
            className="communication-save-head-button"
            onClick={() => {
              setModalType("add");
              setIsModalOpen(true);
            }}
          >
            Add Fee Plan
          </button>
          <Dropdown
            className={`lead-table-head-button ${
              !selectedFee && "user-head-button-disabled"
            }`}
            id="dropdown-basic"
            style={{ cursor: "pointer" }}
            disabled={!selectedFee}
            onClick={
              selectedFee
                ? () => {
                    setFeeDataEdit(selectedFee);
                    setModalType("edit");
                    setIsModalOpen(true);
                  }
                : undefined
            }
          >
            Edit Fee
          </Dropdown>
        </div>
      </div>
      <div
        className={
          theme === "light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"
        }
      >
        <AgGridReact
          columnDefs={columnDefs}
          rowData={listFeeData}
          rowSelection="single"
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          suppressRowClickSelection
          gridOptions={gridOptions}
          onRowClicked={onRowClicked}
          getRowHeight={getRowHeight}
        />
      </div>
      <AddFeePlan
        open={isModalOpen}
        modalType={modalType}
        onClose={() => {
          setIsModalOpen(false);
          setFeeDataEdit(null);
          axiosService
            .get(`${FEE_URLS.GET_ALL_FEE_PLANS}`)
            .then((response) => {
              const data = response?.data?.data;
              setListFeeData(data);
            })
            .catch(() => {});
        }}
        feeMasterId={feeDataEdit?.feeMasterId}
      />
    </div>
  );
}

export default ViewAllFee;
