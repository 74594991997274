import { Box, Step, StepLabel, Stepper } from "@mui/material";
import "./NewForm.css";
import React, { useContext, useEffect, useState } from "react";
import { RxTriangleDown } from "react-icons/rx";
import Select from "react-select";
import { toast } from "react-toastify";
import { axiosService } from "services/axios.service";
import { useLocation, useNavigate } from "react-router-dom";
import { EXAM_MODULE_URLS } from "modules/exam-module/constants/exam-modules-urls";
import Section from "assets/images/exam-module-imgs/Section";
import Close from "assets/images/exam-module-imgs/Close";
import sessionService from "services/sessionstorage.service";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import {
  TbLayoutBottombarCollapseFilled,
  TbLayoutNavbarCollapseFilled,
} from "react-icons/tb";
import Swal from "sweetalert2";
import { ThemeContext } from "ThemeContext";
const ViewForm = () => {
  const location = useLocation();
  const data = location.state;
  const userData = sessionService.getUserData("userData");
  const userId = userData?.userId;
  const [program, setProgram] = useState("");
  const [programCode, setProgramCode] = useState("");
  const [module, setModule] = useState("");
  const [moduleCode, setModuleCode] = useState("");
  const [examDescription, setExamDescription] = useState("");
  const [scheduledDate, setScheduledDate] = useState("");
  const [scheduledEndDate, setScheduledEndDate] = useState("");
  const [scheduledTime, setScheduledTime] = useState("");
  const [passPercentage, setPassPercentage] = useState("");
  const [comments, setComments] = useState("");
  const [programModuleId, setProgramModuleId] = useState("");
  const { theme, toggleTheme } = useContext(ThemeContext);

  const [endTime, setEndTime] = useState("");
  const [duration, setDuration] = useState("");
  const [facultyExamModuleId, setFacultyExamModuleId] = useState("");

  const handleEndTimeChange = (newValue) => {
    const formattedTime = newValue ? dayjs(newValue).format("HH:mm:ss") : "";
    setEndTime(formattedTime);
  };

  const [createdFacultyUserId, setCreatedFacultyUserId] = useState();
  useEffect(() => {
    axiosService
      .get(`${EXAM_MODULE_URLS.GET_ALL_EXAMS}/${data?.facultyExamModuleId}`)
      .then((response) => {
        if (response.status === 200) {
          const responseData = response?.data?.data;
          setFacultyExamModuleId(responseData?.facultyExamModuleId);
          setSections(responseData?.sections);
          setProgram(responseData?.module?.programFeeMaster?.program);
          setProgramCode(responseData?.module?.programFeeMaster?.programCode);
          setModule(responseData?.module?.module?.moduleName);
          setModuleCode(responseData?.module?.module?.moduleCode);
          setExamDescription(responseData?.examDescription);
          setScheduledDate(responseData?.scheduledDate);

          setScheduledTime(responseData?.scheduledTime);
          setComments(responseData?.comments);
          setPassPercentage(responseData?.passPercentage);
          setProgramModuleId(responseData?.module?.programModuleId);
          setCreatedFacultyUserId(responseData?.createdFaculty?.userId);
        }
      });
  }, [location.pathname]);
  const navigate = useNavigate();
  const selectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor:
        theme === "light" ? "#EBEBEB !important" : "#212226 !important",
    }),
  };

  const steps = [
    { id: 1, label: "Exam Paper Details", step: "step 1" },
    { id: 2, label: "Sections & Questions", step: "step 2" },
    { id: 3, label: "Submit for Review", step: "step 3" },
    { id: 3, label: "Publish Exam", step: "step 3" },
  ];

  const [active, setActive] = useState(0);

  const [checkboxOneChecked, setCheckboxOneChecked] = useState(false);
  const [checkboxTwoChecked, setCheckboxTwoChecked] = useState(false);
  const isSaveButtonDisabled = () => {
    return (
      scheduledDate === "",
      scheduledEndDate === "",
      scheduledTime === "",
      endTime === "",
      duration === ""
    );
  };
  const onSubmit = async (isNotify) => {
    const Data = {
      module: {
        programModuleId: programModuleId,
      },
      facultyExamModuleId: facultyExamModuleId,
      facultyApproved: {
        userId: userId,
      },
      createdFaculty: { userId: createdFacultyUserId },
      isApproved: true,
      examDescription: examDescription,
      sections: sections,
      scheduledDate: scheduledDate,
      scheduledEndDate: scheduledEndDate,
      scheduledTime: scheduledTime,
      passPercentage: passPercentage,
      comments: comments,
      endTime: endTime,
      duration: duration,
    };
    try {
      const response = await axiosService.put(
        `${EXAM_MODULE_URLS.APPROVE_EXAMS}?isNotify=${isNotify}`,
        Data
      );
      if (response.status === 200) {
        toast.success("Exam Published successfully");
        navigate("/exam-module/all-exams");
      } else {
        toast.error("failed");
      }
    } catch (error) {
      if (error.response) {
        toast.error(`${error.response.data.message.split(":")[1]?.trim()}`);
      }
    }
  };
  const handleUpdateSubmit = () => {
    if (!checkboxOneChecked || !checkboxTwoChecked) {
      toast.warn("Please check both conditions before publishing the exam.");
      return;
    }
    Swal.fire({
      title: "Publish Exam",
      html: "Are you sure you want to Publish the Exam?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Submit",
      cancelButtonText: "No, cancel",
      customClass: {
        popup: "my-custom-class",
        content: "custom-swal-text",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        onSubmit(false);
      }
    });
  };
  const handleUpdateNotify = () => {
    if (!checkboxOneChecked || !checkboxTwoChecked) {
      toast.warn("Please check both conditions before publishing the exam.");
      return;
    }
    Swal.fire({
      title: "Publish Exam",
      html: "Are you sure you want to Publish the Exam?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Submit",
      cancelButtonText: "No, cancel",
      customClass: {
        popup: "my-custom-class",
        content: "custom-swal-text",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        onSubmit(true);
      }
    });
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const [sections, setSections] = useState([]);

  console.log(sections, "sections");

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleExpandIndex = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const overallTotalMarks = sections.reduce((overallSum, section) => {
    const totalMarks = section.questions.reduce((sum, question) => {
      return sum + (parseInt(question.marks, 10) || 0);
    }, 0);
    return overallSum + totalMarks;
  }, 0);
  const LeftContainer = () => {
    return (
      <div className="col-12 col-md-3 section-left-container p-0">
        <div className="section-left-inner-con">
          <div className="section-left-head-text">Program name</div>

          <div className="container">
            <div className="section-left-text-program">{program}</div>
            <div className="program-name">{program}</div>
          </div>
        </div>
        <div className="section-left-inner-con">
          <div className="section-left-head-text">Module name</div>
          <div className="section-left-text">{module}</div>
        </div>
        <div
          className="section-left-inner-con"
          onClick={toggleExpand}
          style={{ cursor: "pointer" }}
        >
          <div className="section-toggle-dropdown">
            <div className="section-left-head-text">Sections</div>
            <span
              className={`section-dropdown-icon ${
                isExpanded ? "rotate-180" : ""
              }`}
            >
              <RxTriangleDown />
            </span>
          </div>
          {isExpanded && (
            <div>
              {sections.map((section, index) => (
                <div key={index}>
                  <div className="section-left-inner-dd-con">
                    <div className="section-left-dd-head-text">
                      {section.sectionTitle}
                    </div>
                    <div className="section-dropdown-inner-flex">
                      <span className="section-left-text-dd">
                        Duration:{" "}
                        <span className="section-left-text-dd-right">
                          {section.duration}
                        </span>
                      </span>
                      <span className="section-left-text-dd">
                        Questions:{" "}
                        <span className="section-left-text-dd-right">
                          {section.questions.length}
                        </span>
                      </span>
                      <span className="section-left-text-dd">
                        Total Marks:{" "}
                        <span className="section-left-text-dd-right">
                          {section.marks}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div style={{ display: "flex", gap: "15px" }}>
            <span className="section-left-text">
              Total Sections: {sections.length}
            </span>
            <span className="section-left-text">
              Total Marks: {overallTotalMarks}
            </span>
          </div>
        </div>
        <div className="section-left-inner-con">
          <div className="section-left-head-text">Submit for Review</div>
          <div className="section-left-text">Status: -NA-</div>
        </div>
        <div className="section-left-inner-con">
          <div className="section-left-head-text">Publish Exam</div>
          <div className="section-left-text">Status: -NA-</div>
        </div>
      </div>
    );
  };

  const [examData, setExamData] = useState("");
  useEffect(() => {
    if (facultyExamModuleId) {
      axiosService
        .get(`${EXAM_MODULE_URLS.GET_STATUS_HISTORY}/${facultyExamModuleId}`)
        .then((response) => {
          if (response.status === 200) {
            const responseData = response.data.data;
            setExamData(responseData.reverse());
          }
        });
    }
  }, [facultyExamModuleId, location.pathname]);

  return (
    <div>
      <div className="wizard-container user-main-container">
        <div className="user-head-container sticky-header flex-row flex-wrap gap-2">
          <span className="user-title-text">Create New Exam</span>

          <div className="flex gap-2">
            {(active === 1 || active === 2 || active === 3) && (
              <button
                className="hr-module-new-employee-back-button"
                onClick={() => {
                  setActive(active - 1);
                }}
              >
                Back
              </button>
            )}
            {active === 0 && (
              <button
                className="communication-management-cancel-button"
                onClick={() => {
                  setActive(active + 1);
                }}
              >
                Next
              </button>
            )}
            {active === 1 && (
              <button
                className="communication-management-cancel-button"
                onClick={() => {
                  setActive(active + 1);
                }}
              >
                Next
              </button>
            )}
            {active === 2 && (
              <button
                className="communication-management-cancel-button"
                onClick={() => {
                  setActive(active + 1);
                }}
              >
                Next
              </button>
            )}
            {/* {active === 3 && (
              <button
                className="communication-management-cancel-button"
                onClick={() => {
                  onSubmit();
                }}
              >
                Save
              </button>
            )} */}
          </div>
        </div>

        <div className="create-exam-stepper-container">
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={active} alternativeLabel>
              {steps.map((label) => (
                <Step key={label.id}>
                  <StepLabel>
                    <span className="create-exam-step-text">
                      {label?.label}
                    </span>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          <div>
            {active === 0 && (
              <div className="exam-main-container col-12 col-sm-10 col-md-7 col-lg-6">
                <div
                  className="col-12 col-md-5"
                  style={{ height: "auto", width: "100%" }}
                >
                  <div className="exam-details-header py-2 px-3">
                    Enter the details for exam paper
                  </div>

                  <div className="py-3 px-1 d-flex flex-column gap-2">
                    <div className="create-financial-main-input-container col-12">
                      <div className="exam-modal-inner-container col-6 px-2">
                        <div className="label-text-exam">
                          Select a Program *
                        </div>
                        <Select
                          value={
                            program ? { value: program, label: program } : null
                          }
                          readOnly
                          isSearchable={false}
                          classNamePrefix="react-select"
                          styles={selectStyles}
                          style={{
                            height: "33px",
                            borderRadius: "3px",
                            fontSize: "12px",
                            backgroundColor:
                              theme === "light"
                                ? "#EBEBEB !important"
                                : "#212226 !important",
                          }}
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown
                                color={
                                  theme === "light" ? "#000000" : "#ffffff"
                                }
                              />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose an option"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      </div>
                      <div className="exam-modal-inner-container col-6 px-2">
                        <label className="label-text-exam">Program code</label>
                        <input
                          value={programCode ? programCode : null}
                          name="programCode"
                          className="input-exam"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="create-financial-main-input-container col-12">
                      <div className="exam-modal-inner-container col-6 px-2">
                        <div className="label-text-exam">
                          Select a module/subject
                        </div>

                        <Select
                          id="module"
                          value={
                            module ? { value: module, label: module } : null
                          }
                          readOnly
                          isSearchable={false}
                          classNamePrefix="react-select"
                          styles={selectStyles}
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown
                                color={
                                  theme === "light" ? "#000000" : "#ffffff"
                                }
                              />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose an Option"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      </div>
                      <div className="exam-modal-inner-container col-6 px-2">
                        <label className="label-text-exam">Module code</label>
                        <input
                          value={moduleCode ? moduleCode : ""}
                          name="moduleCode"
                          className="input-exam"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="create-financial-main-input-container">
                      <div className="exam-modal-inner-container col-12 px-2">
                        <label className="label-text-exam">
                          Exam Description
                        </label>
                        <input
                          readOnly
                          value={examDescription || ""}
                          className="input-exam"
                          component="textarea"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {active === 1 && (
              <>
                <div className="row text-white g-0" style={{ rowGap: "20px" }}>
                  <LeftContainer />
                  <div className="col-12 col-md-9 ps-md-3">
                    {sections.map((section, index) => {
                      const totalMarks = section.questions.reduce(
                        (sum, question) => {
                          return sum + (parseInt(question.marks, 10) || 0);
                        },
                        0
                      );
                      return (
                        <div
                          className="section-right-container gap-3"
                          key={index}
                        >
                          <div className="section-head">
                            <span className="section-head-start">
                              <Section />
                              <span
                                style={{
                                  display: "flex",
                                  gap: "40px",
                                }}
                              >
                                Section {index + 1}
                                <span>{section.sectionTitle}</span>
                              </span>
                            </span>
                            <div className="section-header-end">
                              <span
                                onClick={() => toggleExpandIndex(index)}
                                style={{ cursor: "pointer", fontSize: "20px" }}
                              >
                                {expandedIndex === index ? (
                                  <TbLayoutNavbarCollapseFilled />
                                ) : (
                                  <TbLayoutBottombarCollapseFilled />
                                )}
                              </span>
                            </div>
                          </div>

                          {expandedIndex === index && (
                            <div>
                              <div className="section-sub-head-container">
                                <div className="section-sub-head col-6">
                                  <label className="section-sub-text">
                                    Section Title *
                                  </label>
                                  <input
                                    className="input-exam"
                                    value={section.sectionTitle}
                                    readOnly
                                    type="text"
                                  />
                                </div>
                                <div className="section-sub-head col-3">
                                  <label className="section-sub-text">
                                    Duration (mins) *
                                  </label>
                                  <input
                                    className="input-exam"
                                    value={section.duration}
                                    readOnly
                                    type="text"
                                  />
                                </div>
                                <div className="section-sub-head col-3">
                                  <label className="section-sub-text">
                                    Total Marks *
                                  </label>
                                  <input
                                    className="input-exam"
                                    value={totalMarks}
                                    type="number"
                                    readOnly
                                  />
                                </div>
                              </div>
                              <div>
                                <span className="section-question-text">
                                  Questions
                                </span>
                                <div className="col-12 d-flex flex-wrap">
                                  {section.questions.map((question, qIndex) => (
                                    <div
                                      className="question-container-data"
                                      key={qIndex}
                                    >
                                      <div
                                        style={{ textAlign: "left" }}
                                        dangerouslySetInnerHTML={{
                                          __html: question?.question,
                                        }}
                                      />

                                      <div className="question-bottom-container">
                                        <span>
                                          Type:
                                          <span className="question-bottom-text-color">
                                            {question?.questionType}
                                          </span>
                                        </span>
                                        <span>
                                          Marks
                                          <span className="question-bottom-text-color">
                                            {question?.marks}
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}

            {active === 2 && (
              <>
                <div className="row text-white g-0" style={{ rowGap: "20px" }}>
                  <LeftContainer />
                  <div className="col-12 col-md-9 ps-md-3 ">
                    <div className="section-left-container">
                      <div className="section-head py-2 px-3 align-items-center">
                        <span className="section-head-start">
                          Exam Publish Status
                        </span>

                        <Close
                          color={theme === "light" ? "#000000" : "#FFFFFF"}
                        />
                      </div>
                      <div className=" py-2 px-3">
                        <div>
                          {examData &&
                            examData.map((dataItem, index) => {
                              const isNewItem = index === 0;
                              return (
                                <div key={dataItem.facultyQuestionHistoryId}>
                                  <span className="history-date-exam">
                                    {new Date(
                                      dataItem.addedOn
                                    ).toLocaleDateString("en-US", {
                                      year: "numeric",
                                      month: "short",
                                      day: "numeric",
                                    })}{" "}
                                  </span>
                                  <div
                                    className={`history-info-Con ${
                                      isNewItem ? "new-item" : ""
                                    }`}
                                  >
                                    <span className="exam-ellipse"></span>
                                    <div className="row d-flex history-info-container pb-3">
                                      <span className="history-text col-12 d-flex">
                                        {dataItem.statusDescription}
                                      </span>
                                      <span className="history-sub-text">
                                        by{" "}
                                        <span
                                          style={{
                                            textDecoration: "underline",
                                            cursor: "context-menu",
                                          }}
                                        >
                                          {dataItem.module?.facultyApproved
                                            ?.firstName
                                            ? dataItem.module?.facultyApproved
                                                ?.firstName
                                            : dataItem?.module?.updatedBy}
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {active === 3 && (
              <>
                <div className="row text-white g-0" style={{ rowGap: "20px" }}>
                  <LeftContainer />
                  <div className="col-12 col-md-9 ps-md-3">
                    <div className="section-left-container">
                      <div className="section-head py-2 px-3 align-items-center">
                        <span className="review-header">Publish Exams</span>
                      </div>

                      <div className="px-3 py-2">
                        <span className="row">
                          <div className="section-sub-head col-12 col-sm-6 col-lg-4 pe-2 mt-2">
                            <span className="label-text-question">
                              Scheduled Exam Date *
                            </span>
                            <input
                              className="input-exam"
                              type="date"
                              readOnly
                              value={scheduledDate || ""}
                            />
                          </div>
                          <div className="section-sub-head col-12 col-sm-6 col-lg-4 pe-2 mt-2">
                            <span className="label-text-question">
                              Scheduled End Date *
                            </span>
                            <input
                              className="input-exam"
                              type="date"
                              onChange={(e) =>
                                setScheduledEndDate(e.target.value)
                              }
                              value={scheduledEndDate || ""}
                            />
                          </div>
                        </span>

                        <div className="row">
                          <div className="section-sub-head col-12 col-sm-6 col-lg-4 pe-2 mt-2">
                            <span className="label-text-question">
                              Start Time *
                            </span>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["TimePicker"]}>
                                <TimePicker
                                  style={{
                                    pointerEvents: "none !important",

                                    cursor: "not-allowed !important",
                                  }}
                                  readOnly
                                  value={
                                    scheduledTime
                                      ? dayjs(scheduledTime, "HH:mm:ss")
                                      : null
                                  }
                                  renderInput={(params) => (
                                    <input
                                      {...params}
                                      readOnly
                                      className="input-exam-time-picker"
                                    />
                                  )}
                                  viewRenderers={{
                                    hours: renderTimeViewClock,
                                    minutes: renderTimeViewClock,
                                    seconds: renderTimeViewClock,
                                  }}
                                  onOpen={() => false}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </div>

                          <div className="section-sub-head view-exam-form col-12 col-sm-6 col-lg-4 pe-2 mt-2">
                            <span className="label-text-question">
                              End Time *
                            </span>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["TimePicker"]}>
                                <TimePicker
                                  value={
                                    endTime ? dayjs(endTime, "HH:mm:ss") : null
                                  }
                                  onChange={handleEndTimeChange}
                                  renderInput={(params) => (
                                    <input
                                      {...params}
                                      className="input-exam-time-picker"
                                    />
                                  )}
                                  viewRenderers={{
                                    hours: renderTimeViewClock,
                                    minutes: renderTimeViewClock,
                                    seconds: renderTimeViewClock,
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </div>
                        </div>

                        <div className="section-sub-head col-12 col-sm-6 col-lg-4 pe-2 mt-2">
                          <span className="label-text-question">
                            Exam Duration *
                          </span>
                          <input
                            className="input-exam"
                            value={duration}
                            onChange={(e) => setDuration(e.target.value)}
                            // onChange={handleChange}
                            // maxLength={5}
                            placeholder="Enter Duration in HH:MM"
                          />
                        </div>

                        <div className="checkbox-exam-container">
                          <div className="checkbox-condition">
                            <input
                              type="checkbox"
                              checked={checkboxOneChecked}
                              onChange={() =>
                                setCheckboxOneChecked(!checkboxOneChecked)
                              }
                            />
                            <span className="publish-exam-check">
                              Condition one to check and confirm before
                              publishing the exam
                            </span>
                          </div>
                          <div className="checkbox-condition">
                            <input
                              type="checkbox"
                              checked={checkboxTwoChecked}
                              onChange={() =>
                                setCheckboxTwoChecked(!checkboxTwoChecked)
                              }
                            />
                            <span className="publish-exam-check">
                              Condition two to check and confirm before
                              publishing the exam to check the timings and other
                              stuffs if necessary. This is only a dummy text to
                              fill place. Check with client or use relevant text
                              as required.
                            </span>
                          </div>
                        </div>
                      </div>

                      <span className="d-flex gap-3 px-3 py-2 pb-4">
                        <button
                          className="communication-management-cancel-button"
                          onClick={() => {
                            setActive(active - 1);
                          }}
                        >
                          Close
                        </button>
                        <button
                          className={`user-save-head-button ${
                            isSaveButtonDisabled()
                              ? "user-save-head-button-disabled"
                              : ""
                          }`}
                          disabled={isSaveButtonDisabled()}
                          type="submit"
                          onClick={handleUpdateSubmit}
                        >
                          Publish Exam
                        </button>
                        <button
                          className={`user-save-head-button ${
                            isSaveButtonDisabled()
                              ? "user-save-head-button-disabled"
                              : ""
                          }`}
                          disabled={isSaveButtonDisabled()}
                          type="submit"
                          onClick={handleUpdateNotify}
                        >
                          Publish & Notify
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewForm;
