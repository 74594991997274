import { Field, Form, Formik } from "formik";
import { LEAD_URL } from "modules/lead-mangement/constants/leads-url";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { RxTriangleDown } from "react-icons/rx";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { axiosService } from "services/axios.service";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { TASK_URLS } from "../../constants/TaskUrls";
import {
  TASK_CONSTANTS,
  TASK_DELETE_CONSTANTS,
  TASK_UPDATE_CONSTANTS,
} from "../../constants/task-constants";
import "./CreateTask.css";
import { ROLE_URLS } from "modules/user-management/components/role-management/constants/user-role-urls";

function CreateTask() {
  const navigate = useNavigate();
  const location = useLocation();
  const taskId = location?.state?.taskId;
  const [leadObject, setLeadObject] = useState();
  const [taskData, setTaskData] = useState(null);
  const [taskOwner, setTaskOwner] = useState(null);
  const [taskOwnerOptions, setTaskOwnerOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);

  useEffect(() => {
    axiosService
      .get(`${LEAD_URL.GET_ALL_LEADS}`)
      .then((response) => {
        const userRole = response?.data?.data;
        const leadOptions = userRole.map((userRole) => ({
          value: userRole.lead.name,
          label: userRole.lead.name + `(${userRole?.leadId})`,
          leadObject: userRole,
        }));
        if (leadOptions) {
          setRoleOptions(leadOptions);
          setSelectedOption([leadOptions]);
        }
      })

      .catch(() => {});
    if (taskId) {
      axiosService
        .get(`${TASK_URLS.GET_TASK_BY_ID}${taskId}`)
        .then((response) => {
          const data = response?.data?.data;
          setTaskData(data);
          setLeadObject(data?.relatedTo);
          setTaskOwner(data?.taskOwner);

          if (data) {
            if (data.dayType === undefined && data.dayBefore === undefined) {
              setSelectedOption("reminder");
            } else {
              setSelectedOption("day");
            }
          }
        });
    }

    axiosService
      .get(`${ROLE_URLS.FACULTY_GET_URL}`)
      .then((response) => {
        const user = response?.data?.data;
        const taskOwner = user.map((facultyData) => ({
          value: facultyData.firstName,
          label: facultyData.firstName,
          taskOwnerObject: facultyData,
        }));
        if (taskOwner) {
          setTaskOwnerOptions(taskOwner);
        }
      })

      .catch(() => {});
  }, [location.pathname]);

  const initialValues = {
    taskOwner: "",
    subject: "",
    relatedTo: "",
    dueDate: "",
    status: "",
    priority: "",
  };

  const validationSchema = Yup.object().shape({});

  const onSubmit = async (values, action, resetForm) => {
    try {
      let requestData = {
        taskOwner: taskOwner,
        subject: values.subject,
        relatedTo: leadObject,
        dueDate: values.dueDate,
        status: values.status,
        taskId: taskId,
        priority: values.priority,
      };
      if (
        (values.relatedTo === "",
        values.status === "",
        values.priority === "",
        values.taskOwner === "")
      ) {
        toast.error("Mandatory fields are required!");
      } else {
        await validationSchema.validate(values, { abortEarly: false });

        if (selectedOption === "reminder") {
          requestData.reminderDate = values.reminderDate;
          requestData.reminderTime = values.reminderTime;
        } else {
          requestData.dayBefore = values.dayBefore;
          requestData.dayType = values.dayType;
          requestData.reminderTime = values.reminderTime;
        }

        if (taskId) {
          try {
            const response = await axiosService.put(
              `${TASK_URLS.UPDATE_TASKS}`,
              requestData
            );

            if (response.status === 200) {
              toast.success(TASK_UPDATE_CONSTANTS.SUCCESS);
              if (action === "saveAndNew") {
                setTaskData(null);
                setLeadObject(null);
                setTaskOwner(null);
              } else {
                navigate("/lead-management/view-task");
              }
            } else {
              toast.error(TASK_UPDATE_CONSTANTS.FAILED);
            }
          } catch (error) {
            toast.error(TASK_UPDATE_CONSTANTS.ERROR_OCCUR);
          }
        } else {
          const response = await axiosService.post(
            `${TASK_URLS.CREATE_TASKS}`,
            requestData
          );

          if (response.status === 200) {
            toast.success(TASK_CONSTANTS.SUCCESS);
            if (action === "saveAndNew") {
              setTaskData(null);
              setLeadObject(null);
              setTaskOwner(null);
              resetForm(null);
            } else {
              navigate("/lead-management/view-task");
            }
          } else {
            toast.error(TASK_CONSTANTS.FAILED);
          }
        }
      }
    } catch (error) {
      toast.error(TASK_CONSTANTS.ERROR_OCCUR);
    }
  };

  const [selectedOption, setSelectedOption] = useState("reminder");

  const Priority = [
    { value: "LOW", label: "LOW" },
    { value: "MEDIUM", label: "MEDIUM" },
    { value: "HIGH", label: "HIGH" },
  ];
  const PriorityStatus = [
    { value: "ACTIVE", label: "ACTIVE" },
    { value: "WARM", label: "WARM" },
    { value: "COLD", label: "COLD" },
    { value: "CLOSEDWON", label: "CLOSED-WON" },
    { value: "CLOSEDLOST", label: "CLOSED-LOST" },
  ];

  const handleDelete = () => {
    Swal.fire({
      title: "Confirm Delete",
      text: "Are you sure you want to delete this lead?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete",
      cancelButtonText: "No, cancel",
      customClass: {
        popup: "my-custom-class",
        content: "custom-swal-text",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axiosService
          .delete(`${TASK_URLS.DELETE_TASKS}${taskId}`)
          .then((response) => {
            if (response.status === 200) {
              toast.success(TASK_DELETE_CONSTANTS.SUCCESS);
              navigate("/lead-management/view-task");
            } else {
              toast.error(TASK_DELETE_CONSTANTS.FAILED);
            }
          })
          .catch((error) => {
            toast.error(TASK_DELETE_CONSTANTS.FAILED);
          });
      }
    });
  };

  const isSaveButtonDisabled = (values) => {
    return (
      values.status === "" ||
      values.priority === "" ||
      values.relatedTo === "" ||
      values.taskOwner === ""
    );
  };
  return (
    <>
      <Formik
        initialValues={taskData || initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({ values, resetForm }) => (
          <Form>
            <div className="user-head-container sticky-header flex-sm-row gap-2 px-2">
              <div className="d-flex align-items-center gap-1 ">
                <span className="user-title-text">
                  {taskId ? "Update Task" : "Create Task"}
                </span>
              </div>
              <div className="d-flex align-items-center gap-3">
                {taskId && (
                  <button
                    className="user-head-button"
                    type="button"
                    onClick={handleDelete}
                  >
                    Delete
                  </button>
                )}
                <button
                  className="user-head-button"
                  type="button"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </button>
                {!taskId && (
                  <button
                    className={`user-head-button ${
                      isSaveButtonDisabled(values)
                        ? "user-head-button-disabled"
                        : ""
                    }`}
                    onClick={() => {
                      onSubmit(values, "saveAndNew", resetForm);
                    }}
                    disabled={isSaveButtonDisabled(values)}
                  >
                    Save & New
                  </button>
                )}

                <button
                  className={`user-save-head-button ${
                    isSaveButtonDisabled(values)
                      ? "user-save-head-button-disabled"
                      : ""
                  }`}
                  onClick={() => onSubmit(values, "save")}
                  disabled={isSaveButtonDisabled(values)}
                >
                  {taskId ? "Update" : "Save"}
                </button>
              </div>
            </div>
            <div className="user-main-container pb-4">
              <div className="task-grid-container">
                <div style={{ paddingTop: "15px" }}>
                  <span className="task-information">Task Information</span>
                </div>
                <div className="row" style={{ marginTop: "20px" }}>
                  <div className="col-12 col-md-4 col-lg-3 d-flex flex-column">
                    <label className="task-label-name">Task Owner *</label>

                    <Field
                      as="select"
                      name="taskOwner"
                      render={({ field, form }) => (
                        <Select
                          value={
                            taskOwner
                              ? taskOwnerOptions.find(
                                  (option) =>
                                    option.taskOwnerObject?.firstName ===
                                    taskOwner?.firstName
                                )
                              : null
                          }
                          onChange={(option) => {
                            form.setFieldValue(
                              field.name,
                              option.taskOwnerObject
                            );
                            setTaskOwner(option?.taskOwnerObject);
                          }}
                          options={taskOwnerOptions}
                          isSearchable={false}
                          classNamePrefix="react-select-big"
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown color="#000000" />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose a Contact"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      )}
                    />
                  </div>
                  <div className="col-12 col-md-4 col-lg-3 d-flex flex-column">
                    <label className="task-label-name">Subject</label>
                    <Field
                      className=" task-subject-input col-8 col-md-10"
                      placeholder="Add a subject to task"
                      name="subject"
                    />
                  </div>
                  <div className="col-12 col-md-4 col-lg-3 d-flex flex-column">
                    <label className="task-label-name">Related To *</label>

                    <Field
                      as="select"
                      name="relatedTo"
                      render={({ field, form }) => (
                        <Select
                          value={
                            leadObject
                              ? roleOptions.find(
                                  (option) =>
                                    option?.leadObject?.leadId ===
                                    leadObject?.leadId
                                )
                              : null
                          }
                          onChange={(option) => {
                            form.setFieldValue("relatedTo", option?.leadObject);
                            setLeadObject(option?.leadObject);
                          }}
                          options={roleOptions}
                          isSearchable={false}
                          classNamePrefix="react-select-big"
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown color="#000000" />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose an Option"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="row" style={{ marginTop: "20px" }}>
                  <div className="col-12 col-md-4 col-lg-3 d-flex flex-column">
                    <label className="task-label-name">Due Date</label>
                    <Field
                      type="date"
                      className="task-date-input col-8 col-md-10"
                      placeholder="Select a date"
                      name="dueDate"
                    />
                  </div>
                  <div className="col-12 col-md-4 col-lg-3 d-flex flex-column">
                    <label className="task-label-name">Status *</label>
                    <Field
                      as="select"
                      name="status"
                      render={({ field, form }) => (
                        <Select
                          value={
                            field.value
                              ? PriorityStatus.find(
                                  (option) => option.value === field.value
                                )
                              : null
                          }
                          onChange={(option) =>
                            form.setFieldValue(field.name, option.value)
                          }
                          options={PriorityStatus}
                          isSearchable={false}
                          classNamePrefix="react-select-big"
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown color="#000000" />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose an Option"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      )}
                    />
                  </div>
                  <div className="col-12 col-md-4 col-lg-3 d-flex flex-column">
                    <label className="task-label-name">Priority *</label>
                    <Field
                      as="select"
                      name="priority"
                      render={({ field, form }) => (
                        <Select
                          id="leadOwner"
                          value={
                            field.value
                              ? Priority.find(
                                  (option) => option.value === field.value
                                )
                              : null
                          }
                          onChange={(option) =>
                            form.setFieldValue(field.name, option.value)
                          }
                          options={Priority}
                          isSearchable={false}
                          classNamePrefix="react-select-big"
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown color="#000000" />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose an Option"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default CreateTask;
