import React, { useContext, useEffect } from "react";
import "./ServicesFacultyView.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { useMemo, useState } from "react";
import { FaFilter } from "react-icons/fa";
import HostelModal from "./modal-view/HostelModal";
import AirportModal from "./modal-view/AirportModal";
import HealthModal from "./modal-view/HealthModal";
import { SERVICE_FACULTY_VIEW } from "modules/student-portal/constants/faculty-service-urls";
import { axiosService } from "services/axios.service";
import "./ServicesFacultyView.css";
import { Dropdown } from "react-bootstrap";
import { ThemeContext } from "ThemeContext";

function ServicesFacultyView() {
  const [data, setData] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(null);
  const [status, setStatus] = useState("");
  const { theme, toggleTheme } = useContext(ThemeContext);

  useEffect(() => {
    if (status === "") {
      axiosService
        .get(`${SERVICE_FACULTY_VIEW.GET_ALL_SERVICE}`)
        .then((response) => {
          if (response.status === 200) {
            const responseData = response.data.data;
            setData(responseData);
          }
        });
    } else {
      axiosService
        .get(`${SERVICE_FACULTY_VIEW.GET_ALL_SERVICE}?status=${status}`)
        .then((response) => {
          if (response.status === 200) {
            const responseData = response.data.data;
            setData(responseData);
          }
        });
    }
  }, [isModalOpen, status]);

  function formatTimestampToTime(timestamp) {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const period = hours >= 12 ? "PM" : "AM";

    return `${formattedHours}:${formattedMinutes} ${period}`;
  }

  const serviceTypes = [
    { value: "AIRPORTMEETANDGREET", label: "Airport Meet and Greet" },
    { value: "ACCOMMODATION", label: "Hostel/Accommodation" },
    { value: "HEALTHINSURANCE", label: "Health Insurance" },
  ];

  const [columnDefs] = useState([
    {
      headerName: "Request Date",
      field: "requestDate",
      valueGetter: function (params) {
        return params.data.requestDate || "-";
      },
    },

    {
      headerName: "Name",
      field: "user",
      cellRenderer: function (params) {
        return (
          <span>
            {params.value.firstName} {params.value.lastName}
          </span>
        );
      },
    },
    {
      headerName: "Requested Service",
      field: "serviceType",
      valueGetter: function (params) {
        const serviceTypeObj = serviceTypes.find(
          (type) => type.value === params.data.serviceType
        );
        return serviceTypeObj ? serviceTypeObj.label : "-";
      },
    },

    {
      headerName: "Status",
      field: "status",
      cellRenderer: function (params) {
        const status = params.value;

        return (
          <span
            className={`${
              status === "APPROVED" && "student-portal-table-status-approved"
            }
          ${status === "REJECTED" && "student-portal-table-status-pending"}
          ${status === "OPEN" && "student-portal-table-status-open"}
          `}
          >
            {status}
          </span>
        );
      },
    },
    {
      headerName: "Check-in / Pickup Date",
      field: "checkInDate",
      valueGetter: function (params) {
        return params.data.checkInDate || "-";
      },
    },
    {
      headerName: "Duration of Stay",
      field: "stayDuration",
      valueGetter: function (params) {
        return params.data.stayDuration
          ? params.data.stayDuration + "days"
          : "-";
      },
    },
    {
      headerName: "Airport Terminal",
      field: "airportTerminal",
      valueGetter: function (params) {
        return params.data.airportTerminal || "-";
      },
    },
    {
      headerName: "Landing Time",
      field: "flightLandingTime",
      valueGetter: function (params) {
        return params.data.flightLandingTime
          ? formatTimestampToTime(params.data.flightLandingTime)
          : "-";
      },
    },
  ]);

  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);

  const onRowClicked = (event) => {
    setRowData(event?.data);
    setIsModalOpen(event?.data?.serviceType);
  };
  const onClose = () => {
    setIsModalOpen(null);
    setRowData(null);
  };
  return (
    <div className="view-leads-table-container">
      <div
        className="lead-table-head-container sticky-header flex-row flex-wrap gap-2 lg:ms-2 "
        style={{ alignItems: "center" }}
      >
        <div
          className="d-flex align-items-center gap-1 "
          style={{ cursor: "pointer" }}
        >
          <Dropdown>
            <Dropdown.Toggle className="student-portal-list-page-filter p-0">
              <span className="sps-fv-filter">
                <FaFilter className="sps-fv-filter-icon" />
              </span>
              <span className="service-head-text">
                {status === "" && "All"}
                {status === "OPEN" && "Open"}
                {status === "REJECTED" && "Rejected"}
                {status === "APPROVED" && "Approved"} Services
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="py-0 dropdown-button-content-container">
              <Dropdown.Item
                className={`${status === "" && "dropdown-item-active"}`}
                onClick={() => setStatus("")}
              >
                All
              </Dropdown.Item>
              <Dropdown.Item
                className={`${status === "OPEN" && "dropdown-item-active"}`}
                onClick={() => setStatus("OPEN")}
              >
                Open
              </Dropdown.Item>
              <Dropdown.Item
                className={`${status === "REJECTED" && "dropdown-item-active"}`}
                onClick={() => setStatus("REJECTED")}
              >
                Rejected
              </Dropdown.Item>
              <Dropdown.Item
                className={`${status === "APPROVED" && "dropdown-item-active"}`}
                onClick={() => setStatus("APPROVED")}
              >
                Approved
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="d-flex flex-wrap lg:align-items-end">
          <div className="sps-fv-top-count sps-fv-ha ps-0 md:ps-15">
            Hostel/Accomodation<span>{data?.accomodationCount}</span>
          </div>
          <div className="sps-fv-top-count sps-fv-amg ps-0 md:ps-15">
            Airport Meet & Greet<span>{data?.airportCount}</span>
          </div>
          <div className="sps-fv-top-count sps-fv-hi ps-0 md:ps-15">
            Health Insurance<span>{data?.healthCount}</span>
          </div>
        </div>
      </div>
      <div
        className={
          theme === "light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"
        }
      >
        <AgGridReact
          columnDefs={columnDefs}
          rowData={data?.portalService}
          rowSelection="single"
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          suppressRowClickSelection
          gridOptions={gridOptions}
          onRowClicked={onRowClicked}
        />
      </div>
      <HostelModal
        open={isModalOpen === "ACCOMMODATION"}
        data={rowData}
        onClose={onClose}
      />
      <AirportModal
        open={isModalOpen === "AIRPORTMEETANDGREET"}
        data={rowData}
        onClose={onClose}
      />
      <HealthModal
        open={isModalOpen === "HEALTHINSURANCE"}
        data={rowData}
        onClose={onClose}
      />
    </div>
  );
}
export default ServicesFacultyView;
