import { Field, Form, Formik } from "formik";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosService } from "services/axios.service";
import { useEffect, useState, useContext } from "react";
import sessionstorageService from "services/sessionstorage.service";
import { ADMISSION_URL_FOR_STUDENT } from "../../constants/admission-application-student-urls";
import { ADMISSION_CONSTANTS_FOR_STUDENTS } from "../../constants/admission-application-student-constants";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { RiCloseCircleFill } from "react-icons/ri";
import "./CreateOtherInformation.css";
import AdmissionSideBar from "../admission-side-bar/AdmissionSideBar";
import Select from "react-select";
import { RxTriangleDown } from "react-icons/rx";
import sessionService from "services/sessionstorage.service";
import { ThemeContext } from "ThemeContext";

function CreateOtherInformation() {
  const navigation = useNavigate();
  const location = useLocation();
  const programId = location?.state?.data;
  const selectStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "0 !important",
      width: "230px !important",
    }),
  };

  const { theme, toggleTheme } = useContext(ThemeContext);

  const [admissionData, setAdmissionData] = useState(null);
  const admissionId = sessionstorageService.getAdmissionId("admissionId");
  const [approved, setApproved] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [isFeeData, setIsFeeData] = useState(false);

  const [enrolledStaffOptions, setEnrolledStaffOptions] = useState([]);
  useState("");
  useEffect(() => {
    axiosService
      .get(`${ADMISSION_URL_FOR_STUDENT.GET_ENROLLED_STAFF_DATA}`)
      .then((response) => {
        const user = response?.data?.data;

        const enrolledStaffOptions = user?.map((user) => ({
          value: user.enrolledStaffName,
          label: user.enrolledStaffName,
          enrolledStaffObject: { staffId: user.staffId },
        }));
        if (enrolledStaffOptions) {
          setEnrolledStaffOptions(enrolledStaffOptions);
        }
      });
  }, []);
  useEffect(() => {
    if (admissionId) {
      axiosService
        .get(
          `${ADMISSION_URL_FOR_STUDENT.GET_ADMISSION_URL_FOR_STUDENT}${admissionId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setAdmissionData(data);
          setApproved(data?.otherInformation?.isApproved);
          setRejected(data?.otherInformation?.isRejected);
          setIsFeeData(data?.fees[0]?.isCompleted);
        });
    }
  }, [location.pathname]);

  const navigateToEmployment = () => {
    navigation("/pre-onboarding/create-employement-work-experience", {
      state: {
        data: programId,
      },
    });
  };
  const initialValues = {
    specialOffer: "",
    enrolledStaff: null,
    consultantAddress: "",
    mobileNumber: "",
    email: "",
    otherId: admissionData?.otherInformation?.otherId,
  };
  const userData = sessionService.getUserData("userData");
  const studentType = userData?.studentType;
  const onSubmit = async (values) => {
    const dataType = "other";

    values.isRejected = false;

    const saveData = {
      dataType,
      studentType,
      data: {
        admissionId: admissionData?.admissionId,
        personal: admissionData?.personalData,
        mailing: admissionData?.mailingAddress,
        guardian: admissionData?.guardianDetail,
        academics: admissionData?.academics,
        employment: admissionData?.employments,
        other: values,
      },
    };
    try {
      const response = await axiosService.post(
        `${ADMISSION_URL_FOR_STUDENT.ADMISSION_URL}`,
        saveData
      );
      if (response.status === 200) {
        toast.success(ADMISSION_CONSTANTS_FOR_STUDENTS.OTHER_INFO_SUCCESS);
        navigation("/pre-onboarding/create-list-upload-files", {
          state: {
            data: programId,
          },
        });
      } else {
        toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.OTHER_INFO_FAILURE);
      }
    } catch (error) {
      if (error.response) {
        toast.error(`Error: ${error.response.data.message}`);
      } else {
        toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.OTHER_INFO_ERROR);
      }
    }
  };
  const onSubmitSkip = async (values) => {
    const dataType = "other";

    values.isRejected = false;

    const saveData = {
      dataType,
      studentType,
      data: {
        admissionId: admissionData?.admissionId,
        personal: admissionData?.personalData,
        mailing: admissionData?.mailingAddress,
        guardian: admissionData?.guardianDetail,
        academics: admissionData?.academics,
        employment: admissionData?.employments,
        other: values,
      },
    };
    try {
      const response = await axiosService.post(
        `${ADMISSION_URL_FOR_STUDENT.ADMISSION_URL}`,
        saveData
      );
      if (response.status === 200) {
        navigation("/pre-onboarding/create-list-upload-files", {
          state: {
            data: programId,
          },
        });
      } else {
        toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.OTHER_INFO_FAILURE);
      }
    } catch (error) {
      if (error.response) {
        toast.error(`Error: ${error.response.data.message}`);
      } else {
        toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.OTHER_INFO_ERROR);
      }
    }
  };
  const dateString = new Date(admissionData?.otherInformation?.updatedOn);
  const formattedDate =
    dateString instanceof Date && !isNaN(dateString)
      ? dateString.toLocaleDateString()
      : "Invalid Date";

  const handleClear = async (formik) => {
    formik.resetForm();
  };

  return (
    <Formik
      initialValues={admissionData?.otherInformation || initialValues}
      // onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {(formik) => (
        <Form>
          <div className="create-admission-main-container">
            <div className="progress-container">
              <div className="create-admission-head-text">Registration</div>
              <div className="col">
                <ProgressBar
                  now={admissionData?.pagePercentage || 0}
                  label={`${admissionData?.pagePercentage}%`}
                />
              </div>
            </div>
            <div className="row create-admission-container g-0">
              <div className="create-admission-inner-left-container col-12 col-lg-3 pt-2">
                <AdmissionSideBar />
              </div>
              <div className="create-admission-inner-right-container col-12 col-lg-9 py-2">
                <div className="create-text-title ">Other Information</div>
                <div>
                  <div className="create-mailing-address-main-input-container">
                    <div className="create-admission-inner-first-input-container-other-info ">
                      <label className="label-text-active">Special Offer</label>
                      <Field
                        name="specialOffer"
                        className="create-admission-input-big"
                      />
                    </div>
                  </div>
                  <div className="create-mailing-address-main-input-container">
                    <div className="create-admission-inner-first-input-container-other-info ">
                      <label className="label-text-active">
                        Name of the Consultant/Staff enrolled by
                      </label>
                      <Field
                        className="create-admission-input-big"
                        name="enrolledStaff"
                        render={({ field, form }) => (
                          <Select
                            value={
                              field.value && field.value.staffId
                                ? enrolledStaffOptions.find(
                                    (option) =>
                                      option.enrolledStaffObject?.staffId ===
                                      field.value.staffId
                                  )
                                : null
                            }
                            onChange={(option) => {
                              form.setFieldValue(
                                field.name,
                                option ? option.enrolledStaffObject : null
                              );
                            }}
                            options={enrolledStaffOptions}
                            isSearchable={false}
                            classNamePrefix="react-select"
                            components={{
                              DropdownIndicator: () => (
                                <RxTriangleDown
                                  color={
                                    theme === "light" ? "#000000" : "#ffffff"
                                  }
                                />
                              ),
                              IndicatorSeparator: null,
                            }}
                            placeholder="Choose an option"
                            menuPlacement="auto"
                            menuPosition="fixed"
                            styles={selectStyles}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="create-mailing-address-main-input-container">
                    <div className="create-admission-inner-first-input-container-other-info ">
                      <label className="label-text-active">
                        Address of the consultant
                      </label>
                      <Field
                        name="consultantAddress"
                        component="textarea"
                        className="create-admission-input-big"
                      />
                      {/* {formik.touched.referredBy && formik.errors.referredBy ? (
                        <div className="error referredBy">{formik.errors.referredBy}</div>
                      ) : null} */}
                    </div>
                  </div>
                  <div className="create-mailing-address-main-input-container">
                    <div className="create-admission-inner-first-input-container-other-info ">
                      <label className="label-text-active">
                        Mobile No. of the consultant
                      </label>
                      <Field
                        name="mobileNumber"
                        className="create-admission-input-big"
                      />
                    </div>
                  </div>
                  <div className="create-mailing-address-main-input-container">
                    <div className="create-admission-inner-first-input-container-other-info ">
                      <label className="label-text-active">
                        Email of the consultant
                      </label>
                      <Field
                        name="email"
                        className="create-admission-input-big"
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex gap-3 my-3 flex-wrap">
                  <button
                    type="button"
                    className="create-admission-previous-button"
                    onClick={navigateToEmployment}
                  >
                    Previous
                  </button>
                  {!admissionData?.otherInformation?.otherId && (
                    <button
                      className="create-admission-save-button"
                      onClick={() => onSubmitSkip(formik.values)}
                    >
                      Skip & Next
                    </button>
                  )}
                  {!admissionData?.otherInformation?.otherId ? (
                    <button
                      type="submit"
                      onClick={() => onSubmit(formik.values)}
                      className="create-admission-save-button"
                      disabled={formik.isSubmitting}
                    >
                      Save & Next
                    </button>
                  ) : (
                    (rejected || (!rejected && !approved && !isFeeData)) && (
                      <button
                        type="submit"
                        className="create-admission-save-button"
                        disabled={formik.isSubmitting}
                        onClick={() => onSubmit(formik.values)}
                      >
                        Edit & Save
                      </button>
                    )
                  )}

                  {!admissionData?.otherInformation?.otherId && (
                    <button
                      type="button"
                      className="create-admission-clear-button"
                      onClick={() => handleClear(formik)}
                    >
                      Clear
                    </button>
                  )}

                  {(admissionData?.otherInformation?.otherId ||
                    admissionData?.otherInformation?.isApproved) && (
                    <button
                      type="button"
                      className=" create-admission-next-button"
                      onClick={() =>
                        navigation("/pre-onboarding/create-list-upload-files", {
                          state: {
                            data: programId,
                          },
                        })
                      }
                    >
                      Next
                    </button>
                  )}
                </div>
                {admissionData?.otherInformation?.isApproved && (
                  <div className="row approve-reject-comments-container">
                    <span className="admission-comments-head-text">
                      Comments
                    </span>
                    <span className="approve-textarea">
                      <IoIosCheckmarkCircle className="admission-approve-check-icon" />
                      Approved
                    </span>
                    <span className="approve-date-text">
                      on {formattedDate}
                    </span>
                  </div>
                )}
                {admissionData?.otherInformation?.isRejected && (
                  <div className="row approve-reject-comments-container">
                    <span className="admission-comments-head-text">
                      Comments
                    </span>
                    <div className="rejected-container p-0 mt-2">
                      <div className="rejcted-head-text">
                        <RiCloseCircleFill className="admission-reject-icon" />
                        Rejected
                      </div>
                      <div className="inner-reject-text">
                        {admissionData?.otherInformation?.rejectComments}
                      </div>
                    </div>
                    <span className="approve-date-text">{formattedDate}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default CreateOtherInformation;
