import { AgGridReact } from "ag-grid-react";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { FaGraduationCap } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { axiosService } from "services/axios.service";
import { EXAMS_URL } from "modules/exams-marksheets/constants/exams-url";
import sessionService from "services/sessionstorage.service";
import ConfirmExamModal from "./ConfirmExamModal";
import { toast } from "react-toastify";
import { ThemeContext } from "ThemeContext";
function StudentExamList() {
  const navigate = useNavigate();
  const userData = sessionService.getUserData("userData");
  let StudentId = userData.admissionManagement?.personalData?.studentId;
  const [data, setData] = useState([]);
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedExam, setSelectedExam] = useState(null);
  const [totalSections, setTotalSections] = useState(null);
  const [totalQuestions, setTotalQuestions] = useState(null);

  const handleModalConfirm = () => {
    navigate("/exam-marksheets/exam-questions", {
      state: { selectedExam, sendData },
    });
    setIsModalOpen(false);
  };
  useEffect(() => {
    axiosService
      .get(`${EXAMS_URL.GET_ALL_STUDENT_EXAMS}${StudentId}`)
      .then((response) => {
        const data = response?.data?.data;

        setData(data);
        console.log(data);
      });
  }, [StudentId]);
  const [columnDefs, setColumnDefs] = useState([
    {
      field: "examName",
      headerName: "Exam Name",
    },
    {
      field: "examDate",
      headerName: "ExamDate",
    },
    {
      field: "examTime",
      headerName: "Exam Time",
    },
    {
      field: "examModule.duration",
      headerName: "Exam Duration",
    },
    {
      headerName: "Status",

      cellRenderer: function (params) {
        const status = params?.data?.isEvaluated;
        let statusClass = "";
        let Status = "";

        switch (status) {
          case true:
            statusClass = "ag-grid-application-status-approved";
            Status = "Evaluated";
            break;
          case false:
            statusClass = "ag-grid-application-status-pending";
            Status = "Submitted";
            break;
          default:
            statusClass = "ag-grid-application-status-not-started";
            Status = "Not Started";
            break;
        }
        return (
          <span
            className={statusClass}
            style={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            {Status}
          </span>
        );
      },
    },
    {
      field: "markPercentage",
      headerName: "Marks in %",
      cellRenderer: (params) => {
        const percentage = `${parseFloat(params.value).toFixed(0)} %`; // No decimal places
        return <span>{percentage}</span>;
      },
    },
  ]);

  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };
  const [sendData, setSendData] = useState(null);
  const fetchData = (id) => {
    axiosService
      .get(`${EXAMS_URL.GET_STUDENT_EXAM_MODULE}${id}`)
      .then((response) => {
        const responseData = response?.data?.data;
        setSelectedExam(responseData);
        setIsModalOpen(true);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  function rowClicked(event) {
    const totalQuestions = event?.data?.examModule?.sections.reduce(
      (acc, sec) => acc + sec.questions.length,
      0
    );
    const totalSections = event?.data?.examModule?.sections.length;
    if (!event?.data?.answer) {
      setTotalSections(totalSections);
      setTotalQuestions(totalQuestions);
      setSendData(event.data);
      fetchData(event.data?.studentQuestionAnswerId);
    } else {
      toast.error("Exam already completed. You cannot retake it.");
    }
  }

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);

  return (
    <div className="exam-management-main-container">
      <div className="communication-management-head-container sticky-header-communication flex-row flex-wrap gap-2">
        <div className="d-flex align-items-center gap-3">
          <span className="communication-management-title-text">My Exams</span>
        </div>

        <div className="d-flex align-items-center gap-3 justify-content-end">
          <FaGraduationCap
            style={{
              color: theme === "light" ? "#000000" : "#ffffff",
              fontSize: "30px",
            }}
          />
          <div style={{ height: "36px" }}>
            <div
              style={{
                fontSize: "14px",
                color: "#F69F3A",
                // backgroundColor: theme === "light" ? "#FFFFFF" : "#212226",
                fontWeight: "700",
              }}
            >
              My Program
            </div>
            <div
              style={{
                fontSize: "14px",
                color: theme === "light" ? "#000000" : "#ffffff",
                fontWeight: "400",
              }}
            >
              {data[0]?.examModule?.module?.programFeeMaster?.programCode} -{" "}
              {data[0]?.examModule?.module?.programFeeMaster?.program}
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          theme === "light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"
        }
      >
        <AgGridReact
          columnDefs={columnDefs}
          rowData={data}
          rowSelection="single"
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          suppressRowClickSelection
          gridOptions={gridOptions}
          onRowClicked={rowClicked}
        />
      </div>
      <ConfirmExamModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        onConfirm={handleModalConfirm}
        totalQuestions={totalQuestions}
        totalSections={totalSections}
      />
    </div>
  );
}

export default StudentExamList;
