import React, { useContext } from "react";
import "./AllStudentPortal.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useMemo, useState } from "react";
import { axiosService } from "services/axios.service";
import useravatar from "assets/images/user-avatar.svg";
import useravatarblack from "assets/images/user-avatar-black.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { STUDENT_PORTAL_URL } from "modules/student-portal/constants/student-portal-urls";
import { ThemeContext } from "ThemeContext";

function AllStudentPortal() {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const location = useLocation();
  const { theme, toggleTheme } = useContext(ThemeContext);

  useEffect(() => {
    axiosService.get(`${STUDENT_PORTAL_URL.ALL_ADMISSION}`).then((response) => {
      const data = response?.data?.data;
      if (data) {
        setData(data.reverse());
      }
    });
  }, [location.pathname]);

  const StudentCellRenderer = (params, theme) => {
    return (
      <div className="d-flex gap-2">
        <span className="d-flex justify-content-center align-items-center">
          {params?.data?.imageUrl !== null ? (
            <img
              src={params?.data?.imageUrl}
              className="lead-icon-image"
              alt="leadiconimage"
            />
          ) : (
            <img
              src={theme === "light" ? useravatarblack : useravatar}
              className="lead-icon-image"
              alt="leadiconimage"
            />
          )}

          {/* {params?.data?.imageUrl && (
            <img
              src={params.data.imageUrl}
              className="lead-icon-image"
              alt="leadiconimage"
            />
          )}
          {!params?.data?.imageUrl && (
            <img
              src={useravatar}
              className="lead-icon-image"
              alt="leadiconimage"
            />
          )} */}
        </span>

        <div className="d-flex align-items-center">
          <span>
            {params?.data?.personalData?.firstName}{" "}
            {params?.data?.personalData?.lastName}
          </span>
        </div>
      </div>
    );
  };
  function customStudentNameFilter(params) {
    const studentName = params.data.personalData;
    const filterText = params.filter;

    if (studentName && filterText) {
      const firstName = studentName.firstName || "";
      const lastName = studentName.lastName || "";
      const leadFields = (firstName + " " + lastName).toLowerCase();
      return leadFields.includes(filterText.toLowerCase());
    }

    return true;
  }

  const columnDefs = useMemo(
    () => [
      {
        headerName: "Student Id",
        valueGetter: function (params) {
          return params.data?.personalData?.studentId;
        },
        width: 160,
      },

      {
        headerName: "Student Name",
        filterParams: {
          applyButton: true,
        },
        valueGetter: function (params) {
          const studentname = params.data?.personalData;
          const firstName = studentname.firstName || "";
          const lastName = studentname.lastName || "";
          return firstName + " " + lastName;
        },
        customFilterFunction: customStudentNameFilter,
        // cellRenderer: StudentCellRenderer,
        cellRenderer: (params) => StudentCellRenderer(params, theme),
      },

      {
        headerName: "Selected Course",
        field: "programDetail.program",
        width: 320,
        cellStyle: {
          whiteSpace: "normal",
          wordWrap: "break-word",
          overflow: "hidden",
        },
      },
      { headerName: "Student Type", field: "studentType" },
      {
        headerName: "Access Number",
        valueGetter: function (params) {
          if (
            params.data.personalData &&
            params.data.personalData.osapAccessNumber
          ) {
            return params.data.personalData.osapAccessNumber;
          } else {
            return "-";
          }
        },
        width: 160,
      },
      {
        headerName: "Location",
        field: "mailingAddress.city",
      },

      {
        headerName: "Phone No",
        field: "mailingAddress.mobile1",
      },

      {
        headerName: "Email",
        field: "personalData.email",
        cellClass: "ag-grid-lowercase",
      },

      {
        headerName: "Country",
        field: "mailingAddress.country",
      },
    ],
    [theme]
  );

  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);

  const onRowClicked = (event) => {
    navigate("/student-portal/view-student", {
      state: event.data,
    });
  };
  const getRowHeight = (params) => {
    if (params.data?.programDetail?.program?.length > 50) {
      return 80;
    } else if (params.data?.programDetail?.program?.length > 100) {
      return 100;
    } else {
      return 41;
    }
  };
  return (
    <div className="view-leads-table-container">
      <div className="lead-table-head-container sticky-header flex-sm-row gap-2 ms-2">
        <div className="d-flex align-items-center gap-1 ">
          <span className="lead-table-title-text">All Students</span>
        </div>
        <div className="d-flex align-items-end gap-1 me-5">
          <span className="total-students-text">
            Total Students
            <span className="total-students-count-text">{data.length}</span>
          </span>
        </div>
      </div>
      <div
        className={
          theme === "light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"
        }
      >
        <AgGridReact
          columnDefs={columnDefs}
          rowData={data}
          rowSelection="single"
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          suppressRowClickSelection
          gridOptions={gridOptions}
          onRowClicked={onRowClicked}
          getRowHeight={getRowHeight}
        />
      </div>
    </div>
  );
}

export default AllStudentPortal;
