import React, { useEffect, useState, useContext } from "react";
import "./CreateForum.css";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
import Select from "react-select";
import SearchIcon from "assets/image-components/SearchIcon";
import { USER_URL } from "modules/user-management/constants/user-urls";
import { axiosService } from "services/axios.service";
import sessionstorageService from "services/sessionstorage.service";
import { FORUM_DISCUSSION_URLS } from "../../constants/forum-discussion-urls";
import { toast } from "react-toastify";
import { FORUM_DISCUSSION_CONSTANTS } from "../../constants/forum-discussion-constants";
import { ThemeContext } from "ThemeContext";

function CreateForum({ open, onClose }) {
  const [moderator, setModerator] = useState(null);
  const [moderatorOptions, setModeratorOptions] = useState([]);
  const [description, setDescription] = useState(null);
  const [title, setTitle] = useState(null);
  const userData = sessionstorageService.getUserData("userData");
  const { theme, toggleTheme } = useContext(ThemeContext);

  useEffect(() => {
    const roleName = "student";
    axiosService
      .get(`${USER_URL.GET_USER_BY_ROLENAME}?roleName=${roleName}`)
      .then((response) => {
        const data = response?.data?.data;
        const options = data
          ?.filter((datas) => datas.userId !== userData?.userId)
          ?.map((datas) => ({
            value:
              datas.firstName +
              " " +
              (datas.lastName !== undefined ? datas.lastName : ""),
            label:
              datas.firstName +
              " " +
              (datas.lastName !== undefined ? datas.lastName : ""),
            receivingObject: datas,
          }));
        if (options) {
          setModeratorOptions(options);
        }
      });
  }, []);

  const onSubmit = async () => {
    try {
      let sendData = {
        forumName: title,
        forumDescription: description,
        forumModerator: { userId: moderator?.userId },
      };
      const response = await axiosService.post(
        `${FORUM_DISCUSSION_URLS.CREATE_NEW_FORUM}`,
        sendData
      );
      if (response.status === 200) {
        toast.success(FORUM_DISCUSSION_CONSTANTS.CREATE_FORUM_SUCCESS);
        onClose();
      } else {
      }
    } catch (error) {}
  };

  return (
    <Modal
      className="crm-modal"
      isOpen={open}
      onRequestClose={() => onClose()}
      shouldReturnFocusAfterClose={false}
    >
      <div className="modal-Con">
        <div
          className="col-11 col-md-5 p-3"
          style={{
            height: "auto",
            backgroundColor: theme === "light" ? "#ffffff" : "#212226",
          }}
        >
          <div className="new-conversation-popup-header">
            <span className="new-conversation-popup-title-text">
              Create New Forum
            </span>
            <span
              className="d-flex justify-content-end align-items-center"
              onClick={onClose}
              style={{ cursor: "pointer" }}
            >
              <IoClose
                color={theme === "light" ? "#000000" : "#ffffff"}
                size={"22px"}
              />
            </span>
          </div>
          <div className="d-flex flex-column gap-2 mt-2">
            <div>
              <label className="new-conversation-popup-label">
                Forum Title *
              </label>

              <input
                value={title}
                onChange={(event) => {
                  setTitle(event.target.value);
                }}
                className="new-conversation-popup-input"
              />
            </div>
            <div>
              <label className="new-conversation-popup-label">
                Forum Description *{" "}
                <span className="new-conversation-popup-label-small">
                  (max 250 chars)
                </span>
              </label>

              <textarea
                value={description}
                onChange={(event) => {
                  setDescription(event.target.value);
                }}
                maxLength={250}
                className="new-conversation-popup-textare-input custom-scrollbar"
              />
            </div>
            <div>
              <label className="new-conversation-popup-label">
                Forum Moderator *
              </label>

              <Select
                noOptionsMessage={() => "No Student found"}
                onChange={(option) => {
                  setModerator(option.receivingObject);
                }}
                options={moderatorOptions}
                isSearchable={true}
                classNamePrefix="react-select"
                components={{
                  DropdownIndicator: () => <SearchIcon />,
                  IndicatorSeparator: null,
                }}
                placeholder="Search Student"
                menuPlacement="auto"
                menuPosition="fixed"
              />
            </div>
            <div className="d-flex gap-3 mt-2">
              <button
                className="communication-management-cancel-button"
                onClick={onClose}
              >
                Close
              </button>
              <button
                disabled={!moderator || !title || !description}
                className={`communication-management-submit-button ${
                  (!moderator || !title || !description) && "opacity-50"
                }`}
                onClick={() => onSubmit()}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CreateForum;
