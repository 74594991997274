import { ErrorMessage, Field, Form, Formik } from "formik";
import { PAYMENT_URL } from "modules/pre-onboarding/components/applications/payment-urls";
import { STUDENT_PORTAL_SERVICE_CONSTANTS } from "modules/student-portal//constants/student-portal-constants";
import { STUDENT_PORTAL_SERVICE_URL } from "modules/student-portal/constants/student-portal-urls";
import React, { useContext, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { BiCheck } from "react-icons/bi";
import { FaEye } from "react-icons/fa";
import { RxTriangleDown } from "react-icons/rx";
import Modal from "react-modal";
import Select from "react-select";
import { toast } from "react-toastify";
import { axiosService } from "services/axios.service";
import * as Yup from "yup";
import "./AddStudentPortalService.css";
import { IoClose } from "react-icons/io5";
import { ThemeContext } from "ThemeContext";

function AddStudentPortalService({ open, onClose, selectedServiceId }) {
  const [selectedServiceType, setSelectedServiceType] = useState(null);
  const [selectedHostalType, setSelectedHostalType] = useState(null);
  const [selectedPaymentType, setSelectedPaymentType] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [admissionData, setAdmissionData] = useState();
  const [serviceData] = useState(null);

  const { theme, toggleTheme } = useContext(ThemeContext);

  const initialValues = {
    serviceType: "",
    requestDate: new Date().toISOString().split("T")[0],
    pickUpDate: "",
    flightLandingTime: "",
    airportName: "",
    airportTerminal: "",
    comments: "",
    checkInDate: "",
    stayDuration: "",
    paymentReceipt: "",
    paymentReceiptName: "",
  };

  const serviceType = [
    { value: "AIRPORTMEETANDGREET", label: "Airport Meet and Greet" },
    { value: "ACCOMMODATION", label: "Hostel/Accommodation" },
    { value: "HEALTHINSURANCE", label: "Health Insurance" },
  ];

  const validationSchema = (values) => {
    const commonSchema = Yup.object().shape({
      serviceType: Yup.string().required("Service type is required"),
      comments: Yup.string().required("Comments is required"),
    });

    if (selectedServiceType === "AIRPORTMEETANDGREET") {
      return commonSchema.shape({
        pickUpDate: Yup.string().required("Pick-up date is required"),
        flightLandingTime: Yup.string().required(
          "Flight landing time is required"
        ),
        airportName: Yup.string().required("Airport name is required"),
        airportTerminal: Yup.string().required("Airport terminal is required"),
        comments: Yup.string().required("Comments is required"),
      });
    } else if (selectedServiceType === "ACCOMMODATION") {
      return commonSchema.shape({
        checkInDate: Yup.string().required("Check-in date is required"),
        stayDuration: Yup.string().required("Stay duration is required"),
        comments: Yup.string().required("Comments is required"),
      });
    } else if (selectedServiceType === "HEALTHINSURANCE") {
      return commonSchema.shape({
        paymentReceipt: Yup.string().required("Payment receipt is required"),
        comments: Yup.string().required("Comments is required"),
      });
    }
    return commonSchema;
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: theme === "light" ? "#EBEBEB" : "#212226 !important",
    }),
  };

  const onSubmit = async (values) => {
    try {
      const requestData = {
        serviceType: values?.serviceType,
        requestDate: values?.requestDate,
        pickUpDate: values?.pickUpDate,
        flightLandingTime: values?.flightLandingTime,
        airportName: values?.airportName,
        airportTerminal: values?.airportTerminal,
        comments: values?.comments,
        checkInDate: values?.checkInDate,
        stayDuration: values?.stayDuration,
        paymentReceipt: values?.paymentReceipt,
        paymentReceiptName: values?.paymentReceiptName,
      };

      const response = await axiosService.post(
        `${STUDENT_PORTAL_SERVICE_URL.POST_SERVICE_URL}`,
        requestData
      );
      if (response.status === 200) {
        toast.success(STUDENT_PORTAL_SERVICE_CONSTANTS.SUCCESS);
        onClose();
        reset();
      } else {
        toast.error(`Error: ${response.status} - ${response.data}`);
      }
    } catch (error) {
      toast.error("All fields are the mandatory");
    }
  };

  const reset = () => {
    setSelectedServiceType("");
    setSelectedPaymentType("");
    setSelectedHostalType("");
  };

  useEffect(() => {
    if (selectedServiceId) {
      axiosService
        .get(
          `${STUDENT_PORTAL_SERVICE_URL.POST_SERVICE_URL}/${selectedServiceId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setAdmissionData(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
      setAdmissionData(null);
    }
  }, [selectedServiceId]);

  return (
    <Modal
      className="crm-modal flex-end"
      isOpen={open}
      onRequestClose={(values) => {
        onClose(values);
        reset();
      }}
      shouldFocusAfterRender={false}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      <div className="modal-Con justify-content-lg-end">
        <div
          className="col-11 col-md-7 col-lg-auto p-3 custom-scrollbar"
          style={{
            maxHeight: "90vh",
            backgroundColor: theme === "light" ? "#ffffff" : "#212226",
            overflow: "auto",
          }}
        >
          <Formik
            validationSchema={validationSchema}
            initialValues={serviceData || initialValues}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {({
              setFieldValue,
              values,
              handleChange,
              handleBlur,
              touched,
              errors,
              resetForm,
            }) => (
              <Form>
                <div className="d-flex flex-column ">
                  <div className="asps-modal-top">
                    <span className="asps-modal-title">Request a Service</span>
                    <div>
                      <IoClose
                        // color="#ffffff"
                        color={theme === "light" ? "#000000" : "#FFFFFF"}
                        size={"23px"}
                        onClick={() => {
                          onClose();
                          reset();
                        }}
                      />
                    </div>
                  </div>
                  <div className="asps-service-selections flex-wrap gap-3 ">
                    <div className="asps-service-type">
                      <label>Service Type *</label>
                      <Select
                        name="serviceType"
                        className="asps-service-type-select"
                        options={serviceType}
                        isSearchable={false}
                        styles={selectStyles}
                        classNamePrefix="react-select"
                        components={{
                          DropdownIndicator: () => (
                            <RxTriangleDown
                              color={theme === "light" ? "#000000" : "#ffffff"}
                            />
                          ),
                          IndicatorSeparator: null,
                        }}
                        placeholder="Choose a Type"
                        menuPlacement="auto"
                        menuPosition="fixed"
                        onChange={(option) => {
                          resetForm();
                          setSelectedServiceType(option.value);
                          setSelectedHostalType(option.value);
                          setSelectedPaymentType(option.value);
                          setFieldValue("serviceType", option.value);
                        }}
                      />
                      <ErrorMessage
                        name="serviceType"
                        component="div"
                        className="sp-service-servicetype-error-message"
                      />
                    </div>
                    <div className="asps-date-of-request">
                      <label>Date of Request </label>
                      <Field
                        name="requestDate"
                        type="date"
                        className="asps-date-of-request-field"
                        placeholder="yyyy/mm/dd"
                        readOnly
                      />
                    </div>
                  </div>
                  {selectedServiceType === "AIRPORTMEETANDGREET" && (
                    <div className="asps-Airport-meet ">
                      <div className="asps-date-time flex-wrap gap-3">
                        <div className="asps-date-of-request">
                          <label>Date of Pickup *</label>
                          <Field
                            name="pickUpDate"
                            type="date"
                            className="asps-pickUpDate-field"
                          />
                          <ErrorMessage
                            name="pickUpDate"
                            component="div"
                            className="sp-service-pickupDate-error-message"
                          />
                        </div>
                        <div className="asps-flight-time">
                          <label>Flight Landig Time *</label>
                          <Field
                            type="datetime-local"
                            name="flightLandingTime"
                            className="asps-flight-time-field"
                          />
                          <ErrorMessage
                            name="flightLandingTime"
                            component="div"
                            className="sp-service-flight-error-message"
                          />
                        </div>
                      </div>
                      <div className="asps-name-terminal flex-wrap gap-3">
                        <div className="asps-date-of-request">
                          <label>Airport Name *</label>
                          <Field
                            className="asps-airportname-field"
                            name="airportName"
                            placeholder="select name"
                          />
                          <ErrorMessage
                            name="airportName"
                            component="div"
                            className="sp-service-airport-error-message"
                          />
                        </div>
                        <div className="asps-flight-time">
                          <label>Airport Terminal *</label>
                          <Field
                            className="asps-flight-time-field"
                            placeholder="Select Time"
                            name="airportTerminal"
                          />
                          <ErrorMessage
                            name="airportTerminal"
                            component="div"
                            className="sp-service-terminal-error-message"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedHostalType === "ACCOMMODATION" && (
                    <div className="asps-reuqest-service flex-wrap gap-3">
                      <div className="asps-date-check">
                        <label>Date of Check-in *</label>
                        <Field
                          name="checkInDate"
                          type="date"
                          className="asps-date-check-field"
                          placeholder="select name"
                        />
                        <ErrorMessage
                          name="checkInDate"
                          component="div"
                          className="sp-service-datecheck-error-message"
                        />
                      </div>
                      <div className="asps-duration">
                        <label>
                          Duration Stay *<span>(in days)</span>
                        </label>
                        <Field
                          name="stayDuration"
                          className="asps-duration-field"
                          placeholder="Enter duration"
                          onChange={(e) => {
                            const numericValue = e.target.value.replace(
                              /\D/,
                              ""
                            );
                            handleChange("stayDuration")(numericValue);
                          }}
                          onBlur={handleBlur("stayDuration")}
                          value={values.stayDuration}
                        />
                        <ErrorMessage
                          name="stayDuration"
                          component="div"
                          className="sp-service-duration-error-message"
                        />
                      </div>
                    </div>
                  )}
                  {selectedPaymentType === "HEALTHINSURANCE" && (
                    <div className="asps-health-insurance flex-wrap gap-3">
                      <label>Upload Document *</label>
                      <div
                        name="paymentReceipt"
                        className="asps-document-upload-field"
                        style={{ width: "100%" }}
                      >
                        <Dropzone
                          onDrop={async (acceptedFiles) => {
                            const sendFile = acceptedFiles[0];
                            setUploadLoading(true);

                            if (sendFile) {
                              try {
                                const formData = new FormData();
                                formData.append("file", sendFile);
                                const response = await axiosService.postFile(
                                  `${PAYMENT_URL.PAYMENT_UPLOAD_URL}`,
                                  sendFile,
                                  true
                                );
                                if (response.status === 200) {
                                  setUploadLoading(false);
                                  setFieldValue(
                                    "paymentReceipt",
                                    response.data.data
                                  );
                                  setFieldValue(
                                    "paymentReceiptName",
                                    sendFile.name
                                  );
                                  setUploadLoading(false);
                                }
                              } catch (error) {
                                setUploadLoading(false);
                                if (error.response) {
                                  toast.error(
                                    `${error.response.data.message
                                      .split(":")[1]
                                      ?.trim()}`
                                  );
                                }
                              }
                            } else {
                              toast.error("Please select a file to upload.");
                              setUploadLoading(false);
                            }
                          }}
                          accept="image/*, application/pdf"
                        >
                          {({ getRootProps, getInputProps, isDragActive }) => (
                            <div
                              {...getRootProps()}
                              className={`asps-upload-input${
                                isDragActive ? "drag-active" : ""
                              }`}
                            >
                              <input
                                {...getInputProps()}
                                className="d-none"
                                type="file"
                                id="upload"
                                name="paymentReceipt"
                              />
                              {values.paymentReceiptName ||
                              admissionData?.paymentReceipt ? (
                                <span className="asps-service-text-white">
                                  {values.paymentReceiptName ||
                                    admissionData?.paymentReceipt}
                                </span>
                              ) : (
                                "No file chosen"
                              )}
                              <div className="create-list-upload-input-choose">
                                Choose File
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        {(admissionData?.paymentReceipt ||
                          values.paymentReceiptName) &&
                        !uploadLoading ? (
                          <BiCheck
                            color="green"
                            size={27}
                            height={30}
                            style={{
                              position: "absolute",
                              right: "30px",
                              top: "1px",
                            }}
                          />
                        ) : null}
                        {uploadLoading && (
                          <div
                            class="lds-ring"
                            style={{
                              position: "absolute",
                              right: "-10px",
                            }}
                          >
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        )}
                        {(values.paymentReceipt ||
                          admissionData?.paymentReceipt) && (
                          <a
                            style={{
                              position: "absolute",
                              right: "5px",
                              top: "5px",
                            }}
                            href={
                              values.paymentReceipt ||
                              admissionData?.paymentReceipt
                            }
                            target="_blank"
                            rel="noreferrer"
                            className="view-eye-icon"
                          >
                            <FaEye />
                          </a>
                        )}
                      </div>
                      {!values.paymentReceipt && (
                        <ErrorMessage
                          name="paymentReceipt"
                          component="div"
                          className="sp-service-reception-error-message"
                        />
                      )}
                    </div>
                  )}
                  <div className="asps-airport-field">
                    <label>
                      Comments/Queries if any *{" "}
                      <span>(max limit 250 chars)</span>
                    </label>
                    <Field
                      as="textarea"
                      className="asps-airport-date-field w-100"
                      maxLength={250}
                      name="comments"
                    />
                    <ErrorMessage
                      name="comments"
                      component="div"
                      className="sp-service-comments-error-message"
                    />
                  </div>
                  <div className="asps-all-buttons d-flex gap-3">
                    <button
                      className="communication-management-cancel-button"
                      type="button"
                      onClick={() => {
                        onClose();
                        reset();
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="communication-management-submit-button"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
}
export default AddStudentPortalService;
