import React, { useContext, useEffect, useState } from "react";
import "./QueryChatList.css";
import SearchIcon from "assets/image-components/SearchIcon";
import { QUERY_FEEDBACK_URLS } from "../../constants/query-feedback-urls";
import sessionstorageService from "services/sessionstorage.service";
import { axiosService } from "services/axios.service";
import { useLocation, useNavigate } from "react-router-dom";
import useravatar from "assets/images/user-avatar.svg";
import useravatarblack from "assets/images/user-avatar-black.svg";
import { ThemeContext } from "ThemeContext";

function QueryChatList({
  setChatId,
  refresh,
  chatId,
  viewQuery,
  closedUpdateRefresh,
}) {
  const userData = sessionstorageService.getUserData("userData");
  const [chatListData, setChatListData] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (viewQuery === "open") {
      axiosService
        .get(
          `${QUERY_FEEDBACK_URLS.GET_OPENED_CHAT_LIST_BY_ID}${userData?.userId}`
        )
        .then((response) => {
          const data = response?.data?.data.reverse();
          setChatId(data[0]?.chatId);
          setChatListData(data);
        });
    } else {
      axiosService
        .get(
          `${QUERY_FEEDBACK_URLS.GET_CLOSED_CHAT_LIST_BY_ID}${userData?.userId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setChatId(data[0]?.chatId);
          setChatListData(data);
        });
    }
  }, [location.pathname, refresh, viewQuery, closedUpdateRefresh]);

  const getCurrentTime = () => new Date();
  const { theme, toggleTheme } = useContext(ThemeContext);
  const calculateTimeDifference = (timestamp) => {
    const currentTime = getCurrentTime();
    const timeDifference = Math.floor(
      (currentTime - new Date(timestamp)) / (1000 * 60)
    );

    if (timeDifference < 1) {
      return "Just now";
    } else if (timeDifference < 60) {
      return `${timeDifference} minute${timeDifference > 1 ? "s" : ""} ago`;
    } else if (timeDifference < 1440) {
      const hours = Math.floor(timeDifference / 60);
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else {
      const days = Math.floor(timeDifference / 1440);
      return `${days} day${days > 1 ? "s" : ""} ago`;
    }
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const filteredChatList = chatListData?.filter(
    (data) =>
      data.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (data.receiver?.userId !== userData?.userId &&
        (data.receiver?.firstName + " " + (data.receiver?.lastName || ""))
          .toLowerCase()
          .includes(searchQuery.toLowerCase())) ||
      (data.sender?.userId !== userData?.userId &&
        (data.sender?.firstName + " " + (data.sender?.lastName || ""))
          .toLowerCase()
          .includes(searchQuery.toLowerCase()))
  );

  const navigate = useNavigate();
  return (
    <>
      <div className="d-none d-md-block w-100">
        <div className="position-relative d-flex justify-content-center align-items-center py-2">
          <input
            className="query-chatlist-search-input"
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
          <span className="query-chatlist-search-input-icon">
            <SearchIcon color={"rgba(96, 96, 96, 1)"} />
          </span>
        </div>
        {filteredChatList?.map((data) => (
          <div
            className="chat-list-container"
            onClick={() => {
              setChatId(data?.chatId);
            }}
          >
            <div className="chat-list-inner-container ">
              <img
                alt="img"
                src={
                  (data?.receiver?.userId !== userData?.userId
                    ? data?.receiver?.profileImage
                    : data?.sender?.profileImage) || theme === "light"
                    ? useravatarblack
                    : useravatar
                }
                className={`chat-list-profile-image ${
                  chatId === data?.chatId && "chat-active-border"
                }`}
              />
              <div className="chat-list-message-name-container">
                <span
                  className={`chat-list-name-title-text ${
                    chatId === data?.chatId && "chat-active-text"
                  }`}
                >
                  {data?.receiver?.userId !== userData?.userId
                    ? data?.receiver?.firstName +
                      " " +
                      (data?.receiver?.lastName || "")
                    : data?.sender?.firstName +
                      " " +
                      (data?.sender?.lastName || "")}
                </span>
                <span
                  className={`chat-list-message-text  ${
                    chatId === data?.chatId && "chat-active-text"
                  }`}
                >
                  {data?.description}
                </span>
              </div>
            </div>
            <div className="chat-list-last-message-text">
              {calculateTimeDifference(data?.updatedOn)}
            </div>
          </div>
        ))}
      </div>
      <div className="d-md-none w-100">
        <p className="open-convo-text pt-2 pb-1">
          {viewQuery === "open" ? "Open Conversations" : "Closed Conversations"}
        </p>
        <div className="position-relative d-flex justify-content-center align-items-center ">
          <input
            className="query-chatlist-search-input"
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
          <span className="query-chatlist-search-input-icon">
            <SearchIcon color={"rgba(96, 96, 96, 1)"} />
          </span>
        </div>

        <p className="tap-to-open-text">Tap on a chat to open</p>
        <div className="custom-scrollbar" style={{ paddingBottom: "50px" }}>
          {filteredChatList?.map((data) => (
            <div
              className="chat-list-container"
              onClick={() => {
                navigate("chat", {
                  state: {
                    chatId: data?.chatId,
                  },
                });
              }}
            >
              <div className="chat-list-inner-container ">
                <img
                  alt="img"
                  src={
                    (data?.receiver?.userId !== userData?.userId
                      ? data?.receiver?.profileImage
                      : data?.sender?.profileImage) || theme === "light"
                      ? useravatarblack
                      : useravatar
                  }
                  className={`chat-list-profile-image `}
                />
                <div className="chat-list-message-name-container">
                  <span className={`chat-list-name-title-text `}>
                    {data?.receiver?.userId !== userData?.userId
                      ? data?.receiver?.firstName +
                        " " +
                        (data?.receiver?.lastName || "")
                      : data?.sender?.firstName +
                        " " +
                        (data?.sender?.lastName || "")}
                  </span>
                  <span className={`chat-list-message-text  `}>
                    {data?.description}
                  </span>
                </div>
              </div>
              <div className="chat-list-last-message-text">
                {calculateTimeDifference(data?.updatedOn)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default QueryChatList;
