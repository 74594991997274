import useravatar from "../../../../../assets/images/user-avatar.svg";
import useravatarblack from "../../../../../assets/images/user-avatar-black.svg";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosService } from "services/axios.service";
import sessionstorageService from "services/sessionstorage.service";
import Swal from "sweetalert2";
import { FORUM_DISCUSSION_CONSTANTS } from "../../constants/forum-discussion-constants";
import { FORUM_DISCUSSION_URLS } from "../../constants/forum-discussion-urls";
import CreateDiscussion from "../create-discussion-popup/CreateDiscussion";
import { ThemeContext } from "ThemeContext";

function ViewForumDiscussion() {
  const navigation = useNavigate();
  const location = useLocation();
  const forumId = location?.state?.forumId;
  const [forumData, setForumData] = useState([]);
  const [openCreateDiscussion, setOpenCreateDiscussion] = useState(false);
  const userData = sessionstorageService.getUserData("userData");
  const [showDeleteButton, setShowDeleteButton] = useState(false);

  const { theme, toggleTheme } = useContext(ThemeContext);

  useEffect(() => {
    axiosService
      .get(`${FORUM_DISCUSSION_URLS.GET_FORUM_BY_FORUMID}${forumId}`)
      .then((response) => {
        const data = response?.data?.data;
        setForumData(data);
        if (userData?.userId === data?.forumModerator?.userId) {
          setShowDeleteButton(true);
        }
      });
  }, [openCreateDiscussion]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };

    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  function hasPermission(permission) {
    let userPermissions =
      sessionstorageService.hasPermissionByButton(permission);
    return userPermissions;
  }

  const handleDeleteForum = (forumId) => {
    Swal.fire({
      title: "Confirm Delete",
      text: "Are you sure you want to delete  the forum and all the discussions inside the forum.This action cannot be reversed. Do you want to delete ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete",
      cancelButtonText: "No, cancel",
      customClass: {
        popup: "my-custom-class",
        content: "custom-swal-text",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axiosService
          .delete(`${FORUM_DISCUSSION_URLS.DELETE_FORUM}${forumId}`)
          .then((response) => {
            if (response.status === 200) {
              toast.success(FORUM_DISCUSSION_CONSTANTS.DELETE_SUCCESS);
              navigation("/communication-management/forums");
            } else {
              toast.error(FORUM_DISCUSSION_CONSTANTS.DELETE_FAILURE);
            }
          })
          .catch((error) => {
            toast.error(FORUM_DISCUSSION_CONSTANTS.FAILED);
          });
      }
    });
  };
  return (
    <div className="communication-management-main-container custom-scrollbar">
      <div className="communication-management-head-container sticky-header-communication flex-row gap-2">
        <div className="d-flex align-items-center gap-3">
          <span className="communication-management-title-text">
            Forum/Discussion
          </span>
        </div>
        <div className="d-flex align-items-center gap-3">
          {showDeleteButton && (
            <button
              className="communication-delete-head-button"
              onClick={() => handleDeleteForum(forumId)}
            >
              Delete Forum
            </button>
          )}
          {hasPermission("Discussions: create") && (
            <button
              className="communication-save-head-button"
              onClick={() => setOpenCreateDiscussion(true)}
            >
              Create New Discussion
            </button>
          )}
        </div>
      </div>
      <div className="forum-discussion-main-container flex-column flex-lg-row">
        <div className="view-forum-discussion-left-container custom-scrollbar">
          <div className="forum-accordian-container">
            <div className="forum-accordian-title-container">
              <span className="forum-accordian-title-text">
                {forumData?.forumName}
              </span>
            </div>
            <div className="forum-discription-text">
              {forumData?.forumDescription}
            </div>
            <div className={` ${"accordian-open h-100 custom-scrollbar"}`}>
              <table
                className="forum-table d-none d-lg-table"
                style={{ height: "auto" }}
              >
                <thead style={{ height: "40px" }}>
                  <tr className="forum-table-header">
                    <th className="forum-table-header-text forum-table-header-topic">
                      Topic
                    </th>
                    <th className="forum-table-header-text forum-table-header-Replies">
                      Replies
                    </th>
                    {/* <th className="forum-table-header-text forum-table-header-Views">
                      Views
                    </th> */}
                    <th className="forum-table-header-text forum-table-header-Latest">
                      Latest
                    </th>
                  </tr>
                </thead>

                {forumData?.discussions
                  ?.slice()
                  .reverse()
                  ?.map((discussion) => (
                    <tbody>
                      <tr style={{ height: "40px" }}>
                        <td className="forum-td forum-topic-td">
                          <button
                            className="forum-topic-inner-td-line-1"
                            onClick={() =>
                              navigation(
                                "/communication-management/view-discussion",
                                {
                                  state: {
                                    discussionId: discussion?.discussionId,
                                  },
                                }
                              )
                            }
                          >
                            {discussion?.title}
                          </button>
                          <span className="forum-topic-inner-td-line-2">
                            <span className="forum-topic-poster-name">
                              {discussion?.createdUser?.firstName}{" "}
                              {discussion?.createdUser?.lastName}
                            </span>
                            <span className="forum-topic-poster-date">
                              {formatDate(discussion?.addedOn)}
                            </span>
                          </span>
                        </td>
                        <td className="forum-td forum-td-text">
                          {" "}
                          {discussion?.posts?.length}{" "}
                        </td>
                        {/* <td className="forum-td forum-td-text">
                          {discussion?.views}
                        </td> */}
                        <td className="forum-td">
                          <div className="forum-td-latest">
                            <img
                              src={discussion?.latestuser?.user1 || useravatar}
                              className="forum-td-latest-user-icon-1"
                            />
                            <img
                              src={discussion?.latestuser?.user2 || useravatar}
                              className="forum-td-latest-user-icon-2"
                            />
                            <img
                              src={discussion?.latestuser?.user3 || useravatar}
                              className="forum-td-latest-user-icon-3"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ))}
              </table>
              {forumData?.discussions
                ?.slice()
                .reverse()
                ?.map((discussion, index) => (
                  <div className="forum-table-card-container-mobile d-block d-lg-none">
                    {index === 0 && (
                      <p className="forum-discussion-table-head-mobile m-0 mb-1">
                        Discussions
                      </p>
                    )}
                    <span className="forum-discussion-title-mobile">
                      {discussion?.title}
                    </span>
                    <div className="d-flex justify-content-between">
                      <span className="forum-discussion-poster-mobile">
                        {" "}
                        {discussion?.createdUser?.firstName}{" "}
                        {discussion?.createdUser?.lastName}
                      </span>
                      <span className="forum-discussion-post-time">
                        {formatDate(discussion?.addedOn)}
                      </span>
                    </div>
                    <div className="d-flex gap-5 mt-3">
                      <div className="d-flex flex-column gap-2">
                        <span className="forum-discussion-table-head-mobile">
                          Replies
                        </span>
                        <span className="forum-discussion-table-data-mobile">
                          {discussion?.posts?.length || 0}
                        </span>
                      </div>

                      <div className="d-flex flex-column gap-2 ps-2">
                        <span className="forum-discussion-table-head-mobile">
                          Latest
                        </span>
                        <div className="forum-td-latest ps-0 text-white">
                          {discussion?.posts?.length > 0
                            ? discussion?.posts
                                ?.slice(0, 3)
                                .reverse()
                                .map((post, index) => (
                                  <img
                                    key={index}
                                    alt="img"
                                    src={post.user?.profileImage || useravatar}
                                    className={`forum-td-latest-user-icon-${
                                      index + 1
                                    }`}
                                  />
                                ))
                            : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              {forumData?.discussions?.length === 0 && (
                <div className="no-discussion-show-text">
                  No discussion to display
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="view-forum-discussion-right-container  custom-scrollbar">
          <div className="forum-discussion-online-staff-container">
            <div className="forum-discussion-online-staff-head">
              Forum Moderator
            </div>
            <div className="forum-discussion-online-staff-profile-container">
              <span className="discussion-poster-container">
                <img
                  src={
                    forumData?.forumModerator?.profileImage || theme === "light"
                      ? useravatarblack
                      : useravatar
                  }
                  className="poster-or-staff-profile-image"
                />
                <span className="discussion-poster-staff-name-container">
                  <div className="forum-online-staff-profile-name">
                    {forumData?.forumModerator?.firstName}{" "}
                    {forumData?.forumModerator?.lastName}
                  </div>
                  <div className="forum-online-staff-profile-designation">
                    {forumData?.forumModerator?.role?.name}
                  </div>
                </span>
              </span>
              <button className="forum-online-staff-profile-button">
                forum-moderator
              </button>
            </div>
          </div>
          <div className="forum-discussion-online-staff-container">
            <div className="forum-discussion-online-staff-head">
              Forum Stats
            </div>
            <div className="forum-discussion-stats-text-container">
              <span className="forum-stats-label">Total Discussions</span>
              <span className="forum-stats-value">
                {forumData?.discussions?.length}
              </span>
            </div>
          </div>
        </div>
      </div>

      {openCreateDiscussion && (
        <CreateDiscussion
          open={openCreateDiscussion}
          forumId={forumId}
          onClose={() => setOpenCreateDiscussion(false)}
        />
      )}
    </div>
  );
}

export default ViewForumDiscussion;
