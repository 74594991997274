import "./AddFeePlan.css";
import { Field, Formik, Form } from "formik";
import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { axiosService } from "services/axios.service";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
import { RxTriangleDown } from "react-icons/rx";
import Select from "react-select";
import { ADMISSION_URL_FOR_STUDENT } from "modules/pre-onboarding/components/constants/admission-application-student-urls";
import { FEE_URLS } from "modules/fee-finance/constants/fee-finance-urls";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { ThemeContext } from "ThemeContext";

function AddFeePlan({ open, onClose, feeMasterId, modalType }) {
  const location = useLocation();
  const [diplomaOptions, setDiplomaOptions] = useState([]);
  const [campusOptions, setCampusOptions] = useState(null);
  const [programEdit, setProgramEdit] = useState("");
  const [campusEdit, setCampusEdit] = useState("");
  const [editData, setEditData] = useState("");
  const { theme, toggleTheme } = useContext(ThemeContext);

  useEffect(() => {
    if (feeMasterId) {
      axiosService
        .get(`${FEE_URLS.GET_FEE_BY_FEEMASTER_ID}${feeMasterId}`)
        .then((response) => {
          const responseData = response?.data?.data;
          setEditData(responseData);
          getCampusOptions(responseData?.programFeeMaster?.program);
          setCampusEdit(responseData?.programFeeMaster?.campus);
          setProgramEdit(responseData?.programFeeMaster?.program);
        });
    }
  }, [location.pathname, feeMasterId]);

  useEffect(() => {
    const fetchDiplomaOptions = async () => {
      axiosService
        .get(`${ADMISSION_URL_FOR_STUDENT.GET_PROGRAM_OPTIONS}`)
        .then((response) => {
          setDiplomaOptions(response.data.data);
        });
    };

    fetchDiplomaOptions();
  }, []);

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "0 !important",
      width: "100% !important",
    }),
  };

  const transformCampusLabel = (label) => {
    if (label === "BRAMPTONANDMISSISSAUGA") {
      return "Both";
    }
    return label.charAt(0) + label.slice(1).toLowerCase();
  };
  const getCampusOptions = (programValue) => {
    axiosService
      .getForUrl(`${FEE_URLS.GET_ALL_CAMPUS_BY_PROGRAM_NAME}${programValue}`)
      .then((response) => {
        const data = response?.data?.data;
        const options = data.map((campus) => ({
          value: campus.value,
          label: transformCampusLabel(campus.label),
        }));
        setCampusOptions(options);
      })
      .catch((error) => {});
  };
  const initialValues = {
    program: feeMasterId ? programEdit : "",
    campus: feeMasterId ? campusEdit : "",
    applicationFee: feeMasterId ? editData.applicationFee : "",
    tuitionFee: feeMasterId ? editData.tuitionFee : "",
    bursaries: feeMasterId ? editData.bursaries : "",
    materialFee: feeMasterId ? editData.materialFee : "",
    fieldTripsFee: feeMasterId ? editData.fieldTripsFee : "",
    internationalFee: feeMasterId ? editData.internationalFee : "",
    eslFee: feeMasterId ? editData.eslFee : "",
    bankFee: feeMasterId ? editData.bankFee : "",
    otherFee: feeMasterId ? editData.otherFee : "",
    otherFeeDetail: feeMasterId ? editData.otherFeeDetail : "",
  };

  const validationSchema = Yup.object().shape({});
  const onSubmit = async (values) => {
    const numericFields = [
      "applicationFee",
      "tuitionFee",
      "bursaries",
      "materialFee",
      "fieldTripsFee",
      "internationalFee",
      "eslFee",
      "bankFee",
      "otherFee",
    ];

    const requestData = {
      // programFeeMaster: { programFeeMasterId: programFeeMasterId },
      program: values.program,
      campus: values.campus,
    };

    numericFields.forEach((field) => {
      requestData[field] = values[field] ? parseInt(values[field], 10) : 0.0;
    });

    try {
      if (feeMasterId) {
        requestData.feeMasterId = editData.feeMasterId;
        const response = await axiosService.put(
          `${FEE_URLS.ADD_FEE_PLANS}`,
          requestData
        );

        if (response.status === 200) {
          toast.success("Fee updated successfully");
          onClose();
        }
      } else {
        const response = await axiosService.post(
          `${FEE_URLS.ADD_FEE_PLANS}`,
          requestData
        );
        if (response.status === 200) {
          toast.success("Fee Saved Successfully");
          onClose();
        }
      }
    } catch (error) {
      toast.error(`${error?.response?.data?.message.split(":")[1]?.trim()}`);
    }
  };

  return (
    <Modal
      className="crm-modal"
      isOpen={open}
      onRequestClose={(values) => onClose(values)}
      shouldReturnFocusAfterClose={false}
    >
      <div className="modal-Con">
        <div
          className="col-11 col-md-9 px-3 custom-scrollbar"
          style={{
            height: "auto",
            maxHeight: "90vh",
            backgroundColor: theme === "light" ? "#ffffff" : "#212226",
          }}
        >
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues || editData}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <div className="new-conversation-popup-header new-conversation-popup-headerss py-2 sticky-header top-0">
                  <span className="add-news-current-affair-title-text">
                    {modalType === "add"
                      ? " Add Fee Plan"
                      : modalType === "edit"
                      ? "Update Fee Plan"
                      : modalType === "view"
                      ? "View Fee Plan"
                      : null}
                  </span>

                  <span
                    className="d-flex justify-content-center align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    <IoClose
                      color={theme === "light" ? "#000000 " : "#ffffff"}
                      size={"23px"}
                      onClick={() => {
                        onClose();
                      }}
                    />
                  </span>
                </div>

                <div
                  className="py-2"
                  style={{
                    borderBottom: "1px solid #7a7a7a",
                  }}
                >
                  <div className="d-flex flex-column flex-md-row mt-2 align-items-center">
                    <div className="col-12 col-md-8 px-1 p-0 fee-select-con">
                      <label className="label-text-active">
                        Select a Program *
                      </label>

                      <Field
                        name="program"
                        render={({ field, form }) => (
                          <Select
                            id="program"
                            isDisabled={modalType === "view"}
                            value={
                              field.value
                                ? diplomaOptions.find(
                                    (option) => option.value === field.value
                                  )
                                : null
                            }
                            onChange={(option) => {
                              form.setFieldValue(field.name, option.value);
                              form.setFieldTouched(field.name, null);
                              getCampusOptions(option.value);
                              form.setFieldValue("campus", "");
                            }}
                            options={diplomaOptions}
                            isSearchable={false}
                            classNamePrefix="react-select"
                            styles={selectStyles}
                            components={{
                              DropdownIndicator: () => (
                                <RxTriangleDown
                                  color={
                                    theme === "light" ? "#000000" : "#ffffff"
                                  }
                                />
                              ),
                              IndicatorSeparator: null,
                            }}
                            placeholder="Choose an Option"
                            menuPlacement="auto"
                            menuPosition="fixed"
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 col-md-4 px-1 p-0 fee-select-con">
                      <label className="label-text-active">Campus *</label>
                      <Field
                        name="campus"
                        render={({ field, form }) => (
                          <Select
                            isDisabled={
                              modalType === "view" || !form.values.program
                            }
                            defaultValue={field.value}
                            value={
                              field.value
                                ? campusOptions?.find(
                                    (option) => option.value === field.value
                                  )
                                : null
                            }
                            onChange={(option) => {
                              form.setFieldValue(field.name, option.value);
                            }}
                            options={campusOptions}
                            isSearchable={false}
                            classNamePrefix="react-select"
                            styles={selectStyles}
                            components={{
                              DropdownIndicator: () => (
                                <RxTriangleDown
                                  color={
                                    theme === "light" ? "#000000" : "#ffffff"
                                  }
                                />
                              ),
                              IndicatorSeparator: null,
                            }}
                            placeholder="Choose "
                            menuPlacement="auto"
                            menuPosition="fixed"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-column flex-md-row mt-2 align-items-center">
                    <div className="col-12 col-md-4 px-1 p-0">
                      <label className="label-text-active">
                        Application Fee
                      </label>
                      <span className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <Field
                          disabled={modalType === "view"}
                          name="applicationFee"
                          className="form-control input-group create-financial-input-small"
                        />
                      </span>
                    </div>
                    <div className="col-12 col-md-4 px-1 p-0">
                      <label className="label-text-active">Tuition Fee</label>
                      <span className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <Field
                          disabled={modalType === "view"}
                          name="tuitionFee"
                          className="form-control input-group create-financial-input-small"
                        />
                      </span>
                    </div>
                    <div className="col-12 col-md-4 px-1 p-0">
                      <label className="label-text-active">Bursaries</label>
                      <span className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <Field
                          disabled={modalType === "view"}
                          name="bursaries"
                          className="form-control input-group create-financial-input-small"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-column flex-md-row mt-2 align-items-center">
                    <div className="col-12 col-md-4 px-1 p-0">
                      <label className="label-text-active">
                        Material/Books Fee
                      </label>
                      <span className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <Field
                          disabled={modalType === "view"}
                          name="materialFee"
                          className="form-control input-group create-financial-input-small"
                        />
                      </span>
                    </div>
                    <div className="col-12 col-md-4 px-1 p-0">
                      <label className="label-text-active">
                        Field Trips Fee
                      </label>
                      <span className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <Field
                          disabled={modalType === "view"}
                          name="fieldTripsFee"
                          className="form-control input-group create-financial-input-small"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-column flex-md-row mt-2 align-items-center">
                    <div className="col-12 col-md-4 px-1 p-0">
                      <label className="label-text-active">
                        International Fee
                      </label>
                      <span className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <Field
                          disabled={modalType === "view"}
                          name="internationalFee"
                          className="form-control input-group create-financial-input-small"
                        />
                      </span>
                    </div>
                    <div className="col-12 col-md-4 px-1 p-0">
                      <label className="label-text-active">ELSIS/ESL Fee</label>
                      <span className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <Field
                          disabled={modalType === "view"}
                          name="eslFee"
                          className="form-control input-group create-financial-input-small"
                        />
                      </span>
                    </div>
                    <div className="col-12 col-md-4 px-1 p-0">
                      <label className="label-text-active">Bank Fee</label>
                      <span className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <Field
                          disabled={modalType === "view"}
                          name="bankFee"
                          className="form-control input-group create-financial-input-small"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-column flex-md-row mt-2 align-items-center">
                    <div className="col-12 col-md-4 px-1 p-0">
                      <label className="label-text-active">
                        Other Compilsory Fee
                      </label>
                      <span className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <Field
                          disabled={modalType === "view"}
                          name="otherFee"
                          className="form-control input-group create-financial-input-small"
                        />
                      </span>
                    </div>

                    <div className="col-12 col-md-8 px-1 p-0">
                      <label className="label-text-active">
                        Other Fee Detail
                      </label>
                      <Field
                        disabled={modalType === "view"}
                        name="otherFeeDetail"
                        className="create-financial-input-big w-100"
                      />
                    </div>
                  </div>
                </div>
                <div className=" d-flex align-items-center gap-3 py-3">
                  <button
                    className="communication-management-cancel-button"
                    type="button"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Close
                  </button>

                  {modalType != "view" && (
                    <button
                      className="communication-management-submit-button"
                      type="submit"
                    >
                      {modalType === "edit" ? "Update" : "Submit"}
                    </button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
}
export default AddFeePlan;
