import { ADMISSION_MANAGEMENT_URL } from "modules/admission-management/admission-management/components/create-admission/constants/admission-management-urls";
import { UserConstants } from "modules/user-management/constants/user-constants";
import { USER_URL } from "modules/user-management/constants/user-urls";
import { useContext, useEffect, useState } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { RxTriangleDown } from "react-icons/rx";
import { SiFiles } from "react-icons/si";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { axiosService } from "services/axios.service";
import sessionstorageService from "services/sessionstorage.service";
import { isValidEmail } from "shared/validation/emailValidation";
import { isValidPassword } from "shared/validation/passwordValidation";
import Swal from "sweetalert2";
import useravatar from "../../../../assets/images/user-avatar.svg";
import { ROLE_URLS } from "../role-management/constants/user-role-urls";
import "./AddUsers.css";
import { IoIosArrowBack } from "react-icons/io";
import Modal from "react-modal";
import { SERVICE_FACULTY_VIEW } from "modules/student-portal/constants/faculty-service-urls";
import { IoClose } from "react-icons/io5";
import { ThemeContext } from "ThemeContext";

function AddUsers() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [firstName, setFullName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setUserName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [mobileNumberError, setMobNumberError] = useState("");
  const [mobileNumber, setMobNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [roleOptions, setRoleOptions] = useState([null]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("ca");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [role, setRole] = useState(null);
  const [roleError, setRoleError] = useState("");
  const [studentType, setStudentType] = useState(null);
  const [studentTypeError, setStudentTypeError] = useState("");
  const [userId, setUserId] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [updateImage, setUpdateImage] = useState(null);
  const [updatePassword, setUpdatePassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(false);
  const { theme, toggleTheme } = useContext(ThemeContext);
  // const loggedUserData = sessionstorageService.getUserData("userData");
  const handleReset = async () => {
    setUserId(userId);
    if (userId) {
      setUserId(userId);
      const userResponse = await axiosService.get(`${USER_URL.USER}/${userId}`);
      const userData = userResponse.data.data;

      setUserData(userData);
      setChecked(userData?.isActive);
      setFullName(userData.firstName);
      setEmail(userData.email);
      setUserName(userData.lastName);
      setMobNumber(userData.mobileNumber);
      setUpdatePassword(userData.password);
      setImageName(userData?.profileImageName);
      setStudentType({
        value: userData?.studentType,
        label: userData?.studentType,
      });
      const userRole = userData?.role;
      const newRoleOption = userRole
        ? {
            value: userRole.name,
            label: userRole.name,
            roleObject: userRole,
          }
        : null;

      if (newRoleOption) {
        setRoleOptions([newRoleOption]);
        setRole(newRoleOption);
        setSelectedOption([newRoleOption]);
      }
      setStudentType({
        value: userData?.studentType,
        label: userData?.studentType,
      });
      setSelectedImage(userData.profileImage);
    } else {
      setFullName("");
      setFirstNameError("");
      setUserName("");
      setLastNameError("");
      setMobNumberError("");
      setMobNumber("ca");
      setEmail("");
      setPassword("");
      setSelectedOption(null);
      setSelectedCountry("ca");
      setEmailError("");
      setPasswordError("");
      setShowPassword(false);
      setShowTooltip(false);
      setRole(null);
      setRoleError("");
      setStudentType(null);
      setStudentTypeError("");
      setUserId(null);
      setUpdatePassword("");
      setMobNumber("");
      setSelectedImage(null);
      setImageName(null);
    }
  };

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  const tooltipContent = (
    <span>
      <h6>Password Must</h6>
      Have one number
      <br />
      Have one uppercase character
      <br />
      Have one lowercase character
      <br />
      Have one special character
      <br />
      Have 8 characters minimum
    </span>
  );

  const handleRoleChange = (selectedRole) => {
    const selectedOption = roleOptions.find(
      (option) => option.roleObject === selectedRole
    );

    if (selectedOption) {
      setRole(selectedOption);
      setSelectedOption(selectedOption);
      setRoleError("");
    }

    if (
      selectedOption?.value === "student" ||
      selectedOption?.value === "prestudent"
    ) {
      setStudentType(null);
    } else {
      setStudentType({ value: "STAFF", label: "STAFF" });
      setStudentTypeError(null);
    }
  };

  const getuserId = location?.state?.userId;
  const onDrop = async (acceptedFiles) => {
    if (!acceptedFiles[0]) {
      toast.error("Please select a image file less than 2MB.");
    } else if (!acceptedFiles[0].type.startsWith("image/")) {
      toast.error(" Invalid file format,Please upload .png,.jpg,.jpeg files");
    } else {
      try {
        if (acceptedFiles[0]?.name?.length > 150) {
          toast.error(UserConstants.IMAGE_LENGTH);
          return;
        }
        setImageName(acceptedFiles[0]?.name);
        setLoading(true);
        sessionstorageService.isLoading("true");
        const formData = new FormData();
        formData.append("file", acceptedFiles[0]);

        const response = await axiosService.postFile(
          `${ADMISSION_MANAGEMENT_URL.FILE_UPLOAD_URL}`,
          acceptedFiles[0],
          true
        );

        if (response.status === 200) {
          setLoading(false);
          setProfileImage(response.data.data);
          setSelectedImage(response.data.data);
        }

        setLoading(false);
      } catch (error) {
        toast.error(`${error?.response?.data?.message.split(":")[1]?.trim()}`);
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (getuserId) {
          setUserId(getuserId);

          const userResponse = await axiosService.get(
            `${USER_URL.USER}/${getuserId}`
          );
          const userData = userResponse.data.data;
          setChecked(userData?.isActive);
          setUserData(userData);
          setFullName(userData.firstName);
          setEmail(userData.email);
          setUserName(userData.lastName);
          setMobNumber(userData.mobileNumber);
          setUpdatePassword(userData.password);
          setUpdateImage(userData.profileImage);
          setSelectedImage(userData.profileImage);
          setImageName(userData?.profileImageName);
          setStudentType({
            value: userData?.studentType,
            label: userData?.studentType,
          });
          const userRole = userData?.role;
          const newRoleOption = userRole
            ? {
                value: userRole.name,
                label: userRole.name,
                roleObject: userRole,
              }
            : null;

          if (newRoleOption) {
            setRole(newRoleOption);
            setSelectedOption([newRoleOption]);
          }
        }
      } catch (error) {}
    };
    axiosService
      .get(`${ROLE_URLS.USER_GET_URL}`)
      .then((response) => {
        const data = response.data;
        const newRoleOptions = data.data.map((role) => ({
          value: role.name,
          label: role.name,
          roleObject: role,
        }));
        setRoleOptions(newRoleOptions);
      })
      .catch((error) => {});
    fetchUserData();
  }, [userId]);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    validatePassword();
  };

  const handleFullNameChange = (e) => {
    const value = e.target.value;
    setFullName(value);
    setFirstNameError("");
  };

  const handleUserNameChange = (e) => {
    const value = e.target.value;
    setUserName(value);
    setLastNameError("");
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    validateEmail();
  };
  // const handleDelete = (userId) => {
  //   if (userId === loggedUserData?.userId) {
  //     Swal.fire({
  //       title: "Warning!!",
  //       text: "Can't delete signin account",
  //       icon: "warning",
  //       customClass: {
  //         popup: "my-custom-class",
  //       },
  //     });
  //   } else {
  //     Swal.fire({
  //       title: "Confirm Delete",
  //       text: "Are you sure you want to delete this user?",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonText: "Yes, delete",
  //       cancelButtonText: "No, cancel",
  //       customClass: {
  //         popup: "my-custom-class",
  //       },
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         axiosService
  //           .delete(`${USER_URL.USER}/${userId}`)
  //           .then((response) => {
  //             if (response.status === 200) {
  //               Swal.fire({
  //                 title: "Deleted!!",
  //                 text: "User deleted successfully.",
  //                 icon: "success",
  //                 customClass: {
  //                   popup: "my-custom-class",
  //                 },
  //               });
  //               navigate("/user-management/view-users");
  //             } else {
  //               Swal.fire("Error", "Failed to delete the user.", "error");
  //             }
  //           })
  //           .catch((error) => {
  //             Swal.fire("Error", "Failed to delete the user.", "error");
  //           });
  //       }
  //     });
  //   }
  // };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      maxWidth: "276px",
    }),
  };

  const validateEmail = () => {
    if (email.trim() === "") {
      setEmailError(UserConstants.EMAIL_REQUIRED);
    } else if (!isValidEmail(email)) {
      setEmailError(UserConstants.INVALID_EMAIL);
    } else {
      setEmailError("");
    }
  };
  const validatePassword = () => {
    const validationResult = isValidPassword(password);
    if (password.trim() === "") {
      setPasswordError(UserConstants.PASSWORD_REQUIRED);
    } else if (validationResult !== true) {
    } else {
      setPasswordError("");
    }
  };

  const saveUser = async (e) => {
    if (userId) {
      e.preventDefault();
      const validationErrors = {
        lastName: !lastName ? UserConstants.LAST_NAME_REQUIRED : "",
        role: !role ? UserConstants.ROLE_REQUIRED : "",
        firstName: !firstName ? UserConstants.FULL_NAME_REQUIRED : "",
      };
      if (Object.values(validationErrors).some((error) => error !== "")) {
        setLastNameError(validationErrors.lastName);
        setRoleError(validationErrors.role);
        setFirstNameError(validationErrors.firstName);
        return;
      }

      const userData = {
        userId,
        email,
        password: updatePassword,
        firstName,
        lastName,
        mobileNumber,
        profileImage: profileImage || updateImage,
        role: selectedOption[0]
          ? selectedOption[0].roleObject
          : selectedOption
          ? selectedOption.roleObject
          : null,
        profileImageName: imageName,
        studentType: studentType ? studentType?.value : null,
      };
      try {
        const registration = 0;
        const response = await axiosService.put(
          `${USER_URL.USER}?registration=${registration}`,
          userData,
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        if (response.status === 200) {
          toast.success(UserConstants.USER_UPDATE_SUCCESS);
          navigate("/user-management/view-users");
        } else {
          toast.error(UserConstants.USER_FAILED);
        }
      } catch (error) {
        if (error.response) {
          toast.error(`Error: ${error.response.data.message}`);
        } else {
          toast.error(UserConstants.ERROR_OCCUR);
        }
      }
    } else {
      e.preventDefault();

      const validationErrors = {
        lastName: !lastName ? UserConstants.LAST_NAME_REQUIRED : "",
        role: !role ? UserConstants.ROLE_REQUIRED : "",
        mobileNumber: !mobileNumber ? UserConstants.MOB_NUM_REQUIRED : "",
        firstName: !firstName ? UserConstants.FULL_NAME_REQUIRED : "",
      };
      if (Object.values(validationErrors).some((error) => error !== "")) {
        setLastNameError(validationErrors.lastName);
        setRoleError(validationErrors.role);
        setMobNumberError(validationErrors.mobileNumber);
        setFirstNameError(validationErrors.firstName);
        return;
      }

      const userData = {
        email,
        password,
        firstName,
        lastName,
        mobileNumber,
        profileImage: profileImage,
        role: selectedOption ? selectedOption.roleObject : null,
        profileImageName: imageName,
        studentType: studentType ? studentType?.value : null,
      };
      try {
        const registration = 0;
        const response = await axiosService.post(
          `${USER_URL.USER}?registration=${registration}`,
          userData,
          {
            headers: { "Content-Type": "application/json" },
          }
        );

        if (response.status === 200) {
          toast.success(UserConstants.USER_SUCCESS);
          navigate("/user-management/view-users");
        } else {
          toast.error(UserConstants.USER_FAILED);
        }
      } catch (error) {
        if (error.response) {
          toast.error(`Error: ${error.response.data.message}`);
        } else {
          toast.error(UserConstants.ERROR_OCCUR);
        }
      }
    }
  };

  const [allFieldsFilled, setAllFieldsFilled] = useState(false);

  useEffect(() => {
    setAllFieldsFilled(
      firstName?.trim() !== "" &&
        lastName?.trim() !== "" &&
        mobileNumber?.trim() !== "" &&
        email?.trim() !== "" &&
        role !== null &&
        (!role || (role.value !== "student" && role.value !== "prestudent")
          ? true
          : studentType !== null) &&
        !loading
    );
  }, [
    firstName,
    lastName,
    mobileNumber,
    email,
    password,
    userId,
    role,
    studentType,
    loading,
  ]);

  const handleGoBack = () => {
    navigate(-1);
  };
  const studentTypeOptions = [
    { value: "OSAP", label: "OSAP" },
    { value: "INTERNATIONAL", label: "INTERNATIONAL" },
    { value: "NVQ", label: "NVQ" },
    { value: "ONTARIO", label: "ONTARIO" },
  ];
  const [modalOpen, setModalOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [type, setType] = useState("");
  const [isChecked, setChecked] = useState(userData?.isActive);

  const reasonTypeOptions = [
    { value: "Medical Issues", label: "Medical Issues" },
    { value: "Family Issues", label: "Family Issues" },
    { value: "Legal Issues", label: "Legal Issues" },
    { value: "Financial Issues", label: "Financial Issues" },
    {
      value: "Transferred to Another College/University",
      label: "Transferred to Another College/University",
    },
    { value: "Approved Leave", label: "Approved Leave" },
    {
      value: "Leave without Notice/approval",
      label: "Leave without Notice/approval",
    },
    { value: "Academic Challenges", label: "Academic Challenges" },
    { value: "Withdrawal/Drop", label: "Withdrawal/Drop" },
    { value: "Other", label: "Other" },
  ];
  const handleReasonTypeChange = (option) => {
    setType(option.value);
  };

  const isSubmitButtonDisabled = () => {
    return reason === "" || type === "";
  };
  const onClose = () => {
    setModalOpen(false);
    setReason("");
    setType("");
  };
  const handleActive = () => {
    Swal.fire({
      text: `Are you sure to Activate this Student?`,
      // text: `Do you want to mark this attendee as ${type}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes, Activate`,
      cancelButtonText: "No, cancel",
      customClass: {
        popup: "my-custom-class",
        content: "custom-swal-text",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleActiveStudent(type);
      }
    });
  };
  const handleSave = async () => {
    const requestData = {
      userId: userId,
      nonActiveType: type,
      nonActiveReason: reason,
    };
    try {
      const response = await axiosService.put(
        `${SERVICE_FACULTY_VIEW.INACTIVE_STUDENT}`,
        requestData
      );

      if (response.status === 200) {
        toast.success("Student Inactivated Successfully!");
        fetchData();
        onClose();
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An error occurred";
      toast.error(errorMessage);
    }
  };

  const handleActiveStudent = async () => {
    try {
      const response = await axiosService.put(
        `${SERVICE_FACULTY_VIEW.INACTIVE_STUDENT}/${userId}`
      );

      if (response.status === 200) {
        toast.success("Student Activated Successfully!");
        fetchData();
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An error occurred";
      toast.error(errorMessage);
    }
  };
  const fetchData = async () => {
    try {
      const response = await axiosService.get(
        `${SERVICE_FACULTY_VIEW.GET_STUDENT_DATA}${userId}`
      );
      const data = response?.data?.data;
      if (data) {
        setChecked(data?.isActive);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="user-main-container pb-4">
      <div className="user-head-container sticky-header flex-row flex-wrap gap-2">
        <div className="d-flex align-items-center gap-1">
          <span className="user-title-text">
            <span onClick={handleGoBack} style={{ cursor: "pointer" }}>
              {" "}
              <IoIosArrowBack size={20} />
            </span>{" "}
            {userId ? "Edit User" : "Add User"}
          </span>
        </div>
        <div className="d-flex gap-3">
          {userId && userData?.studentType === "STAFF" && (
            <div className="">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onClick={() =>
                    isChecked ? setModalOpen(true) : handleActive()
                  }
                />
                <span className="slider round"></span>
              </label>
            </div>
          )}
          <div className="d-none d-md-flex align-items-center gap-3">
            {/* {userId && (
            <button
              className="user-save-head-button"
              onClick={() => handleDelete(userId)}
            >
              Delete
            </button>
          )} */}

            <button
              className="user-head-button"
              onClick={() => navigate("/user-management/view-users")}
            >
              Cancel
            </button>
            <button className="user-save-head-button" onClick={handleReset}>
              Reset
            </button>

            <button
              disabled={!allFieldsFilled}
              className={`user-save-head-button ${
                !allFieldsFilled ? "opacity-50" : ""
              }`}
              onClick={saveUser}
            >
              {userId ? "Update" : "Save"}
            </button>
          </div>

          <Dropdown className="d-md-none">
            <Dropdown.Toggle
              className="lead-table-head-button"
              id="dropdown-basic"
            >
              Actions
            </Dropdown.Toggle>

            <Dropdown.Menu className="py-0">
              {/* {userId && (
              <Dropdown.Item onClick={() => handleDelete(userId)}>
                Delete
              </Dropdown.Item>
            )} */}
              <Dropdown.Item onClick={handleReset}>Reset</Dropdown.Item>
              <Dropdown.Item onClick={() => navigate(-1)}>Cancel</Dropdown.Item>
              <Dropdown.Item
                disabled={!allFieldsFilled}
                className={`${!allFieldsFilled ? "opacity-50" : ""}`}
                onClick={saveUser}
              >
                {userId ? "Update" : "Save"}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <div className="mt-2 d-flex d-md-none flex-column">
        <label className="add-user-label-text">Image Preview</label>
        <div className="add-user-image-preview">
          {selectedImage ? (
            <img
              src={selectedImage}
              alt="userimage"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          ) : (
            <img
              src={useravatar}
              alt="userimage"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          )}
        </div>
      </div>
      <div className="row ">
        <div className="col-12 col-md-9 add-user-main-input-container ">
          <div className="row">
            <div className="col-12 col-md-5 add-user-input-container mt-2">
              <label className="add-user-label-text">First Name *</label>
              <input
                value={firstName}
                onChange={handleFullNameChange}
                className="add-user-input"
                placeholder="Firstname "
              />
              <span className="auth-login-error-text">{firstNameError}</span>
            </div>
            <div className="col-12 col-md-7 add-user-input-container mt-2">
              <label className="add-user-label-text">
                Profile Image (max upload size: 2mb)
              </label>
              <Dropzone
                onDrop={onDrop}
                accept="image/*"
                multiple={false}
                maxSize={2097152}
                disabled={loading}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps()}
                    className="add-user-input-file-container"
                  >
                    <input {...getInputProps()} />
                    <span
                      className={`add-user-input-file-box ${
                        imageName ? "text-white" : ""
                      }`}
                      style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        display: "inline-block",
                        textAlign: "left",
                        paddingTop: "10px",
                        paddingRight: "10px",
                      }}
                    >
                      {imageName ? imageName : "No file selected"}
                    </span>

                    {loading ? (
                      <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    ) : (
                      <>
                        <span className="add-user-input-file-choose d-none d-md-flex">
                          Choose file
                        </span>
                        <span className="add-user-input-file-choose d-flex d-md-none">
                          <SiFiles />
                        </span>
                      </>
                    )}
                  </div>
                )}
              </Dropzone>
            </div>
            <div className="col-12 col-md-5 add-user-input-container mt-2">
              <label className="add-user-label-text">Last Name *</label>
              <input
                value={lastName}
                onChange={handleUserNameChange}
                className="add-user-input"
                placeholder="Lastname"
              />
              <span className="auth-login-error-text">{lastNameError}</span>
            </div>
            {!userId && (
              <div className="col-12 col-md-5 add-user-input-container mt-2">
                <label className="add-user-label-text">Password *</label>
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="password-tooltip" className="custom-tooltip">
                      {tooltipContent}
                    </Tooltip>
                  }
                  show={showTooltip}
                >
                  <input
                    className="add-user-input"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    value={password}
                    onFocus={toggleTooltip}
                    onBlur={toggleTooltip}
                    onChange={handlePasswordChange}
                    disabled={userId ? true : false}
                  />
                </OverlayTrigger>
                <span className="auth-login-error-text">{passwordError}</span>
                {showPassword ? (
                  <AiFillEye
                    className="signup-eye-icon-user"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                ) : (
                  <AiFillEyeInvisible
                    className="signup-eye-icon-user"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                )}
              </div>
            )}
            <div className="col-12 col-md-5 add-user-input-containers mt-2">
              <label className="add-user-label-text">Phone number *</label>

              <PhoneInput
                country={selectedCountry}
                enableSearch={true}
                value={mobileNumber}
                onChange={(phoneNumber) => setMobNumber(phoneNumber)}
                inputProps={{
                  className: "add-user-input phone-input",
                  placeholder: "Valid Mobile number",
                }}
              />
              {mobileNumberError && (
                <span className="auth-login-error-text">
                  {mobileNumberError}
                </span>
              )}
            </div>
            <div className="col-12 col-md-5 add-user-input-container mt-2">
              <label className="add-user-label-text">Email ID *</label>
              <input
                value={email}
                onChange={handleEmailChange}
                className="add-user-input"
                placeholder="Valid Email ID"
              />
              {emailError && (
                <span className="auth-login-error-text">{emailError}</span>
              )}
            </div>

            <div className="col-12 col-md-5 add-user-input-container mt-2">
              <label className="add-user-label-text">Select Role*</label>
              <Select
                value={role}
                onChange={(role) => {
                  handleRoleChange(role.roleObject);
                }}
                options={roleOptions}
                components={{
                  DropdownIndicator: () => <RxTriangleDown size="20px" />,
                  IndicatorSeparator: null,
                }}
                styles={selectStyles}
                isSearchable={false}
                classNamePrefix="react-select-big"
                placeholder="Select an Role"
                menuPlacement="auto"
                menuPosition="fixed"
              ></Select>
              {roleError && (
                <span className="auth-login-error-text">{roleError}</span>
              )}
            </div>
            {role && ["student", "prestudent"].includes(role.value) && (
              <div className="col-12 col-md-5 add-user-input-container mt-2">
                <label className="add-user-label-text">
                  Select Student Type *
                </label>
                <Select
                  value={studentType}
                  onChange={(selectedOption) => {
                    setStudentType(selectedOption);
                    setStudentTypeError(null);
                  }}
                  options={studentTypeOptions}
                  components={{
                    DropdownIndicator: () => <RxTriangleDown size="20px" />,
                    IndicatorSeparator: null,
                  }}
                  styles={selectStyles}
                  isSearchable={false}
                  classNamePrefix="react-select-big"
                  placeholder="Select an Role"
                  menuPlacement="auto"
                  menuPosition="fixed"
                />
                {studentTypeError && (
                  <span className="auth-login-error-text">
                    {studentTypeError}
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="col-3 mt-2 d-none d-md-flex flex-column">
          <label className="add-user-label-text">Image Preview</label>
          <div className="add-user-image-preview">
            {selectedImage ? (
              <img
                src={selectedImage}
                alt="userimage"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            ) : (
              <img
                src={useravatar}
                alt="userimage"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            )}
          </div>
        </div>
      </div>
      <Modal className="crm-modal" isOpen={modalOpen} onRequestClose={onClose}>
        <div className="modal-Con">
          <div
            className="col-12 col-md-8 p-3 d-flex flex-column gap-2"
            style={{
              maxHeight: "100%",
              backgroundColor: "#212226",
            }}
          >
            <div className="new-conversation-popup-header d-flex justify-content-between align-items-center">
              <span
                style={{ fontSize: "18px", fontWeight: 400, color: "#fff" }}
              >
                Reason For Inactivate
              </span>

              <IoClose color="#ffffff" size={"23px"} onClick={onClose} />
            </div>
            <div>
              <div className="academics-add-program-input-con">
                <label>Type *</label>
                <Select
                  value={reasonTypeOptions.find(
                    (option) => option.value === reason
                  )}
                  onChange={handleReasonTypeChange}
                  options={reasonTypeOptions}
                  isSearchable={false}
                  classNamePrefix="react-select-big"
                  components={{
                    DropdownIndicator: () => (
                      <RxTriangleDown
                        color={theme === "light" ? "#000000" : "#ffffff"}
                      />
                    ),
                    IndicatorSeparator: null,
                  }}
                  placeholder="Choose an Option"
                  menuPlacement="auto"
                  menuPosition="fixed"
                />
              </div>
              <div className="academics-add-program-input-con">
                <label>Reason *</label>
                <input
                  className="faculty-student-portal-reason-popup-input"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  classname=""
                />
              </div>
            </div>
            <div className="d-flex align-items-center gap-3">
              <button
                className="communication-management-cancel-button"
                type="button"
                onClick={onClose}
              >
                Cancel
              </button>

              <button
                className={`communication-save-head-button ${
                  isSubmitButtonDisabled()
                    ? "user-save-head-button-disabled"
                    : ""
                }`}
                onClick={() => {
                  handleSave();
                }}
                disabled={isSubmitButtonDisabled()}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AddUsers;
