import { Form, Formik, Field } from "formik";
import { useContext, useEffect, useState } from "react";
import { RxTriangleDown } from "react-icons/rx";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { axiosService } from "services/axios.service";
import sessionstorageService from "services/sessionstorage.service";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Country } from "country-state-city";
import * as Yup from "yup";
import { ADMISSION_URL_FOR_STUDENT } from "modules/pre-onboarding/components/constants/admission-application-student-urls";
import { STUDENT_PORTAL_URL } from "modules/student-portal/constants/student-portal-urls";
import { IoIosArrowBack } from "react-icons/io";
import { ThemeContext } from "ThemeContext";

function EditStudentProfile() {
  const navigation = useNavigate();
  // const programId = location?.state?.data;
  const selectedCountryCode = "ca";

  const [admissionData, setAdmissionData] = useState(null);
  const userData = sessionstorageService.getUserData("userData");
  const admissionId = sessionstorageService.getAdmissionId("admissionId");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const { theme, toggleTheme } = useContext(ThemeContext);

  useEffect(() => {
    if (admissionId) {
      axiosService
        .get(
          `${ADMISSION_URL_FOR_STUDENT.GET_ADMISSION_URL_FOR_STUDENT}${admissionId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setAdmissionData(data);

          if (data?.personalData?.nationality) {
            setSelectedCountry(data.personalData.nationality);
          }
          if (data?.personalData) {
          }
        });
    }
  }, [admissionId]);

  const [formValues, setFormValues] = useState({
    email: userData?.email || "",
    title: "",
    sex: "",
    maritalStatus: "",
    dateOfBirth: "",
    nationality: selectedCountry || "",
    passportNumber: "",
    expiryDate: "",
    passportIssuer: "",
    nationalIdNumber: "",
    drivingLicenseNumber: "",
    personalId: "",
    mobile1: "",
    mobile2: "",
    alternateEmail: "",
  });

  const initialValues = {
    email: userData?.email || "",
    sex: formValues.sex || "",
    maritalStatus: formValues.maritalStatus || "",
    dateOfBirth: formValues.dateOfBirth || "",
    nationality: formValues.nationality || "",
    passportNumber: formValues.passportNumber || "",
    expiryDate: formValues.expiryDate || "",
    passportIssuer: formValues.passportIssuer || "",
    nationalIdNumber: formValues.nationalIdNumber || "",
    drivingLicenseNumber: formValues.drivingLicenseNumber || "",
    personalId: formValues.personalId || "",

    isRejected: false,
  };
  const validationSchema = Yup.object().shape({
    dateOfBirth: Yup.date().required("Date of Birth is required"),
    sex: Yup.string().required("Gender is required"),
    maritalStatus: Yup.string().required("Select the marital status"),
    nationality: Yup.string().required("Nationality is required"),
    passportNumber: Yup.string().required("Passport is required"),
    expiryDate: Yup.string().required("Expirydate is required"),
    passportIssuer: Yup.string().required("Passport Issuer is required"),
  });

  const [countries, setCountries] = useState([]);
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const allCountries = Country.getAllCountries();
        const countryOptions = allCountries.map((country) => ({
          label: country.name,
          value: country.name,
        }));
        setCountries(countryOptions);
      } catch {}
    };

    fetchCountries();
  }, []);
  const onSubmit = async (values) => {
    try {
      const response = await axiosService.put(
        `${STUDENT_PORTAL_URL.UPDATE_STUDENT_PROFILE}${admissionId}`,
        values
      );
      if (response.status === 200) {
        sessionstorageService.setAdmissionId(response.data.data.admissionId);
        toast.success("Profile Data Updated Successfully");
        setFormValues(values);
        navigation("/student-portal/home-student");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        toast.error(`Error: ${error.response.data.message}`);
      } else {
        toast.error("Error Occured");
      }
    }
  };

  const handleClear = async (formik) => {
    formik.resetForm();
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "0 !important",
      width: "210px !important",
    }),
  };

  const MaritalStatusOptions = [
    { value: "MARRIED", label: "Married" },
    { value: "SINGLE", label: "Single" },
    { value: "DIVORCED", label: "Divorced" },
  ];

  const sexOption = [
    { value: "MALE", label: "Male" },
    { value: "FEMALE", label: "Female" },
    { value: "PREFERNOTTOSAY", label: "Prefer not to say" },
  ];

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  };
  const [personalData, setPersonalData] = useState({});
  const [mailingAddress, setMailingAddress] = useState({});

  useEffect(() => {
    if (admissionData) {
      setPersonalData(admissionData.personalData || {});
      setMailingAddress(admissionData.mailingAddress || {});
    }
  }, [admissionData]);
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <div
      className="student-portal-main-container pb-4 custom-scrollbar"
      style={{ color: "#ffffff" }}
    >
      <div
        className="student-portal-header-container sticky-header flex-sm-row gap-2"
        style={{ top: 0 }}
      >
        <div className="d-flex align-items-center gap-3">
          <span className="communication-management-title-text">
            <span onClick={handleGoBack} style={{ cursor: "pointer" }}>
              {" "}
              <IoIosArrowBack size={20} />
            </span>{" "}
            Edit Profile
          </span>
        </div>
      </div>

      <Formik
        initialValues={{
          ...personalData,
          ...mailingAddress,
        }}
        enableReinitialize={true}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <Form>
            <div>
              <div className="create-personal-data-main-input-container">
                <div className="create-admission-inner-first-input-container ">
                  <label className="label-text-active">Email</label>

                  <Field
                    className="create-admission-input-big pre-onboard-create-personal-data"
                    name="email"
                    readOnly
                  />
                </div>
              </div>

              <div className="create-personal-data-main-input-container">
                <div className="create-admission-inner-first-input-container ">
                  <label className="label-text-active">Gender *</label>
                  <Field
                    name="sex"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    component={({ field, form }) => (
                      <>
                        <Select
                          value={sexOption.find(
                            (option) => option.value === field.value
                          )}
                          onChange={(option) =>
                            form.setFieldValue(field.name, option.value)
                          }
                          options={sexOption}
                          isSearchable={false}
                          classNamePrefix="react-select"
                          styles={selectStyles}
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown
                                color={
                                  theme === "light" ? "#000000" : "#ffffff"
                                }
                              />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        ></Select>

                        {formik.touched.sex &&
                        formik.errors.sex &&
                        !formik.values.sex ? (
                          <div className="error sex">{formik.errors.sex}</div>
                        ) : null}
                      </>
                    )}
                  />
                </div>
                <div className="create-admission-inner-first-input-container ">
                  <label className="label-text-active">Marital Status *</label>

                  <Field
                    name="maritalStatus"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    component={({ field, form }) => (
                      <>
                        <Select
                          options={MaritalStatusOptions}
                          value={MaritalStatusOptions.find(
                            (option) => option.value === field.value
                          )}
                          onChange={(option) =>
                            form.setFieldValue(field.name, option.value)
                          }
                          isSearchable={false}
                          classNamePrefix="react-select"
                          styles={selectStyles}
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown
                                color={
                                  theme === "light" ? "#000000" : "#ffffff"
                                }
                              />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        ></Select>

                        {formik.touched.maritalStatus &&
                        formik.errors.maritalStatus &&
                        !formik.values.maritalStatus ? (
                          <div className="error maritalstatus">
                            {formik.errors.maritalStatus}
                          </div>
                        ) : null}
                      </>
                    )}
                  />
                </div>
                <div className="create-admission-inner-first-input-container ">
                  <label className="label-text-active">Date of Birth *</label>
                  <Field
                    type="date"
                    className="create-personal-data-date-input-small pre-onboard-create-personal-data-date-input-small-dob"
                    name="dateOfBirth"
                    placeholder="Choose"
                    max={getCurrentDate()}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.dateOfBirth &&
                  formik.errors.dateOfBirth &&
                  !formik.values.dateOfBirth ? (
                    <div className="error dateofbirth">
                      {formik.errors.dateOfBirth}
                    </div>
                  ) : null}
                </div>
                <div className="create-admission-inner-first-input-container ">
                  <label className="label-text-active">Alternate Email </label>

                  <Field
                    className="create-admission-input-big pre-onboard-create-admission-input-big-PNumber"
                    name="alternateEmail"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="create-admission-inner-first-input-container ">
                  <label className="label-text-active">Nationality *</label>

                  <Field
                    name="nationality"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    component={({ field, form }) => (
                      <>
                        <Select
                          options={countries}
                          classNamePrefix="react-select"
                          styles={selectStyles}
                          value={countries.find(
                            (option) => option.value === field.value
                          )}
                          onChange={(selectedOption) => {
                            form.setFieldValue(
                              field.name,
                              selectedOption ? selectedOption.value : ""
                            );
                            setSelectedCountry(selectedOption.value);
                          }}
                          isSearchable={true}
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown
                                color={
                                  theme === "light" ? "#000000" : "#ffffff"
                                }
                              />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                        {formik.touched.nationality &&
                        formik.errors.nationality &&
                        !formik.values.nationality ? (
                          <div className="error nationality">
                            {formik.errors.nationality}
                          </div>
                        ) : null}
                      </>
                    )}
                  />
                </div>
              </div>

              <div className="create-personal-data-main-input-container">
                <div className="create-admission-inner-first-input-container ">
                  <label className="label-text-active">Passport Number *</label>

                  <Field
                    className="create-admission-input-big pre-onboard-create-admission-input-big-PNumber"
                    name="passportNumber"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />

                  {formik.touched.passportNumber &&
                  formik.errors.passportNumber &&
                  !formik.values.passportNumber ? (
                    <div className="error passportNumber">
                      {formik.errors.passportNumber}
                    </div>
                  ) : null}
                </div>
                <div className="create-admission-inner-first-input-container ">
                  <label className="label-text-active">Expiry Date *</label>

                  <Field
                    type="date"
                    className="create-personal-data-date-input-small pre-onboard-create-personal-data-date-input-small-EDate"
                    name="expiryDate"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />

                  {formik.touched.expiryDate &&
                  formik.errors.expiryDate &&
                  !formik.values.expiryDate ? (
                    <div className="error expiryDate">
                      {formik.errors.expiryDate}
                    </div>
                  ) : null}
                </div>
                <div className="create-admission-inner-first-input-container ">
                  <label className="label-text-active">Passport Issuer *</label>

                  <Field
                    className="create-admission-input-big pre-onboard-create-admission-input-big-PIssue"
                    name="passportIssuer"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />

                  {formik.touched.passportIssuer &&
                  formik.errors.passportIssuer &&
                  !formik.values.passportIssuer ? (
                    <div className="error passportIssuer">
                      {formik.errors.passportIssuer}
                    </div>
                  ) : null}
                </div>
                <div className="create-admission-inner-first-input-container ">
                  <label className="label-text-active">
                    National ID Number
                  </label>

                  <Field
                    className="create-admission-input-big pre-onboard-create-admission-input-big-NId"
                    name="nationalIdNumber"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                </div>

                <div className="create-admission-inner-first-input-container ">
                  <label className="label-text-active">
                    Driving License Number
                  </label>

                  <Field
                    className="create-admission-input-big pre-onboard-create-admission-input-big-dlnumber"
                    name="drivingLicenseNumber"
                  />
                </div>
              </div>

              <div className="create-personal-data-main-input-container">
                <div className="create-admission-inner-first-input-container ">
                  <label className="label-text-active">Mobile 1 *</label>

                  <Field name="mobile1">
                    {({ field, form }) => (
                      <PhoneInput
                        country={selectedCountryCode}
                        enableSearch={true}
                        value={field.value}
                        onChange={(value, country, e, formattedValue) => {
                          form.setFieldValue("mobile1", formattedValue);
                        }}
                        inputProps={{
                          className: "create-admission-input-big1 phone-input1",
                          style: { width: "210px" },
                          placeholder: "Valid Mobile number",
                        }}
                      />
                    )}
                  </Field>
                  {formik.touched.mobile1 && formik.errors.mobile1 ? (
                    <div className="error mobile1">{formik.errors.mobile1}</div>
                  ) : null}
                </div>

                <div className="create-admission-inner-first-input-container ">
                  <label className="label-text-active">Mobile 2</label>

                  <Field name="mobile2">
                    {({ field, form }) => (
                      <PhoneInput
                        country={selectedCountryCode}
                        enableSearch={true}
                        value={field.value}
                        onChange={(value, country, e, formattedValue) => {
                          form.setFieldValue("mobile2", formattedValue);
                        }}
                        inputProps={{
                          className: "create-admission-input-big2 phone-input2",
                          style: { width: "210px" },
                          placeholder: "Valid Mobile number",
                        }}
                      />
                    )}
                  </Field>
                </div>
              </div>
            </div>
            <div className="d-flex gap-3 mt-3">
              <button
                type="button"
                className="create-admission-clear-button"
                onClick={() => handleClear(formik)}
              >
                Clear
              </button>

              <button
                type="submit"
                className="create-admission-save-button"
                disabled={formik.isSubmitting}
              >
                Edit & Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EditStudentProfile;
