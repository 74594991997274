import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosService } from "services/axios.service";
import "./ViewStartDateProgram.css";
import Dropdown from "react-bootstrap/Dropdown";
import { USER_URL } from "modules/user-management/constants/user-urls";
import { ThemeContext } from "ThemeContext";

function ViewStartDateProgram() {
  const location = useLocation();
  const [listStartDate, setListStartDate] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const navigate = useNavigate();

  const { theme, toggleTheme } = useContext(ThemeContext);

  useEffect(() => {
    axiosService
      .get(`${USER_URL.GET_ALL_STARTDATE_PROGRAM}`)
      .then((response) => {
        const data = response?.data?.data;

        setListStartDate(data);
      })
      .catch(() => {});
  }, [location.pathname]);

  function onSelectionChanged(gridOptions) {
    var selectedNodes = gridOptions.api.getSelectedNodes();
    var selectedData = selectedNodes.map((node) => node.data);

    if (selectedData.length > 0) {
      setSelectedDate(selectedData[0]);
    } else {
      setSelectedDate(null);
    }
  }
  const [columnDefs] = useState([
    {
      headerName: "",
      field: "select",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      width: 50,
      floatingFilter: false,
      filter: false,
    },
    {
      headerName: "Program Name",
      valueGetter: (params) => params.data?.programFeeMaster?.program || "-",
      // cellClass: "ag-grid-overflow-wrap",
      width: 320,
      cellStyle: {
        whiteSpace: "normal",
        wordWrap: "break-word",
        overflow: "hidden",
        textDecoration: "underline",
      },
    },
    {
      headerName: "Campus",
      valueGetter: (params) => params.data?.programFeeMaster?.campus || "-",
    },
    {
      headerName: "Start Date",
      field: "startDates",
      width: 320,
      valueGetter: (params) => {
        const startDates = params.data?.startDates || [];
        if (startDates.length > 3) {
          return startDates.slice(0, 3).join(", ") + ", ...";
        } else {
          return startDates.join(", ");
        }
      },
    },
  ]);

  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    checkboxSelection: function (params) {
      return (
        params.columnApi.getAllDisplayedColumns().indexOf(params.column) !== 0
      );
    },
    onSelectionChanged: onSelectionChanged,
  };

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);

  const onRowClicked = (event) => {
    navigate("/academics-management/lookup-view", {
      state: {
        data: {
          Data: event.data,
        },
      },
    });
  };
  const getRowHeight = (params) => {
    if (params.data?.programFeeMaster?.program?.length > 50) {
      return 80;
    } else if (params.data?.programFeeMaster?.program?.length > 100) {
      return 100;
    } else {
      return 41;
    }
  };
  return (
    <div className="view-leads-table-container p-0">
      <div className="lead-table-head-container sticky-header flex-row flex-wrap gap-2">
        <div className="d-flex align-items-center gap-1 ">
          <span className="lead-table-title-text">All Fee Plans </span>
        </div>
        <div className="d-flex align-items-end gap-3">
          <button
            className="communication-save-head-button"
            onClick={() => navigate("/academics-management/lookup-add")}
          >
            Add Start Date
          </button>
          <Dropdown
            className={`lead-table-head-button ${
              !selectedDate && "user-head-button-disabled"
            }`}
            disabled={!selectedDate}
            id="dropdown-basic"
            style={{ cursor: "pointer" }}
            onClick={
              selectedDate
                ? () => {
                    navigate("/academics-management/lookup-add", {
                      state: {
                        data: {
                          Data: selectedDate,
                        },
                      },
                    });
                  }
                : undefined
            }
          >
            Edit Start Date
          </Dropdown>
        </div>
      </div>
      <div
        className={
          theme === "light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"
        }
      >
        <AgGridReact
          columnDefs={columnDefs}
          rowData={listStartDate}
          rowSelection="single"
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          suppressRowClickSelection
          gridOptions={gridOptions}
          onRowClicked={onRowClicked}
          getRowHeight={getRowHeight}
        />
      </div>
    </div>
  );
}

export default ViewStartDateProgram;
