import React, { useEffect, useState } from "react";
import "./StudentPortalFeedback.css";
import { STUDENT_PORTAL_FEEDBACK_URL } from "modules/student-portal/constants/student-portal-urls";
import { axiosService } from "services/axios.service";
import sessionService from "services/sessionstorage.service";
import StudentPortalFeedbackHeader from "./StudentPortalFeedbackHeader";

function StudentPortalFeedback({ studentId }) {
  const [student, setStudent] = useState("");
  const [studentData, setStudentData] = useState({});
  const [faculty, setFaculty] = useState("");
  const [module, setSelectedModule] = useState(null);

  useEffect(() => {
    const userData = sessionService.getUserData("userData");
    let StudentId = userData.admissionManagement?.personalData?.studentId;
    let StudentData = userData.admissionManagement;

    setStudent(StudentId);
    setStudentData(StudentData);
    let facultyId =
      userData.role.name === "faculty" || userData.role.name === "super admin";

    setFaculty(facultyId);
    if (studentId) {
      setStudent(studentId);
    }
  }, []);

  const [editView, setEditView] = useState(true);
  const [questions, setQuestions] = useState([]);

  function getFeedbackQuestions() {
    setEditView(false);
    axiosService
      .get(`${STUDENT_PORTAL_FEEDBACK_URL.GET_ALL_FEEDBACK_MASTER}`)
      .then((response) => {
        const data = response?.data?.data[0]?.feedbacks;

        if (data) {
          setQuestions(data);
        }
      });
  }

  function submitFeedbackQuestions() {
    setEditView(false);
    let Data = {
      studentFeedbackId: module?.studentFeedbackId,
      master: {
        studentMasterId: module?.master?.studentMasterId,
      },
      module: {
        programModuleId: module?.module?.programModuleId,
      },
      moduleCode: module?.moduleCode,
      moduleName: module?.moduleName,
      staffName: module?.staffName,
      feedbacks: questions,
    };

    axiosService
      .put(`${STUDENT_PORTAL_FEEDBACK_URL.SAVED_STUDENT_FEEDBACK}`, Data)
      .then((response) => {
        const data = response;
        if (data) {
          setEditView(true);
          setQuestions([]);
          setSelectedModule(null);
          getAllModulesByID();
        }
      });
  }

  const [datas, setDatas] = useState([]);
  function getAllModulesFacultySide() {
    axiosService
      .get(
        `${STUDENT_PORTAL_FEEDBACK_URL.GET_ALL_STUDENT_FEEDBACK_MODULES}${student}`
      )
      .then((response) => {
        const data = response?.data?.data;

        if (data) {
          setDatas(data);
        }
      });
  }

  function getAllModulesByID() {
    axiosService
      .get(`${STUDENT_PORTAL_FEEDBACK_URL.GET_ALL_Modules_BY_ID}${student}`)
      .then((response) => {
        const data = response?.data?.data;
        if (data) {
          setDatas(data);
        }
      });
  }

  useEffect(() => {
    if (faculty) {
      getAllModulesFacultySide();
    } else if (student) {
      getAllModulesByID();
    }
  }, [student]);

  function viewData(feedback) {
    setQuestions(feedback?.feedbacks);
    setEditView(false);
  }

  // function handleChecked(option, selectedOption, correctOption) {
  //   if (option === selectedOption && option === correctOption) {
  //     return "green";
  //   } else if (option === selectedOption && option !== correctOption) {
  //     return "red";
  //   }
  // }

  function handleChecked(option, selectedOption, correctOption) {
    if (option === selectedOption) {
      return "green";
    }
  }

  const handleOptionChange = (event, a) => {
    a.selectedOption = event.target.value;
  };

  return (
    <div className="student-portal-service-container">
      <div className="sticky-header">
        {!faculty && studentData?.programDetails && (
          <StudentPortalFeedbackHeader
            datas={datas}
            studentData={studentData}
          />
        )}
      </div>
      <div>
        {editView ? (
          <>
            <div className=" student-portal-inner-main-container-feedback custom-scrollbar">
              {Array.isArray(datas) && datas.length > 0 ? (
                <table id="student-portal-feedback-table">
                  <thead className="table-header-student-portal">
                    <tr>
                      <th className="pe-3" style={{ maxWidth: "200px" }}>
                        <div className="table-head-student-portal">
                          <span
                            className="table-head-student-portal-text"
                            style={{ padding: "0px 9px" }}
                          >
                            Module Code
                          </span>
                        </div>
                      </th>
                      <th className="pe-3" style={{ maxWidth: "200px" }}>
                        <div className="table-head-student-portal">
                          <span className="table-head-student-portal-text">
                            Module name
                          </span>
                        </div>
                      </th>
                      <th className="pe-3" style={{ maxWidth: "200px" }}>
                        <div className="table-head-student-portal">
                          <span className="table-head-student-portal-text">
                            Staff Name
                          </span>
                        </div>
                      </th>
                      <th className="pe-3" style={{ maxWidth: "200px" }}>
                        <div className="table-head-student-portal">
                          <span className="table-head-student-portal-text">
                            Status
                          </span>
                        </div>
                      </th>
                      {student && (
                        <th className="pe-3" style={{ maxWidth: "200px" }}>
                          <div className="table-head-student-portal">
                            <span className="table-head-student-portal-text">
                              View/Edit
                            </span>
                          </div>
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {datas.map((feedback, idx) => (
                      <tr key={idx}>
                        <td
                          className="table-data-student-portal"
                          style={{ padding: "0px 9px" }}
                        >
                          {feedback.moduleCode}
                        </td>
                        <td className="table-data-student-portal table-head-student-portal-text-nowrap">
                          {feedback.moduleName}
                        </td>
                        <td className="table-data-student-portal">
                          {feedback.staffName}
                        </td>
                        <td className="table-data-student-portal">
                          {feedback.isStatus ? "Submitted" : "Pending"}
                        </td>
                        {student && (
                          <td
                            className="table-data-student-portal"
                            onClick={() => {
                              feedback.isStatus
                                ? viewData(feedback)
                                : getFeedbackQuestions();
                              setSelectedModule(feedback);
                            }}
                          >
                            {feedback.isStatus ? "View" : "Edit"}
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p className="no-entries-made">No Feedback made</p>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="my-feedback-questions-section-header ">
              <div>
                Module Name:{" "}
                <span>Emerging Technologies and Basic of Data Science</span>
              </div>
              <div>
                Staff Name: <span>Staff Fullname</span>
              </div>
            </div>
            <div className="my-feedback-questions-main-div">
              {questions.map((a, idx) => (
                <div
                  style={{
                    borderRadius: "10px",
                    // color: "white",
                    // backgroundColor: "#333333",
                  }}
                  className="my-feedback-question-card"
                  key={idx}
                >
                  <div className="my-exam-question-question-div pb-2">
                    {a.questionId}. {a.question}
                  </div>
                  <div style={{ padding: "10px 15px" }}>
                    {a.option1 &&
                    a.option2 &&
                    a.option3 &&
                    a.option4 &&
                    a.option5 ? (
                      <div className="Feedback-input-options-div">
                        <div className="option-container">
                          <input
                            type="radio"
                            id={a.option1}
                            name={a.questionId}
                            value={a.option1}
                            checked={handleChecked(
                              a.option1,
                              a.selectedOption,
                              a.correctOption
                            )}
                            // className="my-exam-question-select-checkbox"
                            disabled={module.isStatus}
                            className={
                              faculty
                                ? "my-exam-question-select-checkbox my-exam-question-select-faculty-checkbox"
                                : "my-exam-question-select-checkbox"
                            }
                            onChange={(event) => handleOptionChange(event, a)}
                          />
                          <label
                            checked={a.option1 === a.selectedOption}
                            htmlFor={a.option1}
                          >
                            {a.option1}
                          </label>
                          <br />
                        </div>

                        <div className="option-container">
                          <input
                            type="radio"
                            id={a.option2}
                            name={a.questionId}
                            value={a.option2}
                            checked={handleChecked(
                              a.option2,
                              a.selectedOption,
                              a.correctOption
                            )}
                            className={
                              faculty
                                ? "my-exam-question-select-checkbox my-exam-question-select-faculty-checkbox"
                                : "my-exam-question-select-checkbox"
                            }
                            disabled={module.isStatus}
                            onChange={(event) => handleOptionChange(event, a)}
                          />
                          <label
                            checked={a.option2 === a.selectedOption}
                            htmlFor={a.option2}
                          >
                            {a.option2}
                          </label>
                          <br />
                        </div>

                        <div className="option-container">
                          <input
                            type="radio"
                            id={a.option3}
                            name={a.questionId}
                            value={a.option3}
                            checked={handleChecked(
                              a.option3,
                              a.selectedOption,
                              a.correctOption
                            )}
                            className={
                              faculty
                                ? "my-exam-question-select-checkbox my-exam-question-select-faculty-checkbox"
                                : "my-exam-question-select-checkbox"
                            }
                            disabled={module.isStatus}
                            onChange={(event) => handleOptionChange(event, a)}
                          />
                          <label
                            htmlFor={a.option3}
                            checked={a.option3 === a.selectedOption}
                          >
                            {a.option3}
                          </label>
                          <br />
                        </div>

                        <div className="option-container">
                          <input
                            type="radio"
                            id={a.option4}
                            name={a.questionId}
                            value={a.option4}
                            checked={handleChecked(
                              a.option4,
                              a.selectedOption,
                              a.correctOption
                            )}
                            // className="my-exam-question-select-checkbox"
                            className={
                              faculty
                                ? "my-exam-question-select-checkbox my-exam-question-select-faculty-checkbox"
                                : "my-exam-question-select-checkbox"
                            }
                            disabled={module.isStatus}
                            onChange={(event) => handleOptionChange(event, a)}
                          />
                          <label
                            checked={a.option4 === a.selectedOption}
                            htmlFor={a.option4}
                          >
                            {a.option4}
                          </label>
                          <br />
                        </div>

                        <div className="option-container">
                          <input
                            type="radio"
                            id={a.option5}
                            name={a.questionId}
                            value={a.option5}
                            checked={handleChecked(
                              a.option5,
                              a.selectedOption,
                              a.correctOption
                            )}
                            // className="my-exam-question-select-checkbox"
                            className={
                              faculty
                                ? "my-exam-question-select-checkbox my-exam-question-select-faculty-checkbox"
                                : "my-exam-question-select-checkbox"
                            }
                            disabled={module.isStatus}
                            onChange={(event) => handleOptionChange(event, a)}
                          />
                          <label
                            checked={a.option5 === a.selectedOption}
                            htmlFor={a.option5}
                          >
                            {a.option5}
                          </label>
                          <br />
                        </div>
                      </div>
                    ) : (
                      <div>
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            resize: "none",
                          }}
                        >
                          Comments/Queries if any{" "}
                          <span
                            style={{
                              fontSize: "12px",
                              fontWeight: "400",
                              color: "#7B7B7B",
                            }}
                          >
                            (max limit here in chars)
                          </span>
                        </p>
                        <textarea
                          name="remarks"
                          className="my-exam-question-input-100"
                          rows="3"
                          cols="50"
                          value={a.comments}
                          disabled={module.isStatus}
                          onChange={(event) => {
                            a.comments = event.target.value;
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className="my-exam-question-faculty-button-container pb-4">
              <button
                className="my-exam-question-form-cancel border-0"
                onClick={() => {
                  setEditView(true);
                }}
              >
                Cancel
              </button>
              {!module.isStatus && (
                <button
                  className="my-exam-question-form-submit border-0"
                  onClick={submitFeedbackQuestions}
                >
                  Submit
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
export default StudentPortalFeedback;
