import React, { useContext, useRef, useState } from "react";
import useravatar from "assets/images/user-avatar.svg";
import useravatarblack from "assets/images/user-avatar-black.svg";
import "./Dashboard.css";
import { ThemeContext } from "ThemeContext";

function Dashboard() {
  const [activeTable, setActiveTable] = useState("pending");

  const { theme, toggleTheme } = useContext(ThemeContext);

  const handleTableToggle = (target) => {
    setActiveTable(target);
  };
  const dashboardData = [
    {
      moduleName: " Module or subject name module for the subject name",
      examType: "Exam Type",
      status: "on-going",
      content:
        "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut massa mi. Aliquam in hendrerit urna. Pellentesque sit ame sapien in hendrerit urna. Pel...",
      publisherName: "Name",
      publishedDate: " 11:00 AM, 12th Mar 2023",
    },
    {
      moduleName: " Module or subject name module for the subject name",
      examType: "Exam Type",
      status: "on-going",
      content:
        "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut massa mi. Aliquam in hendrerit urna. Pellentesque sit ame sapien in hendrerit urna. Pel...",
      publisherName: "Name",
      publishedDate: " 11:00 AM, 12th Mar 2023",
    },
    {
      moduleName: " Module or subject name module for the subject name",
      examType: "Exam Type",
      status: "on-going",
      content:
        "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut massa mi. Aliquam in hendrerit urna. Pellentesque sit ame sapien in hendrerit urna. Pel...",
      publisherName: "Name",
      publishedDate: " 11:00 AM, 12th Mar 2023",
    },
    {
      moduleName: " Module or subject name module for the subject name",
      examType: "Exam Type",
      status: "on-going",
      content:
        "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut massa mi. Aliquam in hendrerit urna. Pellentesque sit ame sapien in hendrerit urna. Pel...",
      publisherName: "Name",
      publishedDate: " 11:00 AM, 12th Mar 2023",
    },
    {
      moduleName: " Module or subject name module for the subject name",
      examType: "Exam Type",
      status: "on-going",
      content:
        "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut massa mi. Aliquam in hendrerit urna. Pellentesque sit ame sapien in hendrerit urna. Pel...",
      publisherName: "Name",
      publishedDate: " 11:00 AM, 12th Mar 2023",
    },
    {
      moduleName: " Module or subject name module for the subject name",
      examType: "Exam Type",
      status: "on-going",
      content:
        "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut massa mi. Aliquam in hendrerit urna. Pellentesque sit ame sapien in hendrerit urna. Pel...",
      publisherName: "Name",
      publishedDate: " 11:00 AM, 12th Mar 2023",
    },
    {
      moduleName: " Module or subject name module for the subject name",
      examType: "Exam Type",
      status: "on-going",
      content:
        "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut massa mi. Aliquam in hendrerit urna. Pellentesque sit ame sapien in hendrerit urna. Pel...",
      publisherName: "Name",
      publishedDate: " 11:00 AM, 12th Mar 2023",
    },
  ];
  const itemsPerPage = 3;
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(dashboardData.length / itemsPerPage);

  const scrollToTop = () => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    scrollToTop();
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const visibleData = dashboardData.slice(startIndex, endIndex);

  const topRef = useRef();

  return (
    <div className="view-leads-table-container pb-4" ref={topRef}>
      <div className="lead-table-head-container d-flex sticky-header gap-1 py-2 justify-content-between">
        <span className="user-title-text">Exam Status</span>
        {totalPages > 1 && (
          <div className="pagination-data-db">
            <button
              className={`pagination-button ${
                currentPage === 1 ? "disabled" : ""
              }`}
              onClick={() =>
                handlePageChange(currentPage > 1 ? currentPage - 1 : 1)
              }
              disabled={currentPage === 1}
            >
              {"<"}
            </button>
            <span className="pagination-text">
              {currentPage}/{totalPages}
            </span>
            <button
              className={`pagination-button ${
                currentPage === totalPages ? "disabled" : ""
              }`}
              onClick={() =>
                handlePageChange(
                  currentPage < totalPages ? currentPage + 1 : totalPages
                )
              }
              disabled={currentPage === totalPages}
            >
              {">"}
            </button>
          </div>
        )}
      </div>

      <div className="exam-module-dashboard-grid-con mt-2">
        {visibleData.map((item, index) => (
          <div key={index} className="exam-module-dashboard-grid-card">
            <span className="exam-dashboard-head">{item.moduleName}</span>
            <div className="justify-content-between d-flex exam-db-head-con">
              <span className="exam-db-subhead">{item.examType}</span>
              <span className="exam-db-status-text">{item.status}</span>
            </div>
            <span className="exam-db-text">{item.content}</span>
            <div className="d-flex column align-items-center gap-2 mt-3 ">
              {theme === "light" ? (
                <img
                  alt="img"
                  src={useravatarblack}
                  className="exam-module-img"
                />
              ) : (
                <img alt="img" src={useravatar} className="exam-module-img" />
              )}
              <div className="d-flex row">
                <span className="exam-db-name-text">{item.publisherName}</span>
                <span className="exam-db-date-text">
                  published this on{" "}
                  <span
                    style={{ color: theme === "light" ? "#000000" : "#ffffff" }}
                  >
                    {item.publishedDate}
                  </span>
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="row g-0">
        <div className="table-container-db col-12 col-lg-8 pe-lg-4 mt-2 mt-lg-4">
          <span>Exam Manager</span>
          <span className="dashboard-table-conatainer mt-2">
            <div className="d-flex justify-content-between gap-2 flex-wrap w-100">
              <div className="d-flex flex-wrap gap-2">
                <span
                  className={
                    activeTable === "pending"
                      ? "dashboard-table-active"
                      : "dashboard-table-text"
                  }
                  onClick={() => handleTableToggle("pending")}
                >
                  Pending
                </span>
                <span
                  className={
                    activeTable === "reviewed"
                      ? "dashboard-table-active"
                      : "dashboard-table-text"
                  }
                  onClick={() => handleTableToggle("reviewed")}
                >
                  Reviewed
                </span>
                <span
                  className={
                    activeTable === "published"
                      ? "dashboard-table-active"
                      : "dashboard-table-text"
                  }
                  onClick={() => handleTableToggle("published")}
                >
                  Published
                </span>
              </div>
              <span className="view-exam-text">view all exams</span>
            </div>
            <div className="custom-scrollbar w-100 mt-2">
              {activeTable === "pending" && (
                <table
                  style={{
                    width: "100%",
                    // backgroundColor: "#333333",
                    backgroundColor: theme === "light" ? "#ffffff" : "#333333",
                  }}
                >
                  <thead className="table-head-db">
                    <tr className="home-task-table">
                      <th>Module name</th>
                      <th>Program Code</th>
                      <th>Sections</th>
                      <th>Questions</th>
                      <th>Semester</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody className="table-data">
                    <tr className="db-tr-data ">
                      <td>hi</td>
                      <td>hi</td>
                      <td>hi</td>
                    </tr>
                    <tr className="db-tr-data  ">
                      <td>hi</td>
                      <td>hi</td>
                      <td>hi</td>
                    </tr>
                    <tr className="db-tr-data ">
                      <td>hi</td>
                      <td>hi</td>
                      <td>hi</td>
                    </tr>
                  </tbody>
                </table>
              )}
              {activeTable === "reviewed" && (
                <table
                  style={{
                    width: "100%",
                    backgroundColor: theme === "light" ? "#ffffff" : "#333333",
                  }}
                >
                  <thead className="table-head-db">
                    <tr className="home-task-table">
                      <th>Module name</th>
                      <th>Program Code</th>
                      <th>Sections</th>
                      <th>Questions</th>
                      <th>Semester</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody className="table-data">
                    <tr className="db-tr-data ">
                      <td>hello</td>
                      <td>hello</td>
                      <td>hello</td>
                    </tr>
                    <tr className="db-tr-data  ">
                      <td>hello</td>
                      <td>hello</td>
                      <td>hello</td>
                    </tr>
                    <tr className="db-tr-data ">
                      <td>hello</td>
                      <td>hello</td>
                      <td>hello</td>
                    </tr>
                  </tbody>
                </table>
              )}
              {activeTable === "published" && (
                <table
                  style={{
                    width: "100%",
                    backgroundColor: theme === "light" ? "#ffffff" : "#333333",
                  }}
                >
                  <thead className="table-head-db">
                    <tr className="home-task-table">
                      <th>Module name</th>
                      <th>Program Code</th>
                      <th>Sections</th>
                      <th>Questions</th>
                      <th>Semester</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody className="table-data">
                    <tr className="db-tr-data ">
                      <td>hello</td>
                      <td>hey</td>
                      <td>hey</td>
                    </tr>
                    <tr className="db-tr-data  ">
                      <td>hey</td>
                      <td>hey</td>
                      <td>hey</td>
                    </tr>
                    <tr className="db-tr-data ">
                      <td>hey</td>
                      <td>hey</td>
                      <td>hey</td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </span>
        </div>
        <div className="col-12 col-sm-8 col-md-6 col-lg-4 mt-2 mt-lg-4">
          <span>On-going Sessions</span>
          <div className="exam-status-container mt-2">
            <div className=""></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
