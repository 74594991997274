import { ErrorMessage, Field, Form, Formik } from "formik";
import { RxTriangleDown } from "react-icons/rx";
import Select from "react-select";
import { toast } from "react-toastify";
import { axiosService } from "services/axios.service";
import * as Yup from "yup";
import "./CreateProgramDetails.css";
import { useLocation, useNavigate } from "react-router-dom";
import { ADMISSION_CONSTANTS_FOR_STUDENTS } from "../../constants/admission-application-student-constants";
import { ADMISSION_URL_FOR_STUDENT } from "../../constants/admission-application-student-urls";
import { useContext, useEffect, useState } from "react";
import sessionstorageService from "services/sessionstorage.service";
import { RiCloseCircleFill } from "react-icons/ri";
import { IoIosCheckmarkCircle } from "react-icons/io";
import AdmissionAppilicationSidebar from "../admission-appilication-sidebar/AdmissionAppilicationSidebar";
import { ADMISSION_MANAGEMENT_URL } from "modules/admission-management/admission-management/components/create-admission/constants/admission-management-urls";
import { ThemeContext } from "ThemeContext";

function CreateProgramDetials() {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state?.data;

  const [admissionData, setAdmissionData] = useState(null);
  const [feeData, setFeeData] = useState(null);
  const admissionId = sessionstorageService.getAdmissionId("admissionId");
  const [diplomaOptions, setDiplomaOptions] = useState([]);
  const [programFee, setProgramFee] = useState(null);
  const [isMorning, setIsMorning] = useState("");
  const [isAfternoon, setIsAfternoon] = useState("");
  const [isEvening, setIsEvening] = useState("");
  const [approved, setApproved] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [isFeeData, setIsFeeData] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const { theme, toggleTheme } = useContext(ThemeContext);

  useEffect(() => {
    if (data?.programId) {
      axiosService
        .getForUrl(
          `${ADMISSION_URL_FOR_STUDENT.GET_PROGRAM_BY_PROGRAMID_FOR_STUDENT}${data?.programId}`
        )
        .then((response) => {
          const datas = response?.data?.data;
          setAdmissionData(datas);
          setApproved(data?.isApproved);
          setRejected(data?.isRejected);
          getCampusOptions(datas?.program);
          setProgramFee(datas?.programFee);
          setOverallScore(datas?.score);
          axiosService
            .get(
              `${ADMISSION_URL_FOR_STUDENT.GET_PROGRAM_AND_FEE_POPULATED_VALUES}?program=${datas?.program}&campus=${datas?.campus}`
            )
            .then((response) => {
              const data = response?.data?.data;
              setStartDate(data.programDates?.startDates);
              setIsMorning(data?.isMorning);
              setIsAfternoon(data?.isAfternoon);
              setIsEvening(data?.isEvening);
              setStartTime(data?.startTime);
              setEndTime(data?.endTime);
            })
            .catch((error) => {});
        });

      axiosService
        .getForUrl(
          `${ADMISSION_URL_FOR_STUDENT.GET_FEE_BY_PROGRAMID_FOR_STUDENT}${data?.programId}`
        )
        .then((response) => {
          const datas = response?.data?.data;
          setFeeData(datas);
          const feeId = datas?.feeId;

          if (feeId != undefined) {
            axiosService
              .get(
                `${ADMISSION_MANAGEMENT_URL.GET_ADMISSION_AND_PROGRAM}${admissionId}?programId=${data?.programId}&feeId=${feeId}`
              )
              .then((response) => {
                const data = response.data;
                setIsFeeData(data?.fee?.isCompleted);
              });
          }
        });
    }
  }, [location.pathname]);

  useEffect(() => {
    const fetchDiplomaOptions = async () => {
      axiosService
        .get(
          `${ADMISSION_URL_FOR_STUDENT.GET_PROGRAM_OPTIONS_FOR_PROGRAM_DETAIL}`
        )
        .then((response) => {
          setDiplomaOptions(response.data.data);
        });
    };

    fetchDiplomaOptions();
  }, []);

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "0 !important",
      width: "210px !important",
    }),
  };
  const selectStylesProgram = {
    control: (provided) => ({
      ...provided,
      borderRadius: "0 !important",
      width: "445px !important",
    }),
  };
  const Options = [
    { value: "YES", label: "Yes" },
    { value: "NO", label: "No" },
  ];
  const academicStatusOptions = [
    { value: "PARTTIME", label: "Part Time" },
    { value: "FULLTIME", label: "Full Time" },
  ];
  const trainingProgramOptions = [
    { value: "VOCATIONALANDACADEMIC", label: "Vocational,Academic" },
    { value: "NONVOCATIONAL", label: "Non-Vocational" },
  ];
  const studyLevelOptions = [
    { value: "DIPLOMA", label: "Diploma" },
    { value: "CERTIFICATE", label: "Certificate" },
    { value: "POSTGRADUATEDIPLOMA", label: "Post Graduation Diploma" },
  ];

  const Test = [
    { value: "TOFEL", label: "TOFEL" },
    { value: "IELTS", label: "IELTS" },
    { value: "PTE", label: "PTE" },
    { value: "DUOLINGO", label: "DUOLINGO" },
    { value: "OTHER", label: "OTHER" },
  ];
  const ModeOfLearning = [
    { value: "CAMPUS", label: "Campus" },
    { value: "ONLINE", label: "Online" },
    { value: "HYBRID", label: "Hybrid" },
  ];
  const [campusOptions, setCampusOptions] = useState(null);
  const [overallScore, setOverallScore] = useState(null);
  const initialValues = {
    program: "",
    campus: "",
    academicStatus: "",
    programDuration: "",
    exchangeProgram: "",
    programType: "",
    studyLevel: "",
    scholarship: "",
    internship: "",
    hoursPerWeek: "",
    // modeOfLearning: "",
    classTiming: "",
    fromDate: "",
    toDate: "",
    expirationAcceptanceLetter: "",
    test: "",
    otherTestName: "",
    testDate: "",
    score: "",
    credits: "",
    enrollmentDate: "",
    expectedEnrollment: "",
    midiLetter: "",
    otherInformation: "",
  };
  const validationSchema = Yup.object().shape({
    test: Yup.string().required("Test is required"),

    program: Yup.string().required("Program is required"),
  });

  const handleChange = (values) => {
    const { listening, reading, writing, speaking } = values;

    const isEmptyOrNaN = [listening, reading, writing, speaking].some(
      (value) => value === "" || isNaN(parseFloat(value))
    );

    if (!isEmptyOrNaN) {
      const sum =
        parseFloat(listening) +
        parseFloat(reading) +
        parseFloat(writing) +
        parseFloat(speaking);
      const score = sum / 4;
      setOverallScore(score.toFixed(2));
    } else {
      setOverallScore("0");
    }
  };

  const handleSubmit = async (values) => {
    const dataType = "program";
    values.isRejected = false;

    const { startDate, ...valuesWithoutStartDate } = values;
    const valuesWithProgramFee = {
      ...valuesWithoutStartDate,
      score: overallScore,
      programFee: { programFeeMasterId: programFee?.programFeeMasterId },
    };

    const saveData = {
      dataType,
      data: {
        admissionId: admissionId,
        programDetails: [valuesWithProgramFee],
      },
    };

    try {
      const response = await axiosService.post(
        `${ADMISSION_URL_FOR_STUDENT.ADMISSION_URL}`,
        saveData
      );

      if (response.status === 200) {
        toast.success(ADMISSION_CONSTANTS_FOR_STUDENTS.PROGRAM_SUCCESS);
        if (feeData?.feeId != undefined) {
          const feeValues = {
            applicationFee: programFee?.feeMaster?.applicationFee,
            bursaries: programFee?.feeMaster?.bursaries,
            materialFee: programFee?.feeMaster?.materialFee,
            fieldTripsFee: programFee?.feeMaster?.fieldTripsFee,
            internationalFee: programFee?.feeMaster?.internationalFee,
            eslFee: programFee?.feeMaster?.eslFee,
            bankFee: programFee?.feeMaster?.bankFee,
            otherFee: programFee?.feeMaster?.otherFee,
            tuitionFee: programFee?.feeMaster?.tuitionFee,
            otherFeeDetail: programFee?.feeMaster?.otherFeeDetail,
            isRejected: feeData?.isRejected,
            isApproved: feeData?.isApproved,
            feeId: feeData?.feeId,
            programDetail: {
              programId: response?.data?.data?.programDetails[0]?.programId,
            },
          };

          const dataType = "fees";
          const FeeData = {
            dataType,
            data: {
              admissionId: admissionId,
              fees: [feeValues],
            },
          };

          try {
            const response = await axiosService.post(
              `${ADMISSION_URL_FOR_STUDENT.ADMISSION_URL}`,
              FeeData
            );
            if (response.status === 200) {
              navigate("/pre-onboarding/create-financial", {
                state: {
                  data: {
                    admissionId: admissionId,
                    programId:
                      response?.data?.data?.fees[0]?.programDetail?.programId,
                  },
                },
              });
            } else {
              toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.PROGRAM_FAILED);
            }
          } catch (error) {
            toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.PROGRAM_FAILED);
          }
        } else {
          navigate("/pre-onboarding/create-financial", {
            state: {
              data: {
                admissionId: admissionId,
                programId: response?.data?.data?.programDetails[0]?.programId,
                feeData: {
                  applicationFee: programFee?.feeMaster?.applicationFee,
                  bursaries: programFee?.feeMaster?.bursaries,
                  materialFee: programFee?.feeMaster?.materialFee,
                  fieldTripsFee: programFee?.feeMaster?.fieldTripsFee,
                  internationalFee: programFee?.feeMaster?.internationalFee,
                  eslFee: programFee?.feeMaster?.eslFee,
                  bankFee: programFee?.feeMaster?.bankFee,
                  otherFee: programFee?.feeMaster?.otherFee,
                  tuitionFee: programFee?.feeMaster?.tuitionFee,
                  otherFeeDetail: programFee?.feeMaster?.otherFeeDetail,
                  feeMasterId: programFee?.feeMaster?.feeMasterId,
                },
              },
            },
          });
        }
      } else {
        toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.PROGRAM_FAILED);
      }
    } catch (error) {
      if (error.response) {
        toast.error(`Error: ${error.response.data.message}`);
      } else {
        toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.ERROR_OCCUR);
      }
    }
  };

  const dateString = new Date(admissionData?.updatedOn);
  const [startDate, setStartDate] = useState([]);
  const [programData, setProgramData] = useState([]);

  const formattedDate =
    dateString instanceof Date && !isNaN(dateString)
      ? dateString.toLocaleDateString()
      : "Invalid Date";

  const getValuesOfProgramAndCampus = (form, campusValue) => {
    axiosService
      .getForUrl(
        `${ADMISSION_URL_FOR_STUDENT.GET_PROGRAM_AND_FEE_POPULATED_VALUES}?program=${form.values.program}&campus=${campusValue}`
      )
      .then((response) => {
        const data = response?.data?.data;

        setProgramData(data);
        form.setFieldValue("academicStatus", data.academicStatus);
        form.setFieldValue("programDuration", data.programDuration);
        form.setFieldValue("exchangeProgram", data.exchangeProgram);
        form.setFieldValue("programType", data.programType);
        form.setFieldValue("programType", data.programType);
        form.setFieldValue("otherProgram", data.otherProgram);
        form.setFieldValue("studyLevel", data.studyLevel);
        form.setFieldValue("scholarship", data.scholarship);
        form.setFieldValue("internship", data.workPracticum);
        form.setFieldValue("hoursPerWeek", data.hoursPerWeek);
        // form.setFieldValue("modeOfLearning", data.modeOfLearning);
        setIsMorning(data?.isMorning);
        setIsAfternoon(data?.isAfternoon);
        setIsEvening(data?.isEvening);

        setStartDate(data.programDates?.startDates);
        setStartTime(data?.startTime);
        setEndTime(data?.endTime);

        if (!admissionData) {
          const currentDate = new Date();
          const futureDate = new Date(currentDate);
          futureDate.setMonth(currentDate.getMonth() + 2);
          const formattedDate = futureDate.toISOString().split("T")[0];
          form.setFieldValue("expirationAcceptanceLetter", formattedDate);
        }
        setProgramFee(data);
      })
      .catch((error) => {});
  };

  const getCampusOptions = (programValue) => {
    axiosService
      .getForUrl(
        `${ADMISSION_URL_FOR_STUDENT.GET_PROGRAM_CAMPUS_OPTIONS_FOR_PROGRAM_DETAIL}${programValue}`
      )
      .then((response) => {
        const data = response?.data?.data;
        setCampusOptions(data);
      })
      .catch((error) => {});
  };

  const getEndDate = (startDate, form) => {
    const duration = form.values.programDuration;

    if (startDate !== "") {
      axiosService
        .get(
          `${ADMISSION_URL_FOR_STUDENT.GET_END_DATE_BY_PARAMS}?startDate=${startDate}&duration=${duration}`
        )
        .then((response) => {
          const data = response?.data?.data;
          form.setFieldValue("toDate", data);
        })
        .catch((error) => {});
    }
  };

  const startDateOptions = startDate
    ? startDate.map((date, index) => ({
        value: date,
        label: date,
      }))
    : [];

  const getClassTimingOptions = () => {
    const options = [];

    if (isMorning) {
      options.push({
        value: "06:30 AM - 10:30 AM",
        label: "06:30 AM - 10:30 AM",
      });
    }
    if (isAfternoon) {
      options.push({
        value: "02:30 PM - 06:30 PM",
        label: "02:30 PM - 06:30 PM",
      });
    }
    if (isEvening) {
      options.push({
        value: "06:30 PM - 10:30 PM",
        label: "06:30 PM - 10:30 PM",
      });
    }

    if (!isMorning && !isAfternoon && !isEvening && startTime && endTime) {
      options.push({
        value: `${startTime} - ${endTime}`,
        label: `${startTime} - ${endTime}`,
      });
    }

    return options;
  };

  const options = getClassTimingOptions();

  const handleNumericInputChange = (
    e,
    formikProps,
    handleChange,
    fieldName
  ) => {
    const { value } = e.target;
    const regex = /^$|^[0-9]*\.?[0-9]*$/;
    if (regex.test(value) || value === "") {
      formikProps.handleChange(e);
      handleChange({
        ...formikProps.values,
        [fieldName]: value,
      });
    }
  };

  return (
    <Formik
      initialValues={admissionData || initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {(formikProps) => (
        <Form>
          <div className="create-admission-main-container">
            <div className="progress-container">
              <div className="create-admission-head-text">
                Create New Application
              </div>
            </div>
            <div className="row create-admission-container g-0">
              <div className="create-admission-inner-left-container col-12 col-lg-3 pt-2">
                <AdmissionAppilicationSidebar
                  data={{
                    admissionId: admissionId,
                    programId: data?.programId,
                  }}
                />
              </div>
              <div className="create-admission-inner-right-container col-12 col-lg-9 py-2">
                <div className="create-text-title ">Program Details</div>
                <div className="create-program-main-input-container">
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active width-label-program">
                      Program *
                    </label>
                    <Field
                      name="program"
                      render={({ field, form }) => (
                        <Select
                          id="program"
                          value={
                            field.value
                              ? diplomaOptions.find(
                                  (option) => option.value === field.value
                                )
                              : null
                          }
                          onChange={(option) => {
                            form.setFieldValue(field.name, option.value);
                            form.setFieldTouched(field.name, null);
                            getCampusOptions(option.value);
                            form.setFieldValue("campus", "");
                            form.setFieldValue("academicStatus", "");
                            form.setFieldValue("programDuration", "");
                            form.setFieldValue("exchangeProgram", "");
                            form.setFieldValue("programType", "");
                            form.setFieldValue("programType", "");
                            form.setFieldValue("otherProgram", "");
                            form.setFieldValue("studyLevel", "");
                            // form.setFieldValue("modeOfLearning", "");
                            form.setFieldValue("scholarship", "");
                            form.setFieldValue("internship", "");
                            form.setFieldValue("hoursPerWeek", "");
                            form.setFieldValue("classTiming", "");
                            form.setFieldValue("startDate", "");

                            form.setFieldValue(
                              "expirationAcceptanceLetter",
                              ""
                            );
                          }}
                          options={diplomaOptions}
                          isSearchable={false}
                          classNamePrefix="react-select"
                          styles={selectStylesProgram}
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown
                                color={
                                  theme === "light" ? "#000000" : "#ffffff"
                                }
                              />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose an Option"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      )}
                    />
                    {formikProps.touched.program &&
                      formikProps.errors.program && (
                        <div className="program-error-message">
                          {formikProps.errors.program}
                        </div>
                      )}
                  </div>
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active width-label-program">
                      Campus *
                    </label>
                    <Field
                      name="campus"
                      render={({ field, form }) => (
                        <Select
                          isDisabled={!form.values.program}
                          defaultValue={field.value}
                          value={
                            field.value
                              ? campusOptions?.find(
                                  (option) => option.value === field.value
                                )
                              : null
                          }
                          onChange={(option) => {
                            form.setFieldValue(field.name, option.value);
                            getValuesOfProgramAndCampus(form, option.value);
                          }}
                          options={campusOptions}
                          isSearchable={false}
                          classNamePrefix="react-select"
                          styles={selectStyles}
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown
                                color={
                                  theme === "light" ? "#000000" : "#ffffff"
                                }
                              />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose an Option"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      )}
                    />
                    {formikProps.touched.campus &&
                      formikProps.errors.campus && (
                        <div className="program-error-message">
                          {formikProps.errors.campus}
                        </div>
                      )}
                  </div>
                </div>

                <div className="create-program-main-input-container ">
                  <div className="create-admission-inner-first-input-container ">
                    <label className="label-text-active width-label-program">
                      Academic Status
                    </label>
                    <Field
                      name="academicStatus"
                      render={({ field, form }) => (
                        <Select
                          isDisabled={true}
                          value={academicStatusOptions.find(
                            (option) => option.value === field.value
                          )}
                          onChange={(option) =>
                            form.setFieldValue(field.name, option.value)
                          }
                          options={academicStatusOptions}
                          isSearchable={false}
                          classNamePrefix="react-select"
                          styles={selectStyles}
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown
                                color={
                                  theme === "light" ? "#000000" : "#ffffff"
                                }
                              />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      )}
                    />
                  </div>
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active width-label-program">
                      Program Duration{" "}
                      <span style={{ fontSize: "12px" }}>(In weeks)</span>
                    </label>
                    <Field
                      disabled={true}
                      type="text"
                      name="programDuration"
                      className="create-admission-input-for-program "
                    />
                  </div>

                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active width-label-program">
                      Exchange Program
                    </label>
                    <Field
                      name="exchangeProgram"
                      render={({ field, form }) => (
                        <Select
                          isDisabled={true}
                          value={Options.find(
                            (option) => option.value === field.value
                          )}
                          onChange={(option) =>
                            form.setFieldValue(field.name, option.value)
                          }
                          options={Options}
                          isSearchable={false}
                          classNamePrefix="react-select"
                          styles={selectStyles}
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown
                                color={
                                  theme === "light" ? "#000000" : "#ffffff"
                                }
                              />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="create-program-main-input-container">
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active width-label-program">
                      Type of Training Program
                    </label>
                    <Field
                      name="programType"
                      className="create-admission-select-small"
                      render={({ field, form }) => (
                        <Select
                          isDisabled={true}
                          value={trainingProgramOptions.find(
                            (option) => option.value === field.value
                          )}
                          onChange={(option) =>
                            form.setFieldValue(field.name, option.value)
                          }
                          options={trainingProgramOptions}
                          isSearchable={false}
                          classNamePrefix="react-select"
                          styles={selectStyles}
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown
                                color={
                                  theme === "light" ? "#000000" : "#ffffff"
                                }
                              />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      )}
                    />
                  </div>

                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active width-label-program">
                      or Other:
                    </label>
                    <Field
                      disabled={true}
                      type="text"
                      name="otherTrainingProgram"
                      className="create-admission-input-for-program"
                    />
                  </div>

                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active width-label-program">
                      Study Level
                    </label>
                    <Field
                      name="studyLevel"
                      render={({ field, form }) => (
                        <Select
                          isDisabled={true}
                          value={studyLevelOptions.find(
                            (option) => option.value === field.value
                          )}
                          onChange={(option) =>
                            form.setFieldValue(field.name, option.value)
                          }
                          options={studyLevelOptions}
                          isSearchable={false}
                          classNamePrefix="react-select"
                          styles={selectStyles}
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown
                                color={
                                  theme === "light" ? "#000000" : "#ffffff"
                                }
                              />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="create-program-main-input-container">
                  <div className="create-admission-inner-first-input-container ">
                    <label className="label-text-active width-label-program">
                      Scholarship
                    </label>
                    <Field
                      disabled={true}
                      type="text"
                      name="scholarship"
                      className="create-admission-input-for-program"
                    />
                  </div>
                  {/* <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active width-label-program">
                      Mode Of Learning
                    </label>
                    <Field
                      name="modeOfLearning"
                      render={({ field, form }) => (
                        <Select
                          isDisabled={true}
                          value={ModeOfLearning.find(
                            (option) => option.value === field.value
                          )}
                          onChange={(option) =>
                            form.setFieldValue(field.name, option.value)
                          }
                          options={ModeOfLearning}
                          isSearchable={false}
                          classNamePrefix="react-select"
                          styles={selectStyles}
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown
                                  color={
                                    theme === "light" ? "#000000" : "#ffffff"
                                  }
                                />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      )}
                    />
                  </div> */}
                </div>
                <div className="create-program-main-input-container">
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active width-label-program">
                      Internship/Practicum Length
                    </label>
                    <Field
                      disabled={true}
                      name="internship"
                      className="create-admission-input-for-program"
                    />
                  </div>
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active width-label-program ">
                      Hours of Instruction Per Week
                    </label>
                    <Field
                      disabled={true}
                      type="text"
                      name="hoursPerWeek"
                      className="create-admission-input-for-program"
                    />
                  </div>
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active width-label-program ">
                      Class Timing
                    </label>
                    <Field
                      name="classTiming"
                      render={({ field, form }) => (
                        <Select
                          defaultValue={field.value}
                          value={
                            field.value
                              ? options?.find(
                                  (option) => option.value === field.value
                                )
                              : null
                          }
                          onChange={(option) => {
                            form.setFieldValue(field.name, option.value);
                          }}
                          options={options}
                          isSearchable={false}
                          classNamePrefix="react-select"
                          styles={selectStyles}
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown
                                color={
                                  theme === "light" ? "#000000" : "#ffffff"
                                }
                              />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose an Option"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="create-program-main-input-container">
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active width-label-program">
                      Start Date
                    </label>
                    <Field
                      name="fromDate"
                      render={({ field, form }) => (
                        <Select
                          defaultValue={field.value}
                          value={
                            field.value
                              ? startDateOptions?.find(
                                  (option) => option.value === field.value
                                )
                              : null
                          }
                          onChange={(option) => {
                            form.setFieldValue(field.name, option.value);
                            getEndDate(option.value, form);
                          }}
                          options={startDateOptions}
                          isSearchable={false}
                          classNamePrefix="react-select"
                          styles={selectStyles}
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown
                                color={
                                  theme === "light" ? "#000000" : "#ffffff"
                                }
                              />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose an Option"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      )}
                    />
                  </div>

                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active width-label-program">
                      Expected End Date
                    </label>
                    <Field
                      disabled={true}
                      name="toDate"
                      className="create-admission-input-for-program"
                    />
                  </div>
                  <div className="create-admission-inner-first-input-container ">
                    <label className="label-text-active width-label-program ">
                      Expiration of Acceptance Letter
                    </label>
                    <Field
                      disabled={true}
                      type="date"
                      name="expirationAcceptanceLetter"
                      className="create-admission-input-for-program "
                    />
                  </div>
                </div>

                <div className="create-program-main-input-container">
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active width-label-program">
                      English
                    </label>
                    <label className="label-text-active width-label-program">
                      Test *
                    </label>
                    <Field
                      name="test"
                      component={({ field, form }) => (
                        <Select
                          options={Test}
                          value={Test.find(
                            (option) => option.value === field.value
                          )}
                          onChange={(option) => {
                            form.setFieldValue(field.name, option.value);

                            form.setFieldValue("otherTestName", "");
                            form.setFieldValue("score", "");
                            form.setFieldValue("testDate", "");
                          }}
                          isSearchable={false}
                          classNamePrefix="react-select"
                          styles={selectStyles}
                          components={{
                            DropdownIndicator: () => (
                              <RxTriangleDown
                                color={
                                  theme === "light" ? "#000000" : "#ffffff"
                                }
                              />
                            ),
                            IndicatorSeparator: null,
                          }}
                          placeholder="Choose"
                          menuPlacement="auto"
                          menuPosition="fixed"
                        ></Select>
                      )}
                    />
                    {formikProps.touched.test && formikProps.errors.test && (
                      <div
                        className="program-error-message"
                        style={{ bottom: "-13px" }}
                      >
                        {formikProps.errors.test}
                      </div>
                    )}
                  </div>

                  {formikProps.values.test && (
                    <>
                      {formikProps.values.test === "OTHER" && (
                        <div className="create-admission-inner-first-input-container">
                          <label className="label-text-active width-label-program">
                            Name *
                          </label>
                          <Field
                            type="text"
                            name="otherTestName"
                            className="create-admission-input-for-program"
                            placeholder="Enter name here"
                          />
                        </div>
                      )}

                      <div className="create-admission-inner-first-input-container">
                        <label className="label-text-active width-label-program">
                          Test Date *
                        </label>
                        <Field
                          type="date"
                          name="testDate"
                          className="create-admission-input-for-program"
                        />
                      </div>
                    </>
                  )}

                  {formikProps.values.test && (
                    <div
                      className="create-program-main-input-container"
                      style={{ marginTop: "-20px" }}
                    >
                      <div className="create-admission-inner-first-input-container">
                        <label className="label-text-active width-label-program">
                          {formikProps.values.test === "DUOLINGO"
                            ? "Literacy"
                            : "Listening"}
                        </label>
                        <Field
                          name="listening"
                          className="create-admission-input-for-program-test"
                          onChange={(e) =>
                            handleNumericInputChange(
                              e,
                              formikProps,
                              handleChange,
                              "listening"
                            )
                          }
                          inputMode="numeric"
                        />
                        <ErrorMessage
                          name="listening"
                          component="div"
                          className="test-error-message"
                        />
                      </div>

                      <div className="create-admission-inner-first-input-container">
                        <label className="label-text-active width-label-program">
                          {formikProps.values.test === "DUOLINGO"
                            ? "Conversation"
                            : "Reading"}
                        </label>
                        <Field
                          name="reading"
                          className="create-admission-input-for-program-test"
                          onChange={(e) =>
                            handleNumericInputChange(
                              e,
                              formikProps,
                              handleChange,
                              "reading"
                            )
                          }
                          inputMode="numeric"
                        />
                        <ErrorMessage
                          name="reading"
                          component="div"
                          className="test-error-message"
                        />
                      </div>
                      <div className="create-admission-inner-first-input-container ">
                        <label className="label-text-active width-label-program ">
                          {formikProps.values.test === "DUOLINGO"
                            ? "Comprehension"
                            : "Writing"}
                        </label>
                        <Field
                          name="writing"
                          className="create-admission-input-for-program-test"
                          onChange={(e) =>
                            handleNumericInputChange(
                              e,
                              formikProps,
                              handleChange,
                              "writing"
                            )
                          }
                          inputMode="numeric"
                        />
                        <ErrorMessage
                          name="writing"
                          component="div"
                          className="test-error-message"
                        />
                      </div>
                      <div className="create-admission-inner-first-input-container ">
                        <label className="label-text-active width-label-program">
                          {formikProps.values.test === "DUOLINGO"
                            ? "Production"
                            : "Speaking"}
                        </label>
                        <Field
                          name="speaking"
                          className="create-admission-input-for-program-test"
                          onChange={(e) =>
                            handleNumericInputChange(
                              e,
                              formikProps,
                              handleChange,
                              "speaking"
                            )
                          }
                          inputMode="numeric"
                        />
                        <ErrorMessage
                          name="speaking"
                          component="div"
                          className="test-error-message"
                        />
                      </div>
                      <div className="create-admission-inner-first-input-container">
                        <label className="label-text-active width-label-program">
                          Overall Band Score *
                        </label>
                        <Field
                          name="score"
                          className="create-admission-input-for-program"
                          value={overallScore || ""}
                          onChange={(e) => setOverallScore(e.target.value)}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="create-program-main-input-container">
                  <div className="create-admission-inner-first-input-container ps-0">
                    <label
                      className="label-text-active width-label-program "
                      style={{ width: "200px", textWrap: "wrap" }}
                    >
                      Credits accepted from previous institute
                    </label>
                    <Field
                      type="text"
                      name="credits"
                      className="create-admission-input-for-program"
                    />
                  </div>
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active">Enrollment Date</label>
                    <Field
                      type="date"
                      name="enrollmentDate"
                      className="create-admission-input-for-program"
                    />
                  </div>
                  <div className="create-admission-inner-first-input-container ps-0">
                    <label className="label-text-active">
                      Expected Enrollment
                    </label>
                    <Field
                      type="text"
                      name="expectedEnrollment"
                      className="create-admission-input-for-program"
                    />
                  </div>
                </div>

                <div className="create-program-main-input-container">
                  <div className="create-admission-inner-first-input-container">
                    <label className="label-text-active width-label-program">
                      MIDI Letter
                    </label>
                    <Field
                      type="text"
                      name="midiLetter"
                      className="create-admission-input-for-program "
                    />
                  </div>
                  <div className="create-admission-inner-first-input-container ">
                    <label className="label-text-active width-label-program">
                      Other Information
                    </label>
                    <Field
                      type="text"
                      name="otherInformation"
                      className="create-admission-input-for-program"
                    />
                  </div>
                </div>
                <div className="d-flex gap-3 my-3 flex-wrap">
                  {!admissionData?.programId && (
                    <button
                      type="button"
                      className="create-admission-clear-button"
                      onClick={() => formikProps.resetForm()}
                    >
                      Clear
                    </button>
                  )}

                  {!admissionData?.programId ? (
                    <button
                      type="submit"
                      className="create-admission-save-button"
                    >
                      Save & Next
                    </button>
                  ) : (
                    (rejected || (!rejected && !approved && !isFeeData)) && (
                      <button
                        type="submit"
                        className="create-admission-save-button"
                      >
                        Edit & Save
                      </button>
                    )
                  )}

                  {(admissionData?.programId || admissionData?.isApproved) && (
                    <button
                      type="button"
                      className=" create-admission-next-button"
                      onClick={() =>
                        navigate("/pre-onboarding/create-financial", {
                          state: {
                            data: {
                              admissionId: admissionId,
                              programId: data?.programId,
                              feeData: {
                                applicationFee:
                                  programFee?.feeMaster?.applicationFee,
                                bursaries: programFee?.feeMaster?.bursaries,
                                materialFee: programFee?.feeMaster?.materialFee,
                                fieldTripsFee:
                                  programFee?.feeMaster?.fieldTripsFee,
                                internationalFee:
                                  programFee?.feeMaster?.internationalFee,
                                eslFee: programFee?.feeMaster?.eslFee,
                                bankFee: programFee?.feeMaster?.bankFee,
                                otherFee: programFee?.feeMaster?.otherFee,
                                tuitionFee: programFee?.feeMaster?.tuitionFee,
                                otherFeeDetail:
                                  programFee?.feeMaster?.otherFeeDetail,
                              },
                            },
                          },
                        })
                      }
                    >
                      Next
                    </button>
                  )}
                </div>
                {admissionData?.isApproved && (
                  <div className="row approve-reject-comments-container">
                    <span className="admission-comments-head-text">
                      Comments
                    </span>
                    <span className="approve-textarea">
                      <IoIosCheckmarkCircle className="admission-approve-check-icon" />
                      Approved
                    </span>
                    <span className="approve-date-text">
                      on {formattedDate}
                    </span>
                  </div>
                )}
                {admissionData?.isRejected && (
                  <div className="row approve-reject-comments-container">
                    <span className="admission-comments-head-text">
                      Comments
                    </span>
                    <div className="rejected-container p-0 mt-2">
                      <div className="rejcted-head-text">
                        <RiCloseCircleFill className="admission-reject-icon" />
                        Rejected
                      </div>
                      <div className="inner-reject-text">
                        {admissionData?.rejectComments}
                      </div>
                    </div>
                    <span className="approve-date-text">{formattedDate}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default CreateProgramDetials;
