import ClearIcon from "@mui/icons-material/Clear";
import Chip from "@mui/material/Chip";
import Radio from "@mui/material/Radio";
import Stack from "@mui/material/Stack";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import React, { useEffect, useRef, useState, useContext } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { IoClose } from "react-icons/io5";
import Modal from "react-modal";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosService } from "services/axios.service";
import sessionService from "services/sessionstorage.service";
import { ATTENDANCE_CONSTANTS } from "../constants/attendance-management-constants";
import { ATTENDANCE_MANAGEMENT_URLS } from "../constants/attendance-management-urls";
import "./ViewAttendance.css";
import { ThemeContext } from "ThemeContext";

function MarkAttendance() {
  const typeaheadRef = useRef(null);
  const location = useLocation();
  const [isAbsentopen, setAbsentOpen] = useState(false);
  const [isOnTimeopen, setOnTimeOpen] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const programModuleId = location.state.data.Data.module.programModuleId;
  const startTime = location.state.data.Data.startTime;
  const endTime = location.state.data.Data.endTime;
  const className = location.state.data.Data.module.module.moduleName;
  const Date = location.state.data.Data.startDate;
  const programName = location.state.data.Data.module.programFeeMaster.program;
  const shift = location.state.data.Data.shift;
  const campus = location.state.data.Data.module.programFeeMaster.campus;
  const resource = location.state.data.Data.resource.blockName;
  const scheduleId = location.state.data.Data.splitScheduleId;
  const [loadedData, setLoadedData] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultsForLate, setSearchResultsForLate] = useState([]);
  const [showMarkAllAbsent, setShowMarkAllAbsent] = useState(false);
  const [markOnTime, setMarkOnTime] = useState(false);
  const [studentPresent, setStudentPresent] = useState([]);
  const [studentAbsent, setStudentAbsent] = useState([]);
  const [studentLate, setStudentLate] = useState([]);
  const { theme, toggleTheme } = useContext(ThemeContext);

  const handleMarkAllForAbsent = (status) => {
    const updatedStudents = allStudentsData.map((student) => ({
      ...student,
      studentAbsent: null,
    }));
    setSelectedStudentsAbsent(updatedStudents);
    setSelectedStudentsLate([]);
    handleCloseAbsentModal();
  };

  const handleMarkAllForLate = (status) => {
    const updatedStudents = allStudentsData.map((student) => ({
      ...student,
      studentLate: null,
    }));
    setSelectedStudentsLate(updatedStudents);
    setSelectedStudentsAbsent([]);
    handleCloseOnTimeModal();
  };

  const handleUpdateSubmit = async () => {
    await handleSubmit(false);
  };

  const handleUpdateNotify = async () => {
    await handleSubmit(true);
  };
  const handleSubmit = async (isNotify) => {
    const facultyData = sessionService.getUserData("userData");
    const dataToSend = {
      startTime: startTime,
      endTime: endTime,
      markInDate: Date,
      facultyMarked: {
        userId: facultyData.userId,
      },
      schedule: {
        splitScheduleId: scheduleId,
      },
      studentPresent: studentPresent,
      studentAbsent: studentAbsent,
      studentLate: studentLate,
      isNotify: isNotify,
    };
    try {
      const response = await axiosService.post(
        `${ATTENDANCE_MANAGEMENT_URLS.POST_ATTENDANCE}?isNotify=${isNotify}`,
        dataToSend
      );
      if (response.status === 200) {
        toast.success(ATTENDANCE_CONSTANTS.SUCCESS);
        setIsSaveDisabled(true);
        navigate("/attendance-management/my-classes");
      } else {
        toast.success(response?.data?.message);
      }
    } catch (error) {
      toast.error(ATTENDANCE_CONSTANTS.ERROR_OCCUR);
    }
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const handleMarkAllLate = () => {
    setOnTimeOpen(true);
    setMarkOnTime(true);
  };
  const handleMarkAllAbsent = () => {
    setAbsentOpen(true);
    setShowMarkAllAbsent(true);
  };
  const handleCloseAbsentModal = () => {
    setAbsentOpen(false);
  };
  const handleCloseOnTimeModal = () => {
    setOnTimeOpen(false);
  };

  const handleRadioChange = (selectedStudent, type) => {
    if (selectedStudent && selectedStudent.studentId) {
      setSelectedStudentsAbsent((prevSelectedAbsent) =>
        prevSelectedAbsent.filter(
          (stu) => stu.studentId !== selectedStudent.studentId
        )
      );
      setSelectedStudentsLate((prevSelectedLate) =>
        prevSelectedLate.filter(
          (stu) => stu.studentId !== selectedStudent.studentId
        )
      );

      setStudentAbsent((prevAbsent) =>
        prevAbsent.filter((id) => id !== selectedStudent.studentMasterId)
      );
      setStudentLate((prevLate) =>
        prevLate.filter((id) => id !== selectedStudent.studentMasterId)
      );

      if (type === "present") {
      } else if (type === "absent") {
        setSelectedStudentsAbsent((prevSelectedAbsent) => [
          ...prevSelectedAbsent,
          selectedStudent,
        ]);
        setStudentAbsent((prevAbsent) => [
          ...prevAbsent,
          selectedStudent.studentMasterId,
        ]);
      } else if (type === "late") {
        setSelectedStudentsLate((prevSelectedLate) => [
          ...prevSelectedLate,
          selectedStudent,
        ]);
        setStudentLate((prevLate) => [
          ...prevLate,
          selectedStudent.studentMasterId,
        ]);
      }
    }
  };

  const getRadioStyle = (item) => {
    const colors = {
      a: "white",
      b: "red",
      c: "orange",
    };

    return {
      color: colors[item],
      padding: 0,
    };
  };
  const [selectedValue, setSelectedValue] = React.useState("a");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const navigate = useNavigate();
  const [allStudentsData, setAllStudentsData] = useState();
  const [selectedStudentsForLate, setSelectedStudentsLate] = useState([]);
  const [selectedStudentsAbsent, setSelectedStudentsAbsent] = useState([]);

  const onTimeCount = allStudentsData
    ? allStudentsData.length -
      (selectedStudentsAbsent.length + selectedStudentsForLate.length)
    : 0;

  const handleSelectionForAbsent = (selected) => {
    if (selected && selected[0]) {
      const selectedMasterId = selected[0].studentMasterId;

      setSelectedStudentsAbsent((prevSelectedAbsent) =>
        prevSelectedAbsent.some(
          (student) => student.studentMasterId === selectedMasterId
        )
          ? prevSelectedAbsent
          : [...prevSelectedAbsent, selected[0]]
      );
    }

    setStudentPresent((prevPresent) =>
      prevPresent.filter(
        (studentId) => studentId !== selected[0]?.studentMasterId
      )
    );

    setStudentAbsent((prevAbsent) =>
      (prevAbsent || []).concat(selected[0]?.studentMasterId)
    );

    if (typeaheadRef.current) {
      typeaheadRef.current.clear();
    }

    setAbsent("");
  };

  const handleSelectionForLate = (selected) => {
    if (selected && selected[0]) {
      const selectedMasterId = selected[0].studentMasterId;

      setSelectedStudentsLate((prevSelectedLate) =>
        prevSelectedLate.some(
          (student) => student.studentMasterId === selectedMasterId
        )
          ? prevSelectedLate
          : [...prevSelectedLate, selected[0]]
      );
    }

    setStudentPresent((prevPresent) =>
      prevPresent.filter(
        (studentId) => studentId !== selected[0]?.studentMasterId
      )
    );

    setStudentLate((prevLate) =>
      (prevLate || []).concat(selected[0]?.studentMasterId)
    );

    if (typeaheadRef.current) {
      typeaheadRef.current.clear();
    }

    setLate("");
  };

  const handleDeleteForAbsent = (studentToDelete) => {
    setSelectedStudentsAbsent((prevSelected) =>
      prevSelected.filter((student) => student !== studentToDelete)
    );
    setStudentAbsent((prevAbsent) =>
      prevAbsent?.filter(
        (studentMasterId) =>
          studentMasterId !== studentToDelete?.studentMasterId
      )
    );
  };
  const handleDeleteForLate = (studentToDelete) => {
    setSelectedStudentsLate((prevSelected) =>
      prevSelected.filter((student) => student !== studentToDelete)
    );
    setStudentLate((prevLate) =>
      prevLate?.filter(
        (studentMasterId) =>
          studentMasterId !== studentToDelete?.studentMasterId
      )
    );
  };

  const handleSearch = (query) => {
    setAbsent(query);
    const filteredResults = loadedData.filter(
      (item) =>
        item.admissionManagement.personalData.firstName
          ?.toLowerCase()
          .includes(query?.toLowerCase()) ||
        item.admissionManagement.personalData.lastName
          ?.toLowerCase()
          .includes(query?.toLowerCase()) ||
        item.studentId?.toLowerCase().includes(query?.toLowerCase())
    );
    setSearchResults(filteredResults);
  };

  const handleSearchForLate = (query) => {
    setLate(query);
    const filteredResults = loadedData.filter(
      (item) =>
        item.admissionManagement.personalData.firstName
          ?.toLowerCase()
          .includes(query?.toLowerCase()) ||
        item.admissionManagement.personalData.lastName
          ?.toLowerCase()
          .includes(query?.toLowerCase()) ||
        item.studentId?.toLowerCase().includes(query?.toLowerCase())
    );
    setSearchResultsForLate(filteredResults);
  };

  const filterBy = () => true;
  useEffect(() => {
    const defaultPresentStudents = allStudentsData
      ?.filter((student) => !studentAbsent?.includes(student?.studentMasterId))
      ?.filter((student) => !studentLate?.includes(student?.studentMasterId))
      ?.map((student) => student?.studentMasterId);

    setStudentPresent(defaultPresentStudents);
  }, [location.pathname, allStudentsData, studentAbsent, studentLate]);
  useEffect(() => {
    axiosService
      .get(
        `${ATTENDANCE_MANAGEMENT_URLS.GET_ALL_STUDENTS}?programModuleId=${programModuleId}&startTime=${startTime}&endTime=${endTime}`
      )
      .then((response) => {
        if (response.status === 200) {
          const responseData = response?.data;

          setAllStudentsData(responseData);
          setLoadedData(responseData);
        }
      });
  }, [location.pathname, studentAbsent, studentLate]);

  const [absent, setAbsent] = useState("");
  const [late, setLate] = useState("");

  return (
    <div className="view-leads-table-container">
      <div className="lead-table-head-container sticky-header flex-md-row gap-2 sticky-header">
        <span className="lead-table-title-text align-items-center">
          Class Name : <u>{className}</u>
        </span>
      </div>
      <div
        className="column d-flex mt-2"
        style={{ borderBottom: "1px solid #333333" }}
      >
        <div className=" align-items-start">
          <span className="Doc-head ">Class Details</span>
          <div className="mark-attendance-detail-con">
            <span className="d-flex align-items-center gap-1">
              <span
                className="mark-all-checkbox-label"
                style={{ fontWeight: 600 }}
              >
                Class Time
              </span>
              <span className="mark-all-checkbox-label p-1">
                {startTime} - {endTime}
              </span>
            </span>
            <span className="d-flex align-items-center gap-1">
              <span
                className="mark-all-checkbox-label"
                style={{ fontWeight: 600 }}
              >
                Date{" "}
              </span>
              <span className="mark-all-checkbox-label p-1"> {Date}</span>
            </span>
            <span className="d-flex align-items-center gap-1">
              <span
                className="mark-all-checkbox-label"
                style={{ fontWeight: 600 }}
              >
                Program
              </span>
              <span className="mark-all-checkbox-label p-1">{programName}</span>
            </span>
            <span className="d-flex align-items-center gap-1">
              <span
                className="mark-all-checkbox-label"
                style={{ fontWeight: 600 }}
              >
                Shift
              </span>
              <span className="mark-all-checkbox-label p-1"> {shift}</span>
            </span>
            <span className="d-flex align-items-center gap-1">
              <span
                className="mark-all-checkbox-label"
                style={{ fontWeight: 600 }}
              >
                Campus
              </span>
              <span className="mark-all-checkbox-label p-1"> {campus}</span>
            </span>
            <span className="d-flex align-items-center gap-1">
              <span
                className="mark-all-checkbox-label"
                style={{ fontWeight: 600 }}
              >
                Facility ID
              </span>
              <span className="mark-all-checkbox-label p-1"> {resource}</span>
            </span>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between mt-2 gap-2 flex-wrap">
        <div>
          <span className="Doc-head ">Attendance Details</span>
        </div>
        <div className="d-flex gap-2">
          <span
            style={{
              borderRight: "1px solid rgba(82, 83, 86, 1)",
              paddingRight: "8px",
            }}
            className="me-2 d-flex gap-1 align-items-center"
          >
            <span className="mark-all-checkbox-label">On Time</span>
            <span className="" style={{ fontSize: "18px" }}>
              {onTimeCount}
            </span>
          </span>
          <span
            style={{
              borderRight: "1px solid rgba(82, 83, 86, 1)",
              paddingRight: "8px",
            }}
            className="me-2 d-flex gap-1 align-items-center"
          >
            <span className="mark-all-checkbox-label">Late</span>
            <span
              className="lead-table-text-orange"
              style={{ fontSize: "18px" }}
            >
              {selectedStudentsForLate.length}
            </span>
          </span>
          <span
            style={{
              borderRight: "1px solid rgba(82, 83, 86, 1)",
              paddingRight: "8px",
            }}
            className="me-2 d-flex gap-1 align-items-center"
          >
            <span className="mark-all-checkbox-label">Absent</span>
            <span className=" lead-table-text-red" style={{ fontSize: "18px" }}>
              {selectedStudentsAbsent.length}
            </span>
          </span>
          <span className="d-flex gap-1 align-items-center">
            <span className="mark-all-checkbox-label">Total</span>
            <span className="" style={{ fontSize: "18px" }}>
              {allStudentsData?.length}
            </span>
          </span>
        </div>
      </div>

      <div className="mt-3">
        <p className="mark-all-checkbox-label m-0">Students who were absent</p>
        <div className="p-2 card-view d-flex flex-column gap-2 mt-1">
          {selectedStudentsAbsent?.length > 0 && (
            <div className="d-flex flex-wrap">
              <Stack direction="row" spacing={5}>
                {selectedStudentsAbsent.map((student, index) => (
                  <Chip
                    key={index}
                    label={
                      student?.admissionManagement?.personalData?.firstName +
                      " " +
                      student?.admissionManagement?.personalData?.lastName
                    }
                    style={{
                      color: "rgba(255, 255, 255, 1)",
                      fontWeight: 400,
                      fontSize: "13px",
                      borderColor: "rgba(246, 65, 58, 1)",
                      height: "30px",
                    }}
                    variant="outlined"
                    onDelete={() => handleDeleteForAbsent(student)}
                    deleteIcon={
                      <ClearIcon
                        style={{
                          color: "rgba(255, 255, 255, 1)",
                          fontSize: "15px",
                        }}
                      />
                    }
                  />
                ))}
              </Stack>
            </div>
          )}
          <div className="input-container">
            {(absent?.length < 1 ||
              absent === null ||
              absent === undefined) && (
              <span
                className="red-text"
                style={{
                  // color: "rgba(140, 140, 140, 1)",
                  color: theme === "light" ? "#000000" : "#ffffff",
                  textWrap: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "90%",
                  paddingLeft: "5px",
                }}
              >
                {/* <span style={{ color: "white" }}> | </span> */}
                Type roll no, name, or mark them as absent (
                <span style={{ color: "red" }}>red</span>) in student list
              </span>
            )}

            <AsyncTypeahead
              filterBy={filterBy}
              id="async-example"
              inputProps={{
                style: {
                  color: "white",
                  // backgroundColor: "rgba(51, 51, 51, 1)",
                  backgroundColor: theme === "light" ? "#ffffff" : "#212226",
                  height: "40px",
                },
              }}
              onInputChange={(input) => {
                if (input === "") {
                  setAbsent("");
                }
              }}
              options={searchResults}
              value={absent}
              minLength={1}
              onSearch={handleSearch}
              onChange={handleSelectionForAbsent}
              labelKey={(option) => `${""}`}
              renderMenuItemChildren={(option, props) => (
                <div>
                  <span>
                    {option?.admissionManagement?.personalData?.firstName}
                    {option?.admissionManagement?.personalData?.lastName}
                  </span>
                  <small
                    style={{
                      color: option?.someCondition
                        ? "red"
                        : "rgba(105, 105, 105, 1)",
                    }}
                  >
                    # {option?.studentId}
                  </small>
                </div>
              )}
            />
          </div>
        </div>
      </div>
      <div className="mt-3">
        <p className="mark-all-checkbox-label m-0">
          Students who were late (joined after 15 minutes)
        </p>
        <div className="p-2 card-view d-flex flex-column gap-2 mt-1">
          {selectedStudentsForLate?.length > 0 && (
            <div className="d-flex flex-wrap">
              <Stack direction="row" spacing={5}>
                {selectedStudentsForLate.map((student, index) => (
                  <Chip
                    key={index}
                    label={
                      student?.admissionManagement?.personalData?.firstName +
                      " " +
                      student?.admissionManagement?.personalData?.lastName
                    }
                    style={{
                      color: "rgba(255, 255, 255, 1)",
                      borderColor: "rgba(246, 159, 58, 1)",
                      fontWeight: 400,
                      fontSize: "13px",
                      height: "30px",
                    }}
                    variant="outlined"
                    onDelete={() => handleDeleteForLate(student)}
                    deleteIcon={
                      <ClearIcon
                        style={{
                          color: "rgba(255, 255, 255, 1)",
                          fontSize: "15px",
                        }}
                      />
                    }
                  />
                ))}
              </Stack>
            </div>
          )}

          <div className="input-container">
            {late?.length < 1 && (
              <span
                className="red-text"
                style={{
                  color: "rgba(140, 140, 140, 1)",
                  textWrap: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "90%",
                  paddingLeft: "5px",
                }}
              >
                {/* <span style={{ color: "white" }}> | </span> */}
                Type roll no, name or mark them as absent (
                <span style={{ color: "rgba(246, 159, 58, 1)" }}>yellow</span>)
                in student list
              </span>
            )}
            <AsyncTypeahead
              filterBy={filterBy}
              id="async-example"
              style={{ color: "rgba(140, 140, 140, 1)" }}
              inputProps={{
                style: {
                  color: "white",
                  // backgroundColor: "rgba(51, 51, 51, 1)",
                  backgroundColor: theme === "light" ? "#ffffff" : "#212226",
                  height: "40px",
                },
              }}
              onInputChange={(input) => {
                if (input === "") {
                  setLate("");
                }
              }}
              options={searchResultsForLate}
              value={late}
              minLength={1}
              onSearch={handleSearchForLate}
              onChange={handleSelectionForLate}
              labelKey={(option) => `${""}`}
              renderMenuItemChildren={(option, props) => (
                <div>
                  <span>
                    {option?.admissionManagement?.personalData?.firstName}
                    {option?.admissionManagement?.personalData?.lastName}
                  </span>
                  <small
                    style={{
                      color: option?.someCondition
                        ? "red"
                        : "rgba(105, 105, 105, 1)",
                    }}
                  >
                    # {option?.studentId}
                  </small>
                </div>
              )}
            />
          </div>
        </div>
      </div>

      <div className="card-view d-flex flex-column gap-2 mt-3 p-2">
        <div className="d-flex justify-content-between flex-wrap gap-2">
          <p className="mark-all-checkbox-label p-0 m-0">All Students List</p>

          <div className="d-flex gap-2 gap-md-5">
            <div className="d-flex align-items-center gap-2 m-0 p-0">
              <Radio
                onClick={handleMarkAllAbsent}
                {...controlProps("b")}
                style={getRadioStyle("b")}
              />
              <label
                onClick={handleMarkAllAbsent}
                htmlFor="markAbsent"
                className="mark-all-checkbox-label"
              >
                Mark all Absent
              </label>
            </div>
            <div className="d-flex align-items-center gap-2 m-0 p-0">
              <Radio
                onClick={handleMarkAllLate}
                {...controlProps("c")}
                style={getRadioStyle("c")}
              />
              <label
                onClick={handleMarkAllLate}
                htmlFor="markPresent"
                className="mark-all-checkbox-label"
              >
                Mark all Late
              </label>
            </div>
          </div>
        </div>
        <div class=" all-student-list-mark-attendance">
          {allStudentsData?.map((student, index) => (
            <div key={index} className="d-flex gap-2">
              <Radio
                {...controlProps("a")}
                style={getRadioStyle("a")}
                checked={
                  !selectedStudentsAbsent.some(
                    (stu) => stu?.studentId === student?.studentId
                  ) &&
                  !selectedStudentsForLate.some(
                    (stu) => stu?.studentId === student?.studentId
                  )
                }
                onChange={() => handleRadioChange(student, "present")}
              />
              <Radio
                {...controlProps("b")}
                style={getRadioStyle("b")}
                checked={selectedStudentsAbsent.some(
                  (stu) => stu?.studentId === student?.studentId
                )}
                onChange={() => handleRadioChange(student, "absent")}
              />
              <Radio
                {...controlProps("c")}
                style={getRadioStyle("c")}
                checked={selectedStudentsForLate.some(
                  (stu) => stu?.studentId === student?.studentId
                )}
                onChange={() => handleRadioChange(student, "late")}
              />
              <label className="mark-all-checkbox-label">
                {`${student?.admissionManagement?.personalData?.firstName} ${student?.admissionManagement?.personalData?.lastName}`}
              </label>
            </div>
          ))}
        </div>
      </div>

      <div className="d-flex align-items-center gap-3 py-3">
        <button
          className="communication-management-cancel-button"
          type="button"
          onClick={() => navigate("/attendance-management/my-classes")}
        >
          Back
        </button>
        <button
          className={`communication-management-submit-button ${
            isSaveDisabled ? "user-save-head-button-disabled" : ""
          }`}
          type="submit"
          onClick={handleUpdateSubmit}
          disabled={isSaveDisabled}
        >
          Save
        </button>
        <button
          className={`communication-management-submit-button ${
            isSaveDisabled ? "user-save-head-button-disabled" : ""
          }`}
          type="submit"
          onClick={handleUpdateNotify}
          disabled={isSaveDisabled}
        >
          Save & Notify
        </button>
      </div>

      <Modal
        className="crm-modal"
        isOpen={isAbsentopen}
        onRequestClose={handleCloseAbsentModal}
        shouldReturnFocusAfterClose={false}
      >
        <div className="modal-Con" style={{ zIndex: "2000" }}>
          <div
            className="col-11 col-md-6 p-3"
            style={{
              height: "auto",
              backgroundColor: theme === "light" ? "#ffffff" : "#212226",
            }}
          >
            <div>
              <div className="flex-sm-row justify-content-between">
                <div className="new-conversation-popup-header">
                  <span className="add-news-current-affair-title-text">
                    Confirm choice
                  </span>

                  <span
                    className="d-flex justify-content-center align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    <IoClose
                      onClick={handleCloseAbsentModal}
                      color={theme === "light" ? "#000000" : "#ffffff"}
                      size={"23px"}
                    />
                  </span>
                </div>

                <p
                  style={{
                    color: "rgba(246, 159, 58, 1)",
                    fontSize: "14px",
                    fontWeight: 400,
                    letterSpacing: "0px",
                    textAlign: "left",
                  }}
                  className="m-0 mt-3"
                >
                  Are you sure you want to mark all as absent for this class?
                </p>

                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    letterSpacing: "0px",
                    textAlign: "left",
                    color: theme === "light" ? "#000000" : "#ffffff",
                  }}
                  className="mt-3"
                >
                  Confirming this action will clear all students list in absent
                  and late to class list and will be updated as per choice.
                </p>
              </div>
            </div>
            <div className="new-conversation-popup-header"></div>
            <div className="d-flex align-items-center gap-3 mt-3">
              <button
                className="communication-management-cancel-button"
                type="button"
                onClick={handleCloseAbsentModal}
              >
                Cancel
              </button>
              <button
                className="communication-management-submit-button "
                type="submit"
                onClick={() => handleMarkAllForAbsent("absent")}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        className="crm-modal"
        isOpen={isOnTimeopen}
        onRequestClose={handleCloseOnTimeModal}
        shouldReturnFocusAfterClose={false}
      >
        <div className="modal-Con" style={{ zIndex: "2000" }}>
          <div
            className="col-11 col-md-6 p-3"
            style={{
              height: "auto",
              backgroundColor: theme === "light" ? "#ffffff" : "#212226",
            }}
          >
            <div className="flex-sm-row justify-content-between">
              <div className="new-conversation-popup-header">
                <span className="add-news-current-affair-title-text">
                  Confirm choice
                </span>

                <span
                  className="d-flex justify-content-center align-items-center"
                  style={{ cursor: "pointer" }}
                >
                  <IoClose
                    onClick={handleCloseOnTimeModal}
                    color={theme === "light" ? "#000000" : "#ffffff"}
                    size={"23px"}
                  />
                </span>
              </div>

              <p
                style={{
                  color: "rgba(246, 159, 58, 1)",
                  fontSize: "14px",
                  fontWeight: 400,
                  letterSpacing: "0px",
                  textAlign: "left",
                }}
                className="mt-3"
              >
                Are you sure you want to mark all late for this class?
              </p>

              <p
                style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  letterSpacing: "0px",
                  textAlign: "left",
                  color: theme === "light" ? "#000000" : "#ffffff",
                }}
                className="mt-3"
              >
                Confirming this action will clear all students list in absent
                and late to class list and will be updated as per choice.
              </p>
            </div>

            <div className="d-flex align-items-center gap-3 mt-3">
              <button
                className="communication-management-cancel-button"
                type="button"
                onClick={handleCloseOnTimeModal}
              >
                Cancel
              </button>
              <button
                className="communication-management-submit-button "
                type="submit"
                onClick={() => handleMarkAllForLate("on time")}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default MarkAttendance;
