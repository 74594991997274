import { useContext, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import useravatar from "assets/images/user-avatar.svg";
import useravatarblack from "assets/images/user-avatar-black.svg";
import "./MyProfile.css";
import LocationLogo from "assets/image-components/LocationLogo";
import UpdatePasswordPopup from "../UpdatePasswordPopup/UpdatePasswordPopup";
import { axiosService } from "services/axios.service";
import { toast } from "react-toastify";
import { HR_MODULE_URLS } from "modules/hr-module/constants/hr-module-urls";
import sessionstorageService from "services/sessionstorage.service";
import { DOCUSIGN_URL } from "modules/my-profile/constants/docusign-urls";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import BetaLogo from "assets/images/Beta CRM_files/Mask group (2).png";
import BetaLogo2 from "assets/images/betaaa header-2.png";
import StudentIDVector from "assets/images/Beta CRM_files/studentIdCardVector1.png";
import { FiPhoneCall } from "react-icons/fi";
import { IoIosMail } from "react-icons/io";
import { IoIosGlobe } from "react-icons/io";
import QRCode from "react-qr-code";
import AgreementLetter from "../AgreementLetter/AgreementLetter";
import betalogoImg from "assets/images/icons/beta.svg";
import { ThemeContext } from "ThemeContext";

const EmployeeIdCard = ({
  data,
  triggerDownload,
  setTriggerDownload,
  profileImage,
}) => {
  useEffect(() => {
    if (triggerDownload === true) {
      generatePDF();
      setTriggerDownload(!triggerDownload);
    }
  }, [triggerDownload]);

  const generatePDF = async () => {
    const DPI = 96;

    const pxToMm = (1 / DPI) * 25.4;

    const htmlWidthPx = 592;
    const htmlHeightPx = 942;

    const widthMm = htmlWidthPx * pxToMm;
    const heightMm = htmlHeightPx * pxToMm;

    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: [widthMm, heightMm],
      allowTaint: false,
    });

    const scale = 1;
    doc.scale(scale);

    const toggleContentVisibility = (contentId, isVisible) => {
      const content = document.getElementById(contentId);
      if (isVisible) {
        content.classList.remove("student-id-card-hidden");
      } else {
        content.classList.add("student-id-card-hidden");
      }
    };

    const captureCanvas1Promise = new Promise((resolve, reject) => {
      toggleContentVisibility("content1", true);
      const content1 = document.getElementById("content1");
      const img = content1.querySelector("img");
      const options = {
        scale: scale,
        useCORS: true,
        allowTaint: false,
      };
      if (img.complete) {
        resolve(html2canvas(content1, options));
      } else {
        img.onload = () => resolve(html2canvas(content1, options));
        img.onerror = reject;
      }
    });

    const captureCanvas1 = await captureCanvas1Promise;

    const captureCanvas2Promise = new Promise((resolve, reject) => {
      toggleContentVisibility("content2", true);
      const content1 = document.getElementById("content2");

      const options = {
        scale: scale,
        useCORS: true,
        allowTaint: false,
      };

      if (content1) {
        resolve(html2canvas(content1, options));
      } else {
      }
    });

    const captureCanvas2 = await captureCanvas2Promise;

    toggleContentVisibility("content1", false);
    toggleContentVisibility("content2", false);

    Promise.all([captureCanvas1, captureCanvas2])
      .then((canvases) => {
        // Page 1
        const imgData1 = canvases[0].toDataURL("image/png");
        doc.addImage(imgData1, "JPG", 0, 0);
        doc.addPage();

        // Page 2
        const imgData2 = canvases[1].toDataURL("image/png");
        doc.addImage(imgData2, "PNG", 0, 0);
        doc.save("example.pdf");
      })
      .catch((error) => {
        console.error("Error during PDF generation:", error);
      });
  };

  //     let value = `Student Name: "${admissionData?.admission?.personalData?.firstName} ${admissionData?.admission?.personalData?.lastName}",
  //   Student ID: "${admissionData?.admission?.personalData?.studentId}",
  //   Program: "${admissionData?.admission?.programDetails[0]?.program}",
  //   Start Date : "${admissionData?.admission?.programDetails[0]?.fromDate}",
  //   Expiry Date : "${admissionData?.admission?.programDetails[0]?.toDate}"`;

  let value = `Employee Name: ${data?.employeeFirstName} ${data?.employeeLastName},
      Employee ID: ${data?.employeeId},
      Employee Type: ${data?.employeeType}`;

  return (
    <div>
      {/* <button onClick={generatePDF}>Generate PDF</button> */}
      <div
        id="content1"
        className="student-id-card-hidden"
        style={{ width: "592px", height: "942px", marginTop: "50px" }}
      >
        <div className="student-id-card-orange-box">
          <div className="student-id-card-white-box"></div>
          <div className="faculty-id-card-designation">
            {data?.employeeType}
          </div>
        </div>
        <div className="student-id-card-black-box"></div>

        {/* <h1>STUDENT ID CARD</h1> */}
        <div
          className="faculty-id-card-name"
          style={{
            color: "#3788d8",
          }}
        >
          {data?.employeeFirstName}{" "}
          {data?.employeeLastName ? data?.employeeLastName : ""}
        </div>

        <div
          style={{
            width: "383px",
            height: "383px",
            overflow: "hidden",
            border: "1px solid black",
            borderRadius: "50%",
            zIndex: "2",
            position: "relative",
            top: "212px",
          }}
          className="faculty-id-card-img-container"
        >
          <img
            src={profileImage}
            alt="Profile"
            width="100%"
            id="employee-id-profile"
          />
        </div>
        <p
          style={{
            fontSize: "26px",
            fontWeight: "600",
            alignSelf: "flex-start",
            marginLeft: "100px",
          }}
          id="facultyID-content1-p1"
        >
          Employee ID: {/* <span style={{ fontWeight: "400" }}> */}
          {/* {admissionData?.admission?.personalData?.firstName}{" "}
                        {admissionData?.admission?.personalData?.lastName} */}
          {/* BCH0202104 */}
          {data?.employeeId}
          {/* </span> */}
        </p>
        <p
          style={{
            fontSize: "26px",
            fontWeight: "600",
            alignSelf: "flex-start",
            marginLeft: "100px",
          }}
          id="facultyID-content1-p2"
        >
          Email: {/* <span style={{ fontWeight: "400" }}> */}
          {/* {admissionData?.admission?.personalData?.studentId} */}
          {data?.email}
          {/* </span> */}
        </p>
        {/* <div
                    style={{
                        fontSize: "26px",
                        fontWeight: "600",
                        alignSelf: "flex-start",
                    }}
                    id="StudentID-content1-program"
                >
                    <div style={{ color: "black", paddingRight: "5px" }}>Program: </div>
                    <div style={{ color: "black", fontWeight: "400" }}>
                        
                        Faculty
                    </div>
                </div> */}
        <img src={BetaLogo} alt="Beta Logo" id="student-Id-beta-logo" />
        <img src={StudentIDVector} alt="Profile" id="FacultyIDVector1" />
        <img src={StudentIDVector} alt="Profile" id="FacultyIDVector2" />
        <img src={StudentIDVector} alt="Profile" id="FacultyIDVector3" />
        <img src={StudentIDVector} alt="Profile" id="FacultyIDVector4" />
        <img src={StudentIDVector} alt="Profile" id="FacultyIDVector5" />
      </div>
      <div
        id="content2"
        className="student-id-card-hidden"
        style={{ width: "592px", height: "942px" }}
      >
        <div className="student-id-card2-div-1">
          <div className="student-id-card-content2-white-box-1"></div>
          <img src={BetaLogo2} alt="Beta Logo" id="faculty-Id-beta-logo-2" />
          <div className="student-id-card-content2-white-box-2"></div>
          <div className="student-id-card2-div-1-innerdiv1">
            <span
              style={{
                fontWeight: "900",
                fontSize: "27.05px",
                textDecoration: "underline",
              }}
            >
              MISSISSAUGA CAMPUS:
            </span>
            <br />
            4275 Village Center Court, Suite 201,
            <br />
            Mississauga, ON L4Z 1V3, Canada.
            <br />
            <span style={{ fontWeight: "900", fontSize: "25.55px" }}>
              <FiPhoneCall /> +1 905 848 5050
            </span>
          </div>
          <div className="student-id-card-content2-white-box-3"></div>
          <div className="student-id-card2-div-1-innerdiv2">
            <span
              style={{
                fontWeight: "900",
                fontSize: "27.05px",
                textDecoration: "underline",
              }}
            >
              BRAMPTON CAMPUS:
            </span>
            <br />
            1 Nelson Street West, Suite 200A,
            <br /> Brampton, ON L6X 3E4, Canada.
            <br />
            <span style={{ fontWeight: "900", fontSize: "25.55px" }}>
              <FiPhoneCall /> +1 905 497 9999
            </span>
          </div>
          {/* <div className="student-id-card2-div-1-innerdiv3">
                        <IoIosGlobe /> mybeta.ca
                        <br />
                        <IoIosMail /> hello@mybeta.ca
                    </div> */}
        </div>
        <div className="faculty-id-card2-div-2">
          <div>
            <IoIosGlobe /> www.mybeta.ca
          </div>
          <div>
            <IoIosMail /> hello@mybeta.ca
          </div>
        </div>
        <div className="student-id-card2-div-3">
          {/* <div>QR</div> */}
          <div
            style={{
              height: "auto",
              margin: "0 auto",
              maxWidth: 100,
              width: "100%",
            }}
          >
            <QRCode
              size={300}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={value}
              viewBox={`0 0 256 256`}
            />
          </div>
          <div>
            This Card is the Property of
            <br /> Beta College of Business & Technology..
            <br />
            If Found Please Return to us Immediatly.
          </div>
        </div>
      </div>
    </div>
  );
};

function MyProfile() {
  const location = useLocation();
  const Data = location.state;
  const user_id = 27;
  const [searchParams, setSearchParams] = useSearchParams();
  const envelopeId = searchParams.get("envelopeId");
  const userData = sessionstorageService.getUserData("");
  const [triggerDownload, setTriggerDownload] = useState(false);
  const [openAddContactPopUp, setOpenAddContactPopUp] = useState(false);
  const [data, setData] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [letterOpen, setLetterOpen] = useState(false);

  const { theme, toggleTheme } = useContext(ThemeContext);

  useEffect(() => {
    const handle = async () => {
      const base64 = await fetch(data?.profileImage)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          return new Promise((res) => {
            reader.onloadend = () => {
              res(reader.result);
              setProfileImage(reader.result);
            };
          });
        });
    };

    if (data?.profileImage) {
      handle();
    }
  }, [data?.profileImage]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (envelopeId && data) {
      callback();
    }
  }, [envelopeId, data]);
  const callback = () => {
    try {
      setLoading(true);
      axiosService
        .get(
          `${DOCUSIGN_URL.HANDLE_CALLBACK_FOR_AGREEMENT}?envelopeId=${envelopeId}&empId=${data?.empId}&recipientEmail=${data?.email}&facultyEmail=${data?.reportsTo?.email}`
        )
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);
            toast.success("Signed Agreement Sent to Mail Id");
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(
            `${error?.response?.data?.message.split(":")[1]?.trim()}`
          );
        });
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  function getEmployeeByUserId() {
    try {
      axiosService
        .get(`${HR_MODULE_URLS.GET_EMPLOYEE_BY_USERID}${userData?.userId}`)
        .then((response) => {
          if (response.status === 200) {
            setData(response?.data?.data);
          }
        })
        .catch((error) => {
          toast.error(
            `${error?.response?.data?.message.split(":")[1]?.trim()}`
          );
        });
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getEmployeeByUserId();
  }, [user_id]);
  return (
    <div className="user-main-container pb-4">
      <div className="user-head-container sticky-header flex-sm-row gap-2">
        <div className="d-flex align-items-center gap-1">
          <span className="user-title-text">My Profile</span>
        </div>
        <div className="d-flex align-items-center gap-3">
          <button
            className="communication-save-head-button faculty-id-card-download-button"
            style={{ marginRight: "10px" }}
            // disabled={!admissionData?.admission?.upload?.passportSizePhoto}
            // onClick={() =>
            //   navigate("/student-portal/view-profile-student", {
            //     state: {
            //       studentId:
            //         studentId ||
            //         userData?.admissionManagement?.personalData?.studentId,
            //     },
            //   })
            // }
            onClick={() => setTriggerDownload(!triggerDownload)}
          >
            Download ID Card
          </button>
          <button
            className="user-save-head-button"
            onClick={() => {
              setOpenAddContactPopUp(true);
            }}
          >
            Change Password
          </button>
        </div>
      </div>

      <div className="snap-con-myprofile">
        <div style={{ display: "flex", gap: "20px", flexDirection: "row" }}>
          <div>
            <img
              alt="img"
              src={
                data?.profileImage
                  ? data?.profileImage
                  : theme === "light"
                  ? useravatarblack
                  : useravatar
              }
              style={{
                height: "95px",
                borderRadius: "50%",
                color: "#283557",
                width: "95px",
              }}
            />
          </div>
          <div className="myprofile-name-container">
            <div>
              {data?.gender === "MALE"
                ? "Mr. "
                : data?.gender === "FEMALE"
                ? "Ms. "
                : ""}
              {data?.employeeFirstName}{" "}
              {data?.employeeLastName ? data?.employeeLastName : ""}
            </div>
            <div>{data?.employeeId}</div>
            <div>{data?.employeeType}</div>
          </div>
        </div>
        <div className="myprofile-address-container" style={{}}>
          <span>
            <LocationLogo />
          </span>
          {data?.residentialAddress}
        </div>
      </div>
      <div className="snap-con gap-2">
        <div className="snap-sub-header">Personal Data</div>
        <div className="row snap-head-con d-flex">
          <div className="sub-head col-6 col-lg-3 sub-head-Con d-flex justify-content-start">
            <span className="snap-sub-title">Gender</span>
            <span className="snap-sub-text"> {data?.gender}</span>
          </div>
          <div className="sub-head col-6 col-lg-3  sub-head-Con d-flex justify-content-start">
            <span className="snap-sub-title">Social Status </span>
            <span className="snap-sub-text">{data?.socialStatus}</span>
          </div>
          <div className="sub-head col-6 col-lg-3  sub-head-Con d-flex justify-content-start">
            <span className="snap-sub-title">Date of Birth</span>
            <span className="snap-sub-text">{data?.dateOfBirth}</span>
          </div>

          <div className="sub-head col-6 col-lg-3  sub-head-Con d-flex justify-content-start">
            <span className="snap-sub-title">Email ID</span>
            <span className="snap-sub-text">{data?.email}</span>
          </div>
        </div>
        <div className="row snap-head-con d-flex">
          <div className="sub-head col-6 col-lg-3  sub-head-Con d-flex justify-content-start">
            <span className="snap-sub-title">Contact Number 1</span>
            <span className="snap-sub-text">{data?.contactNumber1}</span>
          </div>
          <div className="sub-head col-6 col-lg-3 sub-head-Con d-flex justify-content-start">
            <span className="snap-sub-title">Contact Number 2</span>
            <span className="snap-sub-text">{data?.contactNumber2}</span>
          </div>
        </div>
      </div>
      <div className="snap-con gap-2">
        <div className="snap-sub-header">Bank Details</div>
        <div className="row snap-head-con d-flex">
          <div className="sub-head col-6 col-lg-3 sub-head-Con d-flex justify-content-start">
            <span className="snap-sub-title">Account Number</span>
            <span className="snap-sub-text"> {data?.accountNumber}</span>
          </div>
          <div className="sub-head col-6 col-lg-3  sub-head-Con d-flex justify-content-start">
            <span className="snap-sub-title">Bank name</span>
            <span className="snap-sub-text">{data?.bankName}</span>
          </div>
          <div className="sub-head col-6 col-lg-3  sub-head-Con d-flex justify-content-start">
            <span className="snap-sub-title">Bank code</span>
            <span className="snap-sub-text">{data?.bankCode}</span>
          </div>

          {data?.swiftCode && (
            <div className="sub-head col-6 col-lg-3  sub-head-Con d-flex justify-content-start">
              <span className="snap-sub-title">Swift Code</span>
              <span className="snap-sub-text">{data?.swiftCode}</span>
            </div>
          )}
        </div>
      </div>
      <div className="snap-con gap-2" style={{ borderBottom: "0px" }}>
        <div className="snap-sub-header">Employee Agreement</div>
        <div style={{ color: "#777777" }}>
          Kindly Click the{" "}
          <span
            style={{
              color: "#0066CC",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            className="employee-Myprofile-agreement"
            onClick={() => {
              setLetterOpen(true);
              setLoading(true);
            }}
          >
            link
          </span>{" "}
          to sign the agreement
        </div>
      </div>
      {letterOpen && (
        <AgreementLetter
          downloadType="view"
          close={() => {
            setLetterOpen(false);
            setLoading(false);
          }}
          data={data}
        />
      )}

      {openAddContactPopUp && (
        <UpdatePasswordPopup
          open={openAddContactPopUp}
          onClose={() => {
            setOpenAddContactPopUp(false);
          }}
          userId={userData?.userId}
        />
      )}
      <div>
        {profileImage && (
          <EmployeeIdCard
            data={data}
            profileImage={profileImage}
            triggerDownload={triggerDownload}
            setTriggerDownload={setTriggerDownload}
          />
        )}
        {loading && (
          <div className="application-loader">
            <img alt="img" className="blink" src={betalogoImg} />
          </div>
        )}
      </div>
    </div>
  );
}

export default MyProfile;
