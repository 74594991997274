import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosService } from "services/axios.service";
import { EXAM_MODULE_URLS } from "modules/exam-module/constants/exam-modules-urls";
import { ThemeContext } from "ThemeContext";

function AllExams() {
  const location = useLocation();
  const navigate = useNavigate();
  const [examData, setExamData] = useState([]);
  const { theme, toggleTheme } = useContext(ThemeContext);

  useEffect(() => {
    axiosService.get(`${EXAM_MODULE_URLS.GET_ALL_EXAMS}`).then((response) => {
      if (response.status === 200) {
        const responseData = response?.data?.data;
        setExamData(responseData);
      }
    });
  }, [location.pathname]);

  const onRowClicked = (event) => {
    navigate("/exam-module/view-exam", {
      state: event.data,
    });
  };
  const getSectionsLength = (params) => params.data.sections.length;

  const getTotalQuestions = (params) => {
    let totalQuestions = 0;
    params.data.sections.forEach((section) => {
      totalQuestions += section.questions.length;
    });
    return totalQuestions;
  };

  const [columnDefs] = useState([
    {
      headerName: "Module Name",
      field: "module.module.moduleName",
    },
    {
      headerName: "Program Code",
      field: "module.programFeeMaster.programCode",
      width: 165,
    },
    {
      headerName: "Sections",
      valueGetter: getSectionsLength,
      width: 120,
    },
    {
      headerName: "Questions",
      valueGetter: getTotalQuestions,
      width: 130,
    },
    {
      headerName: "Semester",
      field: "module.semester",
      width: 130,
    },
    {
      headerName: "Status",
      field: "status",
      width: 200,
      cellStyle: {
        whiteSpace: "normal",
        overflowWrap: "break-word",
      },
      cellRenderer: function (params) {
        const status = params?.value;
        let statusClass = "";
        switch (status) {
          case "Published":
            statusClass = "ag-grid-application-status-approved";
            break;
          case "Review":
            statusClass = "ag-grid-application-status-pending";
            break;
          default:
            statusClass = "ag-grid-application-status-rejected";
            break;
        }
        return (
          <span
            className={statusClass}
            style={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            {status}
          </span>
        );
      },
    },

    {
      headerName: "Created By",
      field: "addedBy",
    },
    {
      headerName: "Created On",
      field: "addedOn",
      valueFormatter: (params) => {
        if (params.value) {
          const datePart = params.value.split("T")[0];
          return datePart;
        }
        return "";
      },
    },
    // {
    //   headerName: "Action",
    //   field: "view",
    // },
  ]);

  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);

  return (
    <div className="view-leads-table-container">
      <div className="lead-table-head-container sticky-header align-items-center flex-wrap flex-row gap-2">
        <span className="lead-table-title-text">All Exams </span>

        <div className="d-flex align-items-center gap-3">
          <button
            className="communication-save-head-button"
            onClick={() => {
              navigate("/exam-module/create-exam");
            }}
          >
            Add New Exams
          </button>
        </div>
      </div>
      <div
        className={
          theme === "light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"
        }
      >
        <AgGridReact
          columnDefs={columnDefs}
          rowData={examData}
          rowSelection="multiple"
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          suppressRowClickSelection
          gridOptions={gridOptions}
          onRowClicked={onRowClicked}
        />
      </div>
    </div>
  );
}

export default AllExams;
