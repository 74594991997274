import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { useContext, useEffect, useMemo, useState } from "react";
import { RxTriangleDown } from "react-icons/rx";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosService } from "services/axios.service";
import sessionService from "services/sessionstorage.service";
import Select from "react-select";
import { ATTENDANCE_MANAGEMENT_URLS } from "../constants/attendance-management-urls";
import { ADMISSION_URL_FOR_STUDENT } from "modules/pre-onboarding/components/constants/admission-application-student-urls";
import { ThemeContext } from "ThemeContext";
function StudentAttendanceFaculty() {
  const location = useLocation();
  const [classData, setClassData] = useState("");
  const studentData = sessionService.getUserData("userData");
  const { theme, toggleTheme } = useContext(ThemeContext);

  const studentId = studentData?.admissionManagement?.personalData?.studentId;

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      border: "none !important",
      height: "36px !important",
      width: "123px !important",
      backgroundColor: "#f69f3a !important",
      borderRadius: "6px !important",
      color: "#212226 !important",
      boxShadow: "none !important",
    }),
  };

  const [program, setProgram] = useState({ value: "", label: "ALL" });
  const [diplomaOptions, setDiplomaOptions] = useState([]);
  useEffect(() => {
    const fetchDiplomaOptions = async () => {
      axiosService
        .get(`${ADMISSION_URL_FOR_STUDENT.GET_PROGRAM_OPTIONS}`)
        .then((response) => {
          const options = response.data.data;
          const allOption = { value: "", label: "ALL" };
          const updatedOptions = [allOption, ...options];
          setDiplomaOptions(updatedOptions);
        });
    };

    fetchDiplomaOptions();
  }, []);
  const [campus, setCampus] = useState({ value: "", label: "ALL" });
  const CampusOptions = [
    { value: "", label: "ALL" },
    { value: "BRAMPTON", label: "Brampton" },
    { value: "MISSISSAUGA", label: "Mississauga" },
  ];
  const [days, setDays] = useState({ value: 7, label: "Last 7 days" });
  const DaysOption = [
    { value: 7, label: "Last 7 days" },
    { value: 14, label: "Last 14 days" },
    { value: 30, label: "Last 30 days" },
    { value: 60, label: "Last 60 days" },
    { value: 90, label: "Last 90 days" },
    { value: 120, label: "Last 120 days" },
  ];
  const handleCampusChange = (selectedOption) => {
    setCampus(selectedOption);
  };
  const handleProgramChange = (selectedOption) => {
    setProgram(selectedOption);
  };
  const handleDaysChange = (selectedOption) => {
    setDays(selectedOption);
  };
  useEffect(() => {
    let url = `${ATTENDANCE_MANAGEMENT_URLS.GET_BY_STUDENT_ID}/${studentId}?days=${days.value}`;

    if (campus.value) {
      url += `&campus=${campus.value}`;
    }

    if (program.value) {
      url += `&program=${program.value}`;
    }

    axiosService.get(url).then((response) => {
      if (response.status === 200) {
        const responseData = response?.data?.data;
        setClassData(responseData);
      }
    });
  }, [location.pathname, days.value, program.value, campus.value]);
  const CustomIconRenderer = (props) => {
    const attendanceType = props?.data?.attendanceType;
    return (
      <div
        className={`${
          attendanceType === "PRESENT" && "ag-grid-application-status-approved"
        }
        ${attendanceType === "ABSENT" && "ag-grid-application-status-rejected"}
        ${attendanceType === "LATE" && "ag-grid-application-status-pending"}
        `}
      >
        {attendanceType}
      </div>
    );
  };

  const [columnDefs] = useState([
    {
      headerName: "Class Name",
      field: "schedule.module.module.moduleName",
      // cellStyle: { "text-decoration": "underline" },
      width: 160,
    },
    {
      headerName: "Program Name",
      field: "schedule.module.programFeeMaster.program",
      width: 300,
      cellStyle: {
        whiteSpace: "normal",
        wordWrap: "break-word",
        overflow: "hidden",
      },
    },
    {
      headerName: "Campus",
      field: "schedule.module.programFeeMaster.campus",
      width: 130,
    },
    {
      headerName: "Date",
      field: "schedule.startDate",
      width: 140,
    },
    {
      headerName: "Time",
      valueGetter: mergeStartEndTime,
      width: 150,
    },

    {
      headerName: "Semester",
      field: "schedule.module.semester",
      width: 130,
    },

    {
      headerName: "Present/Absent",
      width: 170,
      cellRenderer: CustomIconRenderer,
      suppressRowClickSelection: true,
    },
  ]);

  function mergeStartEndTime(params) {
    const startTime = params.data.startTime;
    const endTime = params.data.endTime;
    return `${startTime} - ${endTime}`;
  }

  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);

  const filterSelects = (
    <>
      <div className="d-flex align-items-center gap-1">
        <span class="ag-header-cell-text">Campus </span>
        <Select
          id="campus"
          value={campus}
          onChange={handleCampusChange}
          options={CampusOptions}
          styles={selectStyles}
          isSearchable={true}
          classNamePrefix="react-select-att"
          components={{
            DropdownIndicator: () => <RxTriangleDown color="#000000" />,
            IndicatorSeparator: null,
          }}
          placeholder="ALL"
          menuPlacement="auto"
          menuPosition="fixed"
        />
      </div>

      <div className="d-flex align-items-center gap-1">
        <span class="ag-header-cell-text">Program Name </span>
        <Select
          id="program"
          value={program}
          onChange={handleProgramChange}
          options={diplomaOptions}
          styles={selectStyles}
          isSearchable={true}
          classNamePrefix="react-select-att"
          components={{
            DropdownIndicator: () => <RxTriangleDown color="#000000" />,
            IndicatorSeparator: null,
          }}
          placeholder="ALL"
          menuPlacement="auto"
          menuPosition="fixed"
        />
      </div>

      <div className="d-flex align-items-center gap-1">
        <span class="ag-header-cell-text">Date </span>
        <Select
          id="days"
          value={days}
          onChange={handleDaysChange}
          options={DaysOption}
          styles={selectStyles}
          isSearchable={false}
          classNamePrefix="react-select-att"
          components={{
            DropdownIndicator: () => <RxTriangleDown color="#000000" />,
            IndicatorSeparator: null,
          }}
          placeholder="ALL"
          menuPlacement="auto"
          menuPosition="fixed"
        />
      </div>
    </>
  );

  const [isFiltersVisible, setIsFiltersVisible] = useState(false);

  const handleToggleFilters = () => {
    setIsFiltersVisible(!isFiltersVisible);
  };

  return (
    <div className="view-leads-table-container">
      <div className="lead-table-head-container sticky-header align-items-center flex-row flex-wrap gap-2">
        <div className=" d-flex align-items-center gap-2">
          <button
            className="d-md-none p-0"
            style={{ border: "none", background: "transparent" }}
            onClick={handleToggleFilters}
          >
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.4369 9H8.56315C8.06351 9 7.8114 9.60623 8.16542 9.96026L12.5 14.2955V19.125C12.5 19.3085 12.5896 19.4805 12.7399 19.5858L14.6149 20.8979C14.9848 21.1568 15.5 20.8944 15.5 20.437V14.2955L19.8347 9.96026C20.188 9.60694 19.9376 9 19.4369 9Z"
                fill="#F69F3A"
              />
              <circle cx="14" cy="14" r="13.5" stroke="#F69F3A" />
            </svg>
          </button>
          <span className="lead-table-title-text">My Attendance</span>
        </div>

        <div
          className="d-flex d-md-none align-items-center gap-3 flex-wrap"
          style={
            isFiltersVisible
              ? { height: "auto" }
              : { height: 0, overflow: "hidden" }
          }
        >
          {filterSelects}
        </div>
        <div className="d-none d-md-flex align-items-center gap-3 flex-wrap">
          {filterSelects}
        </div>
      </div>

      <div
        className={
          theme === "light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"
        }
      >
        <AgGridReact
          columnDefs={columnDefs}
          rowData={classData}
          rowSelection="multiple"
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          suppressRowClickSelection
          gridOptions={gridOptions}
        />
      </div>
    </div>
  );
}

export default StudentAttendanceFaculty;
